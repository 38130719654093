import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Clipboard from '@react-native-clipboard/clipboard'
import { Share, SMS } from '@utils'
import { NavigationContainer } from '@src/screens/Common/containers'
import { referralConfigSelector, referralUrlSelector } from '@src/selectors/app'
import { useSnack } from '@src/utils/navigatorContext'
import { ReferralComponent } from '../components/Referral'

export const ReferralContainer = () => {
  const { message, title, description = '' } = useSelector(referralConfigSelector)
  const referralUrl = useSelector(referralUrlSelector) ?? ''
  const showSnack = useSnack()
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [emailIsTouched, setEmailIsTouched] = useState(false)
  const [loading, setLoading] = useState(false)

  const onChangeText = (email: string) => {
    setEmail(email)
  }

  const onBlurText = () => {
    setEmailIsTouched(true)
  }

  const sendInvite = () => {
    if (loading) {
      return
    }
    setLoading(true)

    dispatch({
      type: 'app/sendUserInvite',
      payload: {
        email,
      },
      success: () => {
        setEmail('')
        setEmailIsTouched(false)
        setLoading(false)
        showSnack('Invite Sent!')
      },
      failure: () => {
        setLoading(false)
        showSnack('Send Failed!', null, 'error')
      },
    })
  }

  const sendMessage = (url: string) => {
    SMS.sms({ message: `${message} ${url}` })
  }

  const share = (url: string) => {
    Share.share({ url, message })
  }

  const copy = (text: string) => {
    Clipboard.setString(text)
    showSnack('Link copied!')
  }

  return (
    <NavigationContainer title="Refer a Friend">
      <ReferralComponent
        email={email}
        emailIsTouched={emailIsTouched}
        title={title}
        description={description}
        referralUrl={referralUrl}
        onChangeText={onChangeText}
        onBlurText={onBlurText}
        sendInvite={sendInvite}
        sendMessage={sendMessage}
        copy={copy}
        share={share}
      />
    </NavigationContainer>
  )
}
