import { gql } from '@apollo/client'

export const CREATE_CHART_NOTE = gql`
  mutation createEhrChartNote(
    $data: JSON!
    $kind: EhrChartNoteKind!
    $getHealthieInstanceKind: EhrGetHealthieInstanceKind!
    $appointmentId: ID
    $legacyAppointmentId: ID
  ) {
    createEhrChartNote(
      data: $data
      kind: $kind
      getHealthieInstanceKind: $getHealthieInstanceKind
      appointmentId: $appointmentId
      legacyAppointmentId: $legacyAppointmentId
    ) {
      success
    }
  }
`
