import defaultReducers from '@src/models/defaultReducers'
import { InsurancePolicyCollection } from '@src/types'
import { InsuranceStatusStoreState } from './insuranceStatus.types'

export const reducers = {
  ...defaultReducers,
  updateInsurancePolicies: (
    state: InsuranceStatusStoreState,
    { payload }: { payload: InsurancePolicyCollection },
  ) => {
    return {
      ...state,
      insurancePolicies: payload,
    }
  },
}

export default reducers
