import React from 'react'
import { View } from 'react-native'
import Svg, { Circle, Defs, LinearGradient, Path, Stop } from 'react-native-svg'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useArcIndicator } from '@src/hooks/useArcIndicator'
import { Text } from '@src/components/base'
const { cos, sin } = Math

export const ARC_INDICATOR_PROPERTIES = { width: 170, height: 135, strokeWidth: 17 }
const ARC_WIDTH_PADDING = 15
const ARC_HEIGHT_PADDING = 10
const ARC_COEFFICIENT = 1.3
const MIN_PROGRESS_THRESHOLD = 0.01

export interface GlucoseArcChartProps {
  title: string
  timestamp: string
  value?: number
  minValue: number
  maxValue: number
}

export const GlucoseArcChart = ({
  title,
  value = undefined,
  timestamp,
  minValue,
  maxValue,
}: GlucoseArcChartProps) => {
  const styles = useStyleSheet(themedStyles)

  const { width, height, strokeWidth } = ARC_INDICATOR_PROPERTIES

  const layoutWidth = width + ARC_WIDTH_PADDING
  const layoutHeight = height + ARC_HEIGHT_PADDING
  const { arcPath, centerX, centerY, startAngle, radius, arcIndicatorAngleSize } = useArcIndicator(
    width,
    height,
    layoutWidth,
    strokeWidth,
    ARC_COEFFICIENT,
  )

  const effectiveValue = value ?? 0
  const clampedValue = Math.max(minValue, Math.min(effectiveValue, maxValue))

  // in case the progress is less than the threshold, we want to show a minimum progress
  const progress = Math.max(
    (clampedValue - minValue) / (maxValue - minValue),
    MIN_PROGRESS_THRESHOLD,
  )

  // Calculate the angle corresponding to the current progress
  const angle = startAngle - arcIndicatorAngleSize * progress

  // Calculate the point's position
  const pointX = centerX + radius * cos(angle)
  const pointY = centerY - radius * sin(angle)

  return (
    <View style={styles.container}>
      <Svg width={layoutWidth} height={layoutHeight}>
        <Defs>
          <LinearGradient
            id="grad"
            x1="75.1691"
            y1="114.384"
            x2="75.1694"
            y2="26.8777"
            gradientUnits="userSpaceOnUse"
          >
            <Stop offset="0.0981328" stopColor="#E14F62" />
            <Stop offset="0.27518" stopColor="#FB9434" />
            <Stop offset="0.664685" stopColor="#FAC53B" />
            <Stop offset="1" stopColor="#55BC88" />
          </LinearGradient>
        </Defs>
        <Path
          stroke="url(#grad)"
          fill="none"
          strokeLinecap="round"
          {...{
            strokeWidth,
            d: arcPath,
          }}
        />

        <Circle cx={pointX} cy={pointY} r={15} fill="white" />
        <Circle cx={pointX} cy={pointY} r={10} fill="black" />
      </Svg>
      <View style={styles.infoContainer}>
        <Text type="score" style={styles.value}>
          {value ?? 'N/A'}
        </Text>
        <Text type="regular" bold style={styles.title}>
          {title}
        </Text>
      </View>
      <Text type="tiny" style={styles.timestamp}>
        {timestamp}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  timestamp: {
    textAlign: 'center',
    color: 'theme.text.tertiary',
  },
  value: {
    textAlign: 'center',
  },
  infoContainer: {
    position: 'absolute',
    top: 60,
    left: 0,
    right: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
