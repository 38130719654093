import React, { useState, useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ReactNativeFile } from '@src/utils/image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollableAvoidKeyboard } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { userSelector } from '@selectors/app'
import { useSnack } from '@utils'
import { ProfileContent } from '@src/screens/Profile/components/ProfileContent'
import { Text } from '@src/components/base'

export const Profile = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const insets = useSafeAreaInsets()

  const user = useSelector(userSelector)

  const [photoSource, setPhotoSource] = useState<ReactNativeFile | { uri: string }>({
    uri: user?.avatar || '',
  })

  const onChangeProfilePhoto = useCallback(
    (photo: ReactNativeFile) => {
      const currentPhotoSource = photoSource
      setPhotoSource(photo)
      dispatch({
        payload: { photo },
        type: 'users/updatePhoto',
        success: () => showSnack('Photo saved!'),
        failure: () => {
          showSnack('Photo saving failed!', null, 'error')
          setPhotoSource(currentPhotoSource)
        },
      })
    },
    [dispatch, photoSource, showSnack],
  )

  if (!user) {
    return null
  }

  const { fullName } = user

  const content = (
    <ProfileContent
      onChangeProfilePhoto={onChangeProfilePhoto}
      photoSource={photoSource}
      userFullName={fullName}
    />
  )

  const rightAccessories = [
    {
      renderIconComponent: () => (
        <Text type="large" bold style={styles.editAccount}>
          Edit
        </Text>
      ),
      onPress: () => navigation.navigate('EditAccountDetails'),
    },
  ]

  return (
    <NavigationContainer
      title="Account"
      isDrawerScreen
      rightAccessories={rightAccessories}
      showLoadingIndicator
    >
      <ScrollableAvoidKeyboard
        style={styles.container}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ paddingBottom: (insets.bottom || 0) + 16 }}
      >
        {content}
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  editAccount: {
    marginRight: 16,
    color: 'theme.text.link',
  },
  profilePhoto: {
    alignSelf: 'center',
    marginTop: 16,
  },
  profileName: {
    alignSelf: 'center',
    marginBottom: 24,
  },
  changeText: {
    marginLeft: 16,
    color: 'theme.text.link',
  },
  address: {
    flexShrink: 1,
  },
  textGrow: {
    flex: 1,
  },
  optedIn: {
    marginTop: 4,
    color: 'theme.success.darkest',
  },
  optedOut: {
    marginTop: 4,
    color: 'theme.disabled.text',
  },
})
