import React from 'react'
import { View } from 'react-native'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Divider } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, InlineAlert, TouchableSection, useFeatureFlag } from '@components'
import { Text, Icon, Pill } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { openUrl } from '@src/utils'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'
import {
  BillingProduct,
  InsuranceBerryStreetAppointmentChargeType,
  UiInlineAlert,
} from '@src/types'
import { CustomEventTypes } from '@src/config'
import { userPropertiesSelector } from '@selectors/app.ts'
import { useFeeProduct } from '@screens/NutritionistHub/hooks/useFeeProduct.ts'
import Storage from '@utils/storage.ts'
import { insurancePolicySelector } from '../models/nutritionistHub.selectors'
import { VIDEO_CALL_POLICY_URL } from '../constants'
import { useNutritionistHubTracker } from '../hooks'
import { VideoCallDetailsModal } from './VideoCallDetailsModal'

export const VideoCallConfirmedModal = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<RouteProp<AppStackParamList, 'VideoCallConfirmedModal'>>()
  const {
    id,
    title,
    nutritionistName,
    date,
    addToGoogleCalendarLink,
    unauthenticatedIcsLink,
    appointmentType,
    chargeType,
  } = route.params
  const navigation = useNavigation()
  const { showActionSheet } = useApplicationActionSheet()
  const track = useNutritionistHubTracker()
  const calendarTypes = {
    'Google Calendar': addToGoogleCalendarLink,
    iCal: unauthenticatedIcsLink,
    Outlook: unauthenticatedIcsLink,
  }
  const insurancePolicy = useSelector(insurancePolicySelector)

  const callsOccurred = insurancePolicy?.unitsOccurred ?? undefined
  const callsAuthorized = insurancePolicy?.unitsAuthorized ?? undefined

  const isCallWithin24h = moment(date).diff(moment(), 'hours') < 24
  const isCallWithin3Days = moment(date).diff(moment(), 'days') < 3
  const { videoCallRescheduleFreePassUsed } = useSelector(userPropertiesSelector) ?? {}
  const videoCallFeesEnabled = useFeatureFlag(Feature.VideoCallFees)
  const videoCallRetentionEnabled = useFeatureFlag(Feature.VideoCallRetention)
  const lateCancellationFeeProduct = useFeeProduct(BillingProduct.VideoCallLateCancellationFee)

  // convert 1 to 1st, 2 to 2nd, etc.
  const callOrdinalNumber =
    callsOccurred !== undefined
      ? moment()
          .dayOfYear(callsOccurred + 1)
          .format('DDDo')
      : undefined
  const isInsuranceCovered =
    chargeType === InsuranceBerryStreetAppointmentChargeType.InsuranceCovered
  const shouldShowCallsCountMessage =
    isInsuranceCovered &&
    callsAuthorized !== undefined &&
    callsAuthorized > 0 &&
    callsOccurred !== undefined &&
    callsOccurred >= 0

  const checkInCompleted = Storage.get<boolean>(
    `${Storage.VIDEO_CALL_CHECK_IN_COMPLETED_KEY}_${id}`,
    false,
  )

  const isAddToCalendarDisabled = !addToGoogleCalendarLink

  return (
    <VideoCallDetailsModal
      title={title}
      nutritionistName={nutritionistName}
      date={date}
      isCallConfirmed
    >
      {shouldShowCallsCountMessage && (
        <InlineAlert
          category={UiInlineAlert.Info}
          message={`This is your ${callOrdinalNumber} insurance-covered video call. You have ${
            callsAuthorized - (callsOccurred + 1)
          } remaining this year.`}
          style={styles.infoContainer}
        />
      )}
      {!!lateCancellationFeeProduct?.price && (
        <Text type="small" style={styles.cancelationPolicyContainer}>
          Reschedule or cancel up to 24 hours before your call at no charge. Changes within 24 hours
          or missed calls will incur a ${lateCancellationFeeProduct.price} fee per{' '}
          <Text
            type="small"
            bold
            onPress={() => openUrl(VIDEO_CALL_POLICY_URL)}
            style={styles.link}
          >
            our policy
          </Text>
          .
        </Text>
      )}

      <Divider appearance="large" style={styles.divider} />

      <View style={styles.actionOptionsContainer}>
        {videoCallRetentionEnabled && isCallWithin3Days && (
          <TouchableSection
            onPress={() => {
              const screen = checkInCompleted ? 'VideoCallCheckInAddendum' : 'VideoCallCheckIn'
              navigation.navigate(screen, { appointmentId: id })
            }}
            iconName="paper-plane-tilt"
            title={
              checkInCompleted ? 'Add extra information to Call Check In' : 'Complete Call Check In'
            }
            style={styles.actionOption}
            labelStyle={styles.actionOptionText}
          />
        )}

        <TouchableSection
          disabled={isAddToCalendarDisabled}
          onPress={() => {
            track(CustomEventTypes.NutritionistHubAddToCalendarTapped)
            showActionSheet({
              title: 'Select calendar',
              options: Object.keys(calendarTypes),
              callback: (selectedOption) => {
                const selectedOptionLink =
                  calendarTypes[selectedOption as keyof typeof calendarTypes]
                if (selectedOptionLink) {
                  openUrl(selectedOptionLink)
                }
              },
            })
          }}
          iconName="calendar-plus"
          accessoryLeft={
            <Icon
              name="calendar-plus"
              style={[styles.icon, isAddToCalendarDisabled && styles.disabledIcon]}
            />
          }
          title="Add to Calendar"
          style={[styles.actionOption, isAddToCalendarDisabled && styles.actionOptionDisabledText]}
          labelStyle={[
            styles.actionOptionText,
            isAddToCalendarDisabled && styles.actionOptionDisabledText,
          ]}
          tagComponent={
            isAddToCalendarDisabled && (
              <Pill text="Available in a few minutes" status="warning" size="s" />
            )
          }
        />

        <TouchableSection
          onPress={() => {
            track(CustomEventTypes.NutritionistHubRescheduleAppointmentTapped)
            if (videoCallFeesEnabled && isCallWithin24h && isInsuranceCovered) {
              if (videoCallRescheduleFreePassUsed?.value === 'true') {
                navigation.navigate('LateRescheduleVideoCallModal', {
                  videoCallId: id,
                  appointmentType,
                })
              } else {
                navigation.navigate('FreePassLateRescheduleVideoCallModal', {
                  videoCallId: id,
                  appointmentType,
                  nutritionistName,
                })
              }
            } else {
              navigation.navigate('CallScheduling', {
                appointmentType,
                appointmentId: id,
                allowBackNavigation: true,
              })
            }
          }}
          iconName="pencil"
          title="Reschedule Appointment"
          style={styles.actionOption}
          labelStyle={styles.actionOptionText}
        />

        <TouchableSection
          onPress={() => {
            track(CustomEventTypes.NutritionistHubCancelAppointmentTapped)
            if (videoCallFeesEnabled && isCallWithin24h && isInsuranceCovered) {
              if (videoCallRescheduleFreePassUsed?.value === 'true') {
                navigation.navigate('LateRescheduleOrCancelVideoCallModal', {
                  videoCallId: id,
                  appointmentType,
                  nutritionistName,
                })
              } else {
                navigation.navigate('FreePassLateRescheduleOrCancelVideoCallModal', {
                  videoCallId: id,
                  appointmentType,
                  nutritionistName,
                })
              }
            } else {
              navigation.navigate('RescheduleOrCancelVideoCallModal', {
                videoCallId: id,
                appointmentType,
                nutritionistName,
              })
            }
          }}
          accessoryLeft={<Icon name="x" style={styles.actionOptionCancelIcon} />}
          title="Cancel Appointment"
          style={styles.actionOption}
          labelStyle={styles.actionOptionCancelText}
        />
      </View>
    </VideoCallDetailsModal>
  )
}

const themedStyles = StyleService.create({
  link: {
    color: 'theme.text.link',
  },
  cancelationPolicyContainer: {
    marginTop: 12,
    color: 'theme.text.secondary',
  },
  infoContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
  actionOptionsContainer: {
    marginHorizontal: 8,
    marginTop: 8,
  },
  actionOption: {
    paddingHorizontal: 0,
  },
  actionOptionText: {
    color: 'theme.text.primary',
    fontWeight: '400',
  },
  actionOptionDisabledText: {
    color: 'theme.disabled.text',
    minWidth: 112,
  },
  icon: {
    marginRight: 12,
  },
  disabledIcon: {
    color: 'theme.disabled.text',
  },
  actionOptionCancelText: {
    color: 'theme.range.bad',
    fontWeight: '400',
  },
  actionOptionCancelIcon: {
    tintColor: 'theme.range.bad',
    height: 24,
    width: 24,
    marginRight: 12,
  },
  divider: {
    marginHorizontal: -16,
    marginTop: 24,
  },
})
