import { gql } from '@apollo/client'

export const APPOINTMENT_FIELDS = gql`
  fragment AppointmentFields on Appointment {
    id
    meetingAt
    meetingStatus
    status
    title
    nutritionistName
    addToGoogleCalendarLink
    unauthenticatedIcsLink
    appointmentType
    chargeType
    recurrent
  }
`
