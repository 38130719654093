import { EVA_ICONS, EvaPack, EvaIcon, evaIcon } from './eva'
import { MATERIAL_ICONS, MaterialPack, MaterialIcon, materialIcon } from './material'
import {
  MATERIAL_COMMUNITY_ICONS,
  MaterialCommunityPack,
  MaterialCommunityIcon,
  materialCommunityIcon,
} from './materialCommunity'
import { Base } from './base'

type PackType = typeof EVA_ICONS | typeof MATERIAL_ICONS | typeof MATERIAL_COMMUNITY_ICONS
type IconNames = EvaIcon | MaterialIcon | MaterialCommunityIcon

type IconPackPair = {
  [key in Base]: {
    pack: PackType
    name: IconNames
  }
}

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export type IconPackKey = keyof IconPackPair

/**
 * @deprecated
 */
export const IconPackMap: IconPackPair = {
  // Eva Pack
  [Base.Gift]: evaIcon(EvaPack.Gift),
  [Base.Grid]: evaIcon(EvaPack.Grid),
  [Base.AlertCircleOutline]: evaIcon(EvaPack.AlertCircleOutline),
  [Base.AlertTriangle]: evaIcon(EvaPack.AlertTriangle),
  [Base.Menu]: evaIcon(EvaPack.Menu),
  [Base.Plus]: evaIcon(EvaPack.Plus),
  [Base.PlusCircleOutline]: evaIcon(EvaPack.PlusCircleOutline),
  [Base.SyncOutline]: evaIcon(EvaPack.SyncOutline),
  [Base.FlashOutline]: evaIcon(EvaPack.FlashOutline),
  [Base.FlashOffOutline]: evaIcon(EvaPack.FlashOffOutline),
  [Base.Checkmark]: evaIcon(EvaPack.Checkmark),
  [Base.ChevronUp]: evaIcon(EvaPack.ChevronUp),
  [Base.ChevronDown]: evaIcon(EvaPack.ChevronDown),
  [Base.CreditCardOutline]: evaIcon(EvaPack.CreditCardOutline),
  [Base.EditOutline]: evaIcon(EvaPack.EditOutline),
  [Base.RadioButtonOnOutline]: evaIcon(EvaPack.RadioButtonOnOutline),
  [Base.Options2]: evaIcon(EvaPack.Options2),
  [Base.Options2Outline]: evaIcon(EvaPack.Options2Outline),
  [Base.BarChart]: evaIcon(EvaPack.BarChart),
  [Base.Star]: evaIcon(EvaPack.Star),
  [Base.SwapOutline]: evaIcon(EvaPack.SwapOutline),
  [Base.PieChart2]: evaIcon(EvaPack.PieChart2),
  [Base.BookOpen]: evaIcon(EvaPack.BookOpen),
  [Base.Person]: evaIcon(EvaPack.Person),
  [Base.Settings]: evaIcon(EvaPack.Settings),
  [Base.People]: evaIcon(EvaPack.People),
  [Base.Home]: evaIcon(EvaPack.Home),
  [Base.ListOutline]: evaIcon(EvaPack.ListOutline),
  [Base.MoreVertical]: evaIcon(EvaPack.MoreVertical),
  [Base.Heart]: evaIcon(EvaPack.Heart),
  [Base.HeartOutline]: evaIcon(EvaPack.HeartOutline),
  [Base.Share]: evaIcon(EvaPack.Share),
  [Base.Copy]: evaIcon(EvaPack.Copy),
  [Base.Calendar]: evaIcon(EvaPack.Calendar),
  [Base.Edit2Outline]: evaIcon(EvaPack.Edit2Outline),
  [Base.PlayCircle]: evaIcon(EvaPack.PlayCircle),
  [Base.StopCircle]: evaIcon(EvaPack.StopCircle),
  [Base.MessageSquareOutline]: evaIcon(EvaPack.MessageSquareOutline),
  [Base.LogOut]: evaIcon(EvaPack.LogOut),
  [Base.Clock]: evaIcon(EvaPack.Clock),
  [Base.ClockOutline]: evaIcon(EvaPack.ClockOutline),
  [Base.TrendingUp]: evaIcon(EvaPack.TrendingUp),
  [Base.Lock]: evaIcon(EvaPack.Lock),
  [Base.RadioButtonOn]: evaIcon(EvaPack.RadioButtonOn),
  [Base.Repeat]: evaIcon(EvaPack.Repeat),
  [Base.Download]: evaIcon(EvaPack.Download),
  [Base.TrashOutline]: evaIcon(EvaPack.TrashOutline),
  [Base.Trash]: evaIcon(EvaPack.Trash),
  [Base.Trash2]: evaIcon(EvaPack.Trash2),
  [Base.ArrowIOSForward]: evaIcon(EvaPack.ArrowIOSForward),
  [Base.ArrowIOSBack]: evaIcon(EvaPack.ArrowIOSBack),
  [Base.CheckmarkCircle2]: evaIcon(EvaPack.CheckmarkCircle2),
  [Base.CheckmarkCircle2Outline]: evaIcon(EvaPack.CheckmarkCircle2Outline),
  [Base.BookOpenOutline]: evaIcon(EvaPack.BookOpenOutline),
  [Base.Image]: evaIcon(EvaPack.Image),
  [Base.Redeem]: evaIcon(EvaPack.Redeem),
  [Base.TextSms]: evaIcon(EvaPack.TextSms),
  [Base.MoreHoriz]: evaIcon(EvaPack.MoreHoriz),
  [Base.ArrowIOSDownload]: evaIcon(EvaPack.ArrowIOSDownload),
  [Base.EyeOff]: evaIcon(EvaPack.EyeOff),
  [Base.Eye]: evaIcon(EvaPack.Eye),
  [Base.Info]: evaIcon(EvaPack.Info),
  [Base.InfoOutline]: evaIcon(EvaPack.InfoOutline),
  [Base.CloseOutline]: evaIcon(EvaPack.CloseOutline),
  [Base.Close]: evaIcon(EvaPack.Close),
  [Base.Search]: evaIcon(EvaPack.Search),
  [Base.Link]: evaIcon(EvaPack.Link),
  [Base.ShoppingCart]: evaIcon(EvaPack.ShoppingCart),
  [Base.Email]: evaIcon(EvaPack.Email),
  [Base.EmailOutline]: evaIcon(EvaPack.EmailOutline),
  [Base.Facebook]: evaIcon(EvaPack.Facebook),
  [Base.CameraOutline]: evaIcon(EvaPack.CameraOutline),
  [Base.TrendingUpOutline]: evaIcon(EvaPack.TrendingUpOutline),
  [Base.DiagonalArrowRightUpOutline]: evaIcon(EvaPack.DiagonalArrowRightUpOutline),
  [Base.DiagonalArrowRightDownOutline]: evaIcon(EvaPack.DiagonalArrowRightDownOutline),
  [Base.Minus]: evaIcon(EvaPack.Minus),
  // Material Pack
  [Base.Timeline]: materialIcon(MaterialPack.Timeline),
  [Base.RadioButtonChecked]: materialIcon(MaterialPack.RadioButtonChecked),
  [Base.Create]: materialIcon(MaterialPack.Create),
  [Base.DirectionsWalk]: materialIcon(MaterialPack.DirectionsWalk),
  [Base.Restaurant]: materialIcon(MaterialPack.Restaurant),
  [Base.Tune]: materialIcon(MaterialPack.Tune),
  [Base.Receipt]: materialIcon(MaterialPack.Receipt),
  [Base.History]: materialIcon(MaterialPack.History),
  [Base.ChevronLeft]: materialIcon(MaterialPack.ChevronLeft),
  [Base.ChevronRight]: materialIcon(MaterialPack.ChevronRight),
  [Base.Edit]: materialIcon(MaterialPack.Edit),
  [Base.Delete]: materialIcon(MaterialPack.Delete),
  [Base.Fullscreen]: materialIcon(MaterialPack.Fullscreen),
  [Base.ExpandMore]: materialIcon(MaterialPack.ExpandMore),
  // MaterialCommunity Pack
  [Base.BarcodeScan]: materialCommunityIcon(MaterialCommunityPack.BarcodeScan),
  [Base.Instagram]: materialCommunityIcon(MaterialCommunityPack.Instagram),
}
