import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackNavigationProp } from '@react-navigation/stack'
import { useTheme } from '@ui-kitten/components'
import moment from 'moment'
import { useIsEligibleForResearchSensor } from '@src/screens/Dexcom/Research/hooks/useIsEligibleForDexcomResearch'
import { AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { openUrl, Storage, useGoBack } from '@src/utils'
import { NavigationContainer } from '@screens/Common/containers'
import { researchFaqsSelector, userSelector } from '@src/selectors/app'
import { Button, Text } from '@components/base'
import { ScrollViewWithFade } from '@src/components'
import { TutorialGroups } from '@src/screens/Tutorials/models/tutorials.types'
import { UpgradeToDexcomSensorBanner } from '@src/screens/Events/components/Banners/UpgradeToDexcomSensorBanner'
import { useIsDarkMode } from '@src/config/theme'

const FADE_BUFFER = 24
export const ScanMigrationGuide = () => {
  const isEligibleForDexcomResearch = useIsEligibleForResearchSensor()

  const user = useSelector(userSelector)
  const researchFaqs = useSelector(researchFaqsSelector)
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const { params: { nextScreen } = {} } = useRoute<
    RouteProp<AppStackParamList, 'ScanMigrationGuide'>
  >()

  const goBack = useGoBack()
  const isDarkMode = useIsDarkMode()
  const theme = useTheme()

  const handleExitPress = () => {
    if (nextScreen?.screen) {
      return navigation.navigate(nextScreen.screen as any)
    }

    goBack()
  }

  const handleStartMigrationTutorialPress = () => {
    navigation.navigate('TutorialsGroup', {
      group: TutorialGroups.LibreLinkup,
    })
  }

  const handleScanMigrationFAQUrl = () => {
    openUrl(researchFaqs.disableLibreScanUrl)
  }

  const onRejectPress = () => {
    Storage.set(
      `${Storage.LIBRE_MIGRATION_SWITCH_REJECTED_KEY}_${user?.id}`,
      moment().toISOString(),
    )
    handleExitPress()
  }

  const iconColor = isDarkMode ? theme['theme.solid.white'] : theme['theme.primary.base']

  return (
    <NavigationContainer
      title="Migration Guide"
      leftIcon={{ pack: 'eva', name: 'close' }}
      goBack={handleExitPress}
    >
      <SafeAreaView style={styles.content} edges={['bottom']}>
        <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
          <View style={styles.textBodyContainer}>
            <Text type="regular" style={styles.textBody}>
              Moving forward, you will need to scan in the Libre App to populate your glucose data
              in our app.
            </Text>
          </View>
          <View style={styles.textBodyContainer}>
            <Text type="regular" style={styles.textBody}>
              In the next few steps, we will be walking you through how to migrate over to the new
              way of syncing your glucose data into the Nutrisense app.
            </Text>
          </View>
          <View style={styles.textBodyContainer}>
            <Text type="regular" bold style={styles.textBody}>
              Migrate by Nov 29th to{' '}
              <Text
                type="regular"
                onPress={handleScanMigrationFAQUrl}
                style={styles.migrationFAQLinkStyle}
              >
                avoid disruptions in your glucose tracking
              </Text>
            </Text>
          </View>
        </ScrollViewWithFade>
        <View style={styles.buttons}>
          {isEligibleForDexcomResearch && (
            <View style={styles.upgradeBanner}>
              <UpgradeToDexcomSensorBanner
                containerStyle={styles.dexcomBanner}
                textStyle={styles.dexcomBannerTextStyle}
                iconStyle={{ color: iconColor } as ViewStyle}
              />
            </View>
          )}
          <Button
            accessibilityLabel="Start Migration Tutorial"
            onPress={handleStartMigrationTutorialPress}
            style={styles.nextButton}
            size="block"
            type="primary"
          >
            Switch Sensor
          </Button>
          <Button
            accessibilityLabel="Remind me later"
            type="outline"
            size="block"
            textStyle={styles.secondaryButtonText}
            onPress={onRejectPress}
          >
            Remind me later
          </Button>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },
  textBodyContainer: {
    marginBottom: 16,
  },
  textBody: {
    textAlign: 'center',
  },
  migrationFAQLinkStyle: {
    color: 'theme.text.link',
  },
  dexcomBanner: {
    backgroundColor: 'theme.background.light_',
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    marginHorizontal: 0,
  },
  dexcomBannerTextStyle: {
    color: 'theme.text.primary',
  },
  buttons: {
    gap: 8,
  },
  upgradeBanner: {
    marginBottom: 16,
  },
  nextButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
  secondaryButtonText: {
    textAlign: 'center',
    maxWidth: 203,
  },
})
