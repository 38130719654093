import { useEffect } from 'react'
import { useGoBack, useSnack } from '@src/utils'

const CameraContainer = () => {
  const goBack = useGoBack()
  const showSnack = useSnack()

  useEffect(() => {
    showSnack('Camera is not available on this platform', null, 'error')
    goBack()
  })

  return null
}

export default CameraContainer
