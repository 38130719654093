import React from 'react'
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { noop } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  HistoryScreen,
  EventsScreen,
  LearnScreen,
  NutritionistHubScreen,
  InsightsScreen,
} from '@src/screens'
import { IconName } from '@components/base'
import { TabBarIcon } from '@src/navigation/components/TabBarIcon'
import { TabBarLabel } from '@src/navigation/components/TabBarLabel'
import { TabBarCustomItem } from '@src/navigation/components/TabBarCustomItem'
import { useNotifications } from '@src/components/notifications/utils/hooks'
import { MobileAppFeature, NotificationEngineScreen } from '@src/types'
import { Feature, useFeatureFlag } from '@src/components'
import { Analytics, CustomEventTypes } from '@config'
import { Storage, User } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'
import {
  HomeScreenWalkthroughSteps,
  useHomeScreenWalkthrough,
} from '@src/context/homeScreenWalkthroughContext'
import { TabParamList } from '../types'
import { TabBarIconWalkthroughContainer } from '../components/TabBarItemWithWalkthrough'

const customNavigationOptions = (params: {
  label: string
  iconName?: IconName
  largeItem?: boolean
  redDot?: boolean
  walkthroughProps?: {
    shouldShowWalkthrough: boolean
    isStepVisible: boolean
    onPress: () => void
  }
}) => (): BottomTabNavigationOptions => {
  const { label, iconName, largeItem = false, redDot = false, walkthroughProps } = params

  const { isStepVisible = false, onPress = noop, shouldShowWalkthrough = false } =
    walkthroughProps || {}

  return {
    tabBarIcon:
      largeItem || !iconName
        ? undefined
        : ({ focused }) =>
            // we need to render the TabBarIcon with the Walkthrough component so that
            // press event not to be stolen by the Walkthrough component
            // so based on shouldShow, we render the TabBarIcon with or without the Walkthrough component
            shouldShowWalkthrough && isStepVisible ? (
              <TabBarIconWalkthroughContainer
                isStepVisible={isStepVisible}
                onPress={onPress}
                iconName={iconName}
                label={label}
              />
            ) : (
              <TabBarIcon focused={focused} iconName={iconName} redDot={redDot} />
            ),
    tabBarButton: largeItem ? () => <TabBarCustomItem /> : undefined,
    tabBarLabel: largeItem
      ? undefined
      : ({ focused }) => <TabBarLabel focused={focused} name={label} />,
    tabBarLabelPosition: 'below-icon',
    title: largeItem ? undefined : label,
    tabBarTestID: largeItem ? 'TabBar/PlusButton' : `TabBar/${label}`,
    headerShown: false,
  }
}

export const TAB_NAVIGATION_BAR_HEIGHT = 56

const Tab = createBottomTabNavigator<TabParamList>()

export const TabNavigator = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const {
    shouldShow: shouldShowWalkthrough,
    advanceWalkthroughToNextStep,
    isStepVisible,
  } = useHomeScreenWalkthrough()

  const insightsRedDot = useNotifications(NotificationEngineScreen.Insights).length !== 0
  const [nutritionistHubRedDot] = useStorageValue(Storage.NUTRITIONIST_HUB_RED_DOT)

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)
  const areDailyActionsEnabled = useFeatureFlag(Feature.UserDailyActions)

  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const initialRouteName = isCGMFeatureAvailable ? 'Events' : 'NutritionistHub'

  return (
    <Tab.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        tabBarStyle: [styles.tabBar, { height: TAB_NAVIGATION_BAR_HEIGHT + insets.bottom }],
      }}
    >
      <Tab.Screen
        name="Events"
        component={EventsScreen}
        options={customNavigationOptions({
          label: 'Home',
          iconName: 'house-simple',
        })}
      />

      <Tab.Screen
        name="History"
        component={HistoryScreen}
        options={customNavigationOptions({
          label: areDailyActionsEnabled ? 'Events' : 'History',
          iconName: 'stack',

          walkthroughProps: {
            shouldShowWalkthrough,
            isStepVisible: isStepVisible(HomeScreenWalkthroughSteps.EventsTab),
            onPress: () => {
              Analytics.track(CustomEventTypes.HomeScreenWalkthroughEventsTabTapped)
              advanceWalkthroughToNextStep()
            },
          },
        })}
      />
      <Tab.Screen
        name="Insights"
        component={InsightsScreen}
        options={customNavigationOptions({
          label: insightsTitle,
          iconName: 'trend-up',
          redDot: insightsRedDot,
        })}
      />
      <Tab.Screen
        name="NutritionistHub"
        component={NutritionistHubScreen}
        listeners={{
          tabPress: () => Analytics.track(CustomEventTypes.BaseBarNutritionistHubTapped),
        }}
        options={customNavigationOptions({
          label: 'Nutritionist',
          iconName: 'users',
          redDot: !!nutritionistHubRedDot,
        })}
      />
      <Tab.Screen
        name="Learn"
        component={LearnScreen}
        options={customNavigationOptions({
          label: 'Learn',
          iconName: 'book-open',
        })}
      />
    </Tab.Navigator>
  )
}

const themedStyle = StyleService.create({
  tabBar: {
    backgroundColor: 'theme.background',
    borderTopColor: 'theme.surface.base1',
    borderTopWidth: 1,
  },
})
