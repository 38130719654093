import React, { useCallback, useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  appointmentRecurrenceSelector,
  insurancePolicySelector,
  pastAppointmentsSelector,
  upcomingAppointmentsSelector,
} from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { Button } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { isInsuranceEligible } from '@screens/NutritionistHub/utils.ts'

export const AllVideoCalls = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const upcomingAppointments = useSelector(upcomingAppointmentsSelector)
  const pastAppointments = useSelector(pastAppointmentsSelector)
  const appointmentRecurrence = useSelector(appointmentRecurrenceSelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const [upcomingSelected, setUpcomingSelected] = useState(true)

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchPastAppointments' })
    dispatch({ type: 'nutritionistHub/fetchAppointmentRecurrence' })
  }, [dispatch])

  useFocusEffect(
    useCallback(() => {
      dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
    }, [dispatch]),
  )

  const onUpsertRecurrence = () => {
    if (!insurancePolicy) {
      return
    }

    navigation.navigate('UpsertRecurrence', { appointmentRecurrence, insurancePolicy })
  }

  const upsertButtonText = appointmentRecurrence ? ' Edit Recurrence' : 'Schedule a Recurring Call'

  return (
    <NavigationContainer
      title="Video calls"
      style={{ flexGrow: 1, paddingBottom: insets?.bottom || 0 }}
    >
      <View style={styles.buttons}>
        <Button
          accessibilityLabel="upcomingCalls"
          type={upcomingSelected ? 'primary' : 'outline'}
          size="small"
          onPress={() => setUpcomingSelected(true)}
        >
          Upcoming
        </Button>
        <Button
          accessibilityLabel="pastCalls"
          type={upcomingSelected ? 'outline' : 'primary'}
          size="small"
          onPress={() => setUpcomingSelected(false)}
        >
          Past
        </Button>
      </View>
      <ScrollView style={styles.container}>
        <View style={styles.callsContainer}>
          {(upcomingSelected ? upcomingAppointments : pastAppointments).map((appointment) => (
            <CallCard key={appointment.id} appointment={appointment} />
          ))}
        </View>
      </ScrollView>
      {upcomingSelected && isInsuranceEligible(insurancePolicy) && (
        <Button
          type="primary"
          size="block"
          style={styles.recurrenceButton}
          onPress={onUpsertRecurrence}
          accessibilityLabel={upsertButtonText}
        >
          {upsertButtonText}
        </Button>
      )}
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 16,
  },
  callsContainer: {
    paddingHorizontal: 16,
    rowGap: 24,
  },
  recurrenceButton: {
    margin: 16,
  },
})
