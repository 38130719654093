import { Model } from '@src/models'
import FETCH_INSURANCE_POLICIES from '../graphql/fetchInsurancePolicies'
import CREATE_INSURANCE_POLICY from '../graphql/createInsurancePolicy'
import reducers from './insuranceStatus.reducers'

export default class InsuranceStatus {
  namespace = 'insuranceStatus'

  state = {
    ...Model.defaultState,
    insurancePolicies: [],
  }

  effects = {
    fetchInsurancePolicies: Model.buildEffect({
      name: `${this.namespace}/fetchInsurancePolicies`,
      query: FETCH_INSURANCE_POLICIES,
      caching: false,
      dataPath: 'insurancePolicies',
      reducers: [{ name: 'updateInsurancePolicies' }],
    }),
    createInsurancePolicy: Model.buildEffect({
      name: `${this.namespace}/createInsurancePolicy`,
      query: CREATE_INSURANCE_POLICY,
      caching: false,
      dataPath: 'createInsurancePolicy',
    }),
  }

  reducers = reducers
}
