import { gql } from '@apollo/client'

export const UPSERT_DAILY_ACTION = gql`
  mutation upsertDailyAction($kind: UserDailyActionKind!, $status: UserDailyActionStatus!) {
    upsertDailyAction(kind: $kind, status: $status) {
      id
      title
      description
      status
      kind
      autocomplete
      category
      createdAt
      updatedAt
    }
  }
`
