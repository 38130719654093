import { useSelector } from 'react-redux'
import { userSelector } from '@src/selectors/app'
import { SurveysConfigKind, User } from '@src/types'
import { Feature, useFeatureFlag } from '@src/components'
import { lastFinishedSurveyOfKind } from '@src/screens/Questionnaire/utils'
import { usePrescriptionFlowStep, PrescriptionFlowStep } from './usePrescriptionFlowStep'
import { useResearchFlowStep, ResearchFlowStep } from './useResearchFlowStep'

export enum ApprovalFlowStep {
  Intro,
  CgmPrerequisiteSurvey,
  PrescriptionEligibilitySurvey,
  ResearchEligibilitySurvey,
  ResearchConsentSurvey,
  PendingApproval,
  Rejected,
  Complete,
}

const FINISHED_SURVEY_STATES = [
  ApprovalFlowStep.ResearchConsentSurvey,
  ApprovalFlowStep.Complete,
  ApprovalFlowStep.Rejected,
  ApprovalFlowStep.PendingApproval,
]

const getApprovalFlowStep = (
  prescriptionStep: PrescriptionFlowStep,
  researchStep: ResearchFlowStep,
) => {
  switch (prescriptionStep) {
    case PrescriptionFlowStep.Unknown:
    case PrescriptionFlowStep.EligibilitySurvey:
      return ApprovalFlowStep.PrescriptionEligibilitySurvey
    case PrescriptionFlowStep.Rejected:
      return ApprovalFlowStep.Rejected
    case PrescriptionFlowStep.PendingApproval:
      // After completing HQ, the user's prescription approval request will enter the PendingApproval state;
      // however, if they selected dexcom, then we can and should force them into the research approval flow.
      break
    case PrescriptionFlowStep.Skipped:
    case PrescriptionFlowStep.Complete:
    case PrescriptionFlowStep.NotApplicable:
      // Defer to the research flow state
      break
  }

  switch (researchStep) {
    case ResearchFlowStep.EligibilitySurvey:
      return ApprovalFlowStep.ResearchEligibilitySurvey
    case ResearchFlowStep.ConsentSurvey:
      return ApprovalFlowStep.ResearchConsentSurvey
    case ResearchFlowStep.PendingApproval:
      return ApprovalFlowStep.PendingApproval
    // Since all research users still need to complete HQ, they can safely
    // fallback to a prescription sensor if they are rejected for research.
    case ResearchFlowStep.Rejected:
    case ResearchFlowStep.Withdrawn:
    case ResearchFlowStep.Unknown:
    case ResearchFlowStep.NotApplicable:
    case ResearchFlowStep.Complete:
      if (prescriptionStep === PrescriptionFlowStep.PendingApproval) {
        // The user has already completed or is not required to do the research flow,
        // but they are still required to receive prescription approval.
        return ApprovalFlowStep.PendingApproval
      }
      return ApprovalFlowStep.Complete
  }
}

const getPrerequisiteStep = (approvalStep: ApprovalFlowStep, user: User) => {
  const surveyLinks = user?.surveyLinks || []
  const lastFinishedCgmPrerequisiteSurvey = lastFinishedSurveyOfKind(
    SurveysConfigKind.CgmPrerequisite,
    surveyLinks,
  )

  // Users who have not completed the CGM Prerequisite Survey should be forced to complete it
  // unless they finished a legacy survey which contained the same questions.
  if (!lastFinishedCgmPrerequisiteSurvey && !FINISHED_SURVEY_STATES.includes(approvalStep)) {
    return ApprovalFlowStep.CgmPrerequisiteSurvey
  }
}

const getApprovalStep = (
  prescriptionStep: PrescriptionFlowStep,
  researchStep: ResearchFlowStep,
  user: User,
  isSeparateEnrollmentEnabled: boolean,
) => {
  const approvalFlowStep = getApprovalFlowStep(prescriptionStep, researchStep)
  const prerequisiteStep = getPrerequisiteStep(approvalFlowStep, user)

  return isSeparateEnrollmentEnabled && prerequisiteStep ? prerequisiteStep : approvalFlowStep
}

export const useApprovalFlow = () => {
  const user = useSelector(userSelector)
  const isSeparateEnrollmentEnabled = useFeatureFlag(Feature.SeparateEnrollment)
  const prescriptionStep = usePrescriptionFlowStep()
  const researchStep = useResearchFlowStep()
  const approvalStep = getApprovalStep(
    prescriptionStep,
    researchStep,
    user as User,
    isSeparateEnrollmentEnabled,
  )

  const isPrescriptionRequired = prescriptionStep === PrescriptionFlowStep.EligibilitySurvey
  const isResearchRequired =
    researchStep === ResearchFlowStep.EligibilitySurvey ||
    researchStep === ResearchFlowStep.ConsentSurvey

  const isApprovalRequired =
    prescriptionStep !== PrescriptionFlowStep.Skipped &&
    (isPrescriptionRequired || isResearchRequired)

  return {
    approvalStep,
    isPrescriptionRequired,
    isResearchRequired,
    isApprovalRequired,
  }
}
