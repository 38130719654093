import React from 'react'
import { findIndex } from 'lodash'
import { IndexPath, Select as KittenSelect, SelectItem } from '@ui-kitten/components'
import { MultiSelectProps } from './MultiSelect.types.ts'
import { SelectOption } from './CommonSelect.types'

export const MultiSelect = ({
  options,
  selectedOptions,
  onSelect,
  ...restProps
}: MultiSelectProps) => {
  const initialIndexes = selectedOptions?.map((selectedOption) =>
    findIndex(
      options,
      (o: SelectOption) => o.value === selectedOption?.value || o.text === selectedOption?.text,
    ),
  )

  const indexPaths = initialIndexes
    ? initialIndexes.map((initialIndex) => new IndexPath(initialIndex))
    : undefined

  const generatedOptions = options?.map((option) => (
    <SelectItem key={option.value} title={option.text} />
  ))

  const handleOnSelect = (index: IndexPath | IndexPath[]) => {
    const currentIndexes = Array.isArray(index) ? index : [index]
    onSelect(currentIndexes.map((currentIndex) => options?.[currentIndex.row]))
  }

  return (
    <KittenSelect
      {...restProps}
      multiSelect
      value={selectedOptions?.map((option) => option.text).join(', ')}
      selectedIndex={indexPaths}
      onSelect={handleOnSelect}
    >
      {generatedOptions}
    </KittenSelect>
  )
}
