import React from 'react'
import moment from 'moment'
import { ActivityType, ICON_NAME_BY_ACTIVITY_TYPE } from '@src/screens/Events/models/events.types'
import { ListItemProps } from '@src/components/list/ListItemFactory'
import { useMenuHandling } from '@src/components/list/MenuHandler'
import { ListItemWithScore } from '@src/components/list/common/ListItemWithScore'
import { Activity } from '@src/types'

const TIME_FORMAT = 'h:mm a'

type ActivityListItemProps = ListItemProps<Activity>

export const ActivityListItem = ({ item, highlighted, canJumpToDate }: ActivityListItemProps) => {
  const { defaultMenuItems, onItemEdit } = useMenuHandling(item, canJumpToDate)
  const { title, type, occurredAt, description, externalSource } = item

  const iconName = ICON_NAME_BY_ACTIVITY_TYPE[type as ActivityType]

  return (
    <ListItemWithScore
      title={title}
      subtitle={description}
      info={moment(occurredAt).format(TIME_FORMAT)}
      iconName={iconName}
      menuItems={defaultMenuItems}
      tag={externalSource}
      tagStatus="secondary"
      onItemPress={onItemEdit}
      numberOfLines={1}
      highlighted={highlighted}
    />
  )
}
