/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Rect, Path, Defs, ClipPath, G } from 'react-native-svg'

export const NSLogoGift = () => (
  <Svg width={105} height={108} viewBox="0 0 105 108" fill="none">
    <Rect
      width={76.5607}
      height={76.8885}
      rx={16.6444}
      transform="matrix(1 0 0.000704 1 0.966797 0.00888062)"
      fill="#11494F"
    />
    <G clipPath="url(#clip0_1802_5129)">
      <Path
        d="M28.31 21.2083C22.6931 21.2083 18.7279 25.6912 17.509 28.8043C17.3871 29.0534 17.2042 29.4892 16.8378 29.3647C16.3493 29.2402 16.5931 28.6175 16.7758 28.0572C17.3242 26.4384 17.4454 25.1308 17.4444 23.8233L17.443 21.7064H11.5819L11.6043 53.5847H17.4654L17.4549 38.704C17.4496 31.108 22.3305 26.4384 26.8484 26.4384C30.9389 26.4384 34.0541 28.493 34.0593 35.9023L34.0718 53.5847H39.9328L39.9194 34.657C39.9129 25.3176 35.5142 21.2083 28.31 21.2083Z"
        fill="white"
      />
      <Path
        d="M40.6795 42.502C40.9898 49.5376 45.8162 54.0828 54.3025 54.0828C62.8499 54.0828 68.0363 49.9112 68.0318 43.436C68.0281 38.2059 63.8747 35.7155 55.4488 34.7815C48.6715 34.0344 47.5105 32.6646 47.509 30.6099C47.5075 28.493 49.3377 26.4384 54.283 26.4384C59.7777 26.4384 61.1232 29.7383 61.3078 31.7929H67.0468C66.6152 25.8157 62.3383 21.2083 54.4625 21.2083C45.9151 21.2083 41.645 26.2516 41.6483 31.0458C41.6513 35.2174 44.2181 39.0154 52.3998 39.8871C59.5435 40.6341 62.1694 41.5058 62.1714 44.3698C62.1729 46.4246 60.5261 48.8527 54.543 48.8527C48.4377 48.8527 46.4813 44.9302 46.4185 42.502H40.6795Z"
        fill="white"
      />
    </G>
    <Path
      d="M77.9981 106.5C92.0884 106.5 103.5 95.0298 103.5 80.8943C103.5 66.7588 92.0884 55.2886 77.9981 55.2886C63.9079 55.2886 52.4966 66.7588 52.4966 80.8943C52.4966 95.0298 63.9079 106.5 77.9981 106.5Z"
      fill="#7DDE86"
      stroke="white"
      strokeWidth="3"
    />
    <Path
      d="M88.0057 81.37V90.4097C88.0057 90.676 87.9003 90.9315 87.7128 91.1199C87.5252 91.3082 87.2709 91.4141 87.0056 91.4141H69.0045C68.7392 91.4141 68.4849 91.3082 68.2973 91.1199C68.1098 90.9315 68.0044 90.676 68.0044 90.4097V81.37"
      fill="#CBFED0"
    />
    <Path
      d="M88.0057 81.37V90.4097C88.0057 90.676 87.9003 90.9315 87.7128 91.1199C87.5252 91.3082 87.2709 91.4141 87.0056 91.4141H69.0045C68.7392 91.4141 68.4849 91.3082 68.2973 91.1199C68.1098 90.9315 68.0044 90.676 68.0044 90.4097V81.37"
      stroke="#11494F"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M89.0059 75.3436H67.0045C66.4521 75.3436 66.0044 75.7933 66.0044 76.348V80.3656C66.0044 80.9203 66.4521 81.37 67.0045 81.37H89.0059C89.5582 81.37 90.006 80.9203 90.006 80.3656V76.348C90.006 75.7933 89.5582 75.3436 89.0059 75.3436Z"
      fill="#CBFED0"
      stroke="#11494F"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M78.0049 75.3436V91.4141"
      stroke="#11494F"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M83.6674 73.9249C82.2424 75.3436 78.0049 75.3436 78.0049 75.3436C78.0049 75.3436 78.0049 71.0875 79.4174 69.6562C79.981 69.0901 80.7454 68.7721 81.5424 68.7721C82.3394 68.7721 83.1038 69.0901 83.6674 69.6562C84.231 70.2223 84.5476 70.99 84.5476 71.7906C84.5476 72.5911 84.231 73.3588 83.6674 73.9249V73.9249Z"
      stroke="#11494F"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M72.3421 73.9249C73.7671 75.3436 78.0046 75.3436 78.0046 75.3436C78.0046 75.3436 78.0046 71.0875 76.5921 69.6562C76.0285 69.0901 75.2641 68.7721 74.4671 68.7721C73.6701 68.7721 72.9057 69.0901 72.3421 69.6562C71.7785 70.2223 71.4619 70.99 71.4619 71.7906C71.4619 72.5911 71.7785 73.3588 72.3421 73.9249V73.9249Z"
      stroke="#11494F"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Defs>
      <ClipPath id="clip0_1802_5129">
        <Rect
          width={56.5318}
          height={32.9769}
          fill="white"
          transform="matrix(1 0 0.000704 1 11.5815 21.2083)"
        />
      </ClipPath>
    </Defs>
  </Svg>
)
