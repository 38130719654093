import { Model } from '@models'
import commonReducers from '@src/models/commonReducers'
import { CREATE_REFILL_CONSENT } from '../graphql/createRefillConsent'
import { FETCH_LEGAL_POLICY_CONSENT_STATUSES } from '../graphql/legalPolicyConsentStatuses'
import { ACCEPT_LEGAL_POLICY } from '../graphql/acceptLegalPolicy'
import { PolicyConsentsStoreState } from '../types'

export default class PolicyConsents {
  namespace = 'policyConsents'

  state = {
    refillConsentNeeded: false,
    statuses: [],
    ...Model.defaultState,
  }

  effects = {
    acceptRefillConsent: Model.buildEffect({
      name: `${this.namespace}/acceptRefillConsent`,
      query: CREATE_REFILL_CONSENT,
      dataPath: 'createRefillConsent',
      reducers: [{ name: 'setRefillConsentNeeded', payload: () => false }],
    }),
    statuses: Model.buildEffect({
      name: `${this.namespace}/statuses`,
      query: FETCH_LEGAL_POLICY_CONSENT_STATUSES,
      dataPath: 'legalPolicyConsentStatuses',
      reducers: [{ name: 'fetchList', dataPath: 'statuses', storePath: 'statuses' }],
    }),
    accept: Model.buildEffect({
      name: `${this.namespace}/accept`,
      query: ACCEPT_LEGAL_POLICY,
      dataPath: 'acceptLegalPolicy',
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    fetchList: commonReducers.fetchList,

    setRefillConsentNeeded: (
      state: PolicyConsentsStoreState,
      { payload }: { payload: boolean },
    ) => ({
      ...state,
      refillConsentNeeded: payload,
    }),
  }
}
