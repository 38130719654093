import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { BottomSheet } from '@components'
import { Icon, Text } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { useProducts } from '@screens/Marketplace/utils/hooks'
import { BillingProduct, Product } from '@src/types'
import { useGoBack } from '@utils'

export const UnlockNutritionistSupport = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()

  const { activeSubscriptions, dietitianAddons } = useProducts()

  const nutritionistSupportProduct = dietitianAddons.find(
    (product) => product.key === BillingProduct.NutrisenseDietitian,
  ) as Product | undefined

  const onUpgradePress = () => {
    if (activeSubscriptions.length === 0 || !nutritionistSupportProduct) {
      navigation.replace('Subscriptions')
      return
    }

    navigation.replace('SubscriptionCheckout', {
      product: nutritionistSupportProduct,
    })
  }

  const goToNutritionistHub = () => {
    navigation.navigate('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
  }

  useEffect(() => {
    dispatch({ type: 'app/fetchProducts' })
  }, [dispatch])

  return (
    <BottomSheet
      primaryButton={{ text: 'Upgrade', onPress: onUpgradePress }}
      secondaryButton={{ text: 'Close', onPress: goBack }}
      buttonDirection="column-reverse"
    >
      <Text type="title-2" style={styles.title}>
        Unlock Expert Nutritionist Coaching
      </Text>
      <Text type="regular" style={styles.centeredText}>
        Upgrade your plan to gain access to our nutrition experts. You can achieve this by either
        purchasing unlimited nutritionist coaching or by booking a video call in the{' '}
        <Text type="regular" bold style={styles.link} onPress={goToNutritionistHub}>
          Nutritionist Hub
        </Text>{' '}
        ($0 out of pocket with most insurance if eligible).
      </Text>
      {nutritionistSupportProduct && (
        <View style={styles.productOption}>
          <Text type="regular" bold lineSpacing="none">
            Unlimited Nutrition Support
          </Text>
          <Text type="small" bold style={styles.cardSubtitle}>
            <Text type="large">${nutritionistSupportProduct.price}</Text>
            /month
          </Text>
          {nutritionistSupportProduct.details?.map((text) => (
            <View style={styles.productDetailRow} key={text}>
              <Icon name="check" style={styles.itemIcon} />
              <Text type="regular" style={styles.itemText}>
                {text}
              </Text>
            </View>
          ))}
        </View>
      )}
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    maxWidth: 260,
    marginTop: 24,
    marginBottom: 24,
    textAlign: 'center',
    alignSelf: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
  link: {
    color: 'theme.text.link',
  },
  productOption: {
    width: '100%',
    padding: 20,
    marginVertical: 32,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base3',
  },
  cardSubtitle: {
    marginTop: 8,
    marginBottom: 16,
    color: 'theme.text.secondary',
  },
  productDetailRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  itemIcon: {
    flexShrink: 0,
    marginRight: 12,
    color: 'theme.success.base',
  },
  itemText: {
    flexShrink: 1,
  },
})
