import React from 'react-native'
import { ByosDelaySyncInfo } from './Popups/ByosDelaySyncInfo'
import { DexcomDataSyncPopup } from './Popups/DexcomDataSyncPopup'
import { DexcomSwitchPopup } from './Popups/DexcomSwitchPopup'
import { ExpiringSensorPopup } from './Popups/ExpiringSensorPopup'
import { MissingDexcomIntegrationPopup } from './Popups/MissingDexcomIntegrationPopup'
import { SensorStatusPopup } from './Popups/SensorStatusPopup'
import { LinkupDelayPopup } from './Popups/LinkupDelayPopup'

export const InformationPopups = () => {
  return (
    <>
      <LinkupDelayPopup />
      <MissingDexcomIntegrationPopup />
      <DexcomDataSyncPopup />
      <DexcomSwitchPopup />
      <ByosDelaySyncInfo />
      <SensorStatusPopup />
      <ExpiringSensorPopup />
    </>
  )
}
