import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@src/components/base'
import { useHomeScreenWalkthrough } from '@src/context/homeScreenWalkthroughContext'
import { HomeScreenWalkthroughGraphicLight } from '@src/assets/svgs/homeScreenWalkthroughGraphic.light'
import { useIsDarkMode } from '@src/config/theme'
import { HomeScreenWalkthroughGraphicDark } from '@src/assets/svgs/homeScreenWalkthroughGraphic.dark'
import { useGoBack } from '@src/utils'
import { CustomEventTypes } from '@src/config/analytics'
import { Analytics } from '@src/config'

export const HomeScreenWalkthroughModal = () => {
  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()
  const { advanceWalkthroughToNextStep } = useHomeScreenWalkthrough()
  const isDarkMode = useIsDarkMode()

  return (
    <View style={styles.container}>
      <View style={styles.modalOverlay}>
        <View style={styles.modalContent}>
          <View>
            {isDarkMode ? (
              <HomeScreenWalkthroughGraphicDark />
            ) : (
              <HomeScreenWalkthroughGraphicLight />
            )}
          </View>
          <Text type="regular" style={styles.message}>
            The tools you need are now at your fingertips. Personalized to stay on track with your
            health journey!
          </Text>
          <Button
            style={styles.button}
            type="primary"
            size="block"
            accessibilityLabel="close-modal"
            onPress={() => {
              Analytics.track(CustomEventTypes.HomeScreenWalkthroughSeeWhatsNewTapped)
              advanceWalkthroughToNextStep()
              goBack()
            }}
          >
            See What’s New
          </Button>
        </View>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalOverlay: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: 'rgba(0, 0, 0, 0.5)' as 'theme.transparent',
  },
  modalContent: {
    backgroundColor: 'theme.background.modal',
    borderRadius: 10,
    alignItems: 'center',
  },
  button: {
    height: 48,
    margin: 40,
  },
  message: {
    textAlign: 'center',
    maxWidth: 235,
    marginTop: 36,
  },
})
