import { createSelector } from 'reselect'
import moment from 'moment'
import { RootStoreState } from '@src/models/app.types'
import { calendarDateSelector, historyFiltersTypesSelector } from '@src/selectors/app'
import { eventsCalendarSelector } from './events'

export const historyStoreStateSelector = (state: RootStoreState) => state.history

export const historyObjectsSelector = createSelector(
  historyStoreStateSelector,
  (history) => history.history,
)

export const historyObjectsForDateSelector = createSelector(
  [historyObjectsSelector, eventsCalendarSelector],
  (history, calendar) =>
    history.filter((event) => moment(event.occurredAt).isSame(calendar.startDate, 'day')),
)

export const historySortSelector = createSelector(
  [historyStoreStateSelector],
  (history) => history.sort,
)

export const historySelector = createSelector(
  [historyStoreStateSelector, calendarDateSelector, historyFiltersTypesSelector],
  (history, calendar, filters) => ({
    sort: history.sort,
    items: history.history,
    calendar,
    filters,
  }),
)
