import moment from 'moment'
import { useGetAllOwnSensorSubscriptions } from '@src/hooks/useHasOwnSensorSubscription'
import { Feature, useFeatureFlag } from '@src/components'

// This hook is shared between popups and Ui overlay on the Glucose Timeline graph
export const useShowByosDataInfoUi = () => {
  const allOwnSensorSubscriptions = useGetAllOwnSensorSubscriptions()
  const byosOnboardingHomepageEnabled = useFeatureFlag(Feature.ByosOnboardingHomepage)

  // only true if flag is enabled
  if (!byosOnboardingHomepageEnabled) {
    return false
  }

  // only true for first-time BYOS users
  if (allOwnSensorSubscriptions.length !== 1) {
    return false
  }

  // only true if BYOS subscription is less than 1 day old
  const oneDayAgo = moment().subtract(1, 'days')
  if (moment(allOwnSensorSubscriptions[0].subscribedAt).isBefore(oneDayAgo)) {
    return false
  }

  return true
}
