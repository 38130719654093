import React from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export interface OrderSummaryProps {
  price: number
  estimatedTax: number
  discount: number
  chargeDate: string
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const styles = useStyleSheet(themedStyle)
  const { price, discount, estimatedTax, chargeDate } = props
  const total = Math.max(price - discount + estimatedTax, 0).toFixed(2)
  const chargeDateMoment = moment(chargeDate, 'MM/DD/YYYY')
  const chargeToday = chargeDateMoment.isSame(moment(), 'day')
  const todayTotal = chargeToday ? `$${total}` : '$0'

  return (
    <View style={styles.container}>
      <Text type="regular" bold lineSpacing="none" style={styles.title}>
        Order Summary
      </Text>
      <View style={styles.row}>
        <Text type="regular">Order Total</Text>
        <Text type="regular">${price}</Text>
      </View>
      {discount > 0 && (
        <View style={styles.row}>
          <Text type="regular">Discount</Text>
          <Text type="regular">-${discount}</Text>
        </View>
      )}
      <View style={styles.row}>
        <Text type="regular">Estimated Tax</Text>
        <Text type="regular">${estimatedTax}</Text>
      </View>
      <View style={styles.row}>
        <Text type="regular">Amount charged today</Text>
        <Text type="regular">{todayTotal}</Text>
      </View>
      {!chargeToday && (
        <View style={styles.row}>
          <Text type="regular">Amount charged on {chargeDate}</Text>
          <Text type="regular">${total}</Text>
        </View>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  row: {
    marginBottom: 4,
    flexDirection: 'row',
    algnItems: 'center',
    justifyContent: 'space-between',
  },
})
