import { omit } from 'lodash'
import { DEFAULT_CLIENT_CONFIG } from '@src/fallbacks/fetchClientConfig'
import { transform } from '@src/transforms/clientConfig'
import { Model } from '@models'
import { RootStoreState } from '@src/models/app.types'
import { TutorialKey } from '@screens/Education/types'
import Storage from '@src/utils/storage'
import { MobileAppFeature, UiStateNames, UserRole } from '@src/types'
import { EventsStoreState } from '@src/screens/Events/models/events.types'
import { DailyActionsStoreState } from '@src/screens/DailyActions/models/dailyActions.types'

type OldRootStoreState = RootStoreState & {
  dashboard: any
}

type OldRootStoreStateV41 = Omit<RootStoreState, 'events'> & {
  events: EventsStoreState & DailyActionsStoreState
}

const clearCaches = (state: RootStoreState): RootStoreState => {
  const result = { ...state }
  Object.keys(state).forEach((key) => {
    result[key]['cache'] = {}
  })
  return result
}

const clearClientConfigCache = (state: RootStoreState): RootStoreState => {
  return clearCaches({
    ...state,
    app: {
      ...state.app,
      clientConfig: transform(DEFAULT_CLIENT_CONFIG).clientConfig,
    },
  })
}

const INITIAL_STATE: any = {}

export const migrations: { [key: string]: (state: any) => RootStoreState } = {
  1: (state: OldRootStoreState) =>
    omit(
      {
        ...state,
        events: {
          ...state.dashboard,
        },
      },
      'dashboard',
    ),
  2: (state: RootStoreState) =>
    ({
      ...state,
      notes: {},
      measurements: {},
      activities: {
        favorites: state.activities.favorites,
      },
      meals: {
        ...state.meals,
        sort: {
          ...omit((state.meals as any).sort, 'type'),
          types: [],
        },
      },
      events: {
        ...state.events,
        sort: {
          ...omit((state.events as any).sort, 'type'),
          types: [],
        },
      },
    } as any),
  3: (state: RootStoreState) =>
    ({
      ...state,
      events: {
        ...state.events,
        statistics: { score: 0, today: { statistics: {} }, average: { statistics: {} } },
      },
    } as any),
  11: (state: RootStoreState) => {
    return {
      ...state,
      meals: {
        ...state.meals,
        sort: {
          ...(state.meals as any).sort,
          order: 'descending',
          types: [],
        },
      },
      events: {
        ...state.events,
        sort: {
          ...(state.events as any).sort,
          order: 'descending',
          types: [],
        },
      },
      history: {
        ...state.history,
        sort: {
          ...state.history.sort,
          order: 'descending',
          types: [],
        },
      },
    } as any
  },
  12: (state: RootStoreState) => clearCaches(state),
  14: (state: RootStoreState) => clearCaches(state),
  15: (state: RootStoreState) => {
    return {
      ...state,
      habits: {
        ...state.habits,
        habits: [],
        order: [],
      },
    }
  },
  16: clearClientConfigCache,
  17: (state: RootStoreState) => {
    return clearCaches({
      ...state,
      ingredients: {
        ...Model.defaultState,
        ingredients: [],
        sort: {
          order: 'ascending',
          orderBy: 'occurredAt',
        },
        order: [],
        backup: {
          ingredients: [],
        },
      },
    })
  },
  18: clearClientConfigCache,
  19: () => INITIAL_STATE, // Resetting the Redux store before Releasing 2.0.0 to Production
  20: (state: RootStoreState) => clearCaches(state),
  21: () => INITIAL_STATE, // Resetting the Redux store before Releasing 2.1.0 to Production
  22: (state: RootStoreState) => {
    const keysToOmit = ['lastSyncedBiosense', 'lastSyncedHealth', 'lastSyncedKetoMojo']
    return {
      ...state,
      app: {
        ...omit(state.app, keysToOmit),
        integrationsSyncSettings: {
          lastSyncedBiosense: null,
          lastSyncedHealth: null,
          lastSyncedKetoMojo: null,
        },
      },
    } as any
  },
  // mark education screens as visited for existing users
  23: (state: RootStoreState) => {
    const visitedTutorials = {
      [TutorialKey.CGMEducation]: true,
      [TutorialKey.GlucoseEducation]: true,
    }

    Storage.set(Storage.VISITED_TUTORIALS_KEY, visitedTutorials)

    return {
      ...state,
      education: {
        ...state.education,
        visitedTutorials,
      },
    }
  },
  24: (state: RootStoreState) => state,
  25: (state: RootStoreState) => {
    return {
      ...state,
      app: {
        ...state.app,
        clientConfig: {
          ...state.app.clientConfig,
          healthData: { ...DEFAULT_CLIENT_CONFIG.clientConfig.healthData },
        },
      },
      settings: {
        ...state.settings,
        dailyMeasurementsSourcePriorities: {},
      },
    } as any
  },
  26: clearClientConfigCache,
  27: (state: RootStoreState) => {
    return {
      ...state,
      app: {
        ...state.app,
        products: [],
        login: {
          ...state.app.login,
          user: {
            ...state.app.login.user,
            subscriptions: [],
          },
        },
      },
    } as any
  },
  29: (state: RootStoreState) => {
    Storage.set(Storage.PERMISSIONS_MODAL_VISITED_KEY, true)

    return state
  },
  30: (state: RootStoreState) => {
    return {
      ...state,
      app: {
        ...state.app,
        login: {
          ...state.app.login,
          user: {
            ...state.app.login.user,
            subscriptions: [],
          },
        },
      },
    } as any
  },
  31: (state: RootStoreState) => {
    return {
      ...state,
      marketplace: { promotionCode: null },
    } as any
  },
  32: (state: RootStoreState) => {
    Storage.set(Storage.WALKTHROUGH_SHOWN_KEY, true)
    return state
  },
  33: (state: RootStoreState) => {
    return {
      ...state,
      marketplace: { ...state.marketplace, subscriptionSchedules: [] },
    }
  },
  34: clearClientConfigCache,
  35: (state: RootStoreState) => {
    const user = state.app.login.user
    return {
      ...state,
      app: {
        ...state.app,
        login: {
          ...state.app.login,
          user: {
            ...user,
            uiStates:
              user?.signUpState === 'complete'
                ? [{ name: UiStateNames.CheckoutCompleted, value: true }]
                : user?.uiStates,
          },
        },
      },
    } as any
  },
  36: (state: RootStoreState) => {
    const user = state.app.login.user

    if (!user) {
      return state
    }

    return {
      ...state,
      app: {
        ...state.app,
        login: {
          ...state.app.login,
          user: {
            ...user,
            uiStates: user.uiStates || [],
          },
        },
      },
    }
  },
  37: (state: RootStoreState) => omit(state, ['experiments']) as RootStoreState,
  38: (state: RootStoreState) => {
    const user = state.app.login.user

    if (!user) {
      return state
    }

    const features =
      user.role === UserRole.User
        ? []
        : [MobileAppFeature.AdminAccess, MobileAppFeature.AdminImpersonate]

    return {
      ...state,
      app: {
        ...state.app,
        login: {
          ...state.app.login,
          features,
        },
      },
    }
  },
  39: (state: RootStoreState) => {
    const { refillConsents, ...stateWithoutRefillConsents } = state

    return {
      ...stateWithoutRefillConsents,
      policyConsents: refillConsents,
    }
  },
  40: clearClientConfigCache,
  41: (state: OldRootStoreStateV41) => {
    return {
      ...state,
      events: {
        ...(omit(state.events, ['dailyActions', 'dailyActionsCompletions']) as EventsStoreState),
      },
      dailyActions: {
        dailyActions: state.events.dailyActions,
        dailyActionsCompletions: state.events.dailyActionsCompletions,
      },
    } as RootStoreState
  },
  42: (state: RootStoreState) => {
    const homeScreenWalkthroughVisited = Storage.get<boolean>(
      Storage.HOME_SCREEN_WALKTHROUGH_VISITED_KEY,
    )

    if (!homeScreenWalkthroughVisited) {
      Storage.set(Storage.HOME_SCREEN_WALKTHROUGH_VISITED_KEY, false)
    }

    return state
  },
}
