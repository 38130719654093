import { SurveyLink, SurveysConfigKind, SurveysQuestionKey } from '@src/types'
import { useQuestionnaireState } from './hooks'

type QuestionnaireStateType = ReturnType<typeof useQuestionnaireState>

export const removeCurrentQuestionFollowupAndItsDerivatives = ({
  questionnaireState,
  currentQuestionKey,
  followupSurveysQuestionKeyToKeep,
}: {
  questionnaireState: QuestionnaireStateType
  currentQuestionKey: SurveysQuestionKey
  followupSurveysQuestionKeyToKeep: SurveysQuestionKey[]
}) => {
  if ('loading' in questionnaireState || 'error' in questionnaireState) {
    return
  }

  const {
    questionnaireForm,
    removeQuestionByKey,
    findQuestionStandaloneFollowups,
  } = questionnaireState

  const fieldControllers = Object.keys(questionnaireForm.getValues())
  const followups = findQuestionStandaloneFollowups({ questionKey: currentQuestionKey })
  const followupsToRemove = followups.filter(
    (followup) => !followupSurveysQuestionKeyToKeep.includes(followup.question.key),
  )

  const removeFollowup = (followup: typeof followupsToRemove[0] | undefined) => {
    if (!followup) {
      return
    }

    const followUpKey = followup.question.key

    const fieldControllerToUnregister = fieldControllers.find(
      (fieldController) => fieldController === followUpKey,
    )
    if (fieldControllerToUnregister) {
      questionnaireForm.unregister(fieldControllerToUnregister)
    }

    removeQuestionByKey(followUpKey)

    const followups = findQuestionStandaloneFollowups({ questionKey: followUpKey })
    followups.forEach((followup) => {
      removeFollowup(followup)
    })
  }

  followupsToRemove.forEach((followupToRemove) => {
    removeFollowup(followupToRemove)
  })
}

export const lastFinishedSurveyOfKind = (kind: SurveysConfigKind, surveyLinks: SurveyLink[]) => {
  return surveyLinks
    .filter((surveyLink) => {
      return surveyLink.survey.kind === kind && surveyLink.finished
    })
    .sort((a, b) => b?.startedAt - a?.startedAt)[0]
}
