import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { Measurement } from '@src/screens/Events/models/events.types'
import { ListItemProps } from '@src/components/list/ListItemFactory'
import { useMenuHandling } from '@src/components/list/MenuHandler'
import { measurementTypeDefLookupSelector } from '@selectors/app'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { measurementSourceDefLookupSelector } from '@selectors/app'
import { HealthDataMeasurementSource } from '@src/types'
import { UI_ICONS_MAPPING } from '@src/components/base'
import { ListItemWithScore } from '../common/ListItemWithScore'

const TIME_FORMAT = 'h:mm a'

type MeasurementListItemProps = ListItemProps<Measurement>

export const MeasurementListItem = ({
  item,
  highlighted,
  canJumpToDate,
}: MeasurementListItemProps) => {
  const navigation = useNavigation()
  const { defaultMenuItems } = useMenuHandling(item, canJumpToDate)
  const { title, value, values, units = '', occurredAt, externalSource, type } = item

  const measurementTypeDefLookup = useSelector(measurementTypeDefLookupSelector)
  const measurementSourceDefLookup = useSelector(measurementSourceDefLookupSelector)
  const measurementTypeDef = measurementTypeDefLookup[type]
  const measurementSourceTypeDef =
    measurementSourceDefLookup[externalSource as HealthDataMeasurementSource]
  const isDailyMeasurement = measurementTypeDef?.isDailyMeasurement

  let subtitle = ''
  if (value !== undefined && value !== null) {
    subtitle = `${value} ${units}`
  } else if (values && values.systolic !== undefined && values.systolic !== null) {
    subtitle = `${values.systolic}/${values.diastolic} ${units}`
  }

  const onItemEdit = () => {
    if (isDailyMeasurement) {
      const date = moment(item.occurredAt).format(DATE_FORMAT)
      navigation.navigate('EditDailyMeasurementModal', { date, type: item.type })
    } else {
      navigation.navigate('EditMeasurement', { item })
    }
  }

  return (
    <ListItemWithScore
      title={title}
      info={isDailyMeasurement ? 'Daily' : moment(occurredAt).format(TIME_FORMAT)}
      menuItems={defaultMenuItems}
      iconName={measurementTypeDef?.icon ? UI_ICONS_MAPPING[measurementTypeDef.icon] : undefined}
      subtitle={subtitle}
      tag={measurementSourceTypeDef?.label || externalSource}
      tagStatus="secondary"
      onItemPress={onItemEdit}
      numberOfLines={1}
      highlighted={highlighted}
      isMenuEnabled={!isDailyMeasurement}
    />
  )
}
