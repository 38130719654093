import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { sensorSelector } from '@src/selectors/sensor'
import {
  useIsActiveResearchDexcom,
  useIsLibreLinkup,
  useLibre3NativeEnabled,
} from '@src/utils/hooks'

export const useDisableInAppScan = () => {
  const sensor = useSelector(sensorSelector)
  const isActiveResearchDexcom = useIsActiveResearchDexcom()

  const isLibreLinkup = useIsLibreLinkup()
  const abbottPartnershipEnabled = useFeatureFlag(Feature.AbbottPartnership)
  const { uxSensorModePendingLibre3, currentSensorIsLibre3 } = useLibre3NativeEnabled()

  const disableInAppScan =
    abbottPartnershipEnabled ||
    isLibreLinkup ||
    isActiveResearchDexcom ||
    currentSensorIsLibre3 ||
    (uxSensorModePendingLibre3 && !sensor)

  return disableInAppScan
}
