import AppleHealthKit, { HealthValue } from 'react-native-health'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useRef } from 'react'
import { Connections, DataMessage, getBody, initTerra } from 'terra-react'
import Config from 'react-native-config'
import { TERRA_APPLE_HEALTH, TERRA_GOOGLE_FIT } from '@src/screens/Settings/models/settings.types'
import { Device } from '@src/config'
import { terraProvidersSelector } from '@src/selectors/integrations'
import { userSelector } from '@src/selectors/app'

const RECENT_GLUCOSE_WINDOW = 10
const TERRA_PROVIDER = Device.ios ? TERRA_APPLE_HEALTH : TERRA_GOOGLE_FIT

interface GlucoseDataSample {
  timestamp: string
  // eslint-disable-next-line camelcase
  blood_glucose_mg_per_dL: number
  // eslint-disable-next-line camelcase
  glucose_level_flag: number
  // eslint-disable-next-line camelcase
  trend_arrow: number
}

interface TerraBodyResponse {
  // eslint-disable-next-line camelcase
  glucose_data: {
    // eslint-disable-next-line camelcase
    blood_glucose_samples: Array<GlucoseDataSample>
  }
  // eslint-disable-next-line camelcase
  measurements_data: {
    // eslint-disable-next-line camelcase
    glucose_data: {
      // eslint-disable-next-line camelcase
      blood_glucose_samples: Array<GlucoseDataSample>
    }
  }
}

interface GetBodyParams {
  connection: Connections
  startDate: Date
  endDate: Date
  latestReading?: boolean
  toWebhook?: boolean
}

export const useCheckGlucoseConnection = () => {
  const dispatch = useDispatch()

  const terraProviders = useSelector(terraProvidersSelector)
  const currentUser = useSelector(userSelector)
  const terraInitialized = useRef(false)

  if (terraProviders.length === 0) {
    dispatch({
      type: 'settings/fetchTerraProviders',
    })
  }

  const healthProvider = terraProviders.find((provider) => provider.name === TERRA_PROVIDER)

  const terraActive = healthProvider?.active

  const getBodyWithParams = useCallback(
    ({ connection, startDate, endDate, latestReading, toWebhook }: GetBodyParams) => {
      return getBody(connection, startDate, endDate, latestReading, toWebhook)
    },
    [],
  )

  const checkTerraAppleHealthGlucose = useCallback(async () => {
    const response = (await getBodyWithParams({
      connection: Connections.APPLE_HEALTH,
      startDate: moment().subtract(RECENT_GLUCOSE_WINDOW, 'day').toDate(),
      endDate: moment().toDate(),
      latestReading: false,
      toWebhook: false,
    })) as DataMessage

    const bodyData = (response as any).data?.data as Array<TerraBodyResponse>

    return bodyData.some(
      (daySample: TerraBodyResponse) => daySample.glucose_data.blood_glucose_samples.length > 0,
    )
  }, [getBodyWithParams])

  const checkTerraGoogleFitGlucose = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      dispatch({
        type: 'app/validateGlucosePresenceTerraGoogleFit',
        success: () => {
          resolve(true)
        },
        failure: () => {
          resolve(false)
        },
      })
    })
  }, [dispatch])

  const checkHealthKitGlucose = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      const options = {
        startDate: moment().subtract(RECENT_GLUCOSE_WINDOW, 'day').toISOString(),
      }

      AppleHealthKit.getBloodGlucoseSamples(options, (_: any, results: Array<HealthValue>) => {
        const foundGlucose = !!results && results.length > 0
        // update user's health data immediately so they see it on the glucose timeline
        if (foundGlucose) {
          dispatch({
            type: 'app/updateHealthData',
          })
        }

        resolve(foundGlucose)
      })
    })
  }, [dispatch])

  const checkGlucoseConnection = useCallback(async () => {
    if (!terraActive) {
      return checkHealthKitGlucose()
    }

    if (!terraInitialized.current && currentUser) {
      await initTerra(Config.TERRA_DEVELOPMENT_DEV_ID, currentUser.id.toString())
      terraInitialized.current = true
    }

    if (Device.ios) {
      return checkTerraAppleHealthGlucose()
    }

    if (Device.android) {
      return checkTerraGoogleFitGlucose()
    }

    return false
  }, [
    checkHealthKitGlucose,
    checkTerraAppleHealthGlucose,
    checkTerraGoogleFitGlucose,
    currentUser,
    terraActive,
  ])

  return checkGlucoseConnection
}
