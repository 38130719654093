import { gql } from '@apollo/client'
import { CORE_INSURANCE_POLICY_FIELDS } from '@src/graphql/fragments/insurancePolicy'

export const FETCH_INSURANCE_POLICY = gql`
  ${CORE_INSURANCE_POLICY_FIELDS}
  query insurancePolicy {
    insurancePolicy {
      ...CoreInsurancePolicyFields
    }
  }
`
