import React, { useRef, useState } from 'react'
import { ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Button, Text } from '@src/components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ReactNativeFile } from '@src/utils/image'
import { RootStackParamList } from '@src/navigation/types'
import { useDirectUpload } from '@src/hooks/useDirectUpload'
import { UserFileTag } from '@src/types'
import { ErrorMessages } from '@src/config'
import { useSnack } from '@src/utils'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { useInsuranceCardSubmissionData } from '../../hooks/useInsuranceCardSubmissionData'
import { insurancePolicySelector } from '../../models/nutritionistHub.selectors'
import { InsuranceCardPhoto } from './InsuranceCardPhoto'

enum InsuranceCardSide {
  Front,
  Back,
}

export const SubmitInsuranceCardScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const insuranceCardSubmissionData = useInsuranceCardSubmissionData(insurancePolicy)
  const uploadFile = useDirectUpload()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const showSnack = useSnack()
  const insets = useSafeAreaInsets()

  const [frontPhoto, setFrontPhoto] = useState<ReactNativeFile | undefined>()
  const [backPhoto, setBackPhoto] = useState<ReactNativeFile | undefined>()

  const [performingSubmit, setPerformingSubmit] = useState(false)
  const currentSelection = useRef<InsuranceCardSide | undefined>()

  const isInsuranceCardSubmissionPending =
    insuranceCardSubmissionData.isInsuranceCardSubmissionPending
  const insurancePolicyStateId = insuranceCardSubmissionData.insurancePolicyStateId

  if (!isInsuranceCardSubmissionPending) {
    navigation.replace('Drawer', {
      screen: 'Dashboard',
      params: { screen: 'NutritionistHub' },
    })
  }

  const onSaveButtonPress = async () => {
    try {
      if (!frontPhoto || !backPhoto) {
        return
      }

      setPerformingSubmit(true)

      const frontPhotoFile = await uploadFile({
        dataURL: frontPhoto.uri.substring(8),
        fileTag: UserFileTag.InsuranceCardFrontPhoto,
        filename: frontPhoto.name,
        contentType: frontPhoto.type,
      })

      if (!frontPhotoFile) {
        throw new Error('Failed to upload photo')
      }

      const backPhotoFile = await uploadFile({
        dataURL: backPhoto.uri.substring(8),
        fileTag: UserFileTag.InsuranceCardBackPhoto,
        filename: backPhoto.name,
        contentType: backPhoto.type,
      })

      if (!backPhotoFile) {
        throw new Error('Failed to upload photo')
      }

      dispatch({
        payload: {
          insurancePolicyStateId,
          frontPhotoFileId: frontPhotoFile.id,
          backPhotoFileId: backPhotoFile.id,
        },
        type: 'nutritionistHub/submitInsuranceCard',
        failure: (error: any) => {
          setPerformingSubmit(false)
          const errorMessage = error?.message || ErrorMessages.ServerError
          showSnack(errorMessage, null, 'error')
        },
        success: () => {
          setPerformingSubmit(false)
          showSnack('Insurance Card has been submitted. Review is in progress')
          navigation.replace('Drawer', {
            screen: 'Dashboard',
            params: { screen: 'NutritionistHub' },
          })
        },
      })
    } catch (error: any) {
      setPerformingSubmit(false)
      showSnack('An error occurred!', error.message || ErrorMessages.ServerError, 'error')
    }
  }

  const onPhotoChange = (file: ReactNativeFile | undefined) => {
    if (currentSelection.current === InsuranceCardSide.Front) {
      setFrontPhoto(file)
    } else if (currentSelection.current === InsuranceCardSide.Back) {
      setBackPhoto(file)
    }
  }

  if (performingSubmit) {
    return <LoadingContainer loadingMessage="Submitting..." />
  }

  return (
    <NavigationContainer title="Upload Insurance Card">
      <ScrollView
        style={styles.content}
        contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}
      >
        <Text type="regular" style={styles.descriptionText}>
          We are unable to authenticate your insurance information. Upload the front and back of
          your insurance card to complete your benefits check.
        </Text>
        <Text type="regular" style={styles.titleText}>
          Front of the insurance card
        </Text>
        <InsuranceCardPhoto
          onChange={onPhotoChange}
          onInitialPress={() => {
            currentSelection.current = InsuranceCardSide.Front
          }}
          photo={frontPhoto}
        />
        <Text type="regular" style={styles.titleText}>
          Back of the insurance card
        </Text>
        <InsuranceCardPhoto
          onChange={onPhotoChange}
          onInitialPress={() => {
            currentSelection.current = InsuranceCardSide.Back
          }}
          photo={backPhoto}
        />
        <Button
          type="primary"
          disabled={!frontPhoto || !backPhoto}
          size="block"
          accessibilityLabel="Save"
          style={styles.saveButton}
          onPress={onSaveButtonPress}
        >
          Save
        </Button>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  content: {
    padding: 16,
    flex: 1,
  },
  descriptionText: {
    textAlign: 'center',
    marginBottom: 8,
  },
  titleText: {
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 8,
  },
  saveButton: {
    marginTop: 24,
  },
})
