import React from 'react'
import { ImageBackground, ScrollView, TouchableOpacity, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { cgmImage } from '@src/assets/images'
import { Product, SurveysConfigKind, UiInlineAlert } from '@src/types'
import { InlineAlert } from '@src/components/InlineAlert'
import { GroupableProduct } from '@src/screens/Marketplace/types/types'

interface CGMAvailabilityProps {
  byosProduct: Product
  groupedProducts: GroupableProduct[]
  requiresPlanSelection: boolean
}

export const CGMAvailability = ({
  byosProduct,
  groupedProducts,
  requiresPlanSelection,
}: CGMAvailabilityProps) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const navigateToSelectPlan = () => {
    if (requiresPlanSelection) {
      navigation.navigate('SelectPlan')
    } else {
      navigation.navigate('SelectSubscription', {
        productGroupId: groupedProducts[0].id,
        requireEligibilityCheck: true,
      })
    }
  }

  const handleOnSignOut = () => {
    dispatch({ type: 'app/logout' })
  }

  const onOwnSensorPress = () => {
    navigation.navigate('Questionnaire', {
      questionnaire: SurveysConfigKind.OwnSensor,
      nextScreen: {
        screen: 'Onboarding',
        params: {
          screen: 'EligibilityCheck',
          params: {
            product: byosProduct,
          } as any,
        },
      },
    })
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingTop: (insets?.top || 0) + 16,
          paddingBottom: (insets?.bottom || 0) + 16,
          justifyContent: 'space-between',
        }}
        bounces={false}
      >
        <Button
          type="transparent"
          size="block"
          onPress={handleOnSignOut}
          accessibilityLabel="Sign Out"
          style={styles.signOut}
          textStyle={styles.signOutText}
        >
          Sign Out
        </Button>
        <View style={styles.buttons}>
          <InlineAlert
            style={styles.inlineAlert}
            category={UiInlineAlert.Neutral}
            message="Did you already pay on web? Please log in again using the same email you used to purchase."
          />
          <Button
            type="outline"
            size="block"
            accessibilityLabel="I have a CGM"
            textStyle={styles.text}
            style={styles.button}
            onPress={onOwnSensorPress}
          >
            I have a CGM
          </Button>
          <TouchableOpacity
            hitSlop={{ top: 16, bottom: 16, right: 16, left: 16 }}
            accessibilityLabel="I do not have a CGM"
            onPress={navigateToSelectPlan}
          >
            <Text style={styles.text} type="regular" bold>
              I do not have a CGM
            </Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
      <ImageBackground
        resizeMode="cover"
        style={styles.imageBackground}
        source={cgmImage.imageSource}
      />
    </>
  )
}

const themedStyle = StyleService.create({
  contentContainer: {
    flex: 1,
  },
  buttons: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    flex: 1,
    marginHorizontal: 24,
    marginBottom: 16,
  },
  inlineAlert: {
    marginBottom: 32,
  },
  button: {
    marginBottom: 24,
    borderColor: 'theme.solid.white',
  },
  text: {
    color: 'theme.solid.white',
  },
  imageBackground: {
    flex: 1,
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  signOut: {
    position: 'absolute',
    top: 48,
    right: 0,
  },
  signOutText: {
    color: 'theme.text.link',
  },
})
