import React from 'react'
import { NavigationContainer } from '@src/screens/Common/containers'
import { SettingsComponent } from '../components/Settings'

const Settings = () => {
  return (
    <NavigationContainer title="Settings">
      <SettingsComponent />
    </NavigationContainer>
  )
}

export default Settings
