import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Section, TouchableSection } from '@components'
import { dailyMeasurementTypeDefsSelector } from '@selectors/app'
import { HealthDataMeasurementType } from '@src/types'
import { UI_ICONS_MAPPING } from '@src/components/base'

export const TypesSection = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const typeDefs = useSelector(dailyMeasurementTypeDefsSelector)

  const onTypePress = (measurementType: HealthDataMeasurementType) => {
    navigation.navigate('SourcePriorityList', { measurementType })
  }

  return (
    <>
      <Section title="Assign source priorities" style={styles.section} />
      {typeDefs.map((typeDef) => {
        const iconName = typeDef.icon ? UI_ICONS_MAPPING[typeDef.icon] : undefined

        return (
          <TouchableSection
            key={typeDef.key}
            iconName={iconName}
            title={typeDef.label}
            showRightArrow
            style={styles.typeSection}
            onPress={() => onTypePress(typeDef.key)}
          />
        )
      })}
    </>
  )
}

const themedStyle = StyleService.create({
  section: {
    height: 48,
    backgroundColor: 'theme.surface.base2',
  },
  typeSection: {
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base2',
  },
})
