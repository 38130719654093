import { gql } from '@apollo/client'

export const ACCEPT_LEGAL_POLICY = gql`
  mutation acceptLegalPolicy($kind: LegalPolicyConsentKind!) {
    acceptLegalPolicy(kind: $kind) {
      kind
      state
      createdAt
    }
  }
`
