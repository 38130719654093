import React from 'react'
import { useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InlineAlert, useActionBottomSheet } from '@src/components'
import { RootStackParamList } from '@src/navigation/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { UiInlineAlert } from '@src/types'

export const useDataStaleBottomSheet = () => {
  const showActionBottomSheet = useActionBottomSheet()
  const loadingRef = useRef(false)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const styles = useStyleSheet(themedStyle)

  const onActionPress = () => {
    if (loadingRef.current) {
      return
    }

    navigation.navigate('Insights')
  }

  const primaryButton = {
    text: 'Go to Trends',
    onPress: onActionPress,
  }

  const title = 'Review Your Past Glucose Levels'
  const bodyText =
    'We have not received your most recent glucose levels. ' +
    'In the meantime, we suggest reviewing your past trends and make sure your sensor is active'

  const body = (
    <>
      <Text type="regular" style={styles.text}>
        {bodyText}
      </Text>
      <InlineAlert
        category={UiInlineAlert.Info}
        message="There’s a 3-hour delay due to Dexcom app syncing with Apple Health/Google Fit"
        style={styles.alert}
      />
    </>
  )

  return () => showActionBottomSheet({ title, body, primaryButton })
}

const themedStyle = StyleService.create({
  text: {
    marginTop: 16,
    marginHorizontal: 24,
    textAlign: 'center',
  },
  alert: {
    marginHorizontal: 8,
    marginTop: 16,
  },
})
