import { noop } from 'lodash'
import React, { ReactNode, createContext, useCallback, useContext, useRef, useState } from 'react'
import { useOneTimeVisitedState } from '@src/hooks/useOneTimeVisitedState'
import { Storage } from '@src/utils'
import { useFeatureFlag, Feature } from '@src/components'
import { NavigationRouteConfig } from '@src/utils/analytics'

export enum HomeScreenWalkthroughSteps {
  DialChart = 'DialChart',
  DailyActions = 'DailyActions',
  Customize = 'Customize',
  EventsTab = 'EventsTab',
}

export const HomeScreenWalkthroughStepOrder = [
  HomeScreenWalkthroughSteps.DialChart,
  HomeScreenWalkthroughSteps.DailyActions,
  HomeScreenWalkthroughSteps.Customize,
  HomeScreenWalkthroughSteps.EventsTab,
]

const DEFAULT_STEP = -1

// 0 - skips dial chart walkthrough step
const STEPS_TO_SKIP = [0]

export interface HomeWalkthroughState {
  shouldShow: boolean
  walkthroughInProgress: boolean
  currentStep: number
  markAsShown: () => void
  advanceWalkthroughToNextStep: () => void
  isStepVisible: (step: HomeScreenWalkthroughSteps) => boolean
}

export const defaultHomeWalkthroughState: HomeWalkthroughState = {
  shouldShow: false,
  walkthroughInProgress: false,
  currentStep: DEFAULT_STEP,
  markAsShown: noop,
  advanceWalkthroughToNextStep: noop,
  isStepVisible: (_step: HomeScreenWalkthroughSteps) => false,
}

const HomeScreenWalkthroughContext = createContext<HomeWalkthroughState>(
  defaultHomeWalkthroughState,
)

export const useHomeScreenWalkthrough = () => useContext(HomeScreenWalkthroughContext)

export const HomeScreenWalkthroughProvider = ({
  children,
  routeName,
}: {
  children: ReactNode | ReactNode[]
  routeName?: NavigationRouteConfig['name'] | null
}) => {
  const [currentStep, setCurrentStep] = useState(DEFAULT_STEP)
  const walkthroughInProgressRef = useRef(false)

  const isHomeScreenFocused = routeName === 'Drawer' || routeName === 'Events'

  const shouldSeeDailyActionsHomeWalkthrough = useFeatureFlag(Feature.DailyActionsHomeWalkthrough)
  const homeScreenDialChartEnabled = useFeatureFlag(Feature.HomeScreenDialChart)

  const { shouldShow, markAsShown } = useOneTimeVisitedState(
    Storage.HOME_SCREEN_WALKTHROUGH_VISITED_KEY,
  )

  const advanceWalkthroughToNextStep = useCallback(() => {
    setCurrentStep((prevStep) => {
      if (prevStep === DEFAULT_STEP) {
        walkthroughInProgressRef.current = true
      }
      if (prevStep === HomeScreenWalkthroughStepOrder.length - 1) {
        markAsShown()
        walkthroughInProgressRef.current = false
        return DEFAULT_STEP
      }

      if (STEPS_TO_SKIP.includes(prevStep + 1) && !homeScreenDialChartEnabled) {
        return prevStep + 2
      }

      return prevStep + 1
    })
  }, [homeScreenDialChartEnabled, markAsShown])

  const isStepVisible = useCallback(
    (step: HomeScreenWalkthroughSteps) => {
      return (
        isHomeScreenFocused && shouldShow && HomeScreenWalkthroughStepOrder[currentStep] === step
      )
    },
    [currentStep, isHomeScreenFocused, shouldShow],
  )

  return (
    <HomeScreenWalkthroughContext.Provider
      value={{
        currentStep,
        shouldShow: isHomeScreenFocused && shouldSeeDailyActionsHomeWalkthrough && shouldShow,
        markAsShown,
        walkthroughInProgress: walkthroughInProgressRef.current,
        advanceWalkthroughToNextStep,
        isStepVisible,
      }}
    >
      {children}
    </HomeScreenWalkthroughContext.Provider>
  )
}
