/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const MinusCircle = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <Path
      d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
      fill="#FFD188"
    />
    <Path
      d="M11 16H21"
      stroke="#C6770C"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default MinusCircle
