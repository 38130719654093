import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'

export const IntegrationSuccessfulScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()

  const onContinuePress = () => {
    navigation.navigate('Drawer')
  }

  const warmupMessage =
    'You are all set! As a reminder, if you just recently activated your sensor, there is a 1 hour warm-up period ' +
    'before you can use your sensor.'

  const delayMessage =
    'Once you are past the warm-up period, there is approximately a 20 minute delay ' +
    'from when you scan in the LibreLink app to data showing up in the Nutrisense app.'

  return (
    <SafeAreaView edges={['top', 'bottom']} style={[styles.container]}>
      <View style={styles.contentContainer}>
        <Icon weight="fill" style={styles.icon} name="seal-check" size={48} />
        <Text type="title-2" style={styles.title}>
          Done 🎉
        </Text>
        <View>
          <Text type="large" style={styles.bodyText}>
            {warmupMessage}
          </Text>
        </View>
        <View>
          <Text type="large" style={styles.bodyText}>
            {delayMessage}
          </Text>
        </View>
      </View>

      <View style={styles.button}>
        <Button type="primary" size="block" onPress={onContinuePress} accessibilityLabel="Continue">
          Continue
        </Button>
      </View>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  icon: {
    marginTop: 40,
    color: 'theme.success.base',
  },
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  title: {
    marginTop: 16,
  },
  bodyText: {
    paddingHorizontal: 32,
    marginTop: 40,
    textAlign: 'center',
  },
  button: {
    marginHorizontal: 24,
  },
})
