import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { LoadingFastImage } from '@components'
import { TutorialPreview as TutorialPreviewData } from '../models/tutorials.types'

export const TutorialPreview = ({ tutorial }: { tutorial: TutorialPreviewData }) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  return (
    <View style={styles.container}>
      {tutorial.image && tutorial.image.data && (
        <LoadingFastImage
          source={{ uri: tutorial.image.data.attributes.url }}
          style={styles.fastImage as ImageStyle}
        />
      )}
      <View style={styles.content}>
        <Text type="regular" bold lineSpacing="tight">
          {tutorial.name}
        </Text>
        {tutorial.duration && (
          <Text type="small" style={styles.duration}>
            {tutorial.duration}
          </Text>
        )}
        {tutorial.description && (
          <Text type="small" lineSpacing="tight" style={styles.description}>
            {tutorial.description}
          </Text>
        )}
        {tutorial.buttonText && (
          <Button
            type="primary"
            size="small"
            accessibilityLabel={tutorial.buttonText}
            style={styles.actionButton}
            onPress={() => {
              navigation.navigate('CMSTutorial', {
                tutorialId: tutorial.id,
              })
            }}
          >
            {tutorial.buttonText}
          </Button>
        )}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    padding: 16,
    marginBottom: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  fastImage: {
    width: 105,
    height: 145,
    borderRadius: 8,
  },
  content: {
    marginLeft: 16,
    flex: 1,
  },
  duration: {
    marginTop: 4,
    color: 'theme.text.tertiary',
  },
  description: {
    marginVertical: 8,
    color: 'theme.text.secondary',
  },
  actionButton: {
    marginTop: 8,
    alignSelf: 'flex-end',
  },
})
