import { UserExperienceSensorModeKind } from '@src/types'
import { TutorialGroups, TutorialSensorKind } from '@src/screens/Tutorials/models/tutorials.types'

export const OWN_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorDexcomG6,
  UserExperienceSensorModeKind.OwnSensorDexcomG7,
  UserExperienceSensorModeKind.OwnSensorDexcomStelo,
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorLibre3,
] as const

export const LIBRE_SENSORS = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorLibre3,
]
export const DEXCOM_SENSORS = [
  UserExperienceSensorModeKind.OwnSensorDexcomG6,
  UserExperienceSensorModeKind.OwnSensorDexcomG7,
  UserExperienceSensorModeKind.OwnSensorDexcomStelo,
]

export const SENSOR_NAMES: Record<OwnSensorMode, string> = {
  [UserExperienceSensorModeKind.OwnSensorDexcomG6]: 'Dexcom G6',
  [UserExperienceSensorModeKind.OwnSensorDexcomG7]: 'Dexcom G7',
  [UserExperienceSensorModeKind.OwnSensorDexcomStelo]: 'Stelo',
  [UserExperienceSensorModeKind.OwnSensorLibre1]: 'FreeStyle Libre 14-Day System (Libre 1)',
  [UserExperienceSensorModeKind.OwnSensorLibre2]: 'FreeStyle Libre 2',
  [UserExperienceSensorModeKind.OwnSensorLibre3]: 'FreeStyle Libre 3',
}

export const SENSOR_MODE_TUTORIAL = {
  [UserExperienceSensorModeKind.OwnSensorDexcomG6]: {
    group: TutorialGroups.Dexcom,
    sensorKind: TutorialSensorKind.DexcomG6,
  },
  [UserExperienceSensorModeKind.OwnSensorDexcomG7]: {
    group: TutorialGroups.Dexcom,
    sensorKind: TutorialSensorKind.DexcomG7,
  },
  [UserExperienceSensorModeKind.OwnSensorDexcomStelo]: {
    group: TutorialGroups.Dexcom,
    sensorKind: TutorialSensorKind.DexcomStelo,
  },
  [UserExperienceSensorModeKind.OwnSensorLibre1]: { group: TutorialGroups.LibreNative },
  [UserExperienceSensorModeKind.OwnSensorLibre2]: { group: TutorialGroups.LibreNative },
  [UserExperienceSensorModeKind.OwnSensorLibre3]: { group: TutorialGroups.LibreNative },
}

export type OwnSensorMode = typeof OWN_SENSOR_MODES[number]
