import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import React from 'react'
import { View } from 'react-native'
import { ScrollViewWithFade } from '@src/components'
import { RootStackParamList } from '@src/navigation/types'
import { Button, Icon, Pill, Text } from '@src/components/base'
import { useStyleSheet, StyleService } from '@src/style/service'
import { NSLogoGift } from '@src/assets/svgs/nsLogoGift'
import { Subscription } from '@src/types'
import { BillingProduct } from '@src/types'

export interface UpsellOfferContentProps {
  showCommitmentDisclaimer?: boolean
  billingProduct: BillingProduct
  title: string
  description: string
  billingProductPrice: string
  billingProductIntervalDescription: string
  offerPillText: string
  offerHeaderText: string
  offerSubheaderText: string
  offerFooterText: string
  subscription: Subscription
  primaryButtonText: string
  secondaryButtonText?: string
}

export const UpsellOfferContent = ({
  showCommitmentDisclaimer = true,
  billingProduct,
  title,
  description,
  billingProductPrice,
  billingProductIntervalDescription,
  offerPillText,
  offerHeaderText,
  offerSubheaderText,
  offerFooterText,
  subscription,
  primaryButtonText,
  secondaryButtonText,
}: UpsellOfferContentProps) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const oldPrice = Math.round(Number(subscription.price))

  const handleSubmitPress = () => {
    navigation.navigate('ChurnRecoveryUpsellOfferConfirmation', {
      subscriptionId: subscription.id,
      billingProduct,
      billingProductPrice,
      billingProductIntervalDescription,
    })
  }

  const handleCancelPress = () => {
    navigation.navigate('CancelSubscriptionConfirmation', {
      subscriptionId: subscription.id,
    })
  }

  return (
    <View style={styles.contentContainer}>
      <ScrollViewWithFade
        fadeColor={styles.contentContainer.backgroundColor}
        scrollViewStyle={styles.scrollView}
      >
        <View style={styles.heading}>
          <NSLogoGift />
          <Text type="title-3" style={styles.title}>
            {title}
          </Text>
          <Text type="regular" style={styles.description}>
            {description}
          </Text>
        </View>
        <View style={styles.offerContainer}>
          <Pill text={offerPillText} fill="citron" size="m" style={styles.offerPill} />
          {offerHeaderText && (
            <Text type="regular" bold lineSpacing="tight">
              {offerHeaderText}
            </Text>
          )}
          {offerSubheaderText && (
            <Text type="regular" lineSpacing="tight">
              {offerSubheaderText}
            </Text>
          )}
          <View style={styles.offerPriceContainer}>
            <View style={styles.oldPriceContainer}>
              <View style={styles.oldPriceStrikeThrough} />
              <Text type="title-3" style={styles.oldPrice}>
                ${oldPrice}/mo
              </Text>
            </View>
            <Text type="title-2">${Math.round(Number(billingProductPrice))}/mo</Text>
          </View>
          <Text type="regular" style={styles.offerFooterText}>
            {offerFooterText}
          </Text>
        </View>
      </ScrollViewWithFade>

      <View style={styles.actionsContainer}>
        {showCommitmentDisclaimer && (
          <View style={styles.commitmentDisclaimerContainer}>
            <Icon name="check" weight="bold" size={16} style={styles.checkmark} />
            <Text type="regular" bold style={styles.commitmentText}>
              No commitment - cancel when you wish
            </Text>
          </View>
        )}
        <Button
          type="primary"
          size="block"
          accessibilityLabel={primaryButtonText}
          onPress={handleSubmitPress}
        >
          {primaryButtonText}
        </Button>
        {secondaryButtonText && (
          <Button
            type="transparent"
            size="block"
            accessibilityLabel="Cancel"
            style={styles.cancelButton}
            onPress={handleCancelPress}
          >
            {secondaryButtonText}
          </Button>
        )}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  contentContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    flexGrow: 1,
  },
  heading: {
    paddingTop: 36,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  title: {
    marginTop: 36,
  },
  description: {
    marginTop: 16,
    marginHorizontal: 16,
    textAlign: 'center',
  },
  offerContainer: {
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    borderRadius: 24,
    alignSelf: 'center',
    marginTop: 32,
    paddingTop: 24,
    paddingBottom: 16,
    marginBottom: 16,
    paddingHorizontal: 36,
    alignItems: 'center',
  },
  offerPill: {
    position: 'absolute',
    top: -16,
  },
  oldPriceContainer: {
    position: 'relative',
  },
  offerPriceContainer: {
    flexDirection: 'row',
    gap: 8,
    marginVertical: 16,
    alignItems: 'center',
  },
  oldPrice: {
    color: 'theme.text.secondary',
    textDecorationLine: 'line-through',
  },
  oldPriceStrikeThrough: {
    position: 'absolute',
    top: '35%',
    height: 2,
    width: '100%',
    backgroundColor: 'theme.text.secondary',
  },
  offerFooterText: {
    color: 'theme.text.secondary',
    width: 140,
    textAlign: 'center',
    lineHeight: 16,
  },
  commitmentDisclaimerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    marginBottom: 8,
  },
  checkmark: {
    color: 'theme.success.base',
  },
  commitmentText: {
    color: 'theme.success.base',
  },
  footer: {
    marginTop: 4,
    paddingHorizontal: 64,
    paddingBottom: 32,
  },

  actionsContainer: {
    marginTop: 32,
    padding: 16,
  },
  cancelButton: {
    marginTop: 8,
  },
})
