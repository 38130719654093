import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Button, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  ApprovalFlowStep,
  useApprovalFlow,
} from '@src/screens/SignUp/components/Approvals/useApprovalFlow'
import { InlineAlert } from '@src/components'
import { SurveysConfigKind, UiInlineAlert } from '@src/types'
import {
  useByosProducts,
  useSubscriptionEligibleForByosUpgrade,
} from '@src/screens/Marketplace/utils/hooks'

interface UpsellCGMBannerProps {
  header?: any
  image: React.ReactElement
}

export const UpsellCGMBanner = ({ header, image }: UpsellCGMBannerProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const { approvalStep } = useApprovalFlow()

  const byosProducts = useByosProducts()
  const subscriptionEligibleForByosUpgrade = useSubscriptionEligibleForByosUpgrade()

  if (approvalStep === ApprovalFlowStep.Rejected) {
    return null
  }
  if (approvalStep === ApprovalFlowStep.PendingApproval) {
    return (
      <InlineAlert
        category={UiInlineAlert.Warning}
        message="Your approval for CGM order is pending. We will check and confirm it within 2 working days."
        style={styles.alert}
      />
    )
  }

  const navigateToByosUpgrade = () => {
    navigation.navigate('EligibilityCheck', {
      product: byosProducts[0],
      nextScreen: {
        screen: 'Questionnaire',
        params: {
          questionnaire: SurveysConfigKind.OwnSensor,
          nextScreen: {
            screen: 'FreeTrialSubscriptionOffer',
            params: {
              product: byosProducts[0],
              subscription: subscriptionEligibleForByosUpgrade,
            },
          },
        },
      },
    })
  }

  return (
    <>
      {header}
      <View style={styles.container}>
        {image}
        <View style={styles.content}>
          <Text style={styles.upsellCopy} type="regular">
            Purchase a CGM to track your body's glucose response to food and exercise. Get access to
            superior health insights.
          </Text>
          <View style={styles.buttonsContainer}>
            {subscriptionEligibleForByosUpgrade && byosProducts.length > 0 && (
              <Button
                onPress={navigateToByosUpgrade}
                accessibilityLabel="I already have a CGM"
                type="transparent"
                size="small"
                style={styles.byosUpgradeButton}
              >
                I already have a CGM
              </Button>
            )}
            <Button
              onPress={() => {
                navigation.navigate('Subscriptions')
              }}
              accessibilityLabel="Upsell"
              type="primary"
              size="small"
            >
              Buy Now
            </Button>
          </View>
        </View>
      </View>
    </>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginBottom: 8,
  },
  content: {
    position: 'absolute',
    bottom: '30%',
    left: 0,
    width: '100%',
    paddingHorizontal: 16,
  },
  upsellCopy: {
    paddingVertical: 8,
    textAlign: 'center',
  },
  buttonsContainer: {
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  alert: {
    marginHorizontal: 16,
    marginTop: 16,
    marginBottom: 16,
  },
  byosUpgradeButton: {
    marginRight: 16,
    borderWidth: 2,
    backgroundColor: 'theme.background',
    borderColor: 'theme.surface.base1',
  },
})
