export { useStorageValue } from '@src/utils/storage/mmkv'
import { clear, get, set, remove } from '@src/utils/storage/mmkv'

const APP_VERSION_KEY = 'APP_VERSION'
const APP_FIRST_LOGIN_TIMESTAMP_KEY = 'APP_FIRST_LOGIN_TIMESTAMP'
const UNLEASH_STORAGE_KEY = 'UNLEASH_STORAGE'
const VISITED_TUTORIALS_KEY = 'VISITED_TUTORIALS'
const SENSOR_INFO_MODAL_VISITED_KEY = 'SENSOR_INFO_MODAL_VISITED'
const PERMISSIONS_MODAL_VISITED_KEY = 'PERMISSIONS_MODAL_VISITED'
const HEALTH_QUESTIONNAIRE_APPROVAL_MODAL_VISITED_KEY =
  'HEALTH_QUESTIONNAIRE_APPROVAL_MODAL_VISITED'
const ONBOARDING_CHECKLIST_REMINDER_VISITED_KEY = 'ONBOARDING_CHECKLIST_REMINDER_VISITED'
const LAST_REVIEW_PROMPT_DATE_KEY = 'LAST_REVIEW_PROMPT_DATE'
const REVIEW_PROMPTS_COUNT_KEY = 'REVIEW_PROMPTS_COUNT'
const REVIEW_LEFT_KEY = 'REVIEW_LEFT'
const SENSOR_ACTIVATION_MODAL_VISITED_KEY = 'sensor_activation_modal'
const SCANNED_EXPIRED_SENSOR_KEY = 'SCANNED_EXPIRED_SENSOR'
const GLUCOSE_RECENT_KEY = 'GLUCOSE_RECENT'
const GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT_KEY = 'GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT'
const COURSES_ENGAGEMENT_PROMPT_CONFIG_KEY = 'COURSES_ENGAGEMENT_PROMPT_CONFIG'
const WALKTHROUGH_SHOWN_KEY = 'WALKTHROUGH_SHOWN'
const JOURNAL_ENGAGEMENT_PROMPT_CONFIG_KEY = 'JOURNAL_ENGAGEMENT_PROMPT_CONFIG'
const ACCOUNT_SCREEN_VISITED_KEY = 'ACCOUNT_SCREEN_VISITED'
const NO_ALLOTED_CALLS_ALERT_CLOSED_KEY = 'NO_ALLOTED_CALLS_ALERT_CLOSED'
const CALL_SOON_ALERT_CLOSED_KEY = 'CALL_SOON_ALERT_CLOSED'
const CONNECT_HEALTH_PROVIDER_ALERT_CLOSED_KEY = 'CONNECT_HEALTH_PROVIDER_ALERT_CLOSED'
const HEALTH_PERMISSIONS_MODAL_VISITED_KEY = 'HEALTH_PERMISSIONS_MODAL_VISITED'
const DEXCOM_SENSOR_SWITCH_REJECTED_KEY = 'DEXCOM_SENSOR_SWITCH_REJECTED'
const LIBRE_MIGRATION_SWITCH_REJECTED_KEY = 'LIBRE_MIGRATION_SWITCH_REJECTED'

// Walkthrough State Keys
const ACCOUNT_DASHBOARD_WALKTHROUGH_VISITED_KEY = 'ACCOUNT_DASHBOARD_WALKTHROUGH_VISITED'
const ACCOUNT_PROFILE_WALKTHROUGH_VISITED_KEY = 'ACCOUNT_PROFILE_WALKTHROUGH_VISITED'
const LEARN_WALKTHROUGH_VISITED_KEY = 'LEARN_WALKTHROUGH_VISITED'
const CLAIM_FREE_VIDEO_CALL_MODAL_VISITED_KEY = 'CLAIM_FREE_VIDEO_CALL_MODAL_VISITED'
const GOALS_WALKTHROUGH_VISITED_KEY = 'GOALS_WALKTHROUGH_VISITED'
const GOAL_METRIC_TARGET_TOOLTIP_SHOWN_KEY = 'GOAL_METRIC_TARGET_TOOLTIP_SHOWN'
const HOME_SCREEN_WALKTHROUGH_VISITED_KEY = 'HOME_SCREEN_WALKTHROUGH_VISITED'
const NUTRITIONIST_HUB_RED_DOT = 'NUTRITIONIST_HUB_RED_DOT'
const SCAN_DISABLE_ANNOUNCEMENT_VISITED_KEY = 'SCAN_DISABLE_ANNOUNCEMENT_VISITED'
const SCAN_DISABLED_NOTICE_SHOWN_AT_KEY = 'SCAN_DISABLED_NOTICE_SHOWN_AT'
const VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY = 'VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT'
const VIDEO_CALLS_UPSELL_MODAL_VISITED_RECENT_SUBSCRIPTION =
  'VIDEO_CALLS_UPSELL_MODAL_VISITED_RECENT_SUBSCRIPTION'
const VIDEO_CALLS_UPSELL_MODAL_COMPLETED_KEY = 'VIDEO_CALLS_UPSELL_MODAL_COMPLETED'
const VIDEO_CALL_CHECK_IN_COMPLETED_KEY = 'VIDEO_CALL_CHECK_IN_COMPLETED'
const VIDEO_CALL_FEE_INVOICE_ID_KEY = 'VIDEO_CALL_FEE_INVOICE_ID'

const WALKTHROUGH_STATE_KEYS = [
  ACCOUNT_DASHBOARD_WALKTHROUGH_VISITED_KEY,
  ACCOUNT_PROFILE_WALKTHROUGH_VISITED_KEY,
  LEARN_WALKTHROUGH_VISITED_KEY,
  GOALS_WALKTHROUGH_VISITED_KEY,
  GOAL_METRIC_TARGET_TOOLTIP_SHOWN_KEY,
  HOME_SCREEN_WALKTHROUGH_VISITED_KEY,
]

export default {
  get,
  set,
  remove,
  clear,
  ACCOUNT_DASHBOARD_WALKTHROUGH_VISITED_KEY,
  ACCOUNT_PROFILE_WALKTHROUGH_VISITED_KEY,
  ACCOUNT_SCREEN_VISITED_KEY,
  APP_FIRST_LOGIN_TIMESTAMP_KEY,
  APP_VERSION_KEY,
  CALL_SOON_ALERT_CLOSED_KEY,
  CLAIM_FREE_VIDEO_CALL_MODAL_VISITED_KEY,
  CONNECT_HEALTH_PROVIDER_ALERT_CLOSED_KEY,
  COURSES_ENGAGEMENT_PROMPT_CONFIG_KEY,
  DEXCOM_SENSOR_SWITCH_REJECTED_KEY,
  GLUCOSE_RECENT_KEY,
  GOAL_METRIC_TARGET_TOOLTIP_SHOWN_KEY,
  GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT_KEY,
  GOALS_WALKTHROUGH_VISITED_KEY,
  HEALTH_PERMISSIONS_MODAL_VISITED_KEY,
  HEALTH_QUESTIONNAIRE_APPROVAL_MODAL_VISITED_KEY,
  HOME_SCREEN_WALKTHROUGH_VISITED_KEY,
  JOURNAL_ENGAGEMENT_PROMPT_CONFIG_KEY,
  LAST_REVIEW_PROMPT_DATE_KEY,
  LEARN_WALKTHROUGH_VISITED_KEY,
  LIBRE_MIGRATION_SWITCH_REJECTED_KEY,
  NUTRITIONIST_HUB_RED_DOT,
  NO_ALLOTED_CALLS_ALERT_CLOSED_KEY,
  ONBOARDING_CHECKLIST_REMINDER_VISITED_KEY,
  PERMISSIONS_MODAL_VISITED_KEY,
  REVIEW_LEFT_KEY,
  REVIEW_PROMPTS_COUNT_KEY,
  SCANNED_EXPIRED_SENSOR_KEY,
  SCAN_DISABLE_ANNOUNCEMENT_VISITED_KEY,
  SCAN_DISABLED_NOTICE_SHOWN_AT_KEY,
  SENSOR_ACTIVATION_MODAL_VISITED_KEY,
  SENSOR_INFO_MODAL_VISITED_KEY,
  UNLEASH_STORAGE_KEY,
  VIDEO_CALLS_UPSELL_MODAL_COMPLETED_KEY,
  VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY,
  VIDEO_CALLS_UPSELL_MODAL_VISITED_RECENT_SUBSCRIPTION,
  VIDEO_CALL_CHECK_IN_COMPLETED_KEY,
  VIDEO_CALL_FEE_INVOICE_ID_KEY,
  VISITED_TUTORIALS_KEY,
  WALKTHROUGH_SHOWN_KEY,
  WALKTHROUGH_STATE_KEYS,
}
