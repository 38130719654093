import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, PillStatus, Text } from '@components/base'
import { Fields, ProductSummaryFields } from '@screens/Marketplace/components/ProgramDetails'
import {
  getPriceDescription,
  isFreeSoftwareOnlySubscription,
} from '@screens/Marketplace/utils/utils'
import { Product } from '@src/types'
import { Benefit } from '@components'
import { SubscriptionItem } from './SubscriptionItem'

interface ProductSummaryProps {
  product: Pick<Product, 'title' | 'details' | 'interval' | 'key'>
  eligibleUpgradeProducts?: Product[]
  price: number
  isActive: boolean
  isTrial: boolean
  currentPeriodEndAt?: string
  commitmentEndAt?: string
  cancelAt?: string
  resumesAt?: string
  resetBillingCycleAnchorAt?: string
  manageTitle: string
  pillLabel: string
  pillStatus: PillStatus
  onManagePress?: () => void
}

export const ProductSummary = ({
  product,
  eligibleUpgradeProducts,
  price,
  isActive,
  isTrial,
  currentPeriodEndAt,
  commitmentEndAt,
  cancelAt,
  resumesAt,
  resetBillingCycleAnchorAt,
  manageTitle,
  pillLabel,
  pillStatus,
  onManagePress,
}: ProductSummaryProps) => {
  const styles = useStyleSheet(themedStyle)

  const cost = price === 0 || isTrial ? 'Free' : getPriceDescription(price, product.interval)
  const description = isTrial ? 'Complimentary access' : product.interval.description
  const showBenefits =
    (eligibleUpgradeProducts &&
      eligibleUpgradeProducts.length > 0 &&
      product.details &&
      product.details.length > 0) ||
    false

  const handleManagePress = () => {
    if (onManagePress) {
      onManagePress()
    }
  }

  return (
    <View style={styles.content}>
      <View style={!showBenefits && styles.bottomBorder}>
        <View style={styles.row}>
          <View style={styles.rowContent}>
            <Text type="small" lineSpacing="none" style={styles.textSecondary}>
              Current Plan
            </Text>
            <Text type="large" bold lineSpacing="none">
              {product.title}
            </Text>
          </View>
          {isActive && <Pill text={pillLabel} status={pillStatus} />}
        </View>
        {showBenefits && (
          <View style={styles.details}>
            {product.details?.map((detail) => (
              <Benefit key={detail} benefit={detail} />
            ))}
          </View>
        )}
      </View>
      <View style={[styles.row, styles.bottomBorder]}>
        <Text type="regular" lineSpacing="tight" style={styles.description}>
          {description}
        </Text>
        <Text type="regular" bold style={styles.rowValue}>
          {cost}
        </Text>
      </View>

      {isActive && (
        <>
          <ProductSummaryFields
            product={product}
            price={price}
            currentPeriodEndAt={currentPeriodEndAt}
            commitmentEndAt={commitmentEndAt}
            cancelAt={cancelAt}
            resumesAt={resumesAt}
            resetBillingCycleAnchorAt={resetBillingCycleAnchorAt}
            isTrial={isTrial}
            mode="compact"
            fields={[
              Fields.daysLeft,
              Fields.subscriptionEnds,
              Fields.nextBillingDate,
              Fields.resumesAt,
            ]}
          />
          {(!eligibleUpgradeProducts ||
            eligibleUpgradeProducts.length === 0 ||
            isFreeSoftwareOnlySubscription(product)) && (
            <TouchableOpacity
              accessibilityLabel="Show program details"
              style={styles.row}
              onPress={onManagePress}
            >
              <Text type="regular" bold style={styles.manageText}>
                {manageTitle}
              </Text>
              <Icon name="caret-right" style={styles.arrowIcon} />
            </TouchableOpacity>
          )}
          {eligibleUpgradeProducts &&
            !isFreeSoftwareOnlySubscription(product) &&
            eligibleUpgradeProducts?.map((product, index) => (
              <View key={product.id} style={[styles.marginBottom, index === 0 && styles.marginTop]}>
                <SubscriptionItem
                  title={product.title}
                  description={product.shortDescription}
                  details={product.details}
                  item={product}
                  ctaText="Upgrade"
                  onItemPress={handleManagePress}
                />
              </View>
            ))}
        </>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  content: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 64,
    paddingVertical: 12,
  },
  rowContent: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  rowValue: {
    marginLeft: 16,
  },
  details: {
    marginBottom: 12,
  },
  bottomBorder: {
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  description: {
    flexShrink: 1,
    flexWrap: 'wrap',
  },
  textSecondary: {
    color: 'theme.text.secondary',
  },
  manageText: {
    color: 'theme.text.link',
  },
  arrowIcon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  marginTop: {
    marginTop: 16,
  },
  marginBottom: {
    marginBottom: 16,
  },
})
