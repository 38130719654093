import { StackNavigationProp } from '@react-navigation/stack'
import { CommonActions } from '@react-navigation/native'
import { AppStackParamList } from '@navigation/types'

export const navigateToCheckInCompletedModal = (
  navigation: StackNavigationProp<AppStackParamList>,
) => {
  navigation.dispatch((state) => {
    // Remove current route
    const routes = state.routes.slice(0, -1)

    // navigate to NutritionistHub with CheckInCompletedModal
    const newRoutes = [
      ...routes,
      {
        name: 'Drawer',
        params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } },
      },
      { name: 'CheckInCompletedModal' },
    ]

    return CommonActions.reset({
      index: newRoutes.length - 1,
      routes: newRoutes,
    } as any)
  })
}
