import React, { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { take } from 'lodash'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button, Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollViewWithFade } from '@src/components'
import {
  insurancePolicySelector,
  suggestedAvailabilitySelector,
  upcomingAppointmentsSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { AppStackParamList } from '@navigation/types'
import { SuggestedCallCard } from '@screens/NutritionistHub/components/UpcomingCalls/SuggestedCallCard.tsx'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'
import { CustomEventTypes } from '@config'
import { isInsuranceDeclined } from '@screens/NutritionistHub/utils.ts'

export const UpcomingCalls = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const dispatch = useDispatch()
  const track = useNutritionistHubTracker()
  const upcomingAppointments = useSelector(upcomingAppointmentsSelector)
  const suggestedAvailability = useSelector(suggestedAvailabilitySelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const insuranceDeclined = isInsuranceDeclined(insurancePolicy)

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
  }, [dispatch])

  useFocusEffect(
    useCallback(() => {
      if (!insuranceDeclined) {
        dispatch({ type: 'nutritionistHub/fetchSuggestedAvailability' })
      }
    }, [dispatch, insuranceDeclined]),
  )

  const handleSeeAllPress = () => {
    track(CustomEventTypes.NutritionistHubSeeAllCallsTapped)
    navigation.navigate('AllVideoCalls')
  }

  return (
    <ScrollViewWithFade>
      <View style={styles.container}>
        <View style={styles.sectionHeader}>
          <View style={styles.header}>
            <Icon name="calendar-blank" />
            <View style={styles.headerText}>
              <Text type="regular" bold>
                Upcoming Calls
              </Text>
            </View>
          </View>
          <Button
            accessibilityLabel="seeAllCalls"
            type="outline"
            size="small"
            style={styles.seeAllButton}
            onPress={handleSeeAllPress}
          >
            See All
          </Button>
        </View>
        <View style={styles.callsContainer}>
          {take(upcomingAppointments, 5).map((appointment) => (
            <CallCard key={appointment.id} appointment={appointment} />
          ))}
          {suggestedAvailability && insurancePolicy && !insuranceDeclined && (
            <SuggestedCallCard suggestedAvailability={suggestedAvailability} />
          )}
        </View>
      </View>
    </ScrollViewWithFade>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 24,
  },
  sectionHeader: {
    marginTop: 24,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    marginLeft: 20,
    lineHeight: 24,
    flexDirection: 'column',
  },
  seeAllButton: {
    alignSelf: 'center',
  },
  callsContainer: {
    rowGap: 24,
  },
})
