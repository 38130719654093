import React, { useEffect, useState } from 'react'
import { Image, ImageStyle, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { thresholdsSettingsSelector, unitSystemStoreStateSelector } from '@src/selectors/settings'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import {
  HomeScreenWalkthroughSteps,
  useHomeScreenWalkthrough,
} from '@src/context/homeScreenWalkthroughContext'
import { Analytics, CustomEventTypes } from '@src/config'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { UpsellCGMBanner } from '@src/screens/Events/components/Banners/UpsellCGMBanner'
import { blurredDialChartImage } from '@src/assets/images'
import { useIsDarkMode } from '@src/config/theme'
import { useShowByosIntegrationWarning } from '@src/screens/OwnSensorOnboarding/hooks/useShowByosIntegrationWarning'
import { ByosIntegrationWarning } from '@src/components/Timeline/ByosIntegrationWarning'
import { UnitSystem } from '@src/screens/Settings'
import { usePrimaryStatsData } from '@src/screens/Events/utils/usePrimaryStatsData'
import { GlucoseArcChart } from './GlucoseArcChart'

const GRAPH_LIMITS_PADDING = 50
const TIMESTAMP_REFRESH_INTERVAL = 5000

export const DialChart = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const [_intervalId, setIntervalId] = useState<NodeJS.Timeout>()
  const { glucoseLowThreshold, glucoseHighThreshold } = useSelector(thresholdsSettingsSelector)
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const { primaryStatDisplayValue, lastReadingTime } = usePrimaryStatsData({
    displayLastReading: true,
  })
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const isDarkMode = useIsDarkMode()

  const { thirdPartyWarning, nativeWarning } = useShowByosIntegrationWarning()
  const showByosIntegrationWarning = thirdPartyWarning || nativeWarning
  const glucoseUnits = unitSystem === UnitSystem.Metric ? 'mmol/L' : 'mg/dL'

  const { advanceWalkthroughToNextStep, isStepVisible } = useHomeScreenWalkthrough()

  // rerender component every 5 seconds so that the timestamp is updated
  // skip effect if the CGM feature is not available or the Byos integration warning is shown
  useEffect(() => {
    if (!isCGMFeatureAvailable || showByosIntegrationWarning) {
      return
    }
    const interval = setInterval(() => {
      setIntervalId(interval)
    }, TIMESTAMP_REFRESH_INTERVAL)

    return () => clearInterval(interval)
  }, [isCGMFeatureAvailable, showByosIntegrationWarning])

  const handleDetailsButtonPress = () => {
    Analytics.track(CustomEventTypes.HomeScreenDialChartSeeDetailsTapped)
    navigation.navigate('History', { resetFilters: true })
  }

  if (!isCGMFeatureAvailable) {
    return (
      <UpsellCGMBanner
        image={
          <Image
            style={styles.blurredChartImage as ImageStyle}
            source={blurredDialChartImage(isDarkMode).imageSource}
          />
        }
      />
    )
  }

  if (showByosIntegrationWarning) {
    return (
      <View style={styles.container}>
        <Image
          style={styles.blurredChartImage as ImageStyle}
          source={blurredDialChartImage(isDarkMode).imageSource}
        />
        <ByosIntegrationWarning
          thirdPartyWarning={thirdPartyWarning}
          nativeWarning={nativeWarning}
        />
      </View>
    )
  }

  const validGlucoseValue = !isNaN(Number(primaryStatDisplayValue))

  return (
    <View style={styles.container}>
      <WithWalkthrough
        isVisible={isStepVisible(HomeScreenWalkthroughSteps.DialChart)}
        onClose={advanceWalkthroughToNextStep}
        allowChildInteraction={false}
        accessibilityLabel="Glucose Dial Chart"
        content={
          <WalkthroughTooltip
            title="Your Glucose at a Glance"
            subtitle={'See your latest reading. \n Tap to visit your full interactive chart.'}
          />
        }
        placement="bottom"
      >
        <View style={styles.walkthroughFocusContainer}>
          <GlucoseArcChart
            title={validGlucoseValue ? glucoseUnits : 'No Data'}
            timestamp={lastReadingTime ? lastReadingTime.fromNow() : ''}
            value={validGlucoseValue ? Number(primaryStatDisplayValue) : undefined}
            minValue={glucoseLowThreshold - GRAPH_LIMITS_PADDING}
            maxValue={glucoseHighThreshold + GRAPH_LIMITS_PADDING}
          />
          <TouchableOpacity
            accessibilityLabel="see daily details"
            onPress={handleDetailsButtonPress}
            style={styles.detailsButton}
          >
            <Text type="regular" bold>
              See Details
            </Text>
          </TouchableOpacity>
        </View>
      </WithWalkthrough>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    height: 220,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  },
  blurredChartImage: {
    height: 220,
    width: '100%',
  },
  detailsButton: {
    backgroundColor: 'theme.secondary.base',
    alignSelf: 'center',
    justifyContent: 'center',
    height: 32,
    width: 'auto',
    borderRadius: 32,
    paddingHorizontal: 16,
    marginTop: 16,
    marginBottom: 16,
    alignItems: 'center',
  },
  walkthroughFocusContainer: {
    borderRadius: 24,
    backgroundColor: 'theme.background',
    paddingTop: 12,
    paddingHorizontal: 16,
  },
})
