import React from 'react'
import { useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { InlineAlert, useActionBottomSheet } from '@src/components'
import { RootStackParamList } from '@src/navigation/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { UiInlineAlert } from '@src/types'

export const useDataDelayBottomSheet = () => {
  const showActionBottomSheet = useActionBottomSheet()
  const loadingRef = useRef(false)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const styles = useStyleSheet(themedStyle)

  const onActionPress = () => {
    if (loadingRef.current) {
      return
    }

    navigation.navigate('AddMeal')
  }

  const primaryButton = {
    text: 'Log Meal',
    onPress: onActionPress,
  }

  const title = 'Data Delay from Sensor'
  const bodyText =
    'Glucose data will appear in the Nutrisense app with ' +
    'a 3-hour delay due to syncing with Dexcom and Apple Health/Google Fit.'

  const body = (
    <>
      <Text type="regular" style={styles.text}>
        {bodyText}
      </Text>
      <InlineAlert
        category={UiInlineAlert.Success}
        message="Log meals and activities now to receive insights once data is available"
        style={styles.alert}
      />
    </>
  )

  return () => showActionBottomSheet({ title, body, primaryButton })
}

const themedStyle = StyleService.create({
  text: {
    marginTop: 24,
    marginHorizontal: 24,
    textAlign: 'center',
  },
  alert: {
    marginHorizontal: 8,
    marginTop: 24,
  },
})
