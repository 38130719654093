import ACTIVATE_DISCOUNT from '@src/screens/Marketplace/graphql/activateDiscount'
import { MarketplaceStoreState } from '@src/screens/Marketplace/types/types'
import { Model } from '@models'
import { Subscription } from '@src/types'
import { reducers, updatedSubscriptionsPayload } from '@src/screens/Marketplace/reducers'
import { subscriptionsSelector } from '@src/selectors/app'
import PURCHASE_SUBSCRIPTION from '@src/screens/Marketplace/graphql/purchaseSubscription'
import UPGRADE_SUBSCRIPTION_TRIAL from '@src/screens/Marketplace/graphql/upgradeSubscriptionTrial'
import UPGRADE_SUBSCRIPTION_PRODUCT from '@src/screens/Marketplace/graphql/upgradeSubscriptionProduct'
import ESTIMATE_TAX from '@src/screens/Marketplace/graphql/estimateTax'
import PURCHASE_ONE_TIME_PAYMENT from '@src/screens/Marketplace/graphql/purchaseOneTimePayment'
import FETCH_INVOICE from '@screens/Marketplace/graphql/fetchInvoice'
import PAUSE_SUBSCRIPTION from '../graphql/pauseSubscription'
import UNCANCEL_SUBSCRIPTION from '../graphql/uncancelSubscription'
import SET_SUBSCRIPTION_BILLING_CYCLE from '../graphql/setSubscriptionBillingCycle'
import CANCEL_SUBSCRIPTION from '../graphql/cancelSubscription'
import CREATE_PROVIDER_SETUP_INTENT from '../graphql/createProviderSetupIntent'
import SCHEDULE_SUBSCRIPTION_CHANGE from '../graphql/scheduleSubscriptionChange'
import FETCH_SUBSCRIPTION_SCHEDULES from '../graphql/fetchSubscriptionSchedules'

export default class Marketplace {
  namespace = 'marketplace'

  state: MarketplaceStoreState = {
    promotionCode: null,
    estimatedTax: null,
    subscriptionSchedules: [],
  }

  effects = {
    purchaseSubscription: Model.buildEffect({
      name: `${this.namespace}/purchaseSubscription`,
      query: PURCHASE_SUBSCRIPTION,
      reducers: [{ name: 'subscriptionPurchased' }],
    }),
    upgradeSubscriptionTrial: Model.buildEffect({
      name: `${this.namespace}/upgradeSubscriptionTrial`,
      query: UPGRADE_SUBSCRIPTION_TRIAL,
      reducers: [{ name: 'subscriptionPurchased' }],
    }),
    upgradeSubscriptionProduct: Model.buildEffect({
      name: `${this.namespace}/upgradeSubscriptionProduct`,
      query: UPGRADE_SUBSCRIPTION_PRODUCT,
      reducers: [{ name: 'subscriptionPurchased' }],
    }),
    estimateTax: Model.buildEffect({
      name: `${this.namespace}/estimateTax`,
      query: ESTIMATE_TAX,
    }),
    purchaseOneTimePayment: Model.buildEffect({
      name: `${this.namespace}/purchaseOneTimePayment`,
      query: PURCHASE_ONE_TIME_PAYMENT,
      reducers: [{ name: 'subscriptionPurchased' }],
    }),
    createProviderSetupIntent: Model.buildEffect({
      name: `${this.namespace}/createProviderSetupIntent`,
      query: CREATE_PROVIDER_SETUP_INTENT,
    }),
    pauseSubscription: Model.buildEffect({
      name: `${this.namespace}/pauseSubscription`,
      query: PAUSE_SUBSCRIPTION,
      dataPath: 'pauseSubscription',
      reducers: [{ name: 'app/updateUserState', payload: updatedSubscriptionsPayload }],
    }),
    uncancelSubscription: Model.buildEffect({
      name: `${this.namespace}/uncancelSubscription`,
      query: UNCANCEL_SUBSCRIPTION,
      dataPath: 'uncancelSubscription',
      reducers: [{ name: 'app/updateUserState', payload: updatedSubscriptionsPayload }],
    }),
    setSubscriptionBillingCycle: Model.buildEffect({
      name: `${this.namespace}/setSubscriptionBillingCycle`,
      query: SET_SUBSCRIPTION_BILLING_CYCLE,
      dataPath: 'setSubscriptionBillingCycle',
      reducers: [{ name: 'app/updateUserState', payload: updatedSubscriptionsPayload }],
    }),
    scheduleSubscriptionChange: Model.buildEffect({
      name: `${this.namespace}/scheduleSubscriptionChange`,
      query: SCHEDULE_SUBSCRIPTION_CHANGE,
      dataPath: 'scheduleSubscriptionChange',
      reducers: [{ name: 'app/updateUserState', payload: updatedSubscriptionsPayload }],
    }),
    cancelSubscription: Model.buildEffect({
      name: `${this.namespace}/cancelSubscription`,
      query: CANCEL_SUBSCRIPTION,
      dataPath: 'cancelSubscription',
      reducers: [
        {
          name: 'app/updateUserState',
          *payload(canceledSubscription: Subscription, { select }: any) {
            const subscriptions: Subscription[] = yield select(subscriptionsSelector)

            const updatedSubscriptions = subscriptions.map((subscription) =>
              subscription.id === canceledSubscription.id ? canceledSubscription : subscription,
            )

            return {
              subscriptions: updatedSubscriptions,
            }
          },
        },
        { name: 'users/fetch' },
      ],
    }),
    activateDiscount: Model.buildEffect({
      name: `${this.namespace}/activateDiscount`,
      query: ACTIVATE_DISCOUNT,
      reducers: [{ name: 'updateDiscount' }],
      errorReducers: [{ name: 'updateDiscount' }], // remove promotion code on error
    }),
    fetchSubscriptionSchedules: Model.buildEffect({
      name: `${this.namespace}/fetchSubscriptionSchedules`,
      query: FETCH_SUBSCRIPTION_SCHEDULES,
      dataPath: 'allSubscriptionSchedules',
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'schedules',
          storePath: 'subscriptionSchedules',
        },
      ],
    }),
    fetchInvoice: Model.buildEffect({
      name: `${this.namespace}/fetchInvoice`,
      query: FETCH_INVOICE,
      dataPath: 'invoice',
    }),
  }

  reducers = reducers
}
