import React from 'react'
import { View, ViewStyle } from 'react-native'
import { MultiSliderProps } from '@ptomasroos/react-native-multi-slider'
import { upperCase } from 'lodash'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { Text } from '@components/base'
import { Slider } from '@components'

interface DescriptiveSliderProps extends MultiSliderProps {
  descriptionsLookup: { [key: number]: string }
  value: number
  min?: number
  max?: number
  onValueChanged: (value: number) => void
  style?: ViewStyle
}

export const DescriptiveSlider = (props: DescriptiveSliderProps) => {
  const styles = useStyleSheet(themedStyle)
  const {
    descriptionsLookup,
    value,
    min = 1,
    max = 10,
    onValueChanged,
    style,
    ...sliderProps
  } = props

  const onValuesChange = (values: number[]) => {
    onValueChanged(values[0])
  }

  return (
    <View style={style}>
      <View style={styles.header}>
        <Text type="tiny" bold>
          {upperCase(descriptionsLookup[value])}
        </Text>
      </View>
      <Slider
        min={min}
        max={max}
        step={1}
        snapped
        values={[value]}
        onValuesChange={onValuesChange}
        {...sliderProps}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 16,
  },
})
