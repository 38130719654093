import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Text } from '@components/base'
import { InlineAlert } from '@src/components/InlineAlert'
import { UiInlineAlert } from '@src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useDataDelayBottomSheet } from '@src/screens/OwnSensorOnboarding/hooks/useDataDelayBottomSheet'
import { eventsChartsSelector, eventsObjectsSelector } from '@src/selectors/events'
import { useDataStaleBottomSheet } from '@src/screens/OwnSensorOnboarding/hooks/useDataStaleBottomSheet'
import { Storage } from '@src/utils'
// eslint-disable-next-line max-len
import { useShowByosThirdPartyDelaySyncInfo } from '@src/screens/OwnSensorOnboarding/hooks/useShowByosThirdPartyDelaySyncInfo'
import { EventsItemType } from '../../models/events.types'

export const ByosDelaySyncInfo = () => {
  const hidePopup = useShowByosThirdPartyDelaySyncInfo()
  const styles = useStyleSheet(themedStyles)
  const showDataDelayBottomSheet = useDataDelayBottomSheet()
  const showDataStaleBottomSheet = useDataStaleBottomSheet()

  // this data is only from today
  const { primary } = useSelector(eventsChartsSelector<EventsItemType>(eventsObjectsSelector))

  if (!hidePopup) {
    return null
  }

  let dataFresh = Storage.get(Storage.GLUCOSE_RECENT_KEY)

  // if we have never detected data from today, or never checked, check again
  if (!dataFresh) {
    const glucoseMeasurements = primary.values.filter(
      (value) => value.tag === 'Glucose' && value.y !== null,
    )

    const lastGlucoseMeasurement = glucoseMeasurements[glucoseMeasurements.length - 1]

    // if we have data from today, set the flag and never check it again
    dataFresh = lastGlucoseMeasurement?.x?.isSame(moment(), 'day')
    if (dataFresh) {
      Storage.set(Storage.GLUCOSE_RECENT_KEY, true)
    }
  }

  const infoMessage = dataFresh
    ? 'Reminder, there is a 3 hour delay from the Dexcom app to the Nutrisense app.'
    : 'Have not received data from today.'

  const onPress = () => {
    if (dataFresh) {
      showDataDelayBottomSheet()
    } else {
      showDataStaleBottomSheet()
    }
  }

  return (
    <TouchableOpacity
      accessibilityLabel="BYOS Data Delay"
      activeOpacity={0.7}
      style={styles.wrapperStyle}
      onPress={onPress}
    >
      <InlineAlert style={styles.popup} category={UiInlineAlert.Info} message={infoMessage} />
      <Text type="regular" bold style={styles.textStyle}>
        Learn more
      </Text>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  wrapperStyle: {
    justifyContent: 'center',
    marginHorizontal: 16,
    marginTop: 8,
  },
  popup: {
    paddingRight: 96,
  },
  textStyle: {
    position: 'absolute',
    right: 16,
    color: 'theme.text.link',
  },
})
