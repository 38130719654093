import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { uxSensorModeKindSelector } from '@src/selectors/app'
import { UserExperienceSensorModeKind } from '@src/types'

const ELIGIBLE_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.Default,
  UserExperienceSensorModeKind.PendingLibre3,
  UserExperienceSensorModeKind.PendingResearchDexcom,
]

export const useShowScanDisableAnnouncement = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const isScanDisableAnnouncementEnabled = useFeatureFlag(Feature.ScanDisableAnnouncements)
  // needed in cases when redux state is not yet available
  if (!uxSensorModeKind) {
    return false
  }

  const userInEligibleSensorMode = ELIGIBLE_SENSOR_MODES.includes(uxSensorModeKind)

  return isScanDisableAnnouncementEnabled && userInEligibleSensorMode
}
