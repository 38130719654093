export const HISTORY_DEFAULT_SORT_STATE = {
  orderBy: 'occurred_at',
  order: 'descending',
  types: {},
  query: '',
  startDate: '',
  endDate: '',
  startHour: 0,
  endHour: 24,
}
