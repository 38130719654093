import { gql } from '@apollo/client'

export const APPOINTMENT_RECURRENCE_FIELDS = gql`
  fragment AppointmentRecurrenceFields on EhrAppointmentRecurrence {
    id
    cadence
    days
    timeSlots
    numberOfCalls
  }
`
