import arrowRight from './source/arrow-right.png'
import bandage from './source/bandage.png'
import bluetooth from './source/bluetooth.png'
import blurredArcIndicatorsDark from './source/blurred-arc-indicators-dark.jpg'
import blurredArcIndicators from './source/blurred-arc-indicators.jpg'
import blurredDialChartDark from './source/blurred-dial-chart-dark.jpg'
import blurredDialChart from './source/blurred-dial-chart.jpg'
import blurredGlucoseChartDark from './source/blurred-glucose-chart-dark.jpg'
import blurredGlucoseChart from './source/blurred-glucose-chart.jpg'
import boxContent from './source/box-content.png'
import calendarHeart from './source/calendar-heart.png'
import calendar from './source/calendar.png'
import cgmDexcom from './source/cgm-dexcom.png'
import cgmLibre from './source/cgm-libre.png'
import cgm from './source/cgm.jpg'
import coursesModuleDescription from './source/courses-module-description.png'
import dangerSign from './source/danger-sign.png'
import dexcomG6Box from './source/dexcom-g6-box.png'
import dexcomG7Box from './source/dexcom-g7-box.png'
import dexcomSteloBox from './source/dexcom-stelo-box.png'
import glucoseChartMeal from './source/glucose-chart-meal.png'
import glucoseEducation from './source/glucose-education.png'
import glucoseScoreWheelDark from './source/glucose-score-wheel-dark.png'
import glucoseScoreWheel from './source/glucose-score-wheel.png'
import goalsCircles from './source/goals-circles.png'
import googleFitPermissionDark from './source/google-fit-permission-dark.png'
import googleFitPermissionLight from './source/google-fit-permission-light.png'
import healthKitPermissionDark from './source/health-kit-permission-dark.png'
import healthKitPermissionLight from './source/health-kit-permission-light.png'
import hourglass from './source/hourglass.png'
import imageMeal1 from './source/image-meal-1.jpg'
import imageMeditate1 from './source/image-meditate-1.jpg'
import imageReadyToScan1 from './source/image-ready-to-scan.png'
import installCgm from './source/install-cgm.png'
import karaVideo from './source/kara-video.png'
import knowledgeBaseDark from './source/knowledge-base-dark.png'
import knowledgeBaseLight from './source/knowledge-base-light.png'
import libre1 from './source/libre1.png'
import libre2 from './source/libre2.png'
import libre3 from './source/libre3.png'
import libre3Bluetooth from './source/libre3bluetooth.png'
import membershipCardBg from './source/membership-card-bg.png'
import notificationsPermissionDark from './source/notifications-permission-dark.png'
import notificationsPermissionLight from './source/notifications-permission-light.png'
import nutritionist from './source/nutritionist.jpeg'
import runner from './source/runner.png'
import shower from './source/shower.png'
import upgradeToDexcomSensor from './source/upgrade-to-dexcom-sensor.png'
import videoCall from './source/video-call.jpeg'

import walkthroughAppDark from './source/walktrough/app-dark.png'
import walkthroughApp from './source/walktrough/app.png'
import walkthroughCommunity from './source/walktrough/community.png'
import walkthroughEducationDark from './source/walktrough/education-dark.png'
import walkthroughEducation from './source/walktrough/education.png'
import walkthroughNutritionists from './source/walktrough/nutritionists.png'

export const imageMeditate1Bg = {
  imageSource: imageMeditate1,
}

export const imageMeal1Bg = {
  imageSource: imageMeal1,
}

export const imageReadyToScan = {
  imageSource: imageReadyToScan1,
}

export const glucoseEducationImage = {
  imageSource: glucoseEducation,
}

export const installCgmImage = {
  imageSource: installCgm,
}

export const boxContentImage = {
  imageSource: boxContent,
}

export const calendarImage = {
  imageSource: calendar,
}

export const showerImage = {
  imageSource: shower,
}

export const dangerSignImage = {
  imageSource: dangerSign,
}

export const hourglassImage = {
  imageSource: hourglass,
}

export const bandageImage = {
  imageSource: bandage,
}

export const knowledgeBaseLightImage = {
  imageSource: knowledgeBaseLight,
}

export const knowledgeBaseDarkImage = {
  imageSource: knowledgeBaseDark,
}

export const coursesModuleDescriptionImage = {
  imageSource: coursesModuleDescription,
}

export const membershipCardBgImage = {
  imageSource: membershipCardBg,
}

export const notificationsPermissionLightImage = {
  imageSource: notificationsPermissionLight,
}

export const notificationsPermissionDarkImage = {
  imageSource: notificationsPermissionDark,
}

export const healthKitPermissionLightImage = {
  imageSource: healthKitPermissionLight,
}

export const healthKitPermissionDarkImage = {
  imageSource: healthKitPermissionDark,
}

export const googleFitPermissionLightImage = {
  imageSource: googleFitPermissionLight,
}

export const googleFitPermissionDarkImage = {
  imageSource: googleFitPermissionDark,
}

export const cgmImage = {
  imageSource: cgm,
}

export const walkthroughAppImage = {
  imageSource: walkthroughApp,
}

export const walkthroughAppDarkImage = {
  imageSource: walkthroughAppDark,
}

export const walkthroughCommunityImage = {
  imageSource: walkthroughCommunity,
}

export const walkthroughEducationImage = {
  imageSource: walkthroughEducation,
}

export const walkthroughEducationDarkImage = {
  imageSource: walkthroughEducationDark,
}

export const walkthroughNutritionistsImage = {
  imageSource: walkthroughNutritionists,
}

export const goalsQuestionnaireGroupImage = {
  imageSource: goalsCircles,
}

export const arrowRightImage = {
  imageSource: arrowRight,
}

export const bluetoothImage = {
  imageSource: bluetooth,
}

export const cgmDexcomImage = {
  imageSource: cgmDexcom,
}

export const cgmLibreImage = {
  imageSource: cgmLibre,
}

export const libre1SensorBoxImage = {
  imageSource: libre1,
}

export const libre2SensorBoxImage = {
  imageSource: libre2,
}

export const libre3SensorBoxImage = {
  imageSource: libre3,
}

export const libre3BluetoothImage = {
  imageSource: libre3Bluetooth,
}

export const nutritionistImage = {
  imageSource: nutritionist,
}

export const calendarHeartImage = {
  imageSource: calendarHeart,
}

export const blurredGlucoseChartImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredGlucoseChartDark : blurredGlucoseChart,
})

export const blurredArcIndicatorsImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredArcIndicatorsDark : blurredArcIndicators,
})

export const blurredDialChartImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredDialChartDark : blurredDialChart,
})

export const videoCallImage = {
  imageSource: videoCall,
}

export const dexcomG6BoxImage = {
  imageSource: dexcomG6Box,
}

export const dexcomG7BoxImage = {
  imageSource: dexcomG7Box,
}

export const dexcomSteloBoxImage = {
  imageSource: dexcomSteloBox,
}

export const upgradeToDexcomSensorImage = {
  imageSource: upgradeToDexcomSensor,
}

export const runnerImage = {
  imageSource: runner,
}

export const glucoseScoreWheelImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? glucoseScoreWheelDark : glucoseScoreWheel,
})

export const karaVideoImage = {
  imageSource: karaVideo,
}

export const glucoseChartMealImage = {
  imageSource: glucoseChartMeal,
}
