import React, { useMemo, useRef, useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { cgmDexcomImage, cgmLibreImage } from '@src/assets/images'
import { Button, Icon, Text } from '@components/base'
import {
  AppStackParamList,
  CommonAuthorizedStackParamList,
  OnboardingStackParamsList,
  SubscriptionsStackParamsList,
} from '@src/navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import {
  SensorModel,
  SubscriptionCheckoutOptionKind,
  SurveysConfigKind,
  SurveysUserLinkState,
} from '@src/types'
import { openUrl, useDispatchAsync, useSnack, Zendesk } from '@utils'
import { researchFaqsSelector, userSelector } from '@src/selectors/app'
import { Feature, ScrollViewWithFade, useFeatureFlag } from '@src/components'
import { SUPPORT_EMAIL } from '@src/config/constants'
import { useNavigateToCheckout } from '../../hooks/useNavigateToCheckout'
import { SensorOption, SensorConfig, SelectedSensor } from './SensorOption'

const FADE_BUFFER = 24
const libre1Config: SensorConfig = {
  sensor: SelectedSensor.Default,
  name: 'Freestyle Libre 14-Day',
  highlight: 'Manually Scan Every 8 Hours',
  benefits: [
    '2 x 14-day sensors (28 days) per month',
    'Water resistant to 3ft',
    'Requires prescription',
  ],
  image: cgmLibreImage,
  model: SensorModel.LibreUs,
  checkoutOptionKind: SubscriptionCheckoutOptionKind.Prescription,
}

const libre3Config: SensorConfig = {
  sensor: SelectedSensor.Default,
  name: 'FreeStyle Libre 3',
  highlight: '28 Days Tracking (2x14-day CGMs)',
  benefits: ['Water resistant to 3ft', 'Bluetooth Data Syncing', 'Requires prescription'],
  image: cgmLibreImage,
  model: SensorModel.Libre3,
  tagIcon: 'bluetooth',
  checkoutOptionKind: SubscriptionCheckoutOptionKind.Prescription,
}

const researchConfig: SensorConfig = {
  sensor: SelectedSensor.Research,
  name: 'Dexcom G7',
  highlight: '30 Days Tracking (3x10-day CGMs)',
  benefits: ['Waterproof to 8ft', 'Bluetooth Data Syncing', 'Requires research opt-in*'],
  image: cgmDexcomImage,
  model: SensorModel.DexcomG7,
  tagIcon: 'bluetooth',
  checkoutOptionKind: SubscriptionCheckoutOptionKind.Research,
}

export const SensorSelection = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const route = useRoute<
    | RouteProp<OnboardingStackParamsList, 'SensorSelection'>
    | RouteProp<SubscriptionsStackParamsList, 'SensorSelection'>
    | RouteProp<CommonAuthorizedStackParamList, 'SensorSelection'>
  >()

  const loadingRef = useRef(false)
  const showSnack = useSnack()

  const user = useSelector(userSelector)
  const researchFaqs = useSelector(researchFaqsSelector)

  const isLibre3DefaultEnabled = useFeatureFlag(Feature.Libre3Default)
  const disableLibre3OptIn = useFeatureFlag(Feature.DisableLibre3OptIn)

  const { product, savings, requireEligibilityCheck = false, sensorOptions = undefined } =
    route.params ?? {}

  const navigateToCheckout = useNavigateToCheckout()

  const sensorConfig: Record<SelectedSensor, SensorConfig> = useMemo(() => {
    return {
      [SelectedSensor.Default]: isLibre3DefaultEnabled ? libre3Config : libre1Config,
      [SelectedSensor.Research]: researchConfig,
    }
  }, [isLibre3DefaultEnabled])

  const parsedSensorOptions = Object.values(sensorConfig)
    .map((sensor) => sensor.model)
    .filter((model) => !sensorOptions || sensorOptions.includes(model))

  const selectedSensorDefault =
    parsedSensorOptions.length === 1
      ? (Object.keys(sensorConfig).find(
          (key) => sensorConfig[key as SelectedSensor].model === parsedSensorOptions[0],
        ) as SelectedSensor)
      : SelectedSensor.Research

  const [selectedSensor, setSelectedSensor] = useState<SelectedSensor>(selectedSensorDefault)

  const isOnlyLibre =
    parsedSensorOptions?.length === 1 &&
    (parsedSensorOptions[0] === SensorModel.LibreUs ||
      parsedSensorOptions[0] === SensorModel.Libre3)

  const disabledNextButton = !selectedSensor || (isOnlyLibre && disableLibre3OptIn)

  const handleSelect = (sensor: SelectedSensor) => {
    setSelectedSensor(sensor)
  }

  const handleDataNoticePress = () => {
    openUrl(researchFaqs.dataProtectionUrl)
  }

  const handleEligibilityFAQPress = () => {
    openUrl(researchFaqs.eligibilityUrl)
  }

  const handleNextStepPress = async () => {
    if (loadingRef.current || !selectedSensor) {
      return
    }

    // We're in the checkout process
    if (product) {
      const checkoutOptionKinds = [sensorConfig[selectedSensor].checkoutOptionKind]
      // A subscription will be provided when sensor selection is presented as part of checkout
      navigateToCheckout({ product, requireEligibilityCheck, checkoutOptionKinds, savings })
      return
    }

    const surveyLinks = user?.surveyLinks || []

    if (selectedSensor === SelectedSensor.Default) {
      loadingRef.current = true
      try {
        await dispatchAsync({
          type: 'app/selectSensor',
          payload: { model: sensorConfig[selectedSensor].model },
        })

        await dispatchAsync({ type: 'users/fetch' })

        const isHealthSurveyCompleted = surveyLinks.some(
          (surveyLink) =>
            surveyLink.survey.kind === SurveysConfigKind.Health && surveyLink.finished,
        )

        if (!isHealthSurveyCompleted) {
          navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.Health })
          return
        }

        const isInsuranceSurveyCompleted = surveyLinks.some(
          (surveyLink) =>
            surveyLink.survey.kind === SurveysConfigKind.Insurance && surveyLink.finished,
        )

        if (isInsuranceSurveyCompleted) {
          navigation.replace('Drawer', { screen: 'Dashboard' })
          return
        }

        navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.Insurance })
      } catch {
        showSnack('Failed to select sensor. Please try again.', null, 'warning')
      } finally {
        loadingRef.current = false
      }
    } else {
      const isMedicalHistoryQuestionnaireCompleted = surveyLinks.some(
        (surveyLink) =>
          surveyLink.survey.kind === SurveysConfigKind.MedicalHistory &&
          surveyLink.finished &&
          surveyLink.state !== SurveysUserLinkState.Stopped,
      )

      loadingRef.current = true
      try {
        await dispatchAsync({
          type: 'app/selectSensor',
          payload: { model: sensorConfig[selectedSensor].model },
        })
      } catch {
        showSnack('Failed to select sensor. Please try again.', null, 'warning')
        return
      } finally {
        loadingRef.current = false
      }

      const questionnaire = isMedicalHistoryQuestionnaireCompleted
        ? SurveysConfigKind.DexcomResearchConsent
        : SurveysConfigKind.MedicalHistory

      navigation.replace('Questionnaire', { questionnaire })
    }
  }

  const handleContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  return (
    <NavigationContainer
      title="Sensor Preference"
      rightAccessories={[
        {
          renderIconComponent: () => (
            <View style={styles.questionIcon}>
              <Icon name="question" />
            </View>
          ),
          onPress: () => openUrl(researchFaqs.sensorComparisonUrl),
          accessibilityLabel: 'FAQ',
        },
      ]}
    >
      <SafeAreaView style={styles.content} edges={['bottom']}>
        <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
          {isOnlyLibre && !disableLibre3OptIn && (
            <Text type="large" bold style={styles.headerText}>
              As you have opted out of the research program, we’ll get you set up with the
              prescription Libre Freestyle sensor:
            </Text>
          )}
          {parsedSensorOptions.includes(SensorModel.DexcomG7) && (
            <>
              <SensorOption
                config={sensorConfig.research}
                selected={selectedSensor === SelectedSensor.Research}
                onSelect={() => handleSelect(SelectedSensor.Research)}
              />
              <Text type="small" style={styles.dataNotice}>
                *Participant data is{' '}
                <Text type="small" onPress={handleDataNoticePress} style={styles.dataNoticeLink}>
                  de-identified and protected
                </Text>{' '}
                at all times.
              </Text>
            </>
          )}
          {(parsedSensorOptions.includes(SensorModel.LibreUs) ||
            parsedSensorOptions.includes(SensorModel.Libre3)) && (
            <SensorOption
              config={sensorConfig.default}
              selected={selectedSensor === SelectedSensor.Default && !disableLibre3OptIn}
              onSelect={() => handleSelect(SelectedSensor.Default)}
            />
          )}
          {parsedSensorOptions.length > 1 && (
            <Text type="small" style={styles.eligibilityNotice}>
              Your eligibility for your preferred sensor will be confirmed in a later step.{' '}
              <Text
                type="small"
                style={styles.eligibilityNoticeLink}
                onPress={handleEligibilityFAQPress}
              >
                Learn more here
              </Text>
              .
            </Text>
          )}
          {isOnlyLibre && disableLibre3OptIn && (
            <Text type="large" style={{ marginTop: 8 }}>
              Need help? Please reach out to
              <TouchableOpacity
                accessibilityLabel="contactSupport"
                onPress={handleContactSupportPress}
              >
                <Text type="large" lineSpacing="none" style={styles.link}>
                  {SUPPORT_EMAIL}
                </Text>
              </TouchableOpacity>
            </Text>
          )}
        </ScrollViewWithFade>
        <Button
          accessibilityLabel="Next"
          disabled={disabledNextButton}
          onPress={handleNextStepPress}
          style={styles.nextButton}
          size="block"
          type="primary"
        >
          Next
        </Button>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },
  headerText: {
    marginBottom: 16,
  },
  questionIcon: {
    padding: 12,
    marginRight: 4,
  },
  dataNotice: {
    marginTop: 4,
    marginBottom: 12,
  },
  dataNoticeLink: {
    textDecorationLine: 'underline',
  },
  eligibilityNotice: {
    marginTop: 12,
  },
  eligibilityNoticeLink: {
    color: 'theme.info.base',
    textDecorationLine: 'underline',
  },
  nextButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
  link: {
    color: 'theme.text.link',
  },
})
