import { useSelector } from 'react-redux'
import {
  onboardingSkippedSelector,
  uiStatesSelector,
  uxSensorModeKindSelector,
} from '@src/selectors/app'
import { UiStateNames, UserExperienceSensorModeKind } from '@src/types'
import { TutorialSensorKind, TutorialGroups } from '@src/screens/Tutorials/models/tutorials.types'
import { Feature, useFeatureFlag } from '@src/components'
import { isByosOnboardingSensorMode } from '@src/screens/OwnSensorOnboarding/utils'
import { SENSOR_MODE_TUTORIAL } from '@src/screens/OwnSensorOnboarding/types'
import { useHasOwnSensorSubscription } from '@src/hooks/useHasOwnSensorSubscription'

export const useShouldShowByosOnboardingTutorials = () => {
  const onboardingSkipped = useSelector(onboardingSkippedSelector)
  const shouldShowByosOnboarding = useShouldShowByosOnboarding()
  const uiStates = useSelector(uiStatesSelector)

  if (!shouldShowByosOnboarding || onboardingSkipped) {
    return false
  }

  const tutorialCompletedUiState = uiStates.find(
    (uiState) => uiState.name === UiStateNames.OwnSensorTutorialCompleted,
  )
  const tutorialCompleted = tutorialCompletedUiState?.value || false

  return !tutorialCompleted
}

export const useShouldShowByosOnboarding = () => {
  const onboardingSkipped = useSelector(onboardingSkippedSelector)
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const uiStates = useSelector(uiStatesSelector)
  const byosOnboardingEnabled = useFeatureFlag(Feature.ByosOnboarding)
  const hasOwnSensorSubscription = useHasOwnSensorSubscription()

  if (
    !byosOnboardingEnabled ||
    onboardingSkipped ||
    !hasOwnSensorSubscription ||
    !isByosOnboardingSensorMode(uxSensorModeKind)
  ) {
    return false
  }

  const onboardingCompletedUiState = uiStates.find(
    (uiState) => uiState.name === UiStateNames.OwnSensorOnboardingCompleted,
  )
  const onboardingCompleted = onboardingCompletedUiState?.value || false

  return !onboardingCompleted
}

export const useByosOnboardingTutorial = (): {
  group: TutorialGroups
  sensorKind?: TutorialSensorKind
} => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const byosOnboardingSensorMode = isByosOnboardingSensorMode(uxSensorModeKind)
  if (!byosOnboardingSensorMode) {
    return { group: TutorialGroups.NoTutorialGroup }
  }

  return SENSOR_MODE_TUTORIAL[uxSensorModeKind]
}

const SCANNABLE_SENSOR_MODES = [
  UserExperienceSensorModeKind.OwnSensorLibre1,
  UserExperienceSensorModeKind.OwnSensorLibre2,
  UserExperienceSensorModeKind.OwnSensorLibre3,
]
export const useIsByosScannableSensorMode = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  if (!uxSensorModeKind) {
    return false
  }

  return uxSensorModeKind && SCANNABLE_SENSOR_MODES.includes(uxSensorModeKind)
}
