import React, { useContext } from 'react'
import { Image, ImageStyle, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { NavigationBar } from '@components'
import { goalsQuestionnaireGroupImage } from '@src/assets/images'
import { QuestionnaireContext } from '../questionnaire.navigator'

export enum ProgramType {
  NutritionCoaching = 'NutritionCoaching',
  CGM = 'CGM',
}

export const GoalsQuestionnaireStartScreen = () => {
  const { startScreenConfig: config } = useContext(QuestionnaireContext)
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()

  const content = (
    <View style={styles.topLevelContainer}>
      <NavigationBar
        title="Goals Questionnaire"
        leftAccessory={{
          iconDescriptor: { pack: 'eva', name: 'arrow-ios-back' },
          onPress: config.onCloseButtonPress,
        }}
      />
      <ScrollView contentContainerStyle={styles.container} bounces={false}>
        <Text type="title-1" style={styles.title}>
          We’d love to hear about your goals!
        </Text>
        <Text type="regular">
          Tell us about your lifestyle, goals, and preferences to help your nutritionist support you
          best and customize your app experience.
        </Text>
        <Image
          source={goalsQuestionnaireGroupImage.imageSource}
          style={styles.image as ImageStyle}
        />
      </ScrollView>
      <Button
        type="primary"
        size="block"
        onPress={config.onStartButtonPress}
        accessibilityLabel="saveButton"
        style={[styles.button, { marginBottom: insets.bottom + 32 }]}
      >
        Get Started
      </Button>
    </View>
  )

  return content
}

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    marginTop: 16,
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'left',
    marginBottom: 8,
  },
  image: {
    marginTop: 49,
    marginBottom: 104,
    aspectRatio: 765 / 699,
    alignSelf: 'center',
    height: undefined,
    width: 255,
    maxHeight: 233,
  },
  button: {
    marginHorizontal: 16,
  },
})
