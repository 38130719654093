import React, { useRef, useState } from 'react'
import { TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Checkbox, Text } from '@components/base'
import { AppRouteProp, AppStackParamList } from '@src/navigation/types'
import { MarkdownView } from '@src/components/Markdown'
import { authenticatedUserSelector } from '@src/selectors/app'
import { useDispatchAsync, useGoBack, openUrl } from '@src/utils'
import { ScrollViewWithFade } from '@src/components'
import { LegalPolicyConsentStatusesQuery } from '../graphql/legalPolicyConsentStatuses.generated'

export const PolicyConsent = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatchAsync()
  const user = useSelector(authenticatedUserSelector)
  const [accepted, setAccepted] = useState(false)
  const loadingRef = useRef(false)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const route = useRoute<AppRouteProp<'PolicyConsent'>>()
  const { policy, nextScreen } = route.params

  const toggleAccepted = () => {
    setAccepted((currentlyAccepted) => !currentlyAccepted)
  }

  const onContinue = async () => {
    if (loadingRef.current) {
      return
    }
    loadingRef.current = true

    try {
      await dispatch({
        type: 'policyConsents/accept',
        payload: {
          kind: policy.kind,
        },
      })

      const { statuses } = await dispatch<
        LegalPolicyConsentStatusesQuery['legalPolicyConsentStatuses']
      >({
        type: 'policyConsents/statuses',
      })
      const requiredPolicies = statuses.flatMap((status) => (status.required ? status.policy : []))

      if (requiredPolicies.length > 0) {
        navigation.replace('PolicyConsent', { policy: requiredPolicies[0], nextScreen })
      } else if (nextScreen) {
        navigation.replace(nextScreen.screen as any, nextScreen.params)
      } else {
        goBack()
      }
    } finally {
      loadingRef.current = false
    }
  }

  const checkboxTitle = `I, ${user.fullName}, have read the ${policy.title} and agree to the terms.`
  const { markdown, url } = policy

  return (
    <View style={styles.container}>
      <ScrollViewWithFade
        fadeColor={styles.container.backgroundColor}
        scrollViewStyle={styles.scrollView}
      >
        <SafeAreaView edges={['bottom']} style={styles.contentContainer}>
          <View style={styles.policyContainer}>
            <Text type="title-1">{policy.title}</Text>
            <View style={styles.content}>
              {!!markdown && <MarkdownView content={markdown} />}
              {!!url && (
                <Text type="regular">
                  Please review the updated
                  <TouchableWithoutFeedback
                    accessibilityLabel="Privacy Policy"
                    onPress={() => openUrl(url)}
                  >
                    <Text type="regular" bold style={styles.link}>
                      {' '}
                      {policy.title}{' '}
                    </Text>
                  </TouchableWithoutFeedback>
                  and agree to the to continue using the Nutrisense app.
                </Text>
              )}
            </View>
            <TouchableOpacity
              activeOpacity={0.7}
              style={styles.checkboxContainer}
              accessibilityLabel={checkboxTitle}
              onPress={toggleAccepted}
            >
              <Text type="regular" bold style={styles.checkboxLabel}>
                {checkboxTitle}
              </Text>
              <Checkbox checked={accepted} onChange={toggleAccepted} />
            </TouchableOpacity>
          </View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Continue"
            disabled={!accepted}
            onPress={onContinue}
          >
            Continue
          </Button>
        </SafeAreaView>
      </ScrollViewWithFade>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    marginTop: 64,
    borderRadius: 24,
    backgroundColor: 'theme.background',
    overflow: 'hidden',
  },
  scrollView: {
    flexGrow: 1,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 48,
    paddingBottom: 16,
    justifyContent: 'space-between',
  },
  policyContainer: {
    paddingBottom: 32,
  },
  content: {
    marginTop: 16,
  },
  checkboxContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 16,
    marginTop: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  checkboxLabel: {
    marginRight: 20,
    flexShrink: 1,
  },
  link: {
    color: 'theme.text.link',
  },
})
