import moment, { Moment } from 'moment'
import { findLastIndex, isArray, omit, pick } from 'lodash'
import { Meal as LocalMeal } from '@screens/Events/models/events.types'
import { BaseIngredient } from '@screens/Ingredients/types'
import { ingredientsAreEqual } from '@screens/Ingredients/utils'
import { ChartValue, DraftMeal } from '@screens/Meals/types'
import { Ingredient, IngredientData, Meal, MealKind } from '@src/types'
import { generateReactNativeFile } from '@utils/image'
import {
  MEAL_COMPARISON_MINUTES_AFTER,
  MEAL_COMPARISON_MINUTES_BEFORE,
  TIME_BUFFER_IN_MINUTES,
} from '@src/screens/Meals/constants'

// `LocalMeal` is here just for avoiding TS errors in MealDetail & MealListItem
type GetDraftMealParams =
  | [meal?: Partial<Meal | DraftMeal | LocalMeal>]
  | [meal: Meal, isEditing: true]

export const getDraftMeal = (...[meal, isEditing]: GetDraftMealParams): DraftMeal => ({
  avatar: meal?.avatar,
  createdAt: meal?.createdAt,
  description: meal?.description || '',
  favorite: meal?.favorite ?? false,
  file:
    meal?.avatar?.startsWith('http') || meal?.avatar?.startsWith('file:')
      ? generateReactNativeFile({ uri: meal?.avatar })
      : null,
  hasUnsavedChanges: false,
  id: isEditing ? meal?.id || '' : '',
  ingredients: meal?.ingredients || [],
  kind: meal?.kind || getDefaultMealKind(),
  occurredAt: meal?.occurredAt,
  generatedOccurredAt: meal?.generatedOccurredAt || false,
  source: meal as Meal,
  title: meal?.title || '',
})

export const copyMeal = (meal: DraftMeal | LocalMeal) =>
  pick(meal, ['avatar', 'createdAt', 'description', 'ingredients', 'kind', 'title'])

export const getDefaultMealKind = (time: Moment = moment()): MealKind => {
  const hours = time.hour()
  if (hours > 5 && hours <= 10) {
    return MealKind.Breakfast
  } else if (hours > 10 && hours <= 15) {
    return MealKind.Lunch
  } else if (hours > 15 && hours <= 24) {
    return MealKind.Dinner
  } else {
    return MealKind.Snack
  }
}

export const hasUnsavedChanges = ({
  description = '',
  file,
  ingredients = [],
  source,
}: DraftMeal) => {
  const hasUnsavedDescription = description.trim() && description !== source?.description
  const hasUnsavedImage = !!file && file.uri !== source?.avatar
  const hasUnsavedIngredients = !ingredients.every((ingredient) =>
    source?.ingredients?.some((item) => ingredientsAreEqual(item, ingredient)),
  )

  return hasUnsavedDescription || hasUnsavedImage || hasUnsavedIngredients
}

export const transformToIngredientPayload = (
  ingredient: Ingredient | BaseIngredient,
): IngredientData => ({
  calories: ingredient.calories,
  description: ingredient.description,
  nutrition: isArray(ingredient.nutrition)
    ? ingredient.nutrition.map((item) => ({ key: item.key, value: item.value }))
    : Object.entries(ingredient.nutrition).map(([key, value]) => ({ key, value })),
  servingAmount: ingredient.servingAmount,
  servingUnits: ingredient.servingUnits,
  thirdPartyIngredientId: ingredient.thirdPartyIngredientId,
  thirdPartyIngredientSource: ingredient.thirdPartyIngredientSource,
})

export const transformToMealPayload = (draftMeal: DraftMeal) => {
  const isEditingMeal = !!draftMeal.id
  const sourceMealAvatar = draftMeal.source?.avatar
  // Pass `imageUrl` only for new meals that have the same photo as the meal they were copied from
  const imageUrl =
    sourceMealAvatar && sourceMealAvatar === draftMeal.file?.uri ? sourceMealAvatar : null

  return {
    __typename: 'Meal',
    fake: true,
    // ↑↑↑ fake data for optimistic reducer ↑↑↑

    ...(isEditingMeal ? omit(draftMeal.source, 'type') : {}),
    description: draftMeal.description || '',
    favorite: !!draftMeal.favorite,
    kind: draftMeal.kind || getDefaultMealKind(),
    imageUrl, // only for new meals that have the same photo as the meal they were copied from
    avatar: draftMeal.file?.uri, // for displaying the photo in the UI right after meal creation
    photo: !imageUrl ? draftMeal.file : null, // for uploading the photo to the server
    ingredients: draftMeal.ingredients?.map(transformToIngredientPayload) || [],
    occurredAt: moment(draftMeal.occurredAt).format(),
    // The `time` field is deprecated but we still need to pass it to account for meal updates
    time: moment(draftMeal.occurredAt).format(),
  }
}

export const getDateRangeText = (startDate: string, endDate: string) => {
  const start = moment(startDate)
  const end = moment(endDate)

  if (start.isSame(end, 'day')) {
    return start.format('MMM D')
  }

  if (start.isSame(end, 'month')) {
    return `${start.format('MMM D')}-${end.format('D')}`
  }

  return `${start.format('MMM D')} - ${end.format('MMM D')}`
}

export const getComparisonTimeBoundaries = (meal: Meal, includeBuffer?: boolean) => ({
  startTime: moment(meal.occurredAt).subtract(
    MEAL_COMPARISON_MINUTES_BEFORE + (includeBuffer ? TIME_BUFFER_IN_MINUTES : 0),
    'minutes',
  ),
  endTime: moment(meal.occurredAt).add(
    MEAL_COMPARISON_MINUTES_AFTER + (includeBuffer ? TIME_BUFFER_IN_MINUTES : 0),
    'minutes',
  ),
})

export const getCompareMealsChartColors = (theme: Record<string, string>): string[] => [
  theme['theme.range.regular'],
  theme['theme.text.link'],
]

export const withinMealComparisonTimeRange = (meal: Meal, items: ChartValue[]) => {
  const { startTime, endTime } = getComparisonTimeBoundaries(meal)
  const sortedItems = items
    .map((item) => ({ ...item, x: moment(item.x) }))
    .sort((a, b) => (a.x.isBefore(b.x) ? -1 : 1))

  const indexBeforeFirst = findLastIndex(sortedItems, (item) => item.x.isBefore(startTime))
  const indexAfterLast = sortedItems.findIndex((item) => item.x.isAfter(endTime))

  const includePrevious =
    startTime.diff(sortedItems[indexBeforeFirst].x, 'minutes') < TIME_BUFFER_IN_MINUTES
  const includeNext =
    sortedItems[indexAfterLast].x.diff(endTime, 'minutes') < TIME_BUFFER_IN_MINUTES

  const previous = includePrevious ? [{ ...sortedItems[indexBeforeFirst], x: startTime }] : []
  const next = includeNext ? [{ ...sortedItems[indexAfterLast], x: endTime }] : []

  return [...previous, ...sortedItems.slice(indexBeforeFirst + 1, indexAfterLast), ...next]
}
