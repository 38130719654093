import { useIsOwnSensorGlucoseDetected } from './useIsOwnSensorGlucoseDetected'
import { useIsThirdPartyByosSensorMode } from './useIsThirdPartyByosSensorMode'
import { useShowByosDataInfoUi } from './useShowByosDataInfoUi'

export const useShowByosThirdPartyDelaySyncInfo = () => {
  const shouldShowByosDataDelayUi = useShowByosDataInfoUi()
  const isOwnSensorGlucoseDetected = useIsOwnSensorGlucoseDetected()
  const isThirdPartyByosSensorMode = useIsThirdPartyByosSensorMode()

  if (!shouldShowByosDataDelayUi || !isOwnSensorGlucoseDetected) {
    return false
  }

  return isThirdPartyByosSensorMode
}
