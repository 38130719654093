export const energyDescriptionsLookup = {
  1: 'Very Low Energy',
  2: 'Low Energy',
  3: 'Moderate Energy',
  4: 'High Energy',
  5: 'Very High Energy',
}

export const sleepDescriptionsLookup = {
  1: 'Very Poor Sleep',
  2: 'Poor Sleep',
  3: 'Average Sleep',
  4: 'Good Sleep',
  5: 'Excellent Sleep',
}

export const stressDescriptionsLookup = {
  1: 'Very Low Stress',
  2: 'Low Stress',
  3: 'Moderate Stress',
  4: 'High Stress',
  5: 'Very High Stress',
}

export const appetiteDescriptionsLookup = {
  1: 'Very Low Appetite',
  2: 'Low Appetite',
  3: 'Moderate Appetite',
  4: 'High Appetite',
  5: 'Very High Appetite',
}
