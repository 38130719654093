import React, { MutableRefObject } from 'react'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity, View } from 'react-native'
import { Timeline } from '@src/components/Timeline/Timeline'
import { Feature, useFeatureFlag } from '@src/components/FeatureFlag'
import { Text } from '@src/components/base'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Analytics } from '@src/config'
import { CustomEventTypes } from '@src/config/analytics'
import { DialChart } from './DialChart/DialChart'

export interface HomeScreenChartProps {
  primaryChartRef: MutableRefObject<any>
  secondaryChartRef: MutableRefObject<any>
  onChartLoadEnd: () => void
}

export const HomeScreenChart = ({
  primaryChartRef,
  secondaryChartRef,
  onChartLoadEnd,
}: HomeScreenChartProps) => {
  const isDialChartEnabled = useFeatureFlag(Feature.HomeScreenDialChart)
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyle)

  return isDialChartEnabled ? (
    <DialChart />
  ) : (
    <TouchableOpacity
      accessibilityLabel="Hero Glucose Chart"
      onPress={() => {
        Analytics.track(CustomEventTypes.HomeScreenGlucoseChartTapped)
        navigation.navigate('History', { resetFilters: true })
      }}
    >
      <View pointerEvents="none">
        <Text type="regular" bold lineSpacing="none" style={styles.chartTitle}>
          Glucose Responses
        </Text>
        <Timeline
          primaryChartRef={primaryChartRef}
          secondaryChartRef={secondaryChartRef}
          onChartLoadEnd={onChartLoadEnd}
          hideMultiSlider
          hideChartHeader
          showLastReadingTooltip
        />
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  chartTitle: {
    marginHorizontal: 16,
    marginTop: 16,
  },
})
