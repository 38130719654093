import { BillingProduct } from '@src/types'
import { useActiveSubscriptions } from './useActiveSubscriptions'

export const OWN_SENSOR_PRODUCTS = [
  BillingProduct.SoftwareOnlyOwnSensorAnnualMembership,
  BillingProduct.SoftwareOnlyOwnSensorMonthlyMembership,
]

export const useHasOwnSensorSubscription = () => {
  const subscriptions = useActiveSubscriptions()

  return subscriptions.some((subscription) => subscription.primaryProduct.ownSensor)
}

export const useGetAllOwnSensorSubscriptions = () => {
  const subscriptions = useActiveSubscriptions()

  return subscriptions.filter((subscription) => subscription.primaryProduct.ownSensor)
}
