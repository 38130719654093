import { useSelector } from 'react-redux'
import { sensorSelector } from '@src/selectors/sensor'
import { useIsOwnSensorGlucoseDetected } from './useIsOwnSensorGlucoseDetected'
import { useShowByosDataInfoUi } from './useShowByosDataInfoUi'
import { useIsNativeByosSensorMode } from './useIsNativeByosSensorMode'
import { useIsThirdPartyByosSensorMode } from './useIsThirdPartyByosSensorMode'

export const useShowByosIntegrationWarning = () => {
  const currentSensor = useSelector(sensorSelector)
  const isNativeByosSensorMode = useIsNativeByosSensorMode()

  const isOwnSensorGlucoseDetected = useIsOwnSensorGlucoseDetected()
  const isThirdPartyByosSensorMode = useIsThirdPartyByosSensorMode()

  const showByosDataInfoUi = useShowByosDataInfoUi()

  const nativeWarning = showByosDataInfoUi && isNativeByosSensorMode && !currentSensor
  const thirdPartyWarning =
    showByosDataInfoUi &&
    isThirdPartyByosSensorMode &&
    !isOwnSensorGlucoseDetected &&
    !currentSensor // eventually we wil create sensor records for third party sensors

  return { nativeWarning, thirdPartyWarning }
}

export const useIsByosIntegrationWarning = () => {
  const { nativeWarning, thirdPartyWarning } = useShowByosIntegrationWarning()

  return nativeWarning || thirdPartyWarning
}
