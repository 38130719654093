import React from 'react'
import { useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ChartTypes } from '@src/components/Timeline/constants'
import { NavigationContainer } from '@src/screens/Common/containers'
import { eventsChartsSelector, eventsObjectsSelector } from '@src/selectors/events'
import { AppRouteProp } from '@src/navigation/types'
import { ChartModal as ChartModalComponent } from '../components/ChartModal'
import { EventsItemType } from '../models/events.types'

export const ChartModalContainer = () => {
  const charts = useSelector(eventsChartsSelector<EventsItemType>(eventsObjectsSelector))
  const route = useRoute<AppRouteProp<'ZoomChart'>>()
  const styles = useStyleSheet(themedStyle)

  const { type = ChartTypes.Glucose } = route.params || {}

  return (
    <NavigationContainer
      style={styles.container}
      navigationBarProps={{ dateSelectorType: 'events', backgroundColor: 'transparent' }}
      showLoadingIndicator
    >
      <ChartModalComponent charts={charts} type={type} />
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background.modal',
  },
  chart: {
    flex: 1,
  },
})
