import moment from 'moment'
import { get } from 'lodash'
import { AuthorizeResult } from 'react-native-app-auth'
import { Apollo } from '@config'
import Device from '@config/device'
import { Caching, User } from '@utils'
import { HealthKit, KetoMojo } from '@services'
import { Model } from '@models'

import reducers from '@src/models/app.reducers'
import { transform } from '@src/transforms/clientConfig'
import { DEFAULT_CLIENT_CONFIG } from '@src/fallbacks/fetchClientConfig'
import { AppStoreState, Login } from '@src/models/app.types'
import {
  calendarDateSelector,
  healthKitIgnoreSelector,
  healthKitSourceIgnoreSelector,
  uiStatesSelector,
} from '@src/selectors/app'
import {
  healthLastSyncedSelector,
  ketoMojoLastSyncedSelector,
  ketoMojoUserSelector,
} from '@src/selectors/integrations'
import { darkModeStoreStateSelector } from '@src/selectors/settings'
import { eventsCalendarSelector } from '@src/selectors/events'
import { getLastTimestamp } from '@src/services/ThirdPartyHealth'
import { Stripe } from '@src/config'
import { Reading } from '@src/services/KetoMojo/ketoMojo'
import { HealthDataSampleResult } from '@src/services/ThirdPartyHealth/thirdPartyHealth'
import { SAVE_SURVEY_RESPONSE } from '@src/graphql/saveSurveyResponse'
import { CREATE_DIRECT_UPLOAD } from '@src/graphql/createDirectUpload'
import { CREATE_FILE_FROM_SIGNED_ID } from '@src/graphql/createFileFromSignedId'
import { DELETE_FILE } from '@src/graphql/deleteFile'
import StreamChat from '@src/services/StreamChat'
import { UPSERT_UI_STATE } from '@src/graphql/upsertUiState'
import { UiState, BillingProductCategory } from '@src/types'
import { UpsertUiStateMutation } from '@src/graphql/upsertUiState.generated'
import { UPSERT_DEXCOM_CONNECTION } from '@src/graphql/upsertDexcomConnection'
import { DELETE_DEXCOM_CONNECTION } from '@src/graphql/deleteDexcomConnection'
import { UpsertDexcomConnectionMutation } from '@src/graphql/upsertDexcomConnection.generated'
import { DarkMode } from '@src/screens/Settings'
import { SELECT_DEFAULT_SENSOR_FOR_DEXCOM_RESEARCH } from '@src/graphql/selectDefaultSensorForDexcomResearch'
import { SELECT_SENSOR } from '@src/graphql/selectSensor'
import { START_PARTICIPATION } from '@src/graphql/startDexcomResearchParticipation'
import { SEND_CHAT_CONVERSATION_MESSAGE } from '@src/graphql/sendChatConversationMessage'
import { CREATE_CHAT_CONVERSATION_NOTE } from '@src/graphql/createChatConversationNote'
import { SWITCH_SENSOR_ON_CANCELLATION } from '@src/graphql/switchSensorOnCancellation'
// eslint-disable-next-line max-len
import { VALIDATE_GLUCOSE_PRESENCE_TERRA_GOOGLE_FIT } from '@src/screens/OwnSensorOnboarding/graphql/validateGlucosePresenceTerraGoogleFit'
import { SIGN_IN_USER } from '../graphql/signInUser'
import { SIGN_IN_THIRD_PARTY_USER } from '../graphql/signInThirdPartyUser'
import { UPDATE_HEALTH_DATA } from '../graphql/updateHealthData'
import { UPDATE_KETO_MOJO_DATA } from '../graphql/updateKetoMojoData'
import { FETCH_DATA_EXPORT } from '../graphql/fetchDataExport'
import { FETCH_CLIENT_CONFIG } from '../graphql/fetchClientConfig'
import { FETCH_CALENDAR_DATA } from '../graphql/fetchCalendarData'
import { SEND_USER_INVITE } from '../graphql/sendUserInvite'
import { FETCH_QUESTIONNAIRE } from '../graphql/fetchQuestionnaire'
import { START_QUESTIONNAIRE } from '../graphql/startQuestionnaire'
import { COMPLETE_SURVEY } from '../graphql/completeSurvey'
import { FETCH_USER_FILES } from '../graphql/fetchUserFiles'
import { FETCH_PRODUCTS } from '../graphql/fetchProducts'
import {
  initCalendar,
  processDateRangePayload,
  validateDateRange,
  processCalendarData,
  todayDateRangePayload,
} from './helper'
import { unlessHasOpenedAppToday } from './utils'

const PushNotification = Device.web ? null : require('react-native-push-notification')

const loginDefaults = { success: false, user: null, features: [] }

export default class App {
  namespace = 'app'

  state: AppStoreState = {
    ...Model.defaultState,
    login: loginDefaults,
    lastOpened: moment().toISOString(),
    lastScanned: moment().toISOString(),
    calendar: initCalendar(),
    clientConfig: transform(DEFAULT_CLIENT_CONFIG).clientConfig,
    onboarding: {
      isInProgress: false,
      skipped: false,
    },
    skippedSurveys: {},
    products: [],
    productsByCategory: {},
    lastRequestStatus: null,
  }

  effects = {
    login: Model.buildEffect({
      name: `${this.namespace}/login`,
      query: SIGN_IN_USER,
      dataPath: 'signinUser',
      isSuccess: (response: any) => response?.success,
      *onSuccess(login: Login, { call }: any) {
        yield call(User.onLogin, login)
      },
      reducers: [
        {
          name: 'updateLoginState',
        },
      ],
    }),

    logout: Model.buildEffect({
      name: `${this.namespace}/logout`,
      *onSuccess(_: any, { call, put, select }: any) {
        console.log('app/logout onSuccess Called!')
        if (!Device.web) {
          PushNotification.setApplicationIconBadgeNumber(0)
        }

        const darkMode: DarkMode = yield select(darkModeStoreStateSelector)

        yield call(User.onLogout)
        Apollo.resetEndpoint()
        Stripe.configure(Apollo.endpointEnv)
        StreamChat.configure(Apollo.endpointEnv)

        yield put({ type: 'reset' })
        yield put({ type: 'settings/updateSettings', payload: { darkMode } })
      },
      reducers: [
        {
          name: 'updateAppState',
          payload: { login: loginDefaults },
        },
      ],
    }),

    thirdPartyLogin: Model.buildEffect({
      name: `${this.namespace}/thirdPartyLogin`,
      query: SIGN_IN_THIRD_PARTY_USER,
      dataPath: 'signInThirdPartyUser',
      isSuccess: (response: any) => response?.success,
      *onSuccess(login: Login, { call }: any) {
        yield call(User.onLogin, login)
      },
      reducers: [
        {
          name: 'updateLoginState',
        },
      ],
    }),

    changeDateRange: Model.buildEffect({
      name: `${this.namespace}/changeDateRange`,
      validation: validateDateRange,
      reducers: [
        {
          name: 'updateCalendarState',
          payload: processDateRangePayload,
        },
      ],
    }),

    resetCalendars: Model.buildEffect({
      name: `${this.namespace}/resetCalendars`,
      reducers: [
        {
          name: 'updateCalendarState',
          unless: unlessHasOpenedAppToday,
          payload: todayDateRangePayload,
        },
        {
          name: 'events/updateCalendarState',
          unless: unlessHasOpenedAppToday,
          payload: todayDateRangePayload,
        },
        {
          name: 'insights/updateCalendarState',
          unless: unlessHasOpenedAppToday,
          payload: todayDateRangePayload,
        },
      ],
    }),

    updateHealthData: Model.buildEffect({
      name: `${this.namespace}/updateHealthData`,
      dataPath: 'updateHealthData',
      query: UPDATE_HEALTH_DATA,
      *variables(_: any, { call, select }: any) {
        const lastSyncedHealth: string = yield select(healthLastSyncedSelector)

        const sourceIgnore: string[] = yield select(healthKitSourceIgnoreSelector)
        const ignore: string[] = yield select(healthKitIgnoreSelector)

        const samples: HealthDataSampleResult = yield call(HealthKit.getSamples, {
          lastSynced: lastSyncedHealth,
          ignore,
          sourceIgnore,
        })

        return { healthData: samples }
      },
      isSuccess: (response: any) => response?.success,
      reducers: [
        {
          name: 'settings/updateIntegrationsSyncSettings',
          unless: (_: any, { variables }: { variables: { healthData: HealthDataSampleResult } }) =>
            !getLastTimestamp(variables.healthData),
          payload: (
            _: any,
            { variables }: { variables: { healthData: HealthDataSampleResult } },
          ) => ({
            lastSyncedHealth: getLastTimestamp(variables.healthData),
          }),
        },
      ],
    }),

    updateKetoMojoData: Model.buildEffect({
      name: `${this.namespace}/updateKetoMojoData`,
      dataPath: 'updateKetoMojoData',
      query: UPDATE_KETO_MOJO_DATA,
      *variables({ initial = false }: { initial: boolean }, { call, select }: any) {
        const ketoMojoUser: AuthorizeResult | undefined = yield select(ketoMojoUserSelector)
        const lastSyncedKetoMojo: string | null = yield select(ketoMojoLastSyncedSelector)
        const startDate: string =
          initial || !lastSyncedKetoMojo
            ? moment().subtract(1, 'year').format()
            : moment(lastSyncedKetoMojo).format()
        const endDate = moment().format()

        if (ketoMojoUser?.accessToken) {
          const data: Awaited<ReturnType<typeof KetoMojo.getReadings>> = yield call(
            KetoMojo.getReadings,
            startDate,
            endDate,
            ketoMojoUser.accessToken,
          )

          if (data) {
            return { data }
          }
        }
        return { data: [] }
      },
      isSuccess: (response: any) => response?.success,
      reducers: [
        {
          name: 'settings/updateIntegrationsSyncSettings',
          unless: (_: any, { variables }: { variables: { data: Reading[] } }) => !variables.data[0],
          payload: (_: any, { variables }: { variables: { data: Reading[] } }) => ({
            lastSyncedKetoMojo: variables.data[0].readingTimestamp,
          }),
        },
      ],
    }),

    exportData: Model.buildEffect({
      name: `${this.namespace}/exportData`,
      query: FETCH_DATA_EXPORT,
      isSuccess: (response: any) => get(response, 'dataExport.success'),
    }),

    config: Model.buildEffect({
      name: `${this.namespace}/config`,
      query: FETCH_CLIENT_CONFIG,
      reducers: [
        {
          name: 'updateAppState',
          payload: transform,
        },
      ],
    }),

    fetchCalendarData: Model.buildEffect({
      name: `${this.namespace}/fetchCalendarData`,
      query: FETCH_CALENDAR_DATA,
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('app/calendar', variables),
      reducers: [
        {
          name: 'updateCalendarState',
          payload: processCalendarData(calendarDateSelector),
        },
      ],
    }),

    // This is part of the 'app' namespace instead of the 'events' namespace so that
    // the app/calendar cache can be shared.
    //
    fetchEventsCalendarData: Model.buildEffect({
      name: `${this.namespace}/fetchEventsCalendarData`,
      query: FETCH_CALENDAR_DATA,
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('app/calendar', variables),
      reducers: [
        {
          name: 'events/updateCalendarState',
          payload: processCalendarData(eventsCalendarSelector),
        },
      ],
    }),

    sendUserInvite: Model.buildEffect({
      name: `${this.namespace}/sendUserInvite`,
      query: SEND_USER_INVITE,
      dataPath: 'sendUserInvite',
      isSuccess: (response: any) => response?.success,
    }),

    sendIntercomUserMessage: Model.buildEffect({
      name: `${this.namespace}/sendIntercomUserMessage`,
      query: SEND_CHAT_CONVERSATION_MESSAGE,
      dataPath: 'sendChatConversationMessage',
      isSuccess: (response: any) => response?.success,
    }),

    createChatConversationNote: Model.buildEffect({
      name: `${this.namespace}/createChatConversationNote`,
      query: CREATE_CHAT_CONVERSATION_NOTE,
      dataPath: 'createChatConversationNote',
    }),

    clearCaches: Model.buildEffect({
      name: `${this.namespace}/clearCaches`,
      cacheReducers: [
        { name: 'activities/cacheClear' },
        { name: 'address/cacheClear' },
        { name: 'cacheClear' },
        { name: 'events/cacheClear' },
        { name: 'history/cacheClear' },
        { name: 'ingredients/cacheClear' },
        { name: 'insights/cacheClear' },
        { name: 'meals/cacheClear' },
        { name: 'measurements/cacheClear' },
        { name: 'journalEntries/cacheClear' },
        { name: 'paymentMethod/cacheClear' },
        { name: 'scans/cacheClear' },
        { name: 'sensor/cacheClear' },
        { name: 'settings/cacheClear' },
        { name: 'settings/updateSettings', payload: { dailyMeasurementsSourcePriorities: {} } },
        { name: 'users/cacheClear' },
      ],
      warnings: false, // disable warnings as payload is empty
    }),

    surveyQuestionnaire: Model.buildEffect({
      name: `${this.namespace}/surveyQuestionnaire`,
      query: FETCH_QUESTIONNAIRE,
      dataPath: 'allSurveyLinks',
      caching: false,
    }),

    startQuestionnaire: Model.buildEffect({
      name: `${this.namespace}/startQuestionnaire`,
      query: START_QUESTIONNAIRE,
      dataPath: 'startSurvey',
    }),

    saveSurveyResponse: Model.buildEffect({
      name: `${this.namespace}/saveSurveyResponse`,
      query: SAVE_SURVEY_RESPONSE,
      dataPath: 'saveSurveyResponse',
    }),

    completeSurvey: Model.buildEffect({
      name: `${this.namespace}/completeSurvey`,
      query: COMPLETE_SURVEY,
      caching: false,
    }),

    upsertUiState: Model.buildEffect({
      name: `${this.namespace}/upsertUiState`,
      query: UPSERT_UI_STATE,
      caching: false,
      reducers: [
        {
          name: 'updateUserState',
          *payload(response: UpsertUiStateMutation, { select }: any) {
            const newUiState = response.upsertUiState
            const uiStates: UiState[] = yield select(uiStatesSelector)
            if (uiStates.find((uiState) => uiState.name === newUiState.name)) {
              return {
                uiStates: uiStates.map((uiState) =>
                  uiState.name === newUiState.name ? newUiState : uiState,
                ),
              }
            }

            return {
              uiStates: [...uiStates, newUiState],
            }
          },
        },
      ],
    }),

    switchSensorOnCancellation: Model.buildEffect({
      name: `${this.namespace}/switchSensorOnCancellation`,
      query: SWITCH_SENSOR_ON_CANCELLATION,
      dataPath: 'switchSensorOnCancellation',
    }),

    selectDefaultSensorForDexcomResearch: Model.buildEffect({
      name: `${this.namespace}/selectDefaultSensorForDexcomResearch`,
      query: SELECT_DEFAULT_SENSOR_FOR_DEXCOM_RESEARCH,
      dataPath: 'selectDefaultSensorForDexcomResearch',
    }),

    selectSensor: Model.buildEffect({
      name: `${this.namespace}/selectSensor`,
      query: SELECT_SENSOR,
      dataPath: 'selectSensor',
    }),

    startDexcomResearchParticipation: Model.buildEffect({
      name: `${this.namespace}/startDexcomResearchParticipation`,
      query: START_PARTICIPATION,
      dataPath: 'startDexcomResearchParticipation',
    }),

    upsertDexcomConnection: Model.buildEffect({
      name: `${this.namespace}/upsertDexcomConnection`,
      query: UPSERT_DEXCOM_CONNECTION,
      reducers: [
        {
          name: 'updateUserState',
          payload: (response: UpsertDexcomConnectionMutation) => ({
            dexcomConnection: response.upsertDexcomConnection,
          }),
        },
      ],
    }),

    deleteDexcomConnection: Model.buildEffect({
      name: `${this.namespace}/deleteDexcomConnection`,
      query: DELETE_DEXCOM_CONNECTION,
      reducers: [{ name: 'updateUserState', payload: { dexcomConnection: null } }],
    }),

    refreshDataUponDexcomSync: Model.buildEffect({
      name: `${this.namespace}/refreshDataUponDexcomSync`,
      warnings: false, // disable warnings as payload is empty
      reducers: [{ name: 'events/fetchCharts' }, { name: 'events/fetchNutrition' }],
      cacheReducers: [
        { name: 'cacheClear' },
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        { name: 'events/cacheClear' },
        { name: 'insights/cacheClear' },
      ],
    }),

    validateGlucosePresenceTerraGoogleFit: Model.buildEffect({
      name: `${this.namespace}/validateGlucosePresenceTerraGoogleFit`,
      query: VALIDATE_GLUCOSE_PRESENCE_TERRA_GOOGLE_FIT,
      dataPath: 'validateGlucosePresenceTerraGoogleFit',
    }),

    fetchUserFiles: Model.buildEffect({
      name: `${this.namespace}/fetchUserFiles`,
      query: FETCH_USER_FILES,
      dataPath: 'allFiles',
    }),

    createDirectUpload: Model.buildEffect({
      name: `${this.namespace}/createDirectUpload`,
      query: CREATE_DIRECT_UPLOAD,
      dataPath: 'createDirectUpload',
    }),

    createFileFromSignedId: Model.buildEffect({
      name: `${this.namespace}/createFileFromSignedId`,
      query: CREATE_FILE_FROM_SIGNED_ID,
      dataPath: 'createFileFromSignedId',
    }),

    deleteFile: Model.buildEffect({
      name: `${this.namespace}/deleteFile`,
      query: DELETE_FILE,
    }),

    fetchProducts: Model.buildEffect({
      name: `${this.namespace}/allProducts`,
      query: FETCH_PRODUCTS,
      dataPath: 'allProducts',
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'products',
          storePath: (
            _: any,
            { effectPayload }: { effectPayload?: { category?: BillingProductCategory } },
          ) =>
            effectPayload?.category ? `productsByCategory.${effectPayload.category}` : 'products',
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    ...reducers,
  }
}
