import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { isToday } from '@src/utils/global'
import { eventsCalendarSelector } from '@src/selectors/events'
import { Text } from '@components/base'
import { useConnectingToSensorViaBluetooth } from '@src/utils/hooks'
import { SHORT_TWELVE_HOURS_TIME_FORMAT } from '@src/config/momentFormat'
import { HighlightsCard, TitleIconProps } from '../Highlights/HighlightsCard'
import { useRangeRepresentations } from '../../utils/useRangeRepresentation'
import { usePrimaryStatsData } from '../../utils/usePrimaryStatsData'

export const GlucoseSummaryCard = () => {
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const { endDate } = useSelector(eventsCalendarSelector)

  const displayLastReading = isToday(endDate)

  const connectingToBluetooth = useConnectingToSensorViaBluetooth()

  const {
    primaryStatRanges,
    primaryStatValue,
    primaryStatDisplayValue,
    primaryStatUnits,
    primaryStatTitle,
    lastReadingTime,
    isLiveReading,
  } = usePrimaryStatsData({ displayLastReading })

  const { rangeTitle, rangeColor } = useRangeRepresentations(primaryStatRanges, primaryStatValue)
  const rangeStyle = !!rangeColor && { color: rangeColor }
  const rangeStyleSize = !!rangeStyle && rangeTitle === 'Needs Improvement' && { fontSize: 12 }

  const handleMorePress = () => {
    navigation.navigate('Scans')
  }

  const title = connectingToBluetooth ? 'Sensor syncing...' : primaryStatTitle
  const titleContentRight =
    displayLastReading && !connectingToBluetooth
      ? lastReadingTime?.format(SHORT_TWELVE_HOURS_TIME_FORMAT)
      : undefined
  const bluetoothIconProps: TitleIconProps = { name: 'bluetooth', style: styles.iconStyle }

  return (
    <HighlightsCard
      onMorePress={handleMorePress}
      title={title}
      titleContentRight={titleContentRight}
      titleIconProps={isLiveReading ? bluetoothIconProps : undefined}
    >
      <View style={styles.statsContainer}>
        <Text type="score" style={styles.primaryStatValue}>
          {connectingToBluetooth ? 'N/A' : primaryStatDisplayValue}
        </Text>
        {!connectingToBluetooth && (
          <View>
            <Text type="title-3" style={[styles.primaryStatScore, rangeStyle, rangeStyleSize]}>
              {rangeTitle ?? ''}
            </Text>
            <Text type="regular" bold style={styles.units} lineSpacing="none">
              {primaryStatUnits}
            </Text>
          </View>
        )}
      </View>
    </HighlightsCard>
  )
}

const themedStyles = StyleService.create({
  statsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  primaryStatValue: {
    justifyContent: 'flex-start',
    marginRight: 8,
  },
  primaryStatScore: {
    textTransform: 'uppercase',
  },
  units: {
    color: 'theme.text.tertiary',
    marginTop: -4,
  },
  iconStyle: {
    color: 'theme.info.base',
    height: 20,
    width: 20,
  },
})
