import React, { useContext, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation, useRoute } from '@react-navigation/core'
import { LinkingContext } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppRouteProp, RootStackParamList } from '@navigation/types'
import { useStyleSheet, StyleService } from '@src/style/service'
import { productsByCategorySelector } from '@src/selectors/app'
import { Button, Text } from '@components/base'
import { ScrollViewWithFade } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { BillingProduct, Product } from '@src/types'
import { useSnack } from '@src/utils/navigatorContext'
import { getDeepLinkRoute } from '@src/utils/openUrl'
import { useSubscriptionFromRoute } from '../../utils/hooks'
import { Option } from './Option'
import { OptionCard } from './OptionCard'

export const ChurnRecoveryOptions = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const { subscription } = useSubscriptionFromRoute()
  const { params } = useRoute<AppRouteProp<'ChurnRecoveryOptions'>>()
  const { billingProductCategory, title, description, notes, upsellOfferLink } = params
  const [selectedProduct, setSelectedProduct] = useState<Product>()
  const showSnack = useSnack()
  const linking = useContext(LinkingContext)

  const products = useSelector(productsByCategorySelector(billingProductCategory)) ?? []

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: billingProductCategory },
    })
  }, [billingProductCategory, dispatch])

  if (!subscription) {
    return null
  }

  const displayAsCard = products.length === 1
  const selectedOffer = displayAsCard ? products[0] : selectedProduct

  const handleSubmitPress = () => {
    if (!selectedOffer) {
      return
    }

    dispatch({
      type: 'marketplace/scheduleSubscriptionChange',
      payload: { id: subscription.id, product: selectedOffer.key },
      success: () => {
        showSnack('Subscription cancelled successfully', null, 'success')
        let nextRoute

        if (selectedOffer.key === BillingProduct.FreeSoftwareOnlyVideoCallsAnnualMembership) {
          nextRoute = {
            name: 'InsuranceCallsUpsellScreen' as keyof ReactNavigation.RootParamList,
            params: { showSuccessAlert: false },
          }
        } else {
          nextRoute = {
            name: 'Subscriptions' as keyof ReactNavigation.RootParamList,
          }
          dispatch({ type: 'marketplace/fetchSubscriptionSchedules' })
        }

        navigation.reset({
          index: 1,
          routes: [
            { name: 'Drawer', state: { index: 0, routes: [{ name: 'Dashboard' }] } },
            nextRoute,
          ],
        })
      },
      failure: (error: any) => {
        showSnack(error.message, null, 'error')
      },
    })
  }

  const handleCancelPress = () => {
    const params = {
      subscriptionId: subscription.id,
    }

    const deepLinkRoute =
      upsellOfferLink && getDeepLinkRoute(upsellOfferLink, linking.options?.config)

    if (deepLinkRoute) {
      const deepLinkRouteParams = { ...params, ...deepLinkRoute?.params }

      navigation.replace(deepLinkRoute?.name as any, deepLinkRouteParams)
      return
    }

    navigation.navigate('CancelSubscriptionConfirmation', params)
  }

  return (
    <NavigationContainer title="Cancel Subscription">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <View style={styles.contentContainer}>
          <ScrollViewWithFade
            fadeColor={styles.container.backgroundColor}
            scrollViewStyle={styles.scrollView}
          >
            <View style={styles.heading}>
              <Text type="title-3">{title}</Text>
              <Text type="regular" style={styles.description}>
                {description}
              </Text>
            </View>
            <View style={styles.options}>
              {displayAsCard && selectedOffer ? (
                <OptionCard
                  title={selectedOffer.title}
                  price={selectedOffer.price}
                  interval={selectedOffer.interval}
                />
              ) : (
                products.map((product) => (
                  <Option
                    key={product.id}
                    title={product.title}
                    description={product.shortDescription ?? product.description ?? ''}
                    price={product.price}
                    interval={product.interval}
                    selected={product.id === selectedProduct?.id}
                    onSelect={() => setSelectedProduct(product)}
                  />
                ))
              )}
            </View>
            <View style={styles.footer}>
              <Text type="regular" style={styles.notes}>
                {notes}
              </Text>
            </View>
          </ScrollViewWithFade>
          <View style={styles.actionsContainer}>
            <Button
              type="primary"
              size="block"
              accessibilityLabel="Claim offer"
              disabled={!selectedOffer}
              onPress={handleSubmitPress}
            >
              Claim offer
            </Button>
            <Button
              type="transparent"
              size="block"
              accessibilityLabel="Cancel"
              style={styles.cancelButton}
              onPress={handleCancelPress}
            >
              Cancel my subscription
            </Button>
          </View>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  scrollView: {
    flexGrow: 1,
  },
  heading: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  description: {
    marginTop: 16,
  },
  options: {
    marginTop: 16,
    paddingHorizontal: 16,
  },
  footer: {
    marginTop: 4,
    paddingHorizontal: 64,
    paddingBottom: 32,
  },
  notes: {
    color: 'theme.text.secondary',
    textAlign: 'center',
  },
  actionsContainer: {
    padding: 16,
  },
  cancelButton: {
    marginTop: 8,
  },
})
