import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { authenticatedUserSelector } from '@src/selectors/app'
import Storage from '@src/utils/storage'
import {
  AvailableDates,
  AvailableSlots,
  InsuranceBerryStreetAppointmentType,
  Slot,
} from '@src/types'
import { Analytics, CustomEventTypes } from '@src/config'
import { MixpanelEventProperties } from '@src/config/analytics'
import {
  insurancePolicySelector,
  latestUserAppointmentSelector,
} from './models/nutritionistHub.selectors'
import { hasUnusedCalls, isInsuranceEligible, isUnusedCallsInfoAvailable } from './utils'

export const useAvailableDates = (
  appointmentType: InsuranceBerryStreetAppointmentType,
  appointmentId?: string,
) => {
  const user = useSelector(authenticatedUserSelector)
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [error, setError] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch({
      type: 'nutritionistHub/fetchAvailableDays',
      payload: {
        appointmentType,
        timeZone: user.timeZone,
        appointmentId,
      },
      success: (response: AvailableDates) => {
        setAvailableDates(response.dates)
        if (response.dates.length > 0) {
          setSelectedDate(response.dates[0])
        }
      },
      failure: () => {
        setError(true)
      },
      complete: () => {
        setLoading(false)
      },
    })
  }, [appointmentType, dispatch, user.timeZone, appointmentId])

  return {
    availableDates,
    loading,
    selectedDate,
    setSelectedDate,
    error,
  }
}

export const useAvailableSlots = (
  appointmentType: InsuranceBerryStreetAppointmentType,
  date: string | null,
) => {
  const user = useSelector(authenticatedUserSelector)
  const [availableSlots, setAvailableSlots] = useState<Slot[]>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!date) {
      return
    }
    setLoading(true)
    setAvailableSlots([])
    dispatch({
      type: 'nutritionistHub/fetchAvailableSlots',
      payload: {
        appointmentType,
        timeZone: user.timeZone,
        date,
      },
      success: (response: AvailableSlots) => {
        setAvailableSlots(response.slots)
      },
      complete: () => setLoading(false),
    })
  }, [dispatch, date, appointmentType, user.timeZone])

  return {
    availableSlots,
    loading,
  }
}

export const useNutritionistHubTracker = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const latestAppointment = useSelector(latestUserAppointmentSelector)

  const track = (event: CustomEventTypes, additionalProps?: MixpanelEventProperties) => {
    const defaultProps = {
      insurancePolicyState: insurancePolicy?.lastState?.kind,
      latestAppointment: latestAppointment && {
        status: latestAppointment.status,
        meetingStatus: latestAppointment.meetingStatus,
        appointmentType: latestAppointment.appointmentType,
        date: latestAppointment.date,
      },
    }
    Analytics.track(event, { ...defaultProps, ...additionalProps })
  }
  return track
}
export const useInitialAppointmentType = () => {
  const dispatch = useDispatch()
  const insurancePolicy = useSelector(insurancePolicySelector)

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchInsurancePolicy',
    })
  }, [dispatch])

  if (!insurancePolicy || !insurancePolicy.initialAppointmentType) {
    return
  }

  return insurancePolicy.initialAppointmentType.key
}

export const useNoMoreAllotedCallsAlert = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const [showNoAllotedCallsAlert, setShowNoAllotedCallsAlert] = useState(false)
  const noAllotedCallsAlertClosedKey = `${Storage.NO_ALLOTED_CALLS_ALERT_CLOSED_KEY}_${insurancePolicy?.id}`

  const onClose = () => {
    if (insurancePolicy) {
      Storage.set(noAllotedCallsAlertClosedKey, true)
    }
    setShowNoAllotedCallsAlert(false)
  }

  useFocusEffect(() => {
    if (!insurancePolicy || !isInsuranceEligible(insurancePolicy)) {
      return
    }

    if (!isUnusedCallsInfoAvailable(insurancePolicy) || hasUnusedCalls(insurancePolicy)) {
      return
    }

    const isAllotedCallsAlertClosed = Storage.get<boolean>(noAllotedCallsAlertClosedKey)
    if (!isAllotedCallsAlertClosed) {
      setShowNoAllotedCallsAlert(true)
    }
  })

  return { showNoAllotedCallsAlert, onClose }
}
