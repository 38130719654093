import { useSelector } from 'react-redux'
import { uiStatesSelector } from '@src/selectors/app'
import { UiStateNames } from '@src/types'

export const useIsOwnSensorGlucoseDetected = () => {
  const uiStates = useSelector(uiStatesSelector)

  const ownSensorGlucoseDetected = uiStates.find(
    (uiState) => uiState.name === UiStateNames.OwnSensorGlucoseDetected,
  )

  // only true for users with detected glucose from BYOS device
  return ownSensorGlucoseDetected?.value || false
}
