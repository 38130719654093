interface CmsMedia {
  data: {
    attributes: {
      url: string
      previewUrl: string
    }
  }
}

export enum TutorialStepActionTypes {
  TutorialStepActionLink = 'ComponentActionActionLink',
  TutorialStepActionVideo = 'ComponentActionActionVideo',
  TutorialStepPlatformLink = 'ComponentActionPlatformLink',
}

// mapped to the tutorial group id on cms.nutrisense.io/admin
export enum TutorialGroups {
  'NoTutorialGroup' = 0,
  'LibreLinkup' = 1,
  'LibreNative' = 2,
  'Dexcom' = 4,
}

export enum TutorialTypes {
  List = 'list',
  Carousel = 'carousel',
}

export enum TutorialSensorKind {
  Libre1 = 'libre1',
  Libre2 = 'libre2',
  Libre3 = 'libre3',
  DexcomG6 = 'dexcom_g6',
  DexcomG7 = 'dexcom_g7',
  DexcomStelo = 'dexcom_stelo',
}

export enum TutorialPlatform {
  IOS = 'ios',
  Android = 'android',
}

export interface TutorialPreview {
  id: string
  position: string
  name: string
  title: string
  duration: string
  description: string
  buttonText: string
  image: CmsMedia
  platform: TutorialPlatform
  sensorKind: TutorialSensorKind
}
export interface TutorialGroup {
  id: string
  title: string
  duration: string
  summary: string
  tutorials: TutorialPreview[]
}

export interface TutorialStepActionLink {
  __typename: TutorialStepActionTypes.TutorialStepActionLink
  id: string
  url: string
}
export interface TutorialStepActionVideo {
  __typename: TutorialStepActionTypes.TutorialStepActionVideo
  id: string
  description: string
  title: string
  video: CmsMedia
}

export interface TutorialStepPlatformLink {
  __typename: TutorialStepActionTypes.TutorialStepPlatformLink
  id: string
  android: {
    url: string
  }
  ios: {
    url: string
  }
}
export interface TutorialStep {
  title: string
  description: string
  duration: string
  buttonText: string
  position: string
  image: CmsMedia
  warning: string | null
  action: (TutorialStepActionLink | TutorialStepActionVideo | TutorialStepPlatformLink)[]
}

export interface Tutorial {
  id: string
  position: string
  name: string
  title: string
  duration: string
  description: string
  buttonText: string
  image: CmsMedia
  tutorialType: TutorialTypes
  tutorialSteps: TutorialStep[]
  platform: TutorialPlatform
  sensorKind: TutorialSensorKind
}

export interface TutorialsStoreState {
  groups: TutorialGroup[]
  currentTutorial: Tutorial
}

export interface TutorialGroupResponse {
  id: string
  attributes: {
    title: string
    duration: string
    summary: string
    tutorials: { data: TutorialPreviewResponse[] }
  }
}

interface TutorialPreviewResponse {
  id: string
  attributes: {
    position: string
    name: string
    title: string
    duration: string
    description: string
    buttonText: string
    image: CmsMedia
    platform: TutorialPlatform
    sensorKind: TutorialSensorKind
  }
}

interface TutorialStepResponse {
  id: string
  attributes: {
    title: string
    description: string
    duration: string
    buttonText: string
    position: string
    image: CmsMedia
    warning: string | null
    action: (TutorialStepActionLink | TutorialStepActionVideo | TutorialStepPlatformLink)[]
  }
}
export interface TutorialResponse {
  data: {
    id: string
    attributes: {
      position: string
      name: string
      title: string
      duration: string
      description: string
      buttonText: string
      image: CmsMedia
      tutorialType: TutorialTypes
      tutorialSteps: { data: TutorialStepResponse[] }
    }
  }
}
