import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

const policyConsentsStateSelector = (state: RootStoreState) => state.policyConsents

export const refillConsentNeededSelector = createSelector(
  policyConsentsStateSelector,
  (policyConsents) => policyConsents.refillConsentNeeded,
)

export const policyConsentStatusesSelector = createSelector(
  policyConsentsStateSelector,
  (policyConsents) => policyConsents.statuses || [],
)
