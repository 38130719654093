import { createSelector } from 'reselect'
import moment from 'moment'
import { Selector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { interpolateEvents } from '@src/screens/Events/transforms/chart'
import { EventsItemType } from '@src/screens/Events/models/events.types'
import { HistoryItemType } from '@src/screens/History/models/history.types'
import { dailyMeasurementTypeDefLookupSelector } from './app'

export const eventsStoreStateSelector = (state: RootStoreState) => state.events

export const eventsObjectsSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.events,
)

export const todayEventsSelector = createSelector(eventsObjectsSelector, (events) =>
  events.filter((event) => moment(event.occurredAt).isSame(moment(), 'day')),
)

export const eventsNutritionSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.nutrition,
)

export const eventsStatisticsSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.statistics,
)

export const eventsScoreSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.score,
)

export const chartsSelector = createSelector(eventsStoreStateSelector, (events) => events.charts)

export const eventsChartsSelector = <T extends EventsItemType | HistoryItemType>(
  eventsSelector: Selector<RootStoreState, T[]>,
) =>
  createSelector(
    [eventsSelector, chartsSelector, dailyMeasurementTypeDefLookupSelector],
    (events, charts, dailyMeasurementTypeDefLookup) => {
      const { primary, secondary } = charts

      const primaryValuesWithMoment = primary.values.map((value) => ({
        ...value,
        x: moment(value.x),
      }))

      const secondaryValuesWithMoment = secondary.values.map((value) => ({
        ...value,
        x: moment(value.x),
      }))

      const eventsWithoutDailyMeasurements = events.filter(
        (event) => !('type' in event) || !dailyMeasurementTypeDefLookup[event.type],
      )

      const interpolatedPrimaryEvents = interpolateEvents(
        primaryValuesWithMoment,
        eventsWithoutDailyMeasurements,
        primary.range,
      )

      if (secondary) {
        const interpolatedSecondaryEvents = interpolateEvents(
          secondaryValuesWithMoment,
          eventsWithoutDailyMeasurements,
          secondary.range,
        )

        return {
          ...charts,
          primary: {
            ...primary,
            values: primaryValuesWithMoment.concat(interpolatedPrimaryEvents),
          },
          secondary: {
            ...secondary,
            values: secondaryValuesWithMoment.concat(interpolatedSecondaryEvents),
          },
        }
      }

      return {
        ...charts,
        primary: { ...primary, values: primary.values.concat(interpolatedPrimaryEvents) },
      }
    },
  )

export const eventsCalendarSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.calendar,
)
