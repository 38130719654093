import { startCase } from 'lodash'
import moment, { Moment } from 'moment'
import { Availability, EhrAppointmentRecurrenceDay } from '@src/types.ts'
import { SelectOption } from '@components/CommonSelect.types.ts'
import {
  dayOfWeekToRecurrenceDayLookup,
  TIME_SLOT_FORMAT,
} from '@screens/NutritionistHub/components/UpcomingCalls/constants.ts'

export const toStringOption = (value: number) => ({
  value,
  text: value.toString(),
})

export const toStartCaseOption = (value: string) => ({
  value,
  text: startCase(value),
})

export const toTimeSlotOption = (timeSlot: string | Moment) => {
  const momentTime = moment(timeSlot)
  const value = momentTime.format(TIME_SLOT_FORMAT)
  return {
    value,
    text: `${value} - ${momentTime.add(1, 'hour').format(TIME_SLOT_FORMAT)}`,
  }
}

export const buildTimeSlotOptions = (
  availabilities: Availability[],
  days: EhrAppointmentRecurrenceDay[],
) => {
  const allSlots: SelectOption[] = []
  const hasAllDays = days.includes(EhrAppointmentRecurrenceDay.All)

  availabilities
    .filter(
      (availability) =>
        hasAllDays || days.includes(dayOfWeekToRecurrenceDayLookup[availability.dayOfWeek]),
    )
    .forEach((availability) => {
      const startAt = moment(availability.rangeStartAt)
      const endAt = moment(availability.rangeEndAt)
      const slot = moment(startAt).startOf('hour')
      while (moment(slot).add(1, 'hour').isSameOrBefore(endAt)) {
        if (slot.isSameOrAfter(startAt)) {
          const value = slot.format(TIME_SLOT_FORMAT)
          if (!allSlots.find((s) => s.value === value)) {
            allSlots.push(toTimeSlotOption(slot))
          }
        }
        slot.add(1, 'hour')
      }
    })

  return allSlots.sort((a, b) => {
    const timeA = moment(a.value, TIME_SLOT_FORMAT)
    const timeB = moment(b.value, TIME_SLOT_FORMAT)
    return timeA.isBefore(timeB) ? -1 : 1
  })
}
