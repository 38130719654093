import React from 'react'
import { Pressable, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { DailyActionKind, UserDailyActionStatus } from '@src/types'
import { Text } from '@src/components/base'
import {
  DailyActionsSection,
  TODAY_SECTION_ID,
} from '@src/screens/DailyActions/hooks/useEditDailyActionsSections'
import { useSnack } from '@src/utils'
import MinusCircle from '@src/assets/svgs/minusCircle'
import PlusCircle from '@src/assets/svgs/plusCircle'
import { Analytics, CustomEventTypes } from '@src/config'

export interface SectionItemProps {
  section: DailyActionsSection
  dailyAction: DailyActionKind
}

export const SectionItem = ({ dailyAction, section }: SectionItemProps) => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const isTodaysFocusSection = section.id === TODAY_SECTION_ID

  const updateDailyActionStatus = () => {
    const status = isTodaysFocusSection
      ? UserDailyActionStatus.Inactive
      : UserDailyActionStatus.Active
    const mixpanelEvent = isTodaysFocusSection
      ? CustomEventTypes.EditDailyActionsModalRemoveActionTapped
      : CustomEventTypes.EditDailyActionsModalAddActionTapped

    dispatch({
      type: 'dailyActions/upsert',
      payload: {
        kind: dailyAction.key,
        status,
      },
      failure: (error: Error) => {
        showSnack(error.message, null, 'error')
      },
    })
    Analytics.track(mixpanelEvent, {
      actionKey: dailyAction.key,
      status,
    })
  }

  return (
    <View style={styles.sectionItem}>
      <View style={styles.content}>
        <Text type="large" bold>
          {dailyAction.title}
        </Text>

        <Text type="regular">{dailyAction.description}</Text>
      </View>
      <Pressable accessibilityLabel="Daily Action Item" onPress={updateDailyActionStatus}>
        {isTodaysFocusSection ? <MinusCircle /> : <PlusCircle />}
      </Pressable>
    </View>
  )
}

const themedStyle = StyleService.create({
  sectionItem: {
    flex: 1,
    backgroundColor: 'theme.background.modal',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    marginBottom: 16,
  },
  title: {
    marginBottom: 16,
  },
  content: {
    flex: 1,
  },
})
