import { useSelector } from 'react-redux'
import moment from 'moment'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { Storage } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'

const SHOWN_MIGRATION_INTERVAL = 1

// Show migration screen at most once a day
export const useShowScanMigrationOnScan = () => {
  const user = useSelector(userSelector)
  const showScanDisableNoticeOnScan = useFeatureFlag(Feature.ScanDisableNoticeOnScan)
  const [scanDisabledNoticeShownAt] = useStorageValue(
    `${Storage.SCAN_DISABLED_NOTICE_SHOWN_AT_KEY}_${user?.id}`,
  )

  if (!showScanDisableNoticeOnScan) {
    return false
  }

  // they have never seen the notice after a scan
  if (!scanDisabledNoticeShownAt) {
    return true
  }

  // they haven't seen it in the last 24 hours
  if (moment().diff(scanDisabledNoticeShownAt, 'days') >= SHOWN_MIGRATION_INTERVAL) {
    return true
  }

  return false
}
