import React, { useEffect, useState } from 'react'
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import {
  EligibilityCheckScreen,
  SelectPlanScreen,
  SelectSubscriptionScreen,
  SubscriptionCheckoutScreen,
  TerraProviderScreen,
} from '@screens'
import {
  ProductsLoadingScreen,
  StartPurchaseFlowScreen,
  FreeTrialSubscriptionOfferScreen,
  SensorSelection,
} from '@src/screens/SignUp'
import { useReducedMotion } from '@src/config/accessibility'
import { useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { userSelector } from '@src/selectors/app'
import { TutorialsGroup } from '@src/screens/Tutorials/containers/TutorialsGroup'
import {
  CheckConnectionScreen,
  ConfirmSensorSelectionScreen,
  HealthPermissionScreen,
} from '@src/screens/OwnSensorOnboarding'
import {
  useShouldShowByosOnboarding,
  useShouldShowByosOnboardingTutorials,
} from '@src/screens/OwnSensorOnboarding/hooks'
import { ConnectionSuccessfulScreen } from '@src/screens/OwnSensorOnboarding/ConnectionSuccessfulScreen'
import { OnboardingStackParamsList } from '../types'

const OnboardingStack = createStackNavigator<OnboardingStackParamsList>()

export const OnboardingNavigator = () => {
  const reduceMotionEnabled = useReducedMotion()

  const screenOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [productsLoaded, setProductsLoaded] = useState(false)

  const user = useSelector(userSelector)

  const showPurchaseFlow = useShouldShowPurchaseFlow()
  const shouldShowByosOnboarding = useShouldShowByosOnboarding()
  const shouldShowByosOnboardingTutorials = useShouldShowByosOnboardingTutorials()

  const getInitialRouteName = () => {
    if (user && !showPurchaseFlow && shouldShowByosOnboarding) {
      if (shouldShowByosOnboardingTutorials) {
        return 'ConfirmSensorSelection' as const
      }

      return 'CheckConnection' as const
    }
    if (!productsLoaded) {
      return 'ProductsLoading' as const
    }
    if (showPurchaseFlow) {
      return 'StartPurchaseFlow' as const
    }

    // check for BYOS
    return 'ConfirmSensorSelection' as const
  }

  const initialRouteName = getInitialRouteName()

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      complete: () => {
        setProductsLoaded(true)
      },
    })
  }, [dispatch, navigation])

  return (
    <OnboardingStack.Navigator initialRouteName={initialRouteName} screenOptions={screenOptions}>
      {!productsLoaded && (
        <OnboardingStack.Screen name="ProductsLoading" component={ProductsLoadingScreen} />
      )}
      <OnboardingStack.Screen name="StartPurchaseFlow" component={StartPurchaseFlowScreen} />
      <OnboardingStack.Screen
        name="FreeTrialSubscriptionOffer"
        component={FreeTrialSubscriptionOfferScreen}
      />
      <OnboardingStack.Screen name="SelectPlan" component={SelectPlanScreen} />
      <OnboardingStack.Screen name="SelectSubscription" component={SelectSubscriptionScreen} />
      <OnboardingStack.Screen name="SensorSelection" component={SensorSelection} />
      <OnboardingStack.Screen name="EligibilityCheck" component={EligibilityCheckScreen} />
      <OnboardingStack.Screen name="SubscriptionCheckout" component={SubscriptionCheckoutScreen} />
      <OnboardingStack.Screen
        name="ConfirmSensorSelection"
        component={ConfirmSensorSelectionScreen}
      />
      <OnboardingStack.Screen name="HealthPermission" component={HealthPermissionScreen} />
      <OnboardingStack.Screen name="TerraProvider" component={TerraProviderScreen} />
      <OnboardingStack.Screen name="TutorialsGroup" component={TutorialsGroup} />
      <OnboardingStack.Screen name="CheckConnection" component={CheckConnectionScreen} />
      <OnboardingStack.Screen name="ConnectionSuccessful" component={ConnectionSuccessfulScreen} />
    </OnboardingStack.Navigator>
  )
}
