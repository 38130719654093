import { EventEmitter } from 'events'
import { isString, isError } from 'lodash'
import {
  beginActivation,
  beginScanning,
  cleanUp,
  ScanningOptions,
} from 'react-native-freestyle-libre'
import { Messages, Logger, LoggerScreens } from '@src/config'
import { CGMInteractionsDetailsObjectTypes } from './types'
import { buildCGMInteractionsDetailsObject } from './utils'

const ACTIVATION_CHECK_INTERVAL_MS = 1000
const ACTIVATION_CHECK_TIMEOUT_MS = 120 * 60 * 1000

const MAX_RETRIES_COUNT = 5

class GlobalNFCListener extends EventEmitter {
  isScanning: boolean
  isActivating: boolean
  isContinueScanningEnabled: boolean
  delayBetweenErrorsMs: number
  clearDebounceIntervalHandler: null | ReturnType<typeof setTimeout>
  scanningOptions: ScanningOptions
  loggingEnabled: boolean

  constructor() {
    super()
    this.loggingEnabled = false
    this.isScanning = false
    this.isActivating = false
    this.isContinueScanningEnabled = false
    this.delayBetweenErrorsMs = 0
    this.clearDebounceIntervalHandler = null
    this.scanningOptions = {
      maxRetriesCount: MAX_RETRIES_COUNT,
      enableLibre2Support: false,
      enableLibre3Support: true,
      libreLinkUpPatientId: undefined,
      allowLibre3ScanningWithoutLibreLinkUpPatientId: false,
    }
  }

  enableLogging() {
    this.loggingEnabled = true
  }

  disableLogging() {
    this.loggingEnabled = false
  }

  enableContinueScanning() {
    this.isContinueScanningEnabled = true
  }

  disableContinueScanning() {
    this.isContinueScanningEnabled = false
  }

  async scan() {
    if (this.isScanning) {
      console.log('GlobalNFCListener is already scanning')
      return
    }

    console.log('GlobalNFCListener start scanning', this.isContinueScanningEnabled)
    do {
      try {
        this.isScanning = true
        const result = await beginScanning(this.scanningOptions)

        if (this.loggingEnabled) {
          Logger.sendInfo(
            LoggerScreens.GlobalNFCListener,
            Messages.StartScanning,
            buildCGMInteractionsDetailsObject(result, CGMInteractionsDetailsObjectTypes.Scanning),
          )
        }
        this.emit('onScan', result)
      } catch (error: any) {
        this.onError(error)
      } finally {
        this.isScanning = false
      }
    } while (await this.isContinueScanning())
    console.log('GlobalNFCListener stopped scanning')
  }

  async activate() {
    try {
      this.isActivating = true
      const result = await beginActivation(this.scanningOptions)

      if (this.loggingEnabled) {
        Logger.sendInfo(
          LoggerScreens.GlobalNFCListener,
          Messages.StartActivation,
          buildCGMInteractionsDetailsObject(result, CGMInteractionsDetailsObjectTypes.Activation),
        )
      }

      this.emit('onActivate', result)
    } catch (error: any) {
      this.onError(error)
    } finally {
      this.isActivating = false
    }
  }

  stop() {
    cleanUp()
  }

  private onError = (error: Error | string) => {
    if (
      (isError(error) &&
        (('code' in error && error.code === 'ERROR_SESSION_CANCELLED') ||
          error.constructor.name === 'UserCancel')) ||
      (isString(error) && error === 'cancelled')
    ) {
      console.log('NFCListenerContainer:cancelled')
      this.emit('onCancel', error)
      if (this.loggingEnabled) {
        Logger.sendInfo(LoggerScreens.GlobalNFCListener, Messages.Cancel)
      }
      // The previous scanning state gets cancelled when we stop scanning or run activation
      // There is no really error when we receive this.
    } else {
      const errorMessage = isString(error) ? error : error.message
      if (this.loggingEnabled) {
        Logger.sendError(LoggerScreens.GlobalNFCListener, errorMessage)
      }

      this.emit('onError', error)
    }
  }

  private waitActivationFinished(timeout: number = ACTIVATION_CHECK_TIMEOUT_MS) {
    if (!this.isActivating) {
      return true
    }

    return new Promise((resolve) => {
      let totalWaitMs = 0
      const interval = setInterval(() => {
        totalWaitMs += ACTIVATION_CHECK_INTERVAL_MS
        if (!this.isActivating || totalWaitMs > timeout) {
          clearInterval(interval)
          resolve(true)
        }
      }, ACTIVATION_CHECK_INTERVAL_MS)
    })
  }

  private isContinueScanning() {
    return this.isContinueScanningEnabled && this.waitActivationFinished()
  }
}

export default new GlobalNFCListener()
