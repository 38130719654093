import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import moment from 'moment'
import { useStyleSheet, StyleService } from '@src/style/service'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_FORMAT, formatDateRange } from '@config/momentFormat'
import { Icon, Text } from '@components/base'
import { DateSelectDirection } from './types'

interface DateSelectorProps {
  startDate: string
  endDate: string
  onDatePickerPress: () => void
  onDateChange: (startDate: string, endDate: string) => void
}

export const DateSelector = ({
  startDate,
  endDate,
  onDatePickerPress,
  onDateChange,
}: DateSelectorProps) => {
  const styles = useStyleSheet(themedStyle)

  const showDate =
    moment(startDate).isSame(moment(), 'date') && moment(startDate).isSame(moment(endDate), 'day')

  const onArrowPress = (direction: DateSelectDirection) => {
    const newStartDate = moment(startDate).add(direction, 'd').format(DATE_FORMAT)
    const newEndDate = moment(endDate).add(direction, 'd').format(DATE_FORMAT)

    onDateChange(newStartDate, newEndDate)
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => onArrowPress(DateSelectDirection.PREVIOUS)}
        accessibilityLabel="Previous"
        style={styles.iconContainer}
      >
        <Icon name="caret-left" style={styles.icon} />
      </TouchableOpacity>
      <TouchableOpacity
        accessibilityLabel="formatDateRange"
        style={styles.dateContainer}
        onPress={onDatePickerPress}
      >
        <Icon name="calendar-blank" style={styles.icon} />
        <View style={styles.dateView}>
          <Text type="regular" bold style={styles.date}>
            {formatDateRange(startDate, endDate)}
          </Text>
          {showDate && (
            <Text type="regular">{moment(startDate).format(MONTH_NAME_AND_DATE_FORMAT)}</Text>
          )}
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onArrowPress(DateSelectDirection.NEXT)}
        accessibilityLabel="Next"
        style={styles.iconContainer}
      >
        <Icon name="caret-right" style={styles.icon} />
      </TouchableOpacity>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 4,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    borderRadius: 32,
    flexDirection: 'row',
    gap: 8,
  },
  date: {
    marginHorizontal: 4,
  },
  dateView: {
    flexDirection: 'row',
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
  },
  icon: {
    height: 16,
    width: 16,
    color: 'theme.text.secondary',
  },
})
