import { useCallback, useState } from 'react'
import { useDispatchAsync } from '@src/utils'
import { LegalPolicyConsentStatusesQuery } from '../graphql/legalPolicyConsentStatuses.generated'

export const useGetRequiredPolicyConsents = () => {
  const dispatch = useDispatchAsync()
  const [loading, setLoading] = useState(false)

  const getRequiredPolicyConsents = useCallback(async () => {
    setLoading(true)
    try {
      const { statuses } = await dispatch<
        LegalPolicyConsentStatusesQuery['legalPolicyConsentStatuses']
      >({
        type: 'policyConsents/statuses',
      })

      return statuses.flatMap((status) => (status.required ? status.policy : []))
    } catch {
      // Hopefully this will never happen.
      // Either way, we will retry the next time the user goes to Events, NutritionistHub, or restarts the app.
      return []
    } finally {
      setLoading(false)
    }
  }, [dispatch, setLoading])

  return { getRequiredPolicyConsents, loading }
}
