import { NativeModules } from 'react-native'
import Config from 'react-native-config'
import { LinkingOptions } from '@react-navigation/native'
import { RootStackParamList } from './types'

const { scheme } = NativeModules.Configuration

const commonDeepLinks = {
  AddMeal: {
    path: 'app/modals/newMeal',
  },
  AddActivity: {
    path: 'app/modals/newActivity',
  },
  AddMeasurement: {
    path: 'app/modals/newMeasurement',
  },
  AddScan: {
    path: 'app/modals/newScan',
  },
  AddJournalEntry: {
    path: 'app/modals/newJournalEntry',
  },
  Chat: {
    path: 'app/chat',
  },
  CoursesModule: {
    path: 'app/learn/module',
  },
  OnboardingChecklist: {
    path: 'app/onboardingChecklist',
  },
  Integrations: {
    path: 'app/settings/integrations',
  },
  InsightsDetailedPage: {
    path: 'app/insights/:statSection',
  },
  CompareMealsDetails: {
    path: 'app/compareMeals/:mealIds',
  },
  TerraProvider: {
    path: 'app/settings/terra/:provider',
  },
  SensorSettings: {
    path: 'app/settings/sensor',
  },
  ProgramDetails: {
    path: 'app/programs',
  },
  ChurnRecoveryOptions: {
    path: 'app/churnRecoveryOptions',
  },
  ChurnRecoveryUpsellOffer: {
    path: 'app/churnRecoveryUpsellOffer',
  },
  ChurnRecoveryProactiveUpsellOffer: {
    path: 'app/churnRecoveryProactiveUpsellOffer',
  },
  CancelSubscriptionConfirmation: {
    path: 'app/cancelSubscriptionConfirmation',
  },
  CancelSubscriptionSwitchSensor: {
    path: 'app/cancelSubscriptionSwitchSensor',
  },
  Questionnaire: {
    path: 'app/questionnaire/:questionnaire',
  },
  ReadyToParticipateScreen: {
    path: 'app/readyToParticipate',
  },
  SensorSelection: {
    path: 'app/sensorSelection',
  },
  UpgradeToDexcomSensor: {
    path: 'app/upgradeToDexcomSensor',
  },
  CheckConnection: {
    path: 'app/checkConnection',
  },
  TutorialsGroup: {
    path: 'app/tutorialGroup/:group',
  },
  VideoCallsGettingStartedScreen: {
    path: 'app/videoCalls/gettingStarted',
  },
  VideoCallConfirmedModal: {
    path: 'app/videoCalls/confirmed',
  },
  VideoCallInReviewModal: {
    path: 'app/videoCalls/inReview',
  },
  VideoCallCheckIn: {
    path: 'app/videoCalls/checkIn',
  },
  AllVideoCalls: {
    path: 'app/videoCalls/all',
  },
  PaidCallsIntroScreen: {
    path: 'app/videoCalls/paidCallsIntro',
  },
  ClaimFreeVideoCallModal: {
    path: 'app/videoCalls/claimFreeVideoCall',
  },
  FreeVideoCall: {
    path: 'app/videoCalls/freeVideoCall',
  },
  CMSTutorial: {
    path: 'app/tutorials/:tutorialId',
  },
  LibreLinkupIntegration: {
    path: 'app/settings/libreLinkupIntegration',
  },
  LibreAuth: {
    path: 'app/settings/libreLinkupIntegration/libreAuth',
  },
  NotificationsSettings: {
    path: 'app/settings/notifications',
  },
  UserSettings: {
    path: 'app/settings/system',
  },
  WebViewModal: {
    path: 'app/webview',
  },
  CallScheduling: {
    path: 'app/callScheduling',
  },
  AddLabResults: {
    path: 'app/labResults/new',
  },
  CreatePaymentMethod: {
    path: 'app/marketplace/createPaymentMethod',
  },
  SubmitInsuranceCardScreen: {
    path: 'app/submitInsuranceCard',
  },
  InsuranceStatus: {
    path: 'app/insuranceStatus',
  },
  InsurancePolicyForm: {
    path: 'app/insurancePolicyForm',
  },
}

const largeScreenNavigatorDeepLinks = {
  Drawer: {
    path: 'app',
    screens: {
      Dashboard: {
        path: '',
        screens: {
          Events: {
            path: 'events',
          },
        },
      },
      Account: {
        path: 'account',
      },
      History: {
        path: 'history',
      },
      Learn: {
        path: 'learn',
      },
      Insights: {
        path: 'insights',
      },
      NutritionistHub: {
        path: 'nutritionistHub',
      },
    },
  },
}

const smallScreenNavigatorDeepLinks = {
  Drawer: {
    path: 'app',
    screens: {
      Dashboard: {
        path: '',
        screens: {
          Events: {
            path: 'events',
          },
          History: {
            path: 'history',
          },
          Learn: {
            path: 'learn',
          },
          Insights: {
            path: 'insights',
          },
          NutritionistHub: {
            path: 'nutritionistHub',
          },
        },
      },
    },
  },
  Account: {
    path: 'app/account',
  },
}

const onboardingDeepLinks = {
  Onboarding: {
    path: 'onboarding',
    screens: {
      TerraProvider: {
        path: 'terraProvider/:provider',
      },
    },
  },
}

const coreNavigatorDeepLinks = {
  ...commonDeepLinks,
  ...onboardingDeepLinks,
  FriendReferral: {
    path: 'refer-a-friend',
  },
  AppSettings: {
    path: 'app/settings',
  },
}

export const linking = (
  initialRouteName: keyof RootStackParamList,
  hasLargeScreen: boolean,
): LinkingOptions<RootStackParamList> => {
  const deepLinks = {
    ...coreNavigatorDeepLinks,
    ...(hasLargeScreen ? largeScreenNavigatorDeepLinks : smallScreenNavigatorDeepLinks),
  }

  return {
    prefixes: [Config.LINK_PREFIX, `${scheme}://`],
    config: {
      initialRouteName,
      screens: {
        Auth: {
          screens: {
            PasswordReset: {
              path: 'auth/reset/:passwordToken',
            },
          },
        },
        ...deepLinks,
      },
    },
  }
}
