import { useSelector } from 'react-redux'
import { userSelector } from '@src/selectors/app'
import { SurveysConfigKind, SurveysUserLinkState, UserApprovalRequestState } from '@src/types'
import { useImpersonationContext } from '@src/config/impersonation'
import { useFeatureFlag, Feature } from '@src/components'
import { useIsResearchEligiblePendingEnrollment } from '../SensorSelection/hooks.ts'

export enum ResearchFlowStep {
  NotApplicable,
  EligibilitySurvey,
  ConsentSurvey,
  PendingApproval,
  Rejected,
  Withdrawn,
  Complete,
  Unknown,
}

export const useResearchFlowStep = (): ResearchFlowStep => {
  const user = useSelector(userSelector)
  const surveyLinks = user?.surveyLinks || []
  const isImpersonating = useImpersonationContext()
  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)
  const isResearchEligiblePendingEnrollment = useIsResearchEligiblePendingEnrollment()

  if (
    !user?.lastResearchApprovalRequest ||
    isImpersonating ||
    !isDexcomResearchEnabled ||
    !isResearchEligiblePendingEnrollment
  ) {
    return ResearchFlowStep.NotApplicable
  }

  const isMedicalHistoryQuestionnaireCompleted = surveyLinks.some(
    (surveyLink) =>
      surveyLink.survey.kind === SurveysConfigKind.MedicalHistory &&
      surveyLink.finished &&
      surveyLink.state !== SurveysUserLinkState.Stopped,
  )

  switch (user.lastResearchApprovalRequest.state) {
    case UserApprovalRequestState.Stale:
      return ResearchFlowStep.NotApplicable
    case UserApprovalRequestState.Init:
    case UserApprovalRequestState.PendingMember:
    case UserApprovalRequestState.PendingUpdate:
      if (isMedicalHistoryQuestionnaireCompleted) {
        return ResearchFlowStep.ConsentSurvey
      }
      return ResearchFlowStep.EligibilitySurvey
    case UserApprovalRequestState.PendingApproval:
    case UserApprovalRequestState.PendingRejection:
      return ResearchFlowStep.PendingApproval
    case UserApprovalRequestState.DidNotComplete:
    case UserApprovalRequestState.Rejected:
      return ResearchFlowStep.Rejected
    case UserApprovalRequestState.ConsentWithdrawn:
    case UserApprovalRequestState.ConsentRejected:
      return ResearchFlowStep.Withdrawn
    case UserApprovalRequestState.Approved:
      return ResearchFlowStep.Complete
    default:
      return ResearchFlowStep.Unknown
  }
}
