import React from 'react'
import { View } from 'react-native'
import { IconName, Text } from '@components/base'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { StyleService, useStyleSheet } from '@src/style/service'
import { TabBarIcon } from './TabBarIcon'

export const TabBarIconWalkthroughContainer = ({
  isStepVisible,
  onPress,
  iconName,
  label,
}: {
  isStepVisible: boolean
  onPress: () => void
  iconName: IconName
  label: string
}) => {
  const styles = useStyleSheet(themedStyle)
  return (
    <WithWalkthrough
      isVisible={isStepVisible}
      onClose={onPress}
      onPress={onPress}
      accessibilityLabel="Events Tab"
      content={
        <WalkthroughTooltip
          title="Your Glucose & Events"
          subtitle={'Find your interactive glucose chart, logged meals, \n and activities here.'}
        />
      }
      placement="top"
    >
      <View style={styles.walkthroughContainer}>
        <TabBarIcon focused={false} iconName={iconName} />
        <Text type="small" style={styles.walkthroughLabel}>
          {label}
        </Text>
      </View>
    </WithWalkthrough>
  )
}

const themedStyle = StyleService.create({
  walkthroughContainer: {
    marginTop: 24,
    paddingTop: 3,
    borderRadius: 24,
    backgroundColor: 'theme.background',
    width: 72,
    height: 62,
    alignItems: 'center',
  },
  walkthroughLabel: {
    color: 'theme.text.tertiary',
    lineHeight: 22,
  },
})
