import moment from 'moment'
import { isEmpty, compact, map } from 'lodash'

const GRANULARITY_MINUTES = 4

const transform = ({ samples, sourceIgnore }) => {
  // don't allow granularity higher than ~5 minutes
  //
  if (!isEmpty(samples.bloodGlucose)) {
    let glucoseSamples = samples.bloodGlucose

    let lastSample = glucoseSamples[0]

    glucoseSamples = compact(
      map(glucoseSamples, (sample) => {
        const timeDiff = moment(sample.startDate).diff(lastSample.startDate, 'minutes')
        const skipSample = sourceIgnore.some((source) => sample.sourceId.includes(source))

        if (skipSample) {
          return null
        }

        // always include most recent sample
        if (sample === glucoseSamples[0]) {
          return sample
        }

        if (Math.abs(timeDiff) > GRANULARITY_MINUTES) {
          lastSample = sample
          return sample
        }

        return null
      }),
    )

    return { ...samples, bloodGlucose: glucoseSamples }
  }

  return samples
}

export { transform }
