import React from 'react'
import {
  Animated,
  SafeAreaView,
  StyleProp,
  ViewStyle,
  View,
  TouchableOpacity,
  Easing,
} from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'

export type SnackbarProps = {
  /**
   * Whether the Snackbar is currently visible.
   */
  visible: boolean
  /**
   * Label and press callback for the action button. It should contain the following properties:
   * - `label` - Label of the action button
   * - `onPress` - Callback that is called when action button is pressed.
   */
  action?: {
    label: string
    onPress: () => void
  }
  /**
   * The duration for which the Snackbar is shown.
   */
  duration?: number
  /**
   * Callback called when Snackbar is dismissed. The `visible` prop needs to be updated when this is called.
   */
  onDismiss: () => void
  /**
   * Text content of the Snackbar.
   */
  children: React.ReactNode
  /**
   * Style for the wrapper of the snackbar
   */
  wrapperStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  ref?: React.RefObject<View>
  testID: string
}

const DURATION_SHORT = 4000
const DURATION_MEDIUM = 7000
const DURATION_LONG = 10000

const Snackbar = ({
  visible,
  action,
  duration = DURATION_MEDIUM,
  onDismiss,
  children,
  wrapperStyle,
  style,
  ...rest
}: SnackbarProps) => {
  const { current: opacity } = React.useRef<Animated.Value>(new Animated.Value(0.0))
  const [hidden, setHidden] = React.useState<boolean>(!visible)

  const styles = useStyleSheet(themedStyles)

  const hideTimeout = React.useRef<NodeJS.Timeout | undefined>(undefined)

  React.useEffect(() => {
    return () => {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current)
      }
    }
  }, [])

  React.useLayoutEffect(() => {
    if (visible) {
      // show
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current)
      }
      setHidden(false)
      Animated.timing(opacity, {
        toValue: 1,
        duration: 200,
        useNativeDriver: true,
        easing: Easing.out(Easing.ease),
      }).start(({ finished }) => {
        if (finished) {
          const isInfinity =
            duration === Number.POSITIVE_INFINITY || duration === Number.NEGATIVE_INFINITY

          if (finished && !isInfinity) {
            hideTimeout.current = setTimeout(onDismiss, duration)
          }
        }
      })
    } else {
      // hide
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current)
      }

      Animated.timing(opacity, {
        toValue: 0,
        duration: 100,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          setHidden(true)
        }
      })
    }
  }, [visible, duration, opacity, onDismiss, children])

  if (hidden) {
    return null
  }

  return (
    <SafeAreaView pointerEvents="box-none" style={[styles.wrapper, wrapperStyle]}>
      <Animated.View
        pointerEvents="box-none"
        style={[
          styles.container,
          {
            opacity,
            transform: [
              {
                scale: visible
                  ? opacity.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.9, 1],
                    })
                  : 1,
              },
            ],
          },
          style,
        ]}
        {...rest}
      >
        <View style={[styles.content, { marginRight: action ? 0 : 16 }]}>{children}</View>
        {action ? (
          <TouchableOpacity
            onPress={() => {
              action.onPress?.()
              onDismiss()
            }}
            style={styles.button}
            accessibilityLabel={action.label || 'button'}
          >
            <Text type="regular" bold style={styles.actionLabel}>
              {action.label}
            </Text>
          </TouchableOpacity>
        ) : null}
      </Animated.View>
    </SafeAreaView>
  )
}

/**
 * Show the Snackbar for a short duration.
 */
Snackbar.DURATION_SHORT = DURATION_SHORT

/**
 * Show the Snackbar for a medium duration.
 */
Snackbar.DURATION_MEDIUM = DURATION_MEDIUM

/**
 * Show the Snackbar for a long duration.
 */
Snackbar.DURATION_LONG = DURATION_LONG

const themedStyles = StyleService.create({
  wrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  container: {
    elevation: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
    borderRadius: 4,
    shadowOpacity: 0.2,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 6,
  },
  content: {
    marginLeft: 16,
    marginVertical: 14,
    flexWrap: 'wrap',
    flex: 1,
  },
  button: {
    marginHorizontal: 8,
    marginVertical: 6,
  },
  actionLabel: {
    letterSpacing: 1,
    color: 'theme.text.secondary',
  },
})

export default Snackbar
