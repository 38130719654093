import React from 'react'
import moment from 'moment'
import { TouchableOpacity, ViewStyle } from 'react-native'
import { MONTH_NAME_AND_DATE_FORMAT } from '@src/config/momentFormat'
import { Calendar } from '@src/models/app.types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { isToday } from '@src/utils/global'
import { CalendarIcon } from './CalendarIcon'

interface CalendarIconWithDateProps {
  onPress: () => void
  calendar: Calendar
  styleProps?: ViewStyle
  iconSize?: 'xs' | 's' | 'm' | 'l'
  isSelectedProp?: boolean
}

const ICON_PROPS = { size: 16, weight: 'bold' } as const

export const CalendarIconWithDate = (props: CalendarIconWithDateProps) => {
  const { onPress, calendar, isSelectedProp = true, iconSize = 'm', styleProps } = props
  const styles = useStyleSheet(themedStyle)

  const isSelected = isSelectedProp || !isToday(calendar.endDate)

  if (!isSelected) {
    return (
      <CalendarIcon onPress={onPress} iconProps={ICON_PROPS} size={iconSize} style={styleProps} />
    )
  }

  const formattedStartDate = moment(calendar.startDate).format(MONTH_NAME_AND_DATE_FORMAT)
  const formattedEndDate = moment(calendar.endDate).format(MONTH_NAME_AND_DATE_FORMAT)

  return (
    <TouchableOpacity
      accessibilityLabel="Select Date Range"
      style={[styles.selectedContainer, styleProps]}
      onPress={onPress}
    >
      <Icon name="calendar-blank" style={styles.content} {...ICON_PROPS} />
      <Text type="regular" style={styles.content}>
        {formattedStartDate === formattedEndDate
          ? formattedStartDate
          : `${formattedStartDate} - ${formattedEndDate}`}
      </Text>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  selectedContainer: {
    height: 48,
    gap: 8,
    borderRadius: 24,
    paddingHorizontal: 14,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'theme.primary.base',
  },
  content: {
    color: 'theme.solid.white',
  },
})
