import { useEffect, useState } from 'react'
import Storage, { useStorageValue } from '@src/utils/storage'

export const markModalAsShown = (key: string) => {
  Storage.set(key, true)
}

export const shouldShowModalOnce = (key: string) => {
  const showModal = Storage.get<boolean>(key)

  if (showModal) {
    return false
  }

  return true
}

export const useOneTimeVisitedState = (
  storageKey: string,
  {
    shouldShowCondition = (value: boolean | undefined) => value === false,
  }: { shouldShowCondition?: (value: boolean | undefined) => boolean } = {},
) => {
  const [value] = useStorageValue<boolean>(storageKey)
  const [shouldShow, setShouldShow] = useState(shouldShowCondition(value))

  // this effect is only necessary for testing purposes, so we don't have to restart the app
  useEffect(() => {
    if (shouldShowCondition(value)) {
      setShouldShow(true)
    }
    // do not re-run on shouldShowCondition callback change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const markAsShown = () => {
    setShouldShow(false)
    markModalAsShown(storageKey)
  }

  return {
    shouldShow,
    markAsShown,
  }
}
