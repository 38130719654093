import React, { useEffect } from 'react'
import { ScrollView, View, TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { authenticatedUserSelector } from '@src/selectors/app'
import { openUrl, Zendesk } from '@utils'
import { Product, Subscription, SubscriptionStatus } from '@src/types'
import { STRIPE_VIEW_INVOICES } from '@src/screens/Marketplace/constants/constants'
import { useCanChangeBillingCycle } from '../BillingCycle/useCanChangeBillingCycle'
import { PaymentMethodCard } from '../checkout/PaymentMethodCard'
import { AddressForm, AddressType } from '../checkout/AddressForm'
import { useSubscriptionFromRoute } from '../../utils/hooks'
import { getSubscriptionTagDescriptor } from '../../utils/utils'
import { Actions } from './Actions'
import { SubscriptionFields } from './SubscriptionFields'
import { Fields } from './ProductSummaryFields'

const ProgramDetailsActions = ({
  subscription,
}: {
  subscription: Pick<Subscription, 'status' | 'cancelAt' | 'paused' | 'id'> & {
    primaryProduct: Pick<Product, 'uncancelable'>
  }
}) => {
  const navigation = useNavigation()
  const user = useSelector(authenticatedUserSelector)
  const dispatch = useDispatch()
  const paymentPastDue = subscription.status === SubscriptionStatus.PastDue

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
    })
  }, [dispatch])

  const buttonText = () => {
    if (subscription.cancelAt) {
      if (subscription.primaryProduct.uncancelable) {
        return 'Reactivate'
      } else {
        return undefined
      }
    }

    if (subscription.paused) {
      return 'Resume Subscription'
    }

    return 'Modify Subscription'
  }

  const onPress = () => {
    if (paymentPastDue) {
      openUrl(Zendesk.baseUrl(user?.email))
      return
    }

    if (subscription.cancelAt && subscription.primaryProduct.uncancelable) {
      navigation.navigate('ReactivateSubscriptionModal', { subscriptionId: subscription.id })
      return
    }

    if (subscription.paused) {
      navigation.navigate('ResumeSubscriptionModal')
      return
    }

    navigation.navigate('CancelSubscriptionOtherOptions', {
      subscriptionId: subscription.id,
    })
  }

  return (
    <Actions
      secondaryButtonText={buttonText()}
      secondaryButtonType={subscription.paused ? 'outline' : 'transparent'}
      onSecondaryButtonPress={onPress}
    />
  )
}

export const ProgramDetails = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const user = useSelector(authenticatedUserSelector)

  const { subscription } = useSubscriptionFromRoute()
  const canChangeBillingCycle = useCanChangeBillingCycle(subscription)

  if (!subscription) {
    return null
  }

  const { address, paymentMethod, email } = user || {}

  const onEditPaymentMethodPress = () => {
    navigation.navigate('CreatePaymentMethod')
  }

  const { isTrial, pillLabel, pillStatus } = getSubscriptionTagDescriptor(subscription)
  const { requiresShipping } = subscription.primaryProduct

  const addressType = requiresShipping ? AddressType.Shipping : AddressType.Billing

  const onEditAddressPress = () => {
    navigation.navigate('ChangeAddress', { addressType })
  }

  const onViewStripeInvoicesPress = () => {
    openUrl(`${STRIPE_VIEW_INVOICES}${encodeURIComponent(email)}`)
  }

  const onChangeBillingCyclePress = () => {
    const params = {
      subscriptionId: subscription.id,
    }
    navigation.navigate('ChangeBillingCycleModal', params)
  }

  return (
    <NavigationContainer title="Manage Subscription">
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: (insets?.bottom || 0) + 16 }}
      >
        <View style={styles.field}>
          <View style={styles.fieldSection}>
            <Text type="small" lineSpacing="none" style={styles.label}>
              Current Plan
            </Text>
            <Text type="large" bold lineSpacing="none">
              {subscription.primaryProduct.title}
            </Text>
          </View>
          <Pill text={pillLabel} status={pillStatus} />
        </View>

        <View style={styles.field}>
          <Text type="regular" lineSpacing="none" style={styles.billingInterval}>
            {isTrial ? 'Complimentary access' : subscription.primaryProduct.interval.description}
          </Text>
          <Text type="large" bold>
            ${subscription.price}
          </Text>
        </View>

        <SubscriptionFields
          subscription={subscription}
          mode="full"
          fields={[
            Fields.daysLeft,
            ...(subscription.status !== SubscriptionStatus.PastDue ? [Fields.commitmentEnds] : []),
            Fields.subscriptionEnds,
            Fields.nextBillingDate,
            Fields.resumesAt,
          ]}
        />

        {canChangeBillingCycle && (
          <TouchableOpacity
            onPress={onChangeBillingCyclePress}
            accessibilityLabel="Change shipping & billing Date"
            style={styles.field}
          >
            <Text type="regular" bold style={styles.actionText}>
              Change shipping & billing date
            </Text>
            <Icon name="caret-right" style={styles.arrowIcon} />
          </TouchableOpacity>
        )}

        {subscription.status === SubscriptionStatus.PastDue && (
          <View style={styles.pastDueMessage}>
            <Icon style={styles.alertIcon} name="warning" />
            <Text type="regular" style={styles.message}>
              The latest payment on this subscription was not successful and is now past due. Please
              update your method of payment below.
            </Text>
          </View>
        )}

        <Divider appearance="large" />
        <PaymentMethodCard
          paymentMethod={paymentMethod}
          onEditPaymentMethodPress={onEditPaymentMethodPress}
        />
        <Divider appearance="large" />
        <AddressForm
          addressType={addressType}
          address={address}
          onEditAddressPress={onEditAddressPress}
        />
        <Divider appearance="large" />
        <TouchableOpacity
          onPress={onViewStripeInvoicesPress}
          accessibilityLabel="View Invoices"
          style={styles.field}
        >
          <Text type="regular" bold style={styles.actionText}>
            View Invoices
          </Text>
          <Icon name="caret-right" style={styles.arrowIcon} />
        </TouchableOpacity>

        <ProgramDetailsActions subscription={subscription} />
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    backgroundColor: 'theme.background',
  },
  field: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    marginHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  fieldSection: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  label: {
    color: 'theme.text.secondary',
  },
  billingInterval: {
    flexShrink: 1,
    flexWrap: 'wrap',
    marginRight: 16,
  },
  actionText: {
    color: 'theme.text.link',
  },
  arrowIcon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  pastDueMessage: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  alertIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
    color: 'theme.warning.base',
  },
  message: {
    flex: 1,
  },
})
