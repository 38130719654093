import { useSelector } from 'react-redux'
import { userSelector } from '@src/selectors/app'

export const useIsEligibleForResearchSensor = () => {
  const user = useSelector(userSelector)

  const isEligibleForDexcomResearch =
    user && 'eligibleForDexcomResearch' in user ? user.eligibleForDexcomResearch : false

  return isEligibleForDexcomResearch
}
