import defaultReducers from '@models/defaultReducers'
import {
  DailyActionCollection,
  DailyActionCompletionsCount,
  UserDailyActionKind,
  UserDailyActionStatus,
} from '@src/types'
import { DailyActionCompletion, DailyActionsStoreState } from '../models/dailyActions.types'

const reducers = {
  ...defaultReducers,

  listData: (
    state: DailyActionsStoreState,
    {
      payload,
    }: {
      payload: { listUserDailyActions: DailyActionCollection }
    },
  ) => {
    return {
      ...state,
      dailyActions: payload?.listUserDailyActions?.dailyActions,
      dailyActionsCompletions: payload?.listUserDailyActions.completionCounts.reduce(
        (acc: DailyActionCompletion[], completion: DailyActionCompletionsCount) => {
          acc.push({
            dailyActionId: completion.dailyActionId,
            completed: completion.completionCount > 0,
          })

          return acc
        },
        [] as DailyActionCompletion[],
      ),
    }
  },
  toggleCompletion: (
    state: DailyActionsStoreState,
    {
      payload: { completed, dailyActionId },
    }: { payload: { completed: true; dailyActionId: string } },
  ) => {
    if (!dailyActionId) {
      return state
    }

    const existingCompletion = state.dailyActionsCompletions.some(
      (completion) => completion.dailyActionId === Number(dailyActionId),
    )

    const completedExistingActionsMarked = state.dailyActionsCompletions.map((completion) => {
      if (completion.dailyActionId === Number(dailyActionId)) {
        return {
          ...completion,
          completed,
        }
      }
      return completion
    })

    if (existingCompletion) {
      return {
        ...state,
        dailyActionsCompletions: completedExistingActionsMarked,
      }
    }

    return {
      ...state,
      dailyActionsCompletions: [
        ...state.dailyActionsCompletions,
        { dailyActionId: Number(dailyActionId), completed },
      ],
    }
  },

  upsertData: (
    state: DailyActionsStoreState,
    {
      payload: { kind, status },
    }: { payload: { kind: UserDailyActionKind; status: UserDailyActionStatus } },
  ) => {
    if (!status) {
      return state
    }
    if (status === UserDailyActionStatus.Active) {
      return { ...state, dailyActions: [...state.dailyActions, { kind, status }] }
    }

    const updatedDailyActions = state.dailyActions.filter((action) => action.kind !== kind)

    return { ...state, dailyActions: updatedDailyActions }
  },
}

export default reducers
