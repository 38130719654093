import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/native'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text, UI_ICONS_MAPPING } from '@components/base'
import { ReorderableList, Section, TouchableSection } from '@components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppRouteProp } from '@src/navigation/types'
import { dailyMeasurementsSourcePrioritiesSelector } from '@selectors/integrations'
import { dailyMeasurementTypeDefLookupSelector } from '@selectors/app'
import { SourcePriorityListItem } from '@screens/Settings/models/settings.types'

export const SourcePriorityList = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()

  const route = useRoute<AppRouteProp<'SourcePriorityList'>>()
  const { measurementType } = route.params

  const currentTypeDef = useSelector(dailyMeasurementTypeDefLookupSelector)[measurementType]
  const storeList = useSelector(dailyMeasurementsSourcePrioritiesSelector)[measurementType]

  // Use a temporary list to prevent briefly jumping back to original state during update request
  const [tmpList, setTmpList] = useState<SourcePriorityListItem[]>()

  const fetchSourcePriority = useCallback(() => {
    dispatch({
      type: 'settings/fetchDailyMeasurementsSourcePriority',
      payload: { type: measurementType },
    })
  }, [dispatch, measurementType])

  const assignNewSourcePriority = useCallback(
    ({ data }: { data: SourcePriorityListItem[] }) => {
      setTmpList(data)
      dispatch({
        type: 'settings/updateDailyMeasurementsSourcePriority',
        payload: { type: measurementType, sources: data.map((d) => d.key) },
        complete: () => setTmpList(undefined),
      })
    },
    [dispatch, measurementType],
  )

  useEffect(() => {
    fetchSourcePriority()
  }, [fetchSourcePriority])

  const renderFooterComponent = () => (
    <View style={styles.infoContainer}>
      <Icon name="info" />
      <Text type="small" lineSpacing="tight" style={styles.infoText}>
        To change the priority, touch and hold a source, then drag it to the desired position.
      </Text>
    </View>
  )

  const iconName = currentTypeDef?.icon ? UI_ICONS_MAPPING[currentTypeDef.icon] : undefined

  return (
    <NavigationContainer title="Assign source priority" isDrawerScreen showLoadingIndicator>
      <View style={styles.container}>
        <Section title="Measurement" style={styles.section} />
        <TouchableSection
          title={currentTypeDef?.label || ''}
          iconName={iconName}
          style={styles.typeSection}
        />
        <Section title="Sources" style={styles.section} />
        <ReorderableList
          data={tmpList || storeList || []}
          itemType="measurementSources"
          onRefresh={fetchSourcePriority}
          onDragEnd={assignNewSourcePriority}
          size="normal"
          renderFooterList={renderFooterComponent}
        />
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  section: {
    height: 40,
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  typeSection: {
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base',
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    margin: 32,
  },
  infoText: {
    flex: 1,
    marginLeft: 16,
    color: 'theme.text.secondary',
  },
})
