import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationCard } from '@src/navigation/components/NavigationCard'
import { User, openUrl } from '@src/utils'
import { useDexcomIntegration } from '@src/utils/hooks'
import { TutorialGroups } from '@src/screens/Tutorials/models/tutorials.types'
import { researchFaqsSelector } from '@src/selectors/app'
import { MobileAppFeature } from '@src/types'

export const ListHeader = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const isDexcomIntegration = useDexcomIntegration()
  const researchFaqs = useSelector(researchFaqsSelector)

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const handleFAQsPress = () => {
    navigation.navigate('FAQs')
  }

  const handleSensorGuidesPress = () => {
    if (isDexcomIntegration) {
      openUrl(researchFaqs.gettingStartedUrl)
    } else {
      navigation.navigate('ActivateSensorTutorialsGroup', {
        group: TutorialGroups.LibreNative,
      })
    }
  }

  return (
    <View style={styles.container}>
      <NavigationCard iconName="question" title="FAQs" onPress={handleFAQsPress} />
      {isCGMFeatureAvailable && (
        <NavigationCard
          iconName="radio-button"
          title="Sensor Guides"
          onPress={handleSensorGuidesPress}
        />
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    gap: 8,
  },
})
