import React from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { useDispatch } from 'react-redux'
import { Divider } from '@ui-kitten/components'
import { useRoute } from '@react-navigation/native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Pill, Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useSnack } from '@utils'
import { SubscriptionStatus } from '@src/types'
import { useSubscriptionFromRoute } from '@src/screens/Marketplace/utils/hooks'
import { getSubscriptionTagDescriptor } from '@src/screens/Marketplace/utils/utils'
import { AppRouteProp } from '@src/navigation/types'
import { DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT } from '@src/config/momentFormat'
import { SubscriptionFields, Fields } from '../../ProgramDetails'
import { Actions } from '../../ProgramDetails/Actions'

export const ChurnRecoveryUpsellOfferConfirmation = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const { subscription } = useSubscriptionFromRoute()
  const { params } = useRoute<AppRouteProp<'ChurnRecoveryUpsellOfferConfirmation'>>()
  const { billingProduct, billingProductPrice, billingProductIntervalDescription } = params
  const showSnack = useSnack()

  if (!subscription) {
    return null
  }

  const { pillLabel, pillStatus } = getSubscriptionTagDescriptor(subscription)

  const onConfirm = () => {
    dispatch({
      type: 'marketplace/scheduleSubscriptionChange',
      payload: { id: subscription.id, product: billingProduct },
      success: () => {
        showSnack("Success! You're all set! Your plan is updated with the discount applied")
        const nextRoute = {
          name: 'Subscriptions' as keyof ReactNavigation.RootParamList,
        }

        navigation.reset({
          index: 1,
          routes: [
            { name: 'Drawer', state: { index: 0, routes: [{ name: 'Dashboard' }] } },
            nextRoute,
          ],
        })
      },
      failure: (error: any) => {
        showSnack(error.message, null, 'error')
      },
    })
  }

  return (
    <NavigationContainer title="Subscribe to your New Plan">
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: (insets?.bottom || 0) + 16 }}
      >
        <View style={{ ...styles.field, borderBottomWidth: subscription.commitmentEndAt ? 2 : 0 }}>
          <View style={styles.fieldSection}>
            <Text type="small" lineSpacing="none" style={styles.label}>
              Current Plan
            </Text>
            <Text type="regular" bold lineSpacing="none">
              {subscription.primaryProduct.title}
            </Text>
          </View>
          <Pill text={pillLabel} status={pillStatus} />
        </View>

        <SubscriptionFields
          subscription={subscription}
          mode="full"
          fields={[
            ...(subscription.status !== SubscriptionStatus.PastDue ? [Fields.commitmentEnds] : []),
          ]}
        />

        <Divider appearance="large" />

        <View style={styles.upcomingPlanField}>
          <View style={styles.fieldSection}>
            <Text type="small" lineSpacing="none">
              Upcoming Plan
            </Text>
            <View style={styles.planDescriptionField}>
              <Text type="regular" bold style={styles.billingInterval}>
                {billingProductIntervalDescription}
              </Text>
              <Text type="large" bold style={{ alignSelf: 'center' }}>
                ${Number(billingProductPrice)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.field}>
          <Text type="small" lineSpacing="none" style={styles.label}>
            Upcoming charge
          </Text>
          <Text type="regular" bold>
            {moment(subscription.commitmentEndAt || subscription.currentPeriodEndAt).format(
              DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT,
            )}
          </Text>
        </View>

        <Actions primaryButtonText="Confirm" onPrimaryButtonPress={onConfirm} />
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    backgroundColor: 'theme.background',
  },
  field: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    marginHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  planDescriptionField: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  upcomingPlanField: {
    flex: 1,
    maxHeight: 72,
    marginHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  fieldSection: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  label: {
    color: 'theme.text.secondary',
  },
  billingInterval: {
    flexShrink: 1,
    flexWrap: 'wrap',
    marginRight: 16,
  },
  actionText: {
    color: 'theme.text.link',
  },
  arrowIcon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  pastDueMessage: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
  },
  alertIcon: {
    width: 24,
    height: 24,
    marginRight: 16,
    color: 'theme.warning.base',
  },
  message: {
    flex: 1,
  },
})
