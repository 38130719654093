import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components/FeatureFlag'
import { SubscriptionCheckoutOptionKind, UiStateNames, UserApprovalRequestState } from '@src/types'
import { userSelector } from '@src/selectors/app'

// Indicates that a user has purchased a research-eligible subscription,
// but has not yet completed the enrollment process.
// Often used as a proxy for whether the user should be shown the sensor selection screen.
// Ways this UI state value can be set to false:
// - user selects the non-research sensor in sensor selection
// - user completes dexcom research enrollment
// - user is approved for research or prescription enrollment
export const useIsResearchEligiblePendingEnrollment = () => {
  const user = useSelector(userSelector)
  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)

  const isUserEligibleForResearchSensor = !!user?.uiStates.some(
    ({ name, value }) => name === UiStateNames.DexcomSensorPrompt && value === true,
  )

  return isDexcomResearchEnabled && isUserEligibleForResearchSensor
}

export const useShouldSkipSensorSelection = () => {
  const user = useSelector(userSelector)
  const isSkipSensorSelectionEnabled = useFeatureFlag(Feature.SkipSensorSelection)
  const isResearchEligiblePendingEnrollment = useIsResearchEligiblePendingEnrollment()
  // if the user is approved for at least one sensor, then we can choose a sensor for them
  // and they don't need to see sensor selection
  const isResearchApproved =
    user?.lastResearchApprovalRequest?.state === UserApprovalRequestState.Approved
  const isPrescriptionApproved =
    user?.lastPrescriptionApprovalRequest?.state === UserApprovalRequestState.Approved

  return (
    !isResearchEligiblePendingEnrollment &&
    isSkipSensorSelectionEnabled &&
    (isResearchApproved || isPrescriptionApproved)
  )
}

// Used in cases when we want to automatically select a sensor for the user
// instead of showing them sensor selection.
export const useAutomaticCheckoutOptionKinds = () => {
  const user = useSelector(userSelector)
  const shouldSkipSensorSelection = useShouldSkipSensorSelection()

  // user must do their own sensor selection, we can't do it for them
  if (!shouldSkipSensorSelection) {
    return
  }

  // give precedent to research approval
  if (user?.lastResearchApprovalRequest?.state === UserApprovalRequestState.Approved) {
    return [SubscriptionCheckoutOptionKind.Research]
  }

  if (user?.lastPrescriptionApprovalRequest?.state === UserApprovalRequestState.Approved) {
    return [SubscriptionCheckoutOptionKind.Prescription]
  }
}
