import React from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { dailyActionsWithCompletionsSelector } from '@src/selectors/dailyActions'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import {
  HomeScreenWalkthroughSteps,
  useHomeScreenWalkthrough,
} from '@src/context/homeScreenWalkthroughContext'

import { CustomEventTypes } from '@src/config/analytics'
import { Analytics } from '@src/config'
import { InformationPopups } from '../Events/components/InformationPopups'
import { DailyActionCard } from './DailyActionCard'

export interface DailyActionsSectionProps {
  HeroChartComponent: React.ReactNode
}

export const DailyActionsSection = ({ HeroChartComponent }: DailyActionsSectionProps) => {
  const navigation = useNavigation()
  const dailyActions = useSelector(dailyActionsWithCompletionsSelector)
  const styles = useStyleSheet(themedStyle)

  const { shouldShow, advanceWalkthroughToNextStep, isStepVisible } = useHomeScreenWalkthrough()

  // we need to render the FlatList with the Walkthrough component in order for the list to scroll
  // so based on shouldShow, we render the FlatList with or without the Walkthrough component
  const content = (
    <View style={styles.container}>
      <FlatList
        ListHeaderComponentStyle={styles.listHeaderStyles}
        ListHeaderComponent={
          <>
            {HeroChartComponent}
            <InformationPopups />
            <View style={styles.listHeaderActions}>
              <Text type="regular" bold style={{ alignSelf: 'center' }}>
                Today's Focus
              </Text>
              <TouchableOpacity
                accessibilityLabel="Customize Daily Actions"
                style={styles.customizeAction}
                onPress={() => {
                  navigation.navigate('EditDailyActionsModal')
                }}
              >
                <Text type="regular" bold lineSpacing="none" style={styles.customizeActionText}>
                  Customize
                </Text>
              </TouchableOpacity>
            </View>
          </>
        }
        showsVerticalScrollIndicator={false}
        data={dailyActions}
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <DailyActionCard dailyAction={item} />}
        contentContainerStyle={styles.list}
      />
    </View>
  )

  const contentWithWalkthrough = (
    <>
      {HeroChartComponent}
      <InformationPopups />
      <WithWalkthrough
        isVisible={isStepVisible(HomeScreenWalkthroughSteps.DailyActions)}
        onClose={() => {
          Analytics.track(CustomEventTypes.HomeScreenWalkthroughDailyActionsSectionTapped)
          advanceWalkthroughToNextStep()
        }}
        allowChildInteraction={false}
        accessibilityLabel="Daily Actions Section"
        content={
          <WalkthroughTooltip
            title="Daily Actions to Complete"
            subtitle="Check off what’s important. Tap “Customize” to adjust."
          />
        }
        placement="top"
      >
        <View style={styles.walkthroughFocusContainer}>
          <FlatList
            ListHeaderComponentStyle={styles.listHeaderStyles}
            ListHeaderComponent={
              <View style={styles.listHeaderActions}>
                <Text type="regular" bold style={styles.listHeaderTitle}>
                  Today's Focus
                </Text>

                <WithWalkthrough
                  isVisible={isStepVisible(HomeScreenWalkthroughSteps.Customize)}
                  onClose={() => {
                    Analytics.track(CustomEventTypes.HomeScreenWalkthroughCustomizeTapped)
                    advanceWalkthroughToNextStep()
                  }}
                  allowChildInteraction={false}
                  childContainerStyles={{ flex: 1 }}
                  accessibilityLabel="Daily Actions Section"
                  content={
                    <WalkthroughTooltip
                      title="Personalize Your Actions"
                      subtitle="Customize these actions to fit your health goals."
                    />
                  }
                  placement="bottom"
                >
                  <TouchableOpacity
                    accessibilityLabel="Customize Daily Actions"
                    style={{ ...styles.customizeAction, ...styles.walkthroughFocusContainer }}
                  >
                    <Text type="regular" bold lineSpacing="none" style={styles.customizeActionText}>
                      Customize
                    </Text>
                  </TouchableOpacity>
                </WithWalkthrough>
              </View>
            }
            showsVerticalScrollIndicator={false}
            data={dailyActions.slice(0, 2)} // show only 2 highlighted actions in the walkthrough
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => <DailyActionCard dailyAction={item} />}
            contentContainerStyle={styles.list}
          />
        </View>
      </WithWalkthrough>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={dailyActions.slice(2)} // show the rest of the actions in the list below the walkthrough
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <DailyActionCard dailyAction={item} />}
        contentContainerStyle={styles.list}
      />
    </>
  )

  return shouldShow ? contentWithWalkthrough : content
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  customizeAction: {
    alignSelf: 'center',
  },
  customizeActionText: { color: 'theme.text.link', margin: 8 },
  walkthroughFocusContainer: {
    borderRadius: 24,
    backgroundColor: 'theme.background',
  },
  listHeaderStyles: { marginHorizontal: -16 },
  listHeaderActions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
    marginTop: 16,
    marginHorizontal: 16,
  },
  listHeaderTitle: { alignSelf: 'center' },
  list: {
    marginHorizontal: 16,
  },
})
