import React from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { TIME_FORMAT } from '@src/utils/global'
import { ListItemWithScore } from '@src/components/list/common/ListItemWithScore'
import {
  ActivityType,
  Meal,
  ICON_NAME_BY_ACTIVITY_TYPE,
  ICON_NAME_BY_MEAL_TYPE,
} from '@src/screens/Events/models/events.types'
import { GlucoseGraphCard } from '@src/components/list/items/expanded/Cards'
import { formatScore } from '@src/components/nutritionCard/utils'
import { Activity } from '@src/types'
import { ShareableViews } from './constants'

interface ItemShareableViewProps {
  item: Activity | Meal
  onLoad: () => void
  shareableViewName: ShareableViews
}

const ItemShareableView = (props: ItemShareableViewProps) => {
  const { item, shareableViewName, onLoad } = props

  const { title, occurredAt, description, statistics } = item
  const avatar = (item as Meal).avatar

  const styles = useStyleSheet(themedStyles)

  const isActivity = [ShareableViews.ActivityGlucoseGraph].includes(shareableViewName)

  const enableScore = !isActivity

  const iconName = isActivity
    ? ICON_NAME_BY_ACTIVITY_TYPE[item.type as ActivityType]
    : ICON_NAME_BY_MEAL_TYPE[(item as Meal).kind]

  const renderCard = () => {
    if (shareableViewName === ShareableViews.ActivityGlucoseGraph) {
      return (
        <GlucoseGraphCard
          item={item}
          statistics={statistics}
          renderCharts
          disableAnimation
          onLoad={onLoad}
        />
      )
    }

    return null
  }

  const score = enableScore ? formatScore(statistics?.glucoseScore) : undefined

  return (
    <>
      <ListItemWithScore
        title={title}
        subtitle={description}
        info={moment(occurredAt).format(TIME_FORMAT)}
        imageUri={avatar}
        placeholderImageUri={avatar}
        iconName={iconName}
        score={score}
      />
      <View style={styles.shareCardContainer}>{renderCard()}</View>
    </>
  )
}

const themedStyles = StyleService.create({
  shareCardContainer: {
    paddingVertical: 8,
  },
})

export default ItemShareableView
