import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

const insuranceStatusStateSelector = (state: RootStoreState) => state.insuranceStatus

const insurancePoliciesStateSelector = createSelector(
  insuranceStatusStateSelector,
  (insuranceStatusStore) => insuranceStatusStore?.insurancePolicies,
)

export const insurancePoliciesSelector = createSelector(
  insurancePoliciesStateSelector,
  (insurancePolicies) => insurancePolicies?.insurancePolicies,
)

export const canCreateNewPolicySelector = createSelector(
  insurancePoliciesStateSelector,
  (insurancePolicies) => insurancePolicies?.canCreateNewPolicy,
)
