import React from 'react'
import { FlatListProps } from 'react-native'
import Animated, {
  AnimatedProps,
  Extrapolation,
  SharedValue,
  interpolate,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated'

type AnimFLProps<T> = Omit<AnimatedProps<FlatListProps<T>>, 'CellRendererComponent'>
type excludedProps = 'stickyHeaderIndices' | 'ListHeaderComponent' | 'onScroll'
type ComponentOrFunc<T> = React.ReactNode | ((params: T) => React.ReactNode | null | undefined)
interface FlatListWithCollapsibleHeaderProps<T> extends Exclude<AnimFLProps<T>, excludedProps> {
  headerHeightMin: number
  headerHeightMax: number
  animatedHeader: ComponentOrFunc<SharedValue<number>>
  onScroll?: ReturnType<typeof useAnimatedScrollHandler>
}

const renderComponentOrFunc = <T,>(component: ComponentOrFunc<T>, params: T) =>
  typeof component === 'function' ? component(params) : component

export const FlatListWithCollapsibleHeader = <T,>({
  headerHeightMin,
  headerHeightMax,
  animatedHeader,
  onScroll = () => 0,
  renderItem,
  ...props
}: FlatListWithCollapsibleHeaderProps<T>) => {
  const scrollOffsetY = useSharedValue(0)
  const scrollDistance = headerHeightMax - headerHeightMin

  const scrollHandler = useAnimatedScrollHandler((e) => {
    scrollOffsetY.value = e.contentOffset.y
    if (typeof e === 'function') {
      onScroll(e)
    }
  })

  const headerHeight = useDerivedValue(() =>
    interpolate(
      scrollOffsetY.value,
      [0, scrollDistance],
      [headerHeightMax, headerHeightMin],
      Extrapolation.CLAMP,
    ),
  )

  const animatedHeaderStyles = useAnimatedStyle(() => ({
    height: headerHeight.value,
  }))

  // we need to offset the list itself by how much the header shrunk
  const animatedListStyles = useAnimatedStyle(() => ({
    paddingTop: interpolate(
      scrollOffsetY.value,
      [0, scrollDistance],
      [0, scrollDistance],
      Extrapolation.CLAMP,
    ),
  }))

  return (
    <Animated.FlatList
      {...props}
      stickyHeaderIndices={[0]}
      onScroll={scrollHandler}
      ListHeaderComponent={
        <Animated.View style={animatedHeaderStyles}>
          {renderComponentOrFunc(animatedHeader, headerHeight)}
        </Animated.View>
      }
      renderItem={(item) => {
        // had to apply this to the first item since contentContainerStyle
        // and ListHeaderComponentStyle don't appear to animate properly
        const component = (typeof renderItem === 'function'
          ? renderItem(item)
          : renderItem) as React.ReactElement | null

        if (item.index !== 0) {
          return component
        }

        return <Animated.View style={animatedListStyles}>{component}</Animated.View>
      }}
    />
  )
}
