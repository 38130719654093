import React from 'react'
import { View, Image, ImageStyle, ViewStyle, StyleProp } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useNavigation } from '@react-navigation/native'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { upgradeToDexcomSensorImage } from '@src/assets/images'
import { useIsDarkMode } from '@src/config/theme'
import { WarningBanner } from './WarningBanner'

export interface UpgradeToDexcomSensorBannerProps {
  containerStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
}

export const UpgradeToDexcomSensorBanner = ({
  containerStyle,
  textStyle,
  iconStyle,
}: UpgradeToDexcomSensorBannerProps) => {
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation()

  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const onPress = () => {
    navigation.navigate('UpgradeToDexcomSensor')
  }

  const textColor = isDarkMode ? theme['theme.tooltip.textPrimary'] : theme['theme.text.primary']

  const image = (
    <Image source={upgradeToDexcomSensorImage.imageSource} style={styles.image as ImageStyle} />
  )

  const body = (
    <View style={styles.textContainer}>
      <Text type="regular" style={[{ color: textColor }, textStyle]}>
        <Text type="regular" bold style={[{ color: textColor }, textStyle]}>
          Switch to Dexcom G7.
        </Text>{' '}
        Send data automatically via Bluetooth.
        {'\n'}
        Check if you are eligible!
      </Text>
    </View>
  )

  return (
    <WarningBanner
      accessibilityLabel="Switch to Dexcom G7"
      onPress={onPress}
      image={image}
      body={body}
      containerStyle={containerStyle}
      iconStyle={iconStyle}
    />
  )
}

const themedStyles = StyleService.create({
  image: {
    width: 70,
    height: 52,
    marginRight: 12,
  },
  textContainer: {
    flex: 1,
    marginRight: 8,
  },
})
