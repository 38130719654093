import { UserExperienceSensorModeKind } from '@src/types'
import { DEXCOM_SENSORS, OWN_SENSOR_MODES, OwnSensorMode } from './types'

export const isByosOnboardingSensorMode = (
  sensorModeKind: UserExperienceSensorModeKind | undefined,
): sensorModeKind is OwnSensorMode => {
  return !!sensorModeKind && OWN_SENSOR_MODES.includes(sensorModeKind as OwnSensorMode)
}

export const isHealthPermissionRequired = (sensorMode: OwnSensorMode) => {
  return DEXCOM_SENSORS.includes(sensorMode)
}
