import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollView } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Calendar } from '@models/app.types'
import { AppStackParamList } from '@navigation/types'
import { CalendarTabs } from '@src/screens/Insights/components/CalendarBar/CalendarTabs'
import { insightsCalendarSelector } from '@screens/Insights/models/insights.selectors'
import { useFetchStats } from '@screens/Insights/utils/hooks'
import { Feature, useFeatureFlag } from '@src/components'
import { CalendarIconWithDate } from '@src/components/CalendarTabs/CalendarIconWithDate'
import { CalendarIcon } from '@src/components/CalendarTabs/CalendarIcon'
import { CALENDAR_TABS } from '../../constants'
import { CalendarTabsOld } from './CalendarTabsOld'

export const CalendarBar = () => {
  const dispatch = useDispatch()
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const calendar = useSelector(insightsCalendarSelector)

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const { fetchStats } = useFetchStats()

  const handleCalendarChange = useCallback(
    (newCalendar: Calendar) => {
      dispatch({
        type: 'insights/updateCalendarState',
        payload: newCalendar,
      })
    },
    [dispatch],
  )

  useEffect(() => {
    fetchStats({
      startDate: calendar.startDate,
      endDate: calendar.endDate,
    })
  }, [calendar.startDate, calendar.endDate, fetchStats])

  const onCalendarIconPress = () => {
    navigation.navigate('ChangeDateRange', { type: 'insights' })
  }

  return (
    <ScrollView
      contentContainerStyle={styles.scrollView}
      horizontal
      showsHorizontalScrollIndicator={false}
      bounces={false}
    >
      {userGoalsEnabled ? (
        <>
          <CalendarIconWithDate
            calendar={calendar}
            isSelectedProp={!Object.keys(CALENDAR_TABS).includes(calendar.rangeDays.toString())}
            onPress={onCalendarIconPress}
          />
          <CalendarTabs calendar={calendar} onChange={handleCalendarChange} />
        </>
      ) : (
        <>
          <CalendarIcon onPress={onCalendarIconPress} />
          <CalendarTabsOld calendar={calendar} onChange={handleCalendarChange} />
        </>
      )}
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  scrollView: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 4,
    gap: 8,
  },
})
