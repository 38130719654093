import { gql } from '@apollo/client'

export const FETCH_LEGAL_POLICY_CONSENT_STATUSES = gql`
  query legalPolicyConsentStatuses {
    legalPolicyConsentStatuses {
      statuses {
        kind
        required
        policy {
          kind
          title
          url
          markdown
        }
        lastPolicyConsent {
          kind
          state
          createdAt
        }
      }
    }
  }
`
