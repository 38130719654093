import React, { ReactNode } from 'react'
import {
  ColorValue,
  LayoutChangeEvent,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useForceLandscapeOrientation } from '@src/utils/orientation'
import { Icon, IconDescriptor, DateSelectorController, DateSelectorType } from '@components'
import { Text } from '@components/base'
import { ThemeColor } from '@src/style/theme'
import ImpersonationBar from './ImpersonationBar'
import NoInternetConnectionBar from './NoInternetConnectionBar'

export interface NavigationBarAccessory {
  iconDescriptor?: IconDescriptor
  iconTintColor?: ThemeColor
  renderIconComponent?: () => ReactNode
  onPress: () => void
  onLongPress?: () => void
  disabled?: boolean
  accessibilityLabel?: string
  testID?: string
}

const isNavigationBarAccessory = (
  element: NavigationBarAccessory | ReactNode,
): element is NavigationBarAccessory =>
  element !== undefined &&
  (element as NavigationBarAccessory).onPress !== undefined &&
  ((element as NavigationBarAccessory).iconDescriptor !== undefined ||
    (element as NavigationBarAccessory).renderIconComponent !== undefined)

interface ActionProps {
  element: NavigationBarAccessory | ReactNode
}

const Action = (props: ActionProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)

  const { element } = props

  if (!isNavigationBarAccessory(element)) {
    return <View>{element}</View>
  }

  return (
    <TouchableOpacity
      testID={element.testID}
      key={element.iconDescriptor?.name}
      disabled={!!element.disabled}
      style={!element.renderIconComponent && styles.button}
      activeOpacity={0.7}
      onPress={element.onPress}
      onLongPress={element.onLongPress}
      hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
      accessibilityLabel={element.accessibilityLabel || element.iconDescriptor?.name || 'icon'}
    >
      {element.renderIconComponent
        ? element.renderIconComponent()
        : !!element.iconDescriptor && (
            <Icon
              style={[
                styles.icon,
                element.iconTintColor &&
                  !element.disabled && { tintColor: theme[element.iconTintColor] },
              ]}
              iconDescriptor={element.iconDescriptor}
            />
          )}
    </TouchableOpacity>
  )
}

export interface NavigationBarProps {
  title?: string | ReactNode
  leftAccessory?: NavigationBarAccessory
  rightAccessories?: (NavigationBarAccessory | ReactNode)[]
  titleStyle?: StyleProp<TextStyle>
  backgroundColor?: ColorValue
  dateSelectorType?: DateSelectorType
  setHeaderHeight?: (height: number) => void
  style?: StyleProp<ViewStyle>
  allowRangeSelection?: boolean
}

export const NavigationBar = (props: NavigationBarProps) => {
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)
  const {
    title,
    leftAccessory,
    rightAccessories,
    titleStyle,
    backgroundColor,
    dateSelectorType = 'app',
    allowRangeSelection = true,
    style,
    setHeaderHeight,
  } = props
  const backgroundStyle: StyleProp<any> = backgroundColor ? { backgroundColor } : undefined

  const onLayout = (event: LayoutChangeEvent) => {
    setHeaderHeight?.(event.nativeEvent.layout.height)
  }

  const forceLandscapeOrientation = useForceLandscapeOrientation()

  let titleComponent
  if (title) {
    if (typeof title === 'string') {
      titleComponent = (
        <Text
          type="large"
          bold
          testID="NavigationBar/Title"
          style={[styles.title, titleStyle]}
          numberOfLines={1}
        >
          {title}
        </Text>
      )
    } else {
      titleComponent = title
    }
  } else {
    titleComponent = (
      <DateSelectorController type={dateSelectorType} allowRangeSelection={allowRangeSelection} />
    )
  }

  return (
    <View
      testID="NavigationBar"
      style={[
        styles.container,
        { paddingTop: forceLandscapeOrientation ? 0 : insets?.top },
        backgroundStyle,
        style,
      ]}
      onLayout={onLayout}
      pointerEvents="box-none"
    >
      <NoInternetConnectionBar />
      <ImpersonationBar />
      <View style={[styles.content, backgroundStyle]}>
        <View style={[styles.accessoriesContainer, styles.accessoriesContainerLeft]}>
          {leftAccessory && <Action element={leftAccessory} />}
        </View>

        {titleComponent}

        <View style={[styles.accessoriesContainer, styles.accessoriesContainerRight]}>
          {rightAccessories &&
            rightAccessories.map((element, index) => <Action key={index} element={element} />)}
        </View>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  content: {
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 14,
    backgroundColor: 'theme.background',
  },
  accessoriesContainer: {
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 1,
  },
  accessoriesContainerLeft: {
    left: 0,
  },
  accessoriesContainerRight: {
    right: 0,
  },
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  title: {
    maxWidth: '70%',
  },
  button: {
    width: 44,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 24,
    width: 24,
    tintColor: 'theme.text.primary',
  },
})
