import React, { useRef } from 'react'
import { Vibration } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Code } from 'react-native-vision-camera'
import { AppStackParamList } from '@navigation/types'
import { CameraContainer } from '@screens/Common/containers'
import { normalizeNutritionixItem } from '@screens/Ingredients/utils'
import { Nutritionix } from '@services'

export const BarcodeIngredientSearchContainer = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const scannedRef = useRef(false)

  const onBarcodeRead = async (barcode: string) => {
    const ingredients = await Nutritionix.getItemsByBarcode({ barcode })

    if (!ingredients || ingredients.length === 0) {
      navigation.replace('CancelModal', {
        title: 'Sorry, we can’t find a match for this barcode',
        confirmScreen: 'CustomIngredient',
        confirmText: 'Create new ingredient',
        cancelScreen: 'BarcodeIngredientSearch',
        cancelText: 'Try one more time',
        parentScreen: 'IngredientSearch',
        replace: true,
      })
      return
    }

    navigation.replace('IngredientPortion', {
      ingredient: normalizeNutritionixItem(ingredients[0]),
    })
  }

  const onRead = (code: Code) => {
    if (scannedRef.current) {
      return
    }

    const barcode = code.value

    if (!barcode) {
      return
    }

    scannedRef.current = true

    Vibration.vibrate()

    onBarcodeRead(barcode)
  }

  return <CameraContainer onBarCodeRead={onRead} />
}
