import React, { useRef } from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { arrowRightImage, bluetoothImage, cgmDexcomImage, cgmLibreImage } from '@src/assets/images'
import { Button, Icon, Text } from '@components/base'
import { AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { SensorModel, SurveysConfigKind, SurveysUserLinkState, UiInlineAlert } from '@src/types'
import { openUrl, Storage, useDispatchAsync, useGoBack, useSnack } from '@utils'
import { researchFaqsSelector, userSelector } from '@src/selectors/app'
import { Benefit, InlineAlert, ScrollViewWithFade } from '@src/components'

const FADE_BUFFER = 24

export const UpgradeToDexcomSensor = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()

  const loadingRef = useRef(false)
  const showSnack = useSnack()

  const goBack = useGoBack()

  const user = useSelector(userSelector)
  const researchFaqs = useSelector(researchFaqsSelector)

  const handleNextStepPress = async () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    const surveyLinks = user?.surveyLinks || []

    const isMedicalHistoryQuestionnaireCompleted = surveyLinks.some(
      (surveyLink) =>
        surveyLink.survey.kind === SurveysConfigKind.MedicalHistory &&
        surveyLink.finished &&
        surveyLink.state !== SurveysUserLinkState.Stopped,
    )

    try {
      await dispatchAsync({
        type: 'app/selectSensor',
        payload: { model: SensorModel.DexcomG7 },
      })
    } catch {
      showSnack('Failed to switch sensor. Please try again.', null, 'warning')
      return
    } finally {
      loadingRef.current = false
    }

    const questionnaire = isMedicalHistoryQuestionnaireCompleted
      ? SurveysConfigKind.DexcomResearchConsent
      : SurveysConfigKind.MedicalHistory

    navigation.replace('Questionnaire', { questionnaire })
  }

  const handleDataNoticePress = () => {
    openUrl(researchFaqs.dataProtectionUrl)
  }

  const onRejectPress = () => {
    Storage.set(`${Storage.DEXCOM_SENSOR_SWITCH_REJECTED_KEY}_${user?.id}`, true)
    goBack()
  }

  return (
    <NavigationContainer
      title="Switch to Dexcom G7"
      goBack={goBack}
      rightAccessories={[
        {
          renderIconComponent: () => (
            <View style={styles.questionIcon}>
              <Icon name="question" />
            </View>
          ),
          onPress: () => openUrl(researchFaqs.sensorComparisonUrl),
          accessibilityLabel: 'FAQ',
        },
      ]}
    >
      <SafeAreaView style={styles.content} edges={['bottom']}>
        <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
          <View style={styles.imagesContainer}>
            <View style={styles.sensorImageContainer}>
              <Image
                source={cgmLibreImage.imageSource}
                style={styles.libreSensorImage as ImageStyle}
              />
            </View>
            <View style={styles.arrowImageContainer}>
              <Image source={arrowRightImage.imageSource} style={styles.arrowImage as ImageStyle} />
            </View>
            <View style={styles.sensorImageContainer}>
              <Image
                source={cgmDexcomImage.imageSource}
                style={styles.dexcomSensorImage as ImageStyle}
              />
              <Image
                source={bluetoothImage.imageSource}
                style={styles.bluetoothImage as ImageStyle}
              />
            </View>
          </View>

          <Text type="regular" style={styles.title}>
            Experience greater control and insights for better health management at no added cost to
            you
          </Text>

          <View style={styles.benefits}>
            <Benefit benefit="Automatic bluetooth syncing. Scan-free glucose data every 5 minutes" />
            <Benefit benefit="3 x 10-day sensors (30 days tracking) per month" />
            <Benefit benefit="Waterproof to 8ft." />
          </View>

          <InlineAlert
            style={styles.infoAlert}
            textStyle={styles.infoAlertText}
            iconStyle={styles.infoAlertIcon}
            category={UiInlineAlert.Info}
            // eslint-disable-next-line max-len
            message="Just make sure to opt-in at least 48 hours before your billing date for your next shipment to be G7 sensors."
          />

          <Text type="small" style={styles.dataNotice}>
            Requires research opt-in. de-identified and protected{' '}
            <Text type="small" onPress={handleDataNoticePress} style={styles.dataNoticeLink}>
              de-identified and protected
            </Text>
          </Text>
        </ScrollViewWithFade>
        <View style={styles.buttons}>
          <Button
            accessibilityLabel="Switch Sensor"
            onPress={handleNextStepPress}
            style={styles.nextButton}
            size="block"
            type="primary"
          >
            Switch Sensor
          </Button>
          <Button
            accessibilityLabel="No thanks, I will stick with Freestyle Libre"
            type="transparent"
            size="block"
            textStyle={styles.secondaryButtonText}
            onPress={onRejectPress}
          >
            No thanks, I will stick with Freestyle Libre
          </Button>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },
  questionIcon: {
    padding: 12,
    marginRight: 4,
  },
  imagesContainer: {
    flexDirection: 'row',
    height: 100,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  sensorImageContainer: {
    maxWidth: 100,
    flex: 1,
  },
  arrowImageContainer: {
    maxWidth: 50,
    flex: 1,
    marginHorizontal: 8,
  },
  libreSensorImage: {
    width: '100%',
    flex: 1,
    resizeMode: 'contain',
  },
  arrowImage: {
    maxWidth: 50,
    marginTop: -30,
    flex: 1,
    resizeMode: 'contain',
  },
  dexcomSensorImage: {
    width: '100%',
    flex: 1,
    marginTop: 10,
    resizeMode: 'contain',
  },
  bluetoothImage: {
    width: 32,
    height: 32,
    position: 'absolute',
    top: 4,
    right: 12,
  },
  title: {
    textAlign: 'center',
  },
  dataNotice: {
    marginTop: 4,
    maxWidth: 271,
    alignSelf: 'center',
    marginBottom: 12,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  dataNoticeLink: {
    textDecorationLine: 'underline',
    color: 'theme.text.secondary',
  },
  benefits: {
    paddingVertical: 24,
    maxWidth: 280,
    flex: 1,
    width: '100%',
    alignSelf: 'center',
  },
  infoAlert: {
    borderWidth: 0,
    marginVertical: 16,
    backgroundColor: 'theme.surface.base2',
  },
  infoAlertText: {
    color: 'theme.text.secondary',
  },
  infoAlertIcon: {
    color: 'theme.text.secondary',
  },
  buttons: {
    gap: 8,
  },
  nextButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
  secondaryButtonText: {
    textAlign: 'center',
    maxWidth: 203,
  },
})
