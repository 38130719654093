import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { round } from 'lodash'
import { Text } from '@src/components/base'
import { Interval, Product } from '@src/types'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface FreeTrialPlanProps {
  product: Product
  popular: boolean
  onSelect: (product: Product) => void
  selected: boolean
}

export const FreeTrialPlan = ({ product, popular, onSelect, selected }: FreeTrialPlanProps) => {
  const styles = useStyleSheet(themedStyle)

  const breakdownText = (interval: Interval, price: number) => {
    if (interval.key === 'monthly') {
      return `$${round(price * 12, 2)} per year, billed monthly`
    }

    if (interval.key === 'yearly') {
      return `$${round(price / 12, 2)} per month, billed yearly`
    }

    return ''
  }

  return (
    <TouchableOpacity accessibilityLabel="Select Plan" onPress={() => onSelect(product)}>
      <View style={[styles.container, selected && styles.selected]}>
        {popular && (
          <View style={styles.popular}>
            <Text type="tiny" style={{ color: 'white' }} bold>
              MOST POPULAR
            </Text>
          </View>
        )}
        <Text type="small" bold>
          {product.interval.key.toUpperCase()}
        </Text>
        <Text type="regular" bold>
          ${product.price}/{product.interval.suffix}
        </Text>
        <Text type="tiny" style={{ textAlign: 'center' }}>
          {breakdownText(product.interval, product.price)}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    justifyContent: 'space-between',
    marginVertical: 8,
    padding: 16,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    width: 160,
    backgroundColor: 'theme.background',
    marginHorizontal: 4,
    borderRadius: 24,
  },
  popular: {
    alignItems: 'center',
    backgroundColor: 'theme.primary.base',
    borderRadius: 48,
    transform: [{ translateY: -45 }],
    position: 'absolute',
    top: '50%',
    right: '20%',
    bottom: '70%',
    left: '20%',
  },
  selected: {
    borderColor: 'theme.primary.base',
    backgroundColor: 'theme.surface.base2',
  },
})
