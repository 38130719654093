import { Model } from '@models'
import { Caching } from '@utils'
import {
  eventsCalendarSelector,
  eventsChartsSelector,
  eventsObjectsSelector,
} from '@src/selectors/events'
import { isDataAvailable } from '@src/components/Timeline/utils'
import { Calendar } from '@src/models/app.types'
import { initCalendar, processDateRangePayload } from '@src/models/helper'
import { transformHistoryOrEventItem } from '@src/transforms/eventAndHistoryItemTransform'

import reducers from '../reducers'

import ALL_CHARTS from '../graphql/allCharts'
import ALL_EVENTS from '../graphql/allEvents'
import ALL_NUTRITION from '../graphql/allNutrition'
import { defaultEventsState } from '../constants'
import { EventsItemType } from './events.types'

export default class Events {
  namespace = 'events'

  state = {
    ...Model.defaultState,
    ...defaultEventsState,
    calendar: initCalendar(),
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ALL_EVENTS,
      dataPath: 'allEvents.events',
      *variables(payload: any, { select }: any) {
        if (payload?.filter) {
          return { filter: payload.filter }
        }

        const { startDate, endDate }: Partial<Calendar> = yield select(eventsCalendarSelector)
        return {
          filter: {
            scope: 'events',
            startDate,
            endDate,
          },
        }
      },
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('events/fetch', variables),
      reducers: [{ name: 'fetchList', transform: transformHistoryOrEventItem }],
    }),

    fetchNutrition: Model.buildEffect({
      name: `${this.namespace}/fetchNutrition`,
      query: ALL_NUTRITION,
      dataPath: 'allNutrition',
      *variables(payload: any, { select }: any) {
        let queryVariables = payload

        if (!queryVariables?.filter?.startDate || !queryVariables?.filter?.endDate) {
          const { startDate, endDate }: Partial<Calendar> = yield select(eventsCalendarSelector)
          queryVariables = { filter: { startDate, endDate } }
        }

        return queryVariables
      },
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('events/fetchNutrition', variables),
      reducers: [{ name: 'fetchNutritionData' }],
    }),

    fetchCharts: Model.buildEffect({
      name: `${this.namespace}/fetchCharts`,
      query: ALL_CHARTS,
      *variables(payload: any, { select }: any) {
        const { startDate, endDate }: Partial<Calendar> = yield select(eventsCalendarSelector)
        const payloadFilter = payload?.filter || {}
        return {
          filter: {
            types: [{ key: 'timeline', value: [] }],
            ...payloadFilter,
            startDate: payloadFilter.startDate || startDate,
            endDate: payloadFilter.endDate || endDate,
          },
        }
      },
      dataPath: 'allCharts',
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('events/fetchCharts', variables),
      reducers: [{ name: 'fetchChartsData' }],
    }),

    changeDateRange: Model.buildEffect({
      name: `${this.namespace}/changeDateRange`,
      reducers: [
        {
          name: 'clear',
          storePath: 'events',
          *unless(payload: any, { select }: any) {
            const { startDate, endDate }: Calendar = yield select(eventsCalendarSelector)
            const { primary, secondary } = yield select(
              eventsChartsSelector<EventsItemType>(eventsObjectsSelector),
            )
            const { values: primaryValues = [] } = primary
            const { values: secondaryValues = [] } = secondary

            if (startDate === payload.startDate && endDate === payload.endDate) {
              return true
            }

            if (!isDataAvailable(primaryValues) && !isDataAvailable(secondaryValues)) {
              return true
            }

            return false
          },
        },
        {
          name: 'updateCalendarState',
          payload: processDateRangePayload,
        },
      ],
    }),

    setGlucoseThresholdsUpdated: Model.buildEffect({
      name: `${this.namespace}/setGlucoseThresholdsUpdated`,
      reducers: [{ name: 'glucoseThresholdsUpdated' }],
    }),
  }

  reducers = reducers
}
