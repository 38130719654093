import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { useDispatchAsync } from '@src/utils'
import { UiStateNames, UserExperienceSensorModeKind } from '@src/types'
import { uxSensorModeKindSelector } from '@src/selectors/app'
import { DEXCOM_SENSORS, OwnSensorMode } from './types'
import { useShouldShowByosOnboarding } from './hooks'

const SENSOR_TEXT: Record<
  | UserExperienceSensorModeKind.OwnSensorDexcomG6
  | UserExperienceSensorModeKind.OwnSensorDexcomG7
  | UserExperienceSensorModeKind.OwnSensorDexcomStelo,
  string
> = {
  [UserExperienceSensorModeKind.OwnSensorDexcomG6]: 'Dexcom G6',
  [UserExperienceSensorModeKind.OwnSensorDexcomG7]: 'Dexcom G7',
  [UserExperienceSensorModeKind.OwnSensorDexcomStelo]: 'Stelo by Dexcom',
}

export const ConnectionSuccessfulScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatchAsync = useDispatchAsync()
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)
  const isOnboarding = useShouldShowByosOnboarding()
  const navigation = useNavigation()

  const message = DEXCOM_SENSORS.includes(uxSensorModeKind as OwnSensorMode)
    ? `You're all set. You'll begin to see glucose data in a few minutes. ` +
      `Note, there is a 3 hour delay between the ${
        SENSOR_TEXT[uxSensorModeKind as keyof typeof SENSOR_TEXT]
      } app and Nutrisense.`
    : "You've been connected successfully!"

  const onNextPress = async () => {
    if (!isOnboarding) {
      navigation.navigate('Drawer')
      return
    }

    await dispatchAsync({
      type: 'app/upsertUiState',
      payload: {
        name: UiStateNames.OwnSensorOnboardingCompleted,
        value: true,
      },
    })
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={[styles.container]}>
      <View style={styles.contentContainer}>
        <Icon weight="fill" style={styles.icon} name="seal-check" size={48} />
        <Text type="title-2" style={{ marginTop: 16 }}>
          Done 🎉
        </Text>

        <Text type="large" style={{ paddingHorizontal: 32, marginTop: 40, textAlign: 'center' }}>
          {message}
        </Text>
      </View>

      <View style={styles.button}>
        <Button type="primary" size="block" onPress={onNextPress} accessibilityLabel="Continue">
          Continue
        </Button>
      </View>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  icon: {
    marginTop: 40,
    color: 'theme.success.base',
  },
  container: {
    flex: 1,
    paddingVertical: 16,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  button: {
    marginHorizontal: 24,
  },
})
