import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { Animated } from 'react-native'
import { useSelector } from 'react-redux'
import { find } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { SnackContent, SnackLevel } from '@src/navigation/components/SnackContent'
import loadingOpts from '@src/config/state/loadingOpts'
import { loadingSelector, loadingEffectsSelector } from '@selectors/loading'
import { DynamicStatusBar, LoadingIndicator } from '@src/components'
import { NavigatorContext } from '@src/utils/navigatorContext'
import { QuickActions } from '@src/config'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'

const FADE_ANIMATION_DURATION = 300

export const createNavigator = (WrappedComponent: React.ComponentType<any>) => {
  const Navigator = () => {
    const styles = useStyleSheet(themedStyles)
    const globalLoading = useSelector(loadingSelector)
    const loadingEffects = useSelector(loadingEffectsSelector)

    const opacity = useRef(new Animated.Value(0)).current

    const [snackVisible, setSnackVisible] = useState(false)
    const [snackTitle, setSnackTitle] = useState('')
    const [snackContent, setSnackContent] = useState<string | undefined | null>(null)
    const [snackLevel, setSnackLevel] = useState<SnackLevel>('success')
    const [forceGlobalLoader, setForceGlobalLoader] = useState(false)
    const [loaderVisible, setLoaderVisible] = useState(false)

    useEffect(() => {
      if (loaderVisible) {
        // Fade in animation
        Animated.timing(opacity, {
          toValue: 1,
          duration: FADE_ANIMATION_DURATION,
          useNativeDriver: true,
        }).start()
      } else {
        // Fade out animation
        Animated.timing(opacity, {
          toValue: 0,
          duration: FADE_ANIMATION_DURATION,
          useNativeDriver: true,
        }).start()
      }
    }, [loaderVisible, opacity])

    const disableInAppScan = useDisableInAppScan()

    const showSnack = useCallback(
      (snackTitle: string, snackContent?: string | null, snackLevel: SnackLevel = 'success') => {
        setSnackVisible(true)

        setSnackTitle(snackTitle)
        setSnackContent(snackContent)
        setSnackLevel(snackLevel)
      },
      [],
    )

    const hideSnack = useCallback(() => {
      setSnackVisible(false)
    }, [])

    const enableLoader = useCallback(() => {
      setForceGlobalLoader(true)
    }, [])

    const disableLoader = useCallback(() => {
      setForceGlobalLoader(false)
    }, [])

    useEffect(() => {
      QuickActions.configure(disableInAppScan)
    }, [disableInAppScan])

    useEffect(() => {
      const showOrHideLoader = () => {
        let visible = false

        if (globalLoading) {
          const disableGlobalLoader = loadingOpts.exceptGlobal
          const disableLoader = find(disableGlobalLoader, (key) => !!loadingEffects[key])

          visible = !disableLoader || forceGlobalLoader
        } else {
          visible = forceGlobalLoader
        }

        setLoaderVisible(visible)
      }

      showOrHideLoader()
    }, [globalLoading, loadingEffects, forceGlobalLoader])

    const screenProps = useMemo(
      () => ({
        showSnack,
        hideSnack,
        enableLoader,
        disableLoader,
      }),
      [showSnack, hideSnack, enableLoader, disableLoader],
    )

    return (
      <>
        <DynamicStatusBar />
        <NavigatorContext.Provider value={screenProps}>
          <WrappedComponent />
        </NavigatorContext.Provider>
        <Animated.View
          style={[styles.loaderContainer, { opacity }]}
          pointerEvents={loaderVisible ? 'auto' : 'none'}
        >
          <LoadingIndicator size="large" viewStyles={styles.spinner} color="white" />
        </Animated.View>
        <SnackContent
          title={snackTitle}
          content={snackContent}
          level={snackLevel}
          isVisible={snackVisible}
          onDismiss={hideSnack}
        />
      </>
    )
  }

  return Navigator
}

const themedStyles = StyleService.create({
  loaderContainer: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)' as 'theme.transparent',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    flex: 0,
  },
})
