import { last } from 'lodash'
import { NativeModules } from 'react-native'
import { Device } from '@src/config'
import {
  Tutorial,
  TutorialSensorKind,
  TutorialStepActionLink,
  TutorialStepActionTypes,
  TutorialStepActionVideo,
  TutorialStepPlatformLink,
} from '../models/tutorials.types'

const { scheme } = NativeModules.Configuration

export const isTutorialVideo = (
  action: TutorialStepActionVideo | TutorialStepActionLink | TutorialStepPlatformLink,
): action is TutorialStepActionVideo =>
  action.__typename === TutorialStepActionTypes.TutorialStepActionVideo
export const isTutorialLink = (
  action: TutorialStepActionVideo | TutorialStepActionLink | TutorialStepPlatformLink,
): action is TutorialStepActionLink =>
  action.__typename === TutorialStepActionTypes.TutorialStepActionLink

export const isPlatformLink = (
  action: TutorialStepActionVideo | TutorialStepActionLink | TutorialStepPlatformLink,
): action is TutorialStepPlatformLink =>
  action.__typename === TutorialStepActionTypes.TutorialStepPlatformLink

export const platformLinkUrl = (link: TutorialStepPlatformLink) => {
  const url = Device.ios ? link.ios.url : link.android.url
  const urlWithoutScheme = last(url.split('://'))
  return `${scheme}://${urlWithoutScheme}`
}

export const shouldNavigateToNextTutorial = (link: TutorialStepPlatformLink | undefined) => {
  if (!link) {
    return false
  }
  return platformLinkUrl(link).includes('app/tutorials')
}

export const isDexcomTutorial = (tutorial: Tutorial) => {
  return [
    TutorialSensorKind.DexcomG6,
    TutorialSensorKind.DexcomG7,
    TutorialSensorKind.DexcomStelo,
  ].includes(tutorial.sensorKind)
}
