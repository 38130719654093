import React from 'react'
import { View } from 'react-native'
import { BottomSheet } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils'

export const UpsertRecurrenceSuccessfulModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()

  return (
    <BottomSheet
      buttonDirection="column-reverse"
      primaryButton={{ text: 'Dismiss', onPress: goBack }}
    >
      <View style={styles.iconContainer}>
        <Icon name="check-circle" style={styles.icon} weight="duotone" />
      </View>
      <Text style={styles.content} type="title-2">
        Your recurrence is successfully processing.
      </Text>
      <Text style={styles.content} type="regular">
        We'll send a notification when all your appointments are booked.
      </Text>
      <Text style={styles.content} type="regular">
        Recurring appointments will be scheduled based on your preferred days and times. If
        unavailable, we'll choose the best alternatives. Adjustments can be made in the Upcoming
        Calls menu once scheduling is complete.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    textAlign: 'center',
    margin: 8,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 48,
    height: 48,
  },
})
