import { Moment } from 'moment'
import { IconName } from '@components/base'
import {
  Activity,
  EntryGlucose,
  EntryStatistics,
  HealthDataMeasurementSource,
  HealthDataMeasurementType,
  Ingredient,
  JournalEntry,
  MealKind,
  MealSource,
} from '@src/types'
import { MeasurementValues } from '@screens/Measurements/types'
import { Cache, Calendar } from '../../../models/app.types'
import { AllNutritionQuery } from '../graphql/allNutrition.generated'

export enum MeasurementType {
  BloodGlucose = 'BloodGlucose',
  BloodKetones = 'BloodKetones',
  BreathKetones = 'BreathKetones',
  BloodPressure = 'BloodPressure',
  BodyFatPercentage = 'BodyFatPercentage',
  BodyTemperature = 'BodyTemperature',
  CyclingDistance = 'CyclingDistance',
  RunningDistance = 'RunningDistance',
  FlightsClimbed = 'FlightsClimbed',
  StepCount = 'StepCount',
  ExerciseTime = 'ExerciseTime',
  Weight = 'Weight',
  Stress = 'Stress',
}

export enum ActivityType {
  Exercise = 'Exercise',
  Fasting = 'Fasting',
  Meditation = 'Meditation',
  Sleep = 'Sleep',
}

export enum JournalEntryType {
  JournalEntry = 'journal',
}

export type MealTypeName = 'Meal'
export type MeasurementTypeName = 'Measurement'
export type MeasurementDataTypeName = 'MeasurementData'
export type ActivityTypeName = 'Activity'

export type EventsItemType = Activity | Measurement | Meal | JournalEntry

export interface FavoriteRequestType {
  page: number
  pageSize: number
  filter: { favorite: boolean }
}

export interface Meal {
  id: string
  type: MealKind
  avatar?: string
  title: string
  value: number
  units: string
  description: string
  createdAt: string
  occurredAt: string
  updatedAt: string
  nutrition: Record<string, number>
  ingredients: Ingredient[]
  glucose?: EntryGlucose
  statistics: EntryStatistics
  favorite: boolean
  generatedOccurredAt?: boolean
  __typename: MealTypeName
  fake: boolean
  kind: MealKind
  source: MealSource
}

export interface Measurement {
  id: string
  type: HealthDataMeasurementType
  title: string
  value: number
  values?: MeasurementValues
  units: string
  description: string
  externalSource?: HealthDataMeasurementSource
  occurredAt: string
  createdAt: string
  __typename: MeasurementTypeName
  fake?: boolean
}

export interface Stats {
  scoreTimeOutsideRange?: number
  scorePeak?: number
  scoreMean?: number
  scoreStdDev?: number
  score?: number
}

export interface ScoreStats {
  today: Stats
  average: Stats
}

export interface ChartValue {
  displayDate: string
  tag: string | null
  x: Moment
  y: number | null
  id?: string
  fake?: boolean
}

export interface ChartData {
  description: string
  meta: {
    key: string
    section: string
    tag: string
  }
  range: {
    goal: number | null
    goalMax?: number
    goalMin?: number
    max: number
    min: number
    tickCount?: number
  }
  title: string
  type: string
  values: ChartValue[]
  xAxis: string
  yAxis: string
}

export interface EventCharts {
  primary: ChartData
  secondary: ChartData
}

export interface EventsStoreState {
  cache: Cache
  backup?: {
    events: EventsItemType[]
    charts: EventCharts
  }
  nutrition: Record<string, number>
  score: ScoreStats
  statistics: AllNutritionQuery['allNutrition']['statistics']
  events: EventsItemType[]
  charts: EventCharts
  calendar: Calendar
  glucoseThresholdLastUpdatedAt?: moment.Moment
}

export interface GroupedEvent {
  id: string
  index: number
  title: string
  occurredAt: moment.Moment
  data: EventsItemType[]
}

export interface EventListIndexPath {
  sectionIndex: number
  itemIndex: number
}

export interface EventListViewableItem {
  data?: any
  item: EventsItemType
  index: number
  section: { index: number }
}

export interface ViewableItem {
  item?: EventsItemType
  indexPath?: EventListIndexPath
}

export const LEGACY_MEASUREMENT_TYPE_DEF_MAP: Record<MeasurementType, HealthDataMeasurementType> = {
  [MeasurementType.BloodGlucose]: HealthDataMeasurementType.BloodGlucose,
  [MeasurementType.BloodKetones]: HealthDataMeasurementType.BloodKetones,
  [MeasurementType.BreathKetones]: HealthDataMeasurementType.BreathKetones,
  [MeasurementType.BloodPressure]: HealthDataMeasurementType.BloodPressure,
  [MeasurementType.BodyFatPercentage]: HealthDataMeasurementType.BodyFatPercentage,
  [MeasurementType.BodyTemperature]: HealthDataMeasurementType.BodyTemperature,
  [MeasurementType.CyclingDistance]: HealthDataMeasurementType.CyclingDistance,
  [MeasurementType.RunningDistance]: HealthDataMeasurementType.RunningDistance,
  [MeasurementType.FlightsClimbed]: HealthDataMeasurementType.FlightsClimbed,
  [MeasurementType.StepCount]: HealthDataMeasurementType.StepCount,
  [MeasurementType.ExerciseTime]: HealthDataMeasurementType.ExerciseTime,
  [MeasurementType.Weight]: HealthDataMeasurementType.Weight,
  [MeasurementType.Stress]: HealthDataMeasurementType.Stress,
}

export const ICON_NAME_BY_ACTIVITY_TYPE: { [key in ActivityType]: IconName } = {
  [ActivityType.Exercise]: 'barbell',
  [ActivityType.Fasting]: 'fork-knife',
  [ActivityType.Meditation]: 'yin-yang',
  [ActivityType.Sleep]: 'moon',
}

export const ICON_NAME_BY_MEAL_TYPE: { [key in MealKind]: IconName } = {
  [MealKind.Breakfast]: 'fork-knife',
  [MealKind.Dessert]: 'cake',
  [MealKind.Dinner]: 'fork-knife',
  [MealKind.Drink]: 'pint-glass',
  [MealKind.Lunch]: 'fork-knife',
  [MealKind.Snack]: 'apple-logo',
  [MealKind.Supplement]: 'jar-label',
}

export interface DailyActionCompletion {
  dailyActionId: number
  completed: boolean
}
