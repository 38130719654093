import { useSelector } from 'react-redux'
import { uxSensorModeKindSelector } from '@src/selectors/app'
import { DEXCOM_SENSORS } from '../types'

export const useIsThirdPartyByosSensorMode = () => {
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  if (!uxSensorModeKind) {
    return false
  }

  // TODO: change this to use gql type
  return DEXCOM_SENSORS.includes(uxSensorModeKind)
}
