const { PI, cos, sin } = Math

export const useArcIndicator = (
  width: number,
  height: number,
  layoutWidth: number,
  strokeWidth: number,
  arcCoefficient: number,
) => {
  // Angle will be a bit more than 180°. Change coefficient to make indicator looks more or less like a circle
  const arcIndicatorAngleSize = arcCoefficient * PI

  const radius = (width - strokeWidth) / 2
  const arcLength = radius * arcIndicatorAngleSize

  const distanceFromCircleCenterToArcEnds =
    cos(PI - arcIndicatorAngleSize / 2) * radius + strokeWidth / 2

  const centerX = layoutWidth / 2
  const centerY = height - distanceFromCircleCenterToArcEnds

  const startAngle = PI / 2 + arcIndicatorAngleSize / 2
  const endAngle = startAngle - arcIndicatorAngleSize

  const startX = centerX - radius * cos(startAngle)
  const startY = centerY - radius * sin(startAngle)
  const endX = centerX - radius * cos(endAngle)
  const endY = centerY - radius * sin(endAngle)

  const arcPath = `M ${startX} ${startY} A ${radius} ${radius} 0 1 0 ${endX} ${endY}`

  return {
    arcLength,
    arcPath,
    startX,
    startY,
    endX,
    endY,
    centerX,
    centerY,
    startAngle,
    endAngle,
    radius,
    arcIndicatorAngleSize,
  }
}
