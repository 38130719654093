import React from 'react'
import { ScanMigrationBanner } from './ScanMigrationBanner'
import { useShowScanMigrationBanner, useShowUpgradeToDexcomSensorBanner } from './hooks'
import { UpgradeToDexcomSensorBanner } from './UpgradeToDexcomSensorBanner'

export const ScanMigrationWarningBanner = () => {
  const showUpgradeToDexcomSensorBanner = useShowUpgradeToDexcomSensorBanner()
  const showScanMigrationBanner = useShowScanMigrationBanner()

  if (showUpgradeToDexcomSensorBanner) {
    return <UpgradeToDexcomSensorBanner />
  }

  if (showScanMigrationBanner) {
    return <ScanMigrationBanner />
  }

  return null
}
