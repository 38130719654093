import React from 'react'
import { ViewStyle } from 'react-native'
import { ButtonSize, IconButton, IconProps } from '@components/base'

interface CalendarIconProps {
  onPress: () => void
  iconProps?: Omit<IconProps, 'name'>
  style?: ViewStyle
  size?: ButtonSize
}

export const CalendarIcon = (props: CalendarIconProps) => {
  const { onPress, iconProps, style, size = 'm' } = props

  return (
    <IconButton
      accessibilityLabel="Select Date Range"
      iconName="calendar-blank"
      size={size}
      type="outline"
      onPress={onPress}
      iconProps={iconProps}
      style={style}
    />
  )
}
