import {
  AnswersGroup,
  Response,
  SurveysConfigObjectQuestionsGroupQuestionResponseKind as QuestionResponseKind,
} from '@src/types'

export enum InputType {
  NUMBER = 'number',
  STRING = 'string',
}

export interface TextInputConfig extends Response {
  kind: QuestionResponseKind.TextInput | QuestionResponseKind.TextArea
  kindMetadata?: {
    label?: string
    placeholder?: string
    type?: InputType
  }
}

export interface TextAreaInputConfig extends Response {
  kind: QuestionResponseKind.TextArea
  kindMetadata?: {
    label?: string
    placeholder?: string
    type?: InputType
  }
}

export interface TextAreaWithSkipInputConfig extends Response {
  kind: QuestionResponseKind.TextAreaWithSkip
  kindMetadata?: {
    placeholder: string
    skip_label: string
    skip_value: string
    type?: InputType
  }
}

export interface SliderInputConfig extends Response {
  kind: QuestionResponseKind.Slider
  kindMetadata: {
    min: number
    max: number
    min_label: string
    max_label: string
  }
}

export interface SliderInputWithSkipConfig extends Response {
  kind: QuestionResponseKind.SliderWithSkip
  kindMetadata: {
    min: number
    max: number
    min_label: string
    max_label: string
    skip_label: string
    skip_value: string
  }
}
export interface RadioInputConfig extends Response {
  kind: QuestionResponseKind.Radio
  answersGroups: AnswersGroup[]
}

export interface CheckboxMultiInputConfig extends Response {
  kind: QuestionResponseKind.CheckboxMulti
  answersGroups: AnswersGroup[]
}

export interface TabsTextInputConfig extends Response {
  kind: QuestionResponseKind.TabsTextInput
  kindMetadata: {
    placeholder: string
    type?: InputType
  }
  answersGroups: AnswersGroup[]
}

export enum FileUploadEndpoints {
  USER_FILES = 'user_files',
}

export interface FileUploadInputConfig extends Response {
  kind: QuestionResponseKind.FileUpload
  kindMetadata: {
    endpoints: FileUploadEndpoints
  }
}

export interface DateInputConfig extends Response {
  kind: QuestionResponseKind.DateInput
  kindMetadata?: {
    label?: string
    disallow_future_dates?: boolean
  }
}

export interface ConsentInputConfig extends Response {
  kind: QuestionResponseKind.Consent
  kindMetadata?: {
    label: string
    consent_label?: string
    opt_out_label?: string
    consent_body?: string
  }
}
