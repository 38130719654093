import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InsurancePolicy, UserInsurancePolicyStateKind } from '@src/types'
import { insuranceCardSubmissionSelector } from '../models/nutritionistHub.selectors'

const REQUEST_INSURANCE_CARD_REASON = 'Issue Checking Coverage - Request Insurance Card'

export const useInsuranceCardSubmissionData = (insurancePolicy: InsurancePolicy | null) => {
  const insuranceCardSubmission = useSelector(insuranceCardSubmissionSelector)
  const dispatch = useDispatch()
  const isInsurancePolicyOnHold =
    insurancePolicy && insurancePolicy.lastState?.kind === UserInsurancePolicyStateKind.Hold

  const isInsuranceCardSubmissionNeeded =
    insurancePolicy?.lastState?.reason === REQUEST_INSURANCE_CARD_REASON

  useEffect(() => {
    if (
      insurancePolicy &&
      insurancePolicy.lastState &&
      isInsurancePolicyOnHold &&
      isInsuranceCardSubmissionNeeded
    ) {
      dispatch({
        type: 'nutritionistHub/fetchInsuranceCardSubmission',
        payload: {
          insurancePolicyStateId: insurancePolicy.lastState.id,
        },
      })
    }
  }, [insurancePolicy, dispatch, isInsurancePolicyOnHold, isInsuranceCardSubmissionNeeded])

  const insurancePolicyStateId = insurancePolicy?.lastState?.id

  if (!isInsurancePolicyOnHold || !isInsuranceCardSubmissionNeeded) {
    return { insurancePolicyStateId, isInsuranceCardSubmissionPending: false }
  }

  if (!insuranceCardSubmission) {
    return { insurancePolicyStateId, isInsuranceCardSubmissionPending: true }
  }

  return {
    insurancePolicyStateId,
    isInsuranceCardSubmissionPending:
      insuranceCardSubmission.insurancePolicyStateId !== insurancePolicy?.lastState?.id,
  }
}
