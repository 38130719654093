import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  beginActivation,
  beginScanning,
  SensorStatus,
  nfcLogger,
  bluetoothLogger,
  GlucoseData,
  SensorTypes,
  transportFunctionType,
} from 'react-native-freestyle-libre'

import { isArray, isString, snakeCase } from 'lodash'
import { TagEvent } from 'react-native-nfc-manager'
import {
  BetterStack,
  buildCGMInteractionsDetailsObject,
  CGMInteractionsDetailsObjectTypes,
  globalNFCListener,
} from '@services'
import { Sensor, User } from '@utils'
import { Debug, Device, ErrorMessages, Logger, LoggerScreens, Messages } from '@config'
import { useImpersonationContext } from '@src/config/impersonation'
import { canScanExpiredSelector } from '@src/selectors/settings'
import { useSnack } from '@src/utils/navigatorContext'
import { sensorSelector } from '@src/selectors/sensor'
import Storage from '@src/utils/storage'
import { Feature, useFeatureFlag } from '@src/components'
import { isInternetReachableSelector } from '@src/selectors/network'
import { useNfcState } from '@src/hooks/useNfcState'
import { useBluetoothScanning } from '@src/hooks/useBluetoothScanning'
import { libreLinkUpPatientIdSelector, userSelector } from '@src/selectors/app'
import { ScanningContext } from '@src/context/scanningContext'
import { getCurrentRouteName, navigate } from '@src/config/navigation'
import { useIsActiveResearchDexcom, useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { MobileAppFeature } from '@src/types'
import { BetterStackCgmLogKind } from '@src/config/logger'
import { useShowScanMigrationOnScan } from '@src/screens/Scans/hooks/useShowScanMigrationOnScan'

/**
 * Adding the mobile app logger to react-native-freestyle-libre loggers.
 * It will send the logs to Mixpanel
 * The Logs Levels are controlled below the code by a Feature Flag
 * rawMsg [
 *  string, // the log message, sent to Mixpanel as the 'event'
 *  object, // key-value pairs, ex. { 'serialNumber': '1234567890', error: 'NFCError' }
 *   to add context to the event that are sent to Mixpanel as 'properties'
 * ]
 */
const buildCustomTransport = (screen: LoggerScreens): transportFunctionType => (props) => {
  if (!isArray(props.rawMsg) || !isString(props.rawMsg[0])) {
    Logger.sendError(screen, 'unknown error', {
      payload: JSON.stringify(props.rawMsg),
    })
    return
  }

  const message = props.rawMsg[0]
  const context = props.rawMsg?.[1]

  if (props.level.severity >= 3) {
    Logger.sendError(screen, message, context)
  } else {
    Logger.sendInfo(screen, message, context)
  }
}

/**
 * Adding the mobile app logger to react-native-freestyle-libre loggers.
 * It will send the logs to BetterStack.
 * rawMsg [
 *  string, // the log message, sent to BetterStack as the 'event'
 *  object, // key-value pairs, ex. { 'serialNumber': '1234567890', error: 'NFCError' }
 *   to add context to the event that are sent to BetterStack as 'metrics'
 * ]
 */
const buildBetterStackTransport = (
  screen: LoggerScreens,
  userId: string,
  kind: BetterStackCgmLogKind,
): transportFunctionType => (props) => {
  let message = ''
  let context: any = ''

  if (!isArray(props.rawMsg) || !isString(props.rawMsg[0])) {
    message = 'unknown error'
    context = { payload: JSON.stringify(props.rawMsg) }
  } else {
    message = props.rawMsg[0]
    context = props.rawMsg?.[1]
  }

  const event = `${screen} - ${message}`
  BetterStack.saveLog(event, userId, kind, context)
}

enum SnackMessages {
  SensorScanIsCompleted = 'Success! Sensor scan is completed.',
  // eslint-disable-next-line max-len
  SensorScanIsCompletedOffline = 'Success! Sensor scan is completed. Your dashboard will be updated when you are online.',
  ExpiredSensorLastScan = 'Success! Last scan is completed. All remaining data has been saved.',
  ScanSessionIsClosed = 'Scan session has been closed.',
  SensorIsAlreadyActivated = 'Sensor is already activated!',
  ActivationSuccessful = 'Activation Successful!',
  ActivationFailed = 'Activation Failed!',
  ScanFailed = 'Scan Failed!',
}

const SCAN_DISABLED_SENSORS = [
  SensorTypes.LibreUS,
  SensorTypes.Libre1,
  SensorTypes.Libre2,
  SensorTypes.Libre2EU,
  SensorTypes.LibrePro,
]

const NFCListenerContainer = ({ children }: { children: JSX.Element }) => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const isImpersonating = useImpersonationContext()
  const canScanExpired = useSelector(canScanExpiredSelector)
  const currentSensor = useSelector(sensorSelector)
  const isInternetReachable = useSelector(isInternetReachableSelector)
  const libreLinkUpPatientId = useSelector(libreLinkUpPatientIdSelector)

  const allowLastExpiredSensorScan = useFeatureFlag(Feature.AllowExpiredSensorScan)
  const debugNfcSensorScanning = useFeatureFlag(Feature.DebugNfcSensorScanning)
  const debugNfcSensorScanningError = useFeatureFlag(Feature.DebugNfcSensorScanningError)
  const debugBluetoothSensorScanning = useFeatureFlag(Feature.DebugBluetoothSensorScanning)
  const enableLibre2Support = useFeatureFlag(Feature.Libre2Support)
  const enableSaveCgmLogsToSegment = useFeatureFlag(Feature.SaveCgmLogsToSegment)
  const enableSaveCgmLogsToBetterStack = useFeatureFlag(Feature.SaveCgmLogsToBetterStack)
  const showMigrationGuide = useShowScanMigrationOnScan()
  const scanDisabledLibre1And2 = useFeatureFlag(Feature.ScanDisableFullLibre1And2)
  const allowLibre3ScanningWithoutLibreLinkUpPatientId = useFeatureFlag(
    Feature.Libre3ScanningWithoutLibreLinkUpPatientId,
  )
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const currentSensorSerialNumber = currentSensor?.serialNumber
  const currentSensorIsLibre3 = currentSensor?.model === SensorTypes.Libre3

  const user = useSelector(userSelector)
  const isAuth = !!user
  const isActiveResearchDexcom = useIsActiveResearchDexcom()
  const shouldShowPurchaseFlow = useShouldShowPurchaseFlow()
  const allowNFCScanning = !useFeatureFlag(Feature.AbbottPartnership) && !Device.web

  const cgmSegmentTransportAdded = useRef(false)
  const cgmBetterStackTransportAdded = useRef(false)

  const { isSupported: isNfcSupported, isEnabled: isNfcEnabled } = useNfcState()

  const enableScanning =
    isAuth &&
    allowNFCScanning &&
    isCGMFeatureAvailable &&
    !shouldShowPurchaseFlow &&
    !isActiveResearchDexcom &&
    !isImpersonating

  const createErrorLog = useCallback(
    (message: ErrorMessages, payload: Record<string, unknown>) => {
      if (debugNfcSensorScanningError) {
        Logger.sendError(LoggerScreens.NFCListenerContainer, message, payload)
      }
    },
    [debugNfcSensorScanningError],
  )

  const createInfoLog = useCallback(
    (message: Messages, payload: Record<string, unknown>) => {
      if (debugNfcSensorScanning) {
        Logger.sendInfo(LoggerScreens.NFCListenerContainer, message, payload)
      }
    },
    [debugNfcSensorScanning],
  )

  const submitScan = useCallback(
    ({
      payload,
      type,
      retry,
      onSuccess,
    }: {
      payload: any
      type: 'scans/submit' | 'scans/submitBluetooth'
      retry: boolean
      onSuccess?: () => void
    }) => {
      createInfoLog(Messages.SaveScanAttempt, payload)

      dispatch({
        type,
        payload,
        meta: {
          retry,
        },
        success: () => {
          createInfoLog(Messages.SaveScan, payload)
          onSuccess?.()
        },
        failure: () => {
          showSnack(
            retry ? SnackMessages.SensorScanIsCompletedOffline : ErrorMessages.ScanNotSaved,
            null,
            'error',
          )
          createErrorLog(ErrorMessages.ScanNotSaved, payload)
        },
      })
    },
    [dispatch, showSnack, createErrorLog, createInfoLog],
  )

  const submitBluetoothScan = useCallback(
    ({
      serialNumber,
      glucoseData,
      onSuccess,
    }: {
      serialNumber: string
      glucoseData: GlucoseData[]
      onSuccess?: () => void
    }) => {
      const payload = {
        data: {
          serialNumber,
          history: glucoseData.map((glucose) => ({
            glucoseRaw: glucose.value,
            minutesSinceStart: glucose.lifeCount,
          })),
        },
      }

      submitScan({ payload, type: 'scans/submitBluetooth', retry: false, onSuccess })
    },
    [submitScan],
  )

  const {
    onLibre3Scan,
    connectionState: bluetoothConnectionState,
    realTimeGlucoseValue,
    realTimeGlucoseTime,
    patchInfo,
    isBluetoothEnabled,
    isBluetoothPermitted,
    connectToSensor,
  } = useBluetoothScanning({
    currentSensorIsLibre3,
    currentSensorSerialNumber: enableScanning ? currentSensorSerialNumber : undefined,
    submitBluetoothScan,
  })

  useEffect(() => {
    if (patchInfo && currentSensorSerialNumber) {
      const { patchState } = patchInfo
      dispatch({
        type: 'sensor/updateStatus',
        payload: {
          serialNumber: currentSensorSerialNumber,
          status: snakeCase(patchState),
        },
      })
    }
  }, [currentSensorSerialNumber, dispatch, patchInfo])

  useEffect(() => {
    if (debugNfcSensorScanning) {
      nfcLogger.setSeverity('debug')
      globalNFCListener.enableLogging()
    } else {
      nfcLogger.setSeverity('error')
      globalNFCListener.disableLogging()
    }
  }, [debugNfcSensorScanning])

  useEffect(() => {
    if (debugBluetoothSensorScanning) {
      bluetoothLogger.setSeverity('debug')
    } else {
      bluetoothLogger.setSeverity('error')
    }
  }, [debugBluetoothSensorScanning])

  useEffect(() => {
    if (!Debug.shouldEnableBetterStack) {
      return
    }

    if (cgmSegmentTransportAdded.current) {
      return
    }

    if (enableSaveCgmLogsToSegment && user) {
      cgmSegmentTransportAdded.current = true
      nfcLogger.add(buildCustomTransport(LoggerScreens.NFCListenerContainer))
      bluetoothLogger.add(buildCustomTransport(LoggerScreens.Bluetooth))
    }
  }, [enableSaveCgmLogsToSegment, user])

  useEffect(() => {
    if (!Debug.shouldEnableBetterStack) {
      return
    }

    if (cgmBetterStackTransportAdded.current) {
      return
    }

    if (enableSaveCgmLogsToBetterStack && user) {
      cgmBetterStackTransportAdded.current = true
      nfcLogger.add(
        buildBetterStackTransport(
          LoggerScreens.NFCListenerContainer,
          user.id,
          BetterStackCgmLogKind.CgmNfcLogs,
        ),
      )
      bluetoothLogger.add(
        buildBetterStackTransport(
          LoggerScreens.Bluetooth,
          user.id,
          BetterStackCgmLogKind.CgmBluetoothLogs,
        ),
      )
    }
  }, [enableSaveCgmLogsToBetterStack, user])

  useEffect(() => {
    if (!enableScanning) {
      return
    }

    if (!isNfcSupported) {
      globalNFCListener.disableContinueScanning()
      return
    }

    const onSaveScan = (
      sensor: Exclude<Awaited<ReturnType<typeof beginScanning>>['sensor'], null>,
    ) => {
      console.log('NFCListenerContainer:onSaveScan')
      const { data, status, model, serialNumber, isReady, isValid } = sensor
      const { history, trend, startTime, timeSinceStart, maxLifeInMinutes, calibrationInfo } = data

      const payload = {
        time: moment().unix(),
        data: {
          status,
          model,
          serialNumber,
          isReady,
          isValid,
          history,
          trend,
          startTime,
          maxLifeInMinutes,
          timeSinceStart,
          calibrationInfo,
        },
      }

      submitScan({ payload, type: 'scans/submit', retry: true })
    }

    const onSaveActivation = (
      sensor: Exclude<Awaited<ReturnType<typeof beginActivation>>['sensor'], null>,
    ) => {
      console.log('NFCListenerContainer:onSaveActivation')
      const {
        model,
        serialNumber,
        status,
        data: { calibrationInfo, startTime, maxLifeInMinutes },
      } = sensor

      const payload = {
        time: moment().unix(),
        data: {
          startTime,
          model,
          serialNumber,
          status,
          calibrationInfo,
          maxLifeInMinutes,
        },
      }

      createInfoLog(Messages.SaveActivationAttempt, payload)

      dispatch({
        type: 'scans/activate',
        payload,
        success: () => {
          createInfoLog(Messages.SaveActivation, payload)
        },
        failure: () => {
          showSnack(ErrorMessages.ActivationNotSaved, null, 'error')
          createErrorLog(ErrorMessages.ActivationNotSaved, payload)
        },
      })
    }

    const onActivate = (
      data: Pick<Awaited<ReturnType<typeof beginActivation>>, 'success' | 'sensor'> & {
        error?: string | null
        tag?: TagEvent | null
      },
    ) => {
      console.log('NFCListenerContainer:onActivate')

      const { success, sensor } = data

      if (!success || !sensor) {
        // we're navigating to error screen in ScanModal
        return
      }

      console.log('NFCListenerContainer:onActivate:sensor: ', sensor)
      if (sensor.model === SensorTypes.Libre3 && 'bleAddress' in sensor && 'blePin' in sensor) {
        onLibre3Scan({
          serialNumber: sensor.serialNumber,
          bleAddress: sensor.bleAddress as string,
          blePin: sensor.blePin as string,
        })
      }

      if (sensor.serialNumber === currentSensorSerialNumber) {
        showSnack(SnackMessages.SensorIsAlreadyActivated, null, 'warning')
        return
      }
      showSnack(SnackMessages.ActivationSuccessful)
      createInfoLog(
        Messages.FinishedActivation,
        buildCGMInteractionsDetailsObject(data, CGMInteractionsDetailsObjectTypes.Activation),
      )
      onSaveActivation(sensor)
    }

    const onScan = (data: Awaited<ReturnType<typeof beginScanning>>) => {
      console.log('NFCListenerContainer:onScan')

      const { success, sensor } = data

      if (!success || !sensor) {
        showSnack(SnackMessages.ScanFailed, null, 'error')
        return
      }

      if (scanDisabledLibre1And2 && SCAN_DISABLED_SENSORS.includes(sensor.model)) {
        return navigate('ScanMigrationGuide', { nextScreen: { screen: 'Drawer' } })
      }

      // navigate to home page if we scan successfully in the error modal (Android only)
      if (getCurrentRouteName() === 'ErrorScanningModal') {
        navigate('Dashboard', { screen: 'Events' })
      }

      console.log('NFCListenerContainer:onScan: Success!')
      console.log('NFCListenerContainer:onScan:history: ', sensor.data.history)
      console.log({ canScanExpired })
      createInfoLog(
        Messages.FinishedScanning,
        buildCGMInteractionsDetailsObject(data, CGMInteractionsDetailsObjectTypes.Scanning),
      )

      if (sensor.status === SensorStatus.Starting && currentSensor?.status !== sensor.status) {
        // if sensor was activated on scan
        onActivate(data)
        return
      } else if (
        sensor.model === SensorTypes.Libre3 &&
        'bleAddress' in sensor &&
        'blePin' in sensor
      ) {
        onLibre3Scan({
          serialNumber: sensor.serialNumber,
          bleAddress: sensor.bleAddress as string,
          blePin: sensor.blePin as string,
        })
      }

      if (sensor.isReady || (Debug.allowExpiredSensor && canScanExpired)) {
        // sensor is ready (or scanning expired sensor for testing purposes)
        const title = isInternetReachable
          ? SnackMessages.SensorScanIsCompleted
          : SnackMessages.SensorScanIsCompletedOffline
        showSnack(title)
        onSaveScan(sensor)
        if (showMigrationGuide && SCAN_DISABLED_SENSORS.includes(sensor.model)) {
          Storage.set(
            `${Storage.SCAN_DISABLED_NOTICE_SHOWN_AT_KEY}_${user?.id}`,
            moment().toISOString(),
          )
          return navigate('ScanMigrationGuide', { nextScreen: { screen: 'Drawer' } })
        }
        return
      }

      if (
        allowLastExpiredSensorScan &&
        (sensor.status === SensorStatus.Expired || sensor.status === SensorStatus.Shutdown)
      ) {
        // if sensor is expired
        const expiredSensorKey = `${Storage.SCANNED_EXPIRED_SENSOR_KEY}_${sensor.serialNumber}`
        const scannedExpiredSensor = Storage.get(expiredSensorKey, false)

        if (!scannedExpiredSensor) {
          // if it's the first scan of expired sensor
          showSnack(SnackMessages.ExpiredSensorLastScan)
          onSaveScan(sensor)
          Storage.set(expiredSensorKey, true)
          return
        }
      }

      // display reason why we cannot scan
      showSnack(
        Sensor.statusDescription({
          isNfcEnabled,
          isNfcSupported,
          status: sensor.status as SensorStatus,
          action: 'scan',
        }),
        null,
        'error',
      )

      createErrorLog(ErrorMessages.ScanFailed, {
        status: sensor.status,
      })
    }

    const onError = (error: Error | string | null) => {
      console.warn('Error on global NFC listener Start or Scan', error)
      if ((error as any)?.code === 'ERROR_SESSION_ERROR') {
        showSnack(SnackMessages.ScanSessionIsClosed, null, 'warning')
      }
    }

    globalNFCListener.addListener('onScan', onScan)
    globalNFCListener.addListener('onActivate', onActivate)
    globalNFCListener.addListener('onError', onError)

    // start listening on Android
    if (Device.hasNFCEvents) {
      globalNFCListener.enableContinueScanning()

      globalNFCListener.scanningOptions.enableLibre2Support = enableLibre2Support
      globalNFCListener.scanningOptions.enableLibre3Support = true
      globalNFCListener.scanningOptions.libreLinkUpPatientId = libreLinkUpPatientId || undefined
      // eslint-disable-next-line max-len
      globalNFCListener.scanningOptions.allowLibre3ScanningWithoutLibreLinkUpPatientId = allowLibre3ScanningWithoutLibreLinkUpPatientId
      globalNFCListener.scan()
      console.log('NFC global listener started')
    }

    return () => {
      console.log('NFC global listener stopped')
      globalNFCListener.removeListener('onScan', onScan)
      globalNFCListener.removeListener('onActivate', onActivate)
      globalNFCListener.removeListener('onError', onError)
      globalNFCListener.stop()
    }
  }, [
    allowLastExpiredSensorScan,
    allowLibre3ScanningWithoutLibreLinkUpPatientId,
    canScanExpired,
    createErrorLog,
    createInfoLog,
    currentSensor?.status,
    currentSensorSerialNumber,
    dispatch,
    enableLibre2Support,
    enableScanning,
    isInternetReachable,
    isNfcEnabled,
    isNfcSupported,
    libreLinkUpPatientId,
    onLibre3Scan,
    scanDisabledLibre1And2,
    showMigrationGuide,
    showSnack,
    submitScan,
    user?.id,
  ])

  return (
    <ScanningContext.Provider
      value={{
        bluetoothConnectionState,
        realTimeGlucoseValue,
        realTimeGlucoseTime,
        patchInfo,
        isBluetoothEnabled,
        isBluetoothPermitted,
        isNfcEnabled,
        isNfcSupported,
        connectToSensor,
      }}
    >
      {children}
    </ScanningContext.Provider>
  )
}

export default NFCListenerContainer
