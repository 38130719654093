import React from 'react'
import Tooltip, { TooltipProps } from 'react-native-walkthrough-tooltip'
import { TouchableOpacity, ViewStyle } from 'react-native'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useTheme } from '@ui-kitten/components'
import { merge } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'

export interface WithWalkthroughProps extends TooltipProps {
  isVisible: boolean
  children: React.ReactNode
  accessibilityLabel: string
  onPress?: () => void
  onLongPress?: () => void
  onClose: () => void
  childContainerStyles?: ViewStyle
}

export const WithWalkthrough = ({
  isVisible,
  children,
  onPress,
  onLongPress,
  accessibilityLabel,
  tooltipStyle,
  placement = 'right',
  content,
  onClose,
  disableShadow,
  childContainerStyles = {},
  ...rest
}: WithWalkthroughProps) => {
  const theme = useTheme()
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)

  // needed to adjust the tooltip position on iOS due to an issue with React Native's measure function on Android
  // https://github.com/jasongaare/react-native-walkthrough-tooltip?tab=readme-ov-file#props
  const topAdjustment = Device.ios ? 0 : insets.top * -1
  // needed to adjust the tooltip shadow on Android because shadowRadius, shadowOpacity are not supported on Android
  // https://reactnative.dev/docs/shadow-props
  const elevationStyles = Device.ios || disableShadow ? {} : { elevation: 4 }

  const displayInsets = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }

  const mergedTooltipStyle = disableShadow
    ? tooltipStyle
    : merge(tooltipStyle, {
        shadowRadius: 4,
        shadowOpacity: 0.4,
        elevation: 6,
      })

  return (
    <Tooltip
      isVisible={isVisible}
      topAdjustment={topAdjustment}
      placement={placement}
      content={content}
      tooltipStyle={mergedTooltipStyle}
      displayInsets={displayInsets}
      contentStyle={{
        backgroundColor: theme['theme.tooltip.surface'],
        ...elevationStyles,
      }}
      useInteractionManager
      onClose={onClose}
      disableShadow={disableShadow}
      {...rest}
    >
      <View style={[styles.childContainer, childContainerStyles]}>
        <TouchableOpacity
          onPress={onPress}
          onLongPress={onLongPress}
          accessibilityLabel={accessibilityLabel}
          activeOpacity={1}
        >
          {children}
        </TouchableOpacity>
      </View>
    </Tooltip>
  )
}

const themedStyle = StyleService.create({
  childContainer: {
    width: '100%',
  },
})
