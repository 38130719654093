import React from 'react'
import { View } from 'react-native'
import { DailyAction } from '@src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text, UI_ICONS_MAPPING } from '@src/components/base'
import { DailyActionCardInteraction } from './DailyActionCardInteraction'

export interface DailyActionCardProps {
  dailyAction: DailyAction & { completed: boolean }
}

export const DailyActionCard = ({ dailyAction }: DailyActionCardProps) => {
  const styles = useStyleSheet(themedStyle)

  const iconName = UI_ICONS_MAPPING[dailyAction.icon]

  return (
    <View style={styles.container}>
      {iconName && (
        <View style={styles.icon}>
          <View style={styles.iconCircleBackground} />
          <Icon name={iconName} style={styles[dailyAction.category]} />
        </View>
      )}
      <View style={styles.body}>
        <Text type="regular" bold style={styles.title} lineSpacing="tight">
          {dailyAction.title}
        </Text>
        <Text type="small" style={styles.subTitle} lineSpacing="tight">
          {dailyAction.description}
        </Text>
      </View>
      <DailyActionCardInteraction dailyAction={dailyAction} />
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.background.modal',
    padding: 12,
    marginBottom: 12,
    alignItems: 'center',
  },
  body: {
    flex: 5,
    marginRight: 20,
  },
  title: {
    marginBottom: 4,
  },
  subTitle: {
    color: 'theme.text.secondary',
  },
  iconCircleBackground: {
    borderRadius: 100,
    backgroundColor: 'theme.surface.base3',
    width: 40,
    height: 40,
    position: 'absolute',
  },
  icon: { flex: 1, marginRight: 12, justifyContent: 'center', alignItems: 'center' },
  nutrition: {
    color: 'theme.success.base',
  },
  activity: {
    color: 'theme.info.base',
  },
  experiment: {
    color: 'theme.warning.base',
  },
  journal: {
    color: 'theme.accent.brick',
  },
  trends: {
    color: 'theme.primary.base',
  },
})
