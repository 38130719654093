import React, { useEffect, useMemo } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { policyConsentStatusesSelector } from '@src/selectors/policyConsents'
import { useGoBack } from '@src/utils'
import { AppStackParamList } from '@src/navigation/types'

const formatList = (items: string[]) => {
  if (items.length === 2) {
    return items[0] + ' and ' + items[1]
  } else if (items.length > 2) {
    const first = items.slice(0, -1)
    const last = items.slice(-1)

    return first.join(', ') + ', and ' + last
  }
  return items[0]
}

export const RequiredPolicyConsent = () => {
  const goBack = useGoBack()
  const styles = useStyleSheet(themedStyle)
  const statuses = useSelector(policyConsentStatusesSelector)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const requiredPolicies = useMemo(
    () => statuses.flatMap((status) => (status.required ? status.policy : [])),
    [statuses],
  )

  const requiredPolicyTitles = formatList(requiredPolicies.map((policy) => policy.title))

  useEffect(() => {
    if (!requiredPolicies.length) {
      // Nothing to review
      goBack()
    }
  }, [requiredPolicies, goBack])

  const onReview = () => {
    navigation.replace('PolicyConsent', { policy: requiredPolicies[0] })
  }

  return (
    <View style={styles.container}>
      <SafeAreaView edges={['bottom']} style={styles.contentContainer}>
        <View>
          <Text type="title-1">We've updated our Terms and Conditions.</Text>
          <Text type="regular" style={styles.description}>
            Our terms of service have changed. Please review and agree to the {requiredPolicyTitles}{' '}
            to continue using the Nutrisense app.
          </Text>
        </View>
        <Button type="primary" size="block" accessibilityLabel="Review" onPress={onReview}>
          Review
        </Button>
      </SafeAreaView>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    marginTop: 64,
    borderRadius: 24,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 48,
    paddingBottom: 16,
    justifyContent: 'space-between',
  },
  description: {
    marginVertical: 16,
  },
})
