/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const PlusCircle = (props: any) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    {...props}
  >
    <Path
      d="M16 28.5C22.6274 28.5 28 23.1274 28 16.5C28 9.87258 22.6274 4.5 16 4.5C9.37258 4.5 4 9.87258 4 16.5C4 23.1274 9.37258 28.5 16 28.5Z"
      fill="#7DDE86"
    />
    <Path
      d="M11 16.5H21"
      stroke="#198155"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M16 11.5V21.5"
      stroke="#198155"
      strokeWidth="2.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
)

export default PlusCircle
