import { gql } from '@apollo/client'

export const GENERATE_TERRA_AUTH_URL = gql`
  query generateTerraAuthUrl($provider: String!, $authRedirectUrl: String) {
    generateTerraAuthUrl(provider: $provider, authRedirectUrl: $authRedirectUrl) {
      url
      userId
    }
  }
`
