import React, { ReactElement } from 'react'
import { ImageProps, View } from 'react-native'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface TrialBenefitProps {
  title: string
  description: string
  image: ReactElement<ImageProps>
}

export const TrialBenefit = ({ title, description, image }: TrialBenefitProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.benefit}>
      <View style={styles.imageContainer}>{image}</View>
      <View style={styles.benefitTextContainer}>
        <Text type="regular" bold>
          {title}
        </Text>
        <Text type="regular">{description}</Text>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  benefit: {
    flexDirection: 'row',
    padding: 8,
    marginTop: 8,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    borderRadius: 16,
  },
  benefitTextContainer: {
    textAlign: 'left',
    flexShrink: 1,
  },
  imageContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  benefitImage: {
    width: 74,
    height: 58,
  },
})
