import React, { useState } from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { useNavigation, useRoute } from '@react-navigation/core'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT } from '@src/config/momentFormat'
import { useDispatchAsync } from '@src/utils'
import { useSnack } from '@src/utils/navigatorContext'
import { AppRouteProp } from '@navigation/types'
import { SurveyLink, SurveyLinkCollection, SurveysConfigKind } from '@src/types.ts'
import { isOnCommitment } from '../utils/utils'
import { useActiveSubscriptionAddons, useSubscriptionFromRoute } from '../utils/hooks'
import { SubscriptionField } from './ProgramDetails/SubscriptionField'
import { SubscriptionActionScreen } from './SubscriptionActionScreen'
import { Actions } from './ProgramDetails/Actions'

const getNextScreen = ({
  insuranceSurveyLink,
  showInsuranceCallsUpsell,
}: {
  insuranceSurveyLink: SurveyLink | undefined
  showInsuranceCallsUpsell?: string
}) => {
  if (showInsuranceCallsUpsell === 'true' && !insuranceSurveyLink?.finished) {
    return 'InsuranceCallsUpsellScreen'
  }

  return 'Subscriptions'
}

export const CancelSubscriptionConfirmation = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatchAsync = useDispatchAsync()
  const showSnack = useSnack()
  const { subscription } = useSubscriptionFromRoute()
  const activePlanAddons = useActiveSubscriptionAddons(subscription)
  const { params } = useRoute<AppRouteProp<'CancelSubscriptionConfirmation'>>()
  const { showInsuranceCallsUpsell } = params

  if (!subscription) {
    return null
  }

  let endsAt = moment(subscription.currentPeriodEndAt)

  if (subscription.commitmentEndAt) {
    const commitmentEndsAt = moment(subscription.commitmentEndAt)
    const commitmentDaysLeft = commitmentEndsAt.diff(moment(), 'days')

    if (commitmentDaysLeft > 0) {
      endsAt = commitmentEndsAt
    }
  }

  const onConfirm = async () => {
    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)

    try {
      await dispatchAsync({
        type: 'marketplace/cancelSubscription',
        payload: { id: subscription.id },
      })
    } catch (error: any) {
      if (error?.message.includes('paused')) {
        showSnack(
          'You cannot cancel a paused subscription via the app. Please contact customer support',
          null,
          'error',
        )
      } else {
        showSnack('Failed to cancel subscription', null, 'error')
      }
      setIsSubmitting(false)
      return
    }

    const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
      type: 'app/surveyQuestionnaire',
    })
    const insuranceSurveyLink = surveyLinks.find(
      (link) => link.survey.kind === SurveysConfigKind.Insurance,
    )

    navigation.reset({
      index: 1,
      routes: [
        { name: 'Drawer', state: { index: 0, routes: [{ name: 'Dashboard' }] } },
        {
          name: getNextScreen({
            insuranceSurveyLink,
            showInsuranceCallsUpsell,
          }),
        },
      ],
    })
  }

  const commitmentOrPaid = isOnCommitment(subscription.commitmentEndAt) ? 'commitment' : 'paid'
  const description = [
    `Your subscription will be cancelled when the ${commitmentOrPaid} period ends. You will not be billed again.`,
    'We’ll be here waiting when you’re ready to resume your health journey with Nutrisense!',
  ].join('\n\n')
  const activePlanAddonsText = activePlanAddons.map((product) => product.title).join(', ')

  return (
    <SubscriptionActionScreen
      name="Cancel Subscription"
      title="Review and confirm"
      description={description}
    >
      {endsAt && (
        <SubscriptionField
          name="Subscription ends"
          value={endsAt.format(DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT)}
          secondaryValue={`(${pluralize('day', endsAt.diff(moment(), 'days'), true)} left)`}
        />
      )}
      {activePlanAddons.length > 0 && (
        <View style={styles.warning}>
          <Icon name="warning-circle" style={styles.alertIcon} />
          <Text type="small" style={styles.warningText}>
            Please note that your {activePlanAddonsText}{' '}
            {pluralize('subscription', activePlanAddons.length)} will not be cancelled with the
            cancellation of your primary plan. If you do not want to continue with the{' '}
            {activePlanAddonsText}, please cancel that separately from the Programs menu.
          </Text>
        </View>
      )}
      <Actions primaryButtonText="Confirm" onPrimaryButtonPress={onConfirm} />
    </SubscriptionActionScreen>
  )
}

const themedStyles = StyleService.create({
  warning: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 24,
    marginVertical: 16,
  },
  alertIcon: {
    marginRight: 8,
    color: 'theme.warning.base',
  },
  warningText: {
    flex: 1,
  },
})
