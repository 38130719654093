import React, { useEffect, useRef, useState } from 'react'
import { LayoutChangeEvent, View } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { StyleService, useStyleSheet } from '@src/style/service'

const TIMELINE_COLLAPSE_ANIMATION_DURATION = 150

export interface CollapsibleTimelineProps {
  expanded: boolean
  ChartComponent: React.ReactNode
}

export const CollapsibleTimeline = (props: CollapsibleTimelineProps) => {
  const { expanded, ChartComponent } = props
  const styles = useStyleSheet(themedStyles)

  const [fixedHeight, setFixedHeight] = useState(0)
  const animatedHeight = useSharedValue(0)
  const hasMeasured = useRef(false)

  // Measure the fixedHeight once when the component first mounts
  const onLayout = (event: LayoutChangeEvent) => {
    if (fixedHeight === event.nativeEvent.layout.height) {
      return
    }

    const { height: layoutHeight } = event.nativeEvent.layout
    setFixedHeight(layoutHeight)
    hasMeasured.current = true
  }

  useEffect(() => {
    animatedHeight.value = expanded
      ? withTiming(fixedHeight, { duration: TIMELINE_COLLAPSE_ANIMATION_DURATION })
      : withTiming(0, { duration: TIMELINE_COLLAPSE_ANIMATION_DURATION })
  }, [animatedHeight, expanded, fixedHeight])

  const collapsableStyle = useAnimatedStyle(() => {
    return {
      height: animatedHeight.value,
      overflow: 'hidden',
    }
  })

  return (
    <Animated.View style={collapsableStyle}>
      <View onLayout={onLayout} style={styles.content}>
        {ChartComponent}
      </View>
    </Animated.View>
  )
}

const themedStyles = StyleService.create({
  content: {
    position: 'absolute',
    width: '100%',
  },
})
