import React from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { Product } from '@src/types'
import { DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT } from '@src/config/momentFormat'
import { getPriceDescription } from '../../utils/utils'
import { SubscriptionField } from './SubscriptionField'

interface ProductSummaryFieldsProps {
  product: Pick<Product, 'interval'>
  price: number
  currentPeriodEndAt?: string
  commitmentEndAt?: string
  cancelAt?: string
  resumesAt?: string
  resetBillingCycleAnchorAt?: string
  isTrial: boolean
  mode: 'full' | 'compact'
  fields: Fields[]
}

export enum Fields {
  billedAmount,
  commitmentEnds,
  subscriptionEnds,
  nextBillingDate,
  resumesAt,
  discountedRate,
  daysLeft,
}

export const ProductSummaryFields = ({
  product,
  price: amount,
  currentPeriodEndAt,
  commitmentEndAt,
  cancelAt: cancelAtString,
  resumesAt: resumesAtString,
  resetBillingCycleAnchorAt,
  isTrial,
  mode = 'full',
  fields,
}: ProductSummaryFieldsProps) => {
  const nextBillingDate = moment(resetBillingCycleAnchorAt || currentPeriodEndAt)
  const billingDateIsSameAsCancelDate = nextBillingDate.isSame(cancelAtString, 'day')
  const subscriptionFieldSize = mode === 'full' ? 'normal' : 'small'
  const resumesAt = resumesAtString ? moment(resumesAtString) : null

  const billedAmountField = () => {
    return (
      <SubscriptionField name="Billed amount" value={`$${amount}`} size={subscriptionFieldSize} />
    )
  }

  const commitmentEndsField = () => {
    if (!commitmentEndAt) {
      return null
    }

    const commitmentEndsAt = moment(commitmentEndAt)
    const daysLeft = commitmentEndsAt.diff(moment(), 'days')

    if (daysLeft <= 0) {
      return null
    }

    return (
      <SubscriptionField
        name="Commitment ends"
        value={commitmentEndsAt.format(DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT)}
        secondaryValue={`(${pluralize('day', daysLeft, true)} left)`}
        size={subscriptionFieldSize}
      />
    )
  }

  const subscriptionEndsField = () => {
    if (!cancelAtString) {
      return null
    }

    const cancelAt = moment(cancelAtString)

    return (
      <SubscriptionField
        name="Subscription ends"
        value={cancelAt.format(DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT)}
        secondaryValue={`(${pluralize('day', cancelAt.diff(moment(), 'days'), true)} left)`}
        size={subscriptionFieldSize}
      />
    )
  }

  const nextBillingDateField = () => {
    if (billingDateIsSameAsCancelDate || (resumesAt && resumesAt.isAfter(nextBillingDate))) {
      return null
    }

    return (
      <SubscriptionField
        name="Next billing date"
        value={nextBillingDate.format(DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT)}
        size={subscriptionFieldSize}
      />
    )
  }

  const daysLeftField = () => {
    if (!isTrial) {
      return null
    }

    const daysLeft = moment(currentPeriodEndAt).diff(moment(), 'days')

    return (
      <SubscriptionField
        name="Days left"
        value={daysLeft.toString()}
        size={subscriptionFieldSize}
      />
    )
  }

  const resumesAtField = () => {
    if (!resumesAt) {
      return null
    }

    return (
      <SubscriptionField
        name="Subscription resumes"
        value={resumesAt.format(DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT)}
        size={subscriptionFieldSize}
      />
    )
  }

  const discountedRateField = () => {
    return (
      <SubscriptionField
        name="Discounted rate"
        value={getPriceDescription(amount, product.interval)}
        size={subscriptionFieldSize}
      />
    )
  }

  const componentByField = {
    [Fields.billedAmount]: billedAmountField,
    [Fields.commitmentEnds]: commitmentEndsField,
    [Fields.subscriptionEnds]: subscriptionEndsField,
    [Fields.nextBillingDate]: nextBillingDateField,
    [Fields.resumesAt]: resumesAtField,
    [Fields.discountedRate]: discountedRateField,
    [Fields.daysLeft]: daysLeftField,
  }

  return (
    <>
      {fields.map((field) => {
        const fieldComponent = componentByField[field]()

        return fieldComponent && <React.Fragment key={field}>{fieldComponent}</React.Fragment>
      })}
    </>
  )
}
