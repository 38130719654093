import React, { useCallback } from 'react'
import { Image, ScrollView } from 'react-native'
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  CommonRefreshControl,
  Feature,
  NavigationBarAccessory,
  useFeatureFlag,
} from '@src/components'
import { ArcIndicatorsPager, CalendarBar, InsightsList } from '@src/screens/Insights/components'
import { useFetchStats } from '@src/screens/Insights/utils/hooks'
import { NotificationBanner } from '@src/components/notifications/NotificationBanner'
import { MobileAppFeature, NotificationEngineScreen, UiStateNames } from '@src/types'
import { Storage, User } from '@src/utils'
import {
  authenticatedUserSelector,
  goalMetricsSelector,
  primaryGoalSelector,
} from '@src/selectors/app'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { Icon } from '@src/components/base'
import { useOneTimeVisitedState } from '@src/hooks/useOneTimeVisitedState'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { blurredArcIndicatorsImage } from '@src/assets/images'
import { UpsellCGMBanner } from '@src/screens/Events/components/Banners/UpsellCGMBanner'
import { useIsDarkMode } from '@src/config/theme'
import { GoalView } from '../components/GoalView'

export const InsightsScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const isDarkMode = useIsDarkMode()
  const user = useSelector(authenticatedUserSelector)

  const { isLoading, refetch } = useFetchStats()

  const {
    shouldShow: shouldShowGoalsWalkthrough,
    markAsShown: markGoalsWalkthroughAsShown,
  } = useOneTimeVisitedState(Storage.GOALS_WALKTHROUGH_VISITED_KEY)

  const rightAccessories: NavigationBarAccessory[] = [
    {
      renderIconComponent: () => <Icon name="gear" style={styles.icon} />,
      onPress: () => navigation.navigate('InsightsSettings'),
      accessibilityLabel: 'Insights Settings',
    },
  ]

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const primaryGoal = useSelector(primaryGoalSelector)

  const goalMetrics = useSelector(goalMetricsSelector)

  const primaryGoalMetrics = goalMetrics.filter((metric) => metric.primary)

  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  const isFocused = useIsFocused()

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const hasViewedTrends = user.uiStates.some(
    ({ name, value }) => name === UiStateNames.ViewedTrends && value === true,
  )

  useFocusEffect(
    useCallback(() => {
      dispatch({ type: 'users/fetch' })
      if (!hasViewedTrends) {
        dispatch({
          type: 'app/upsertUiState',
          payload: {
            name: UiStateNames.ViewedTrends,
            value: true,
          },
        })
      }
    }, [dispatch, hasViewedTrends]),
  )

  return (
    <AccountAvatarNavigationBar
      title={insightsTitle}
      rightAccessories={rightAccessories}
      hideLeftIcon
    >
      <ScrollView
        style={styles.content}
        contentContainerStyle={[styles.content]}
        showsVerticalScrollIndicator={false}
        refreshControl={<CommonRefreshControl refreshing={isLoading} onRefresh={refetch} />}
      >
        <NotificationBanner screen={NotificationEngineScreen.Insights} />
        <CalendarBar />
        {isCGMFeatureAvailable ? (
          <ArcIndicatorsPager />
        ) : (
          <UpsellCGMBanner
            image={<Image source={blurredArcIndicatorsImage(isDarkMode).imageSource} />}
          />
        )}
        {userGoalsEnabled && primaryGoal && isCGMFeatureAvailable && (
          <WithWalkthrough
            isVisible={shouldShowGoalsWalkthrough && isFocused}
            tooltipStyle={styles.tooltipStyle}
            accessibilityLabel="Goal walkthrough"
            placement="top"
            content={
              <WalkthroughTooltip
                title="Here is Your Goal"
                subtitle="Now you can view your goal, primary metrics to focus on and your progress in one place"
              />
            }
            onClose={markGoalsWalkthroughAsShown}
          >
            <GoalView goal={primaryGoal} metrics={primaryGoalMetrics} />
          </WithWalkthrough>
        )}
        <InsightsList primaryMetrics={userGoalsEnabled ? primaryGoalMetrics : []} />
      </ScrollView>
    </AccountAvatarNavigationBar>
  )
}

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'theme.background',
  },
  icon: {
    marginRight: 16,
  },
  tooltipStyle: {
    maxWidth: 324,
    width: '100%',
  },
})
