import { useSelector } from 'react-redux'
import { skippedSurveysSelector, userSelector } from '@src/selectors/app'
import { SurveysConfigKind, UserApprovalRequestState } from '@src/types'

export enum PrescriptionFlowStep {
  NotApplicable,
  EligibilitySurvey,
  PendingApproval,
  Skipped,
  Rejected,
  Complete,
  Unknown,
}

export const usePrescriptionFlowStep = (): PrescriptionFlowStep => {
  const user = useSelector(userSelector)
  const skippedSurveys = useSelector(skippedSurveysSelector)

  if (skippedSurveys[SurveysConfigKind.Health]) {
    return PrescriptionFlowStep.Skipped
  }

  if (!user?.lastPrescriptionApprovalRequest) {
    return PrescriptionFlowStep.NotApplicable
  }

  switch (user.lastPrescriptionApprovalRequest.state) {
    case UserApprovalRequestState.Stale:
      return PrescriptionFlowStep.NotApplicable
    case UserApprovalRequestState.Init:
    case UserApprovalRequestState.PendingMember:
    case UserApprovalRequestState.PendingUpdate:
      return PrescriptionFlowStep.EligibilitySurvey
    case UserApprovalRequestState.PendingApproval:
    case UserApprovalRequestState.PendingRejection:
      return PrescriptionFlowStep.PendingApproval
    case UserApprovalRequestState.DidNotComplete:
    case UserApprovalRequestState.Rejected:
    case UserApprovalRequestState.ConsentRejected:
      return PrescriptionFlowStep.Rejected
    case UserApprovalRequestState.ConsentWithdrawn:
      // This state should not occur yet and this behavior will need to change in the future.
      // As currently conceptualized, it would occur in the event that the user's refill consent
      // expires (after having previously been approved).
      return PrescriptionFlowStep.Complete
    case UserApprovalRequestState.Approved:
      return PrescriptionFlowStep.Complete
    default:
      return PrescriptionFlowStep.Unknown
  }
}
