import React, { SectionList, View } from 'react-native'
import { useCallback, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Icon } from '@src/components/base'
import { DailyActionKind } from '@src/types'
import {
  DailyActionsSection,
  useEditDailyActionsSections,
} from '../hooks/useEditDailyActionsSections'
import { SectionHeader } from './SectionHeader'
import { SectionItem } from './SectionItem'

export const EditDailyActionsModal = () => {
  const styles = useStyleSheet(themedStyles)
  const sections = useEditDailyActionsSections()
  const insets = useSafeAreaInsets()
  const [expandedSections, setExpandedSections] = useState<Set<number>>(
    new Set(sections.map((section) => section.id)),
  )

  const handleToggleSection = (id: number) => {
    setExpandedSections((expandedSections) => {
      const next = new Set(expandedSections)
      if (next.has(id)) {
        next.delete(id)
      } else {
        next.add(id)
      }
      return next
    })
  }

  const renderSectionItem = useCallback(
    ({ section, item }: { section: DailyActionsSection; item: DailyActionKind }) => {
      if (!expandedSections.has(section.id)) {
        return null
      }
      return <SectionItem dailyAction={item} section={section} key={item.key} />
    },
    [expandedSections],
  )

  const renderSectionHeader = useCallback(
    ({ section: { id, title } }: { section: DailyActionsSection }) => {
      return (
        <SectionHeader
          title={title}
          onPress={() => handleToggleSection(id)}
          expanded={expandedSections.has(id)}
        />
      )
    },
    [expandedSections],
  )

  return (
    <NavigationContainer
      style={styles.modal}
      leftIcon={<Icon name="x" size="20" weight="bold" style={styles.closeButton} />}
      title="Customize Today's Focus"
    >
      <View style={styles.container}>
        <SectionList
          sections={sections}
          extraData={expandedSections}
          renderItem={renderSectionItem}
          renderSectionHeader={renderSectionHeader}
          keyExtractor={(item) => item.key}
          contentContainerStyle={{ paddingBottom: insets.bottom }}
          style={styles.sectionList}
        />
      </View>
    </NavigationContainer>
  )
}
const themedStyles = StyleService.create({
  modal: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  closeButton: {
    marginLeft: 16,
  },
  container: {
    flex: 1,
  },
  sectionList: {
    paddingHorizontal: 16,
  },
})
