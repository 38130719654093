/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export enum Base {
  // Eva Pack
  Gift = 'gift',
  Grid = 'grid',
  AlertCircleOutline = 'alert-circle-outline',
  AlertTriangle = 'alert-triangle',
  DiagonalArrowRightUpOutline = 'diagonal-arrow-right-up-outline',
  DiagonalArrowRightDownOutline = 'diagonal-arrow-right-down-outline',
  Menu = 'menu',
  Plus = 'plus',
  Minus = 'minus',
  PlusCircleOutline = 'plus-circle-outline',
  SyncOutline = 'sync-outline',
  FlashOutline = 'flash-outline',
  FlashOffOutline = 'flash-off-outline',
  Checkmark = 'checkmark',
  CreditCardOutline = 'credit-card-outline',
  EditOutline = 'edit-outline',
  RadioButtonOnOutline = 'radio-button-on-outline',
  Options2 = 'options-2',
  Options2Outline = 'options-2-outline',
  BarChart = 'bar-chart',
  Star = 'star',
  SwapOutline = 'swap-outline',
  PieChart2 = 'pie-chart-2',
  BookOpen = 'book-open',
  Person = 'person',
  Settings = 'settings',
  People = 'people',
  Home = 'home',
  ListOutline = 'list-outline',
  MoreVertical = 'more-vertical',
  Heart = 'heart',
  HeartOutline = 'heart-outline',
  Share = 'share',
  Copy = 'copy',
  Calendar = 'calendar',
  Edit2Outline = 'edit-2-outline',
  PlayCircle = 'play-circle',
  StopCircle = 'stop-circle',
  MessageSquareOutline = 'message-square-outline',
  LogOut = 'log-out',
  Clock = 'clock',
  ClockOutline = 'clock-outline',
  TrendingUp = 'trending-up',
  Lock = 'lock',
  RadioButtonOn = 'radio-button-on',
  Repeat = 'repeat',
  Download = 'download',
  TrashOutline = 'trash-outline',
  Trash = 'trash',
  Trash2 = 'trash-2',
  ArrowIOSBack = 'arrow-ios-back',
  ArrowIOSForward = 'arrow-ios-forward',
  CheckmarkCircle2 = 'checkmark-circle-2',
  CheckmarkCircle2Outline = 'checkmark-circle-2-outline',
  BookOpenOutline = 'book-open-outline',
  Image = 'image',
  Redeem = 'redeem',
  TextSms = 'textsms',
  MoreHoriz = 'more-horiz',
  ArrowIOSDownload = 'arrow-ios-downward',
  EyeOff = 'eye-off',
  Eye = 'eye',
  Info = 'info',
  InfoOutline = 'info-outline',
  CloseOutline = 'close-outline',
  Close = 'close',
  Search = 'search',
  Link = 'link',
  ShoppingCart = 'shopping-cart',
  Email = 'email',
  EmailOutline = 'email-outline',
  Facebook = 'facebook',
  CameraOutline = 'camera-outline',
  TrendingUpOutline = 'trending-up-outline',
  // Material Pack
  Timeline = 'timeline',
  RadioButtonChecked = 'radio-button-checked',
  Create = 'create',
  DirectionsWalk = 'directions-walk',
  Restaurant = 'restaurant',
  Tune = 'tune',
  Receipt = 'receipt',
  History = 'history',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  ChevronDown = 'chevron-down',
  Edit = 'edit',
  Delete = 'delete',
  Fullscreen = 'fullscreen',
  ExpandMore = 'expand-more',
  // MaterialCommunity Pack
  BarcodeScan = 'barcode-scan',
  Instagram = 'instagram',
}
