import { gql } from '@apollo/client'

const FETCH_INVOICE = gql`
  query invoice($id: ID!) {
    invoice(id: $id) {
      id
      stripeId
      total
      status
      primaryProduct {
        id
        key
        title
      }
    }
  }
`

export default FETCH_INVOICE
