import React from 'react'
import {
  TouchableOpacity,
  Image as ReactNativeImage,
  StyleProp,
  ImageStyle,
  View,
} from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@src/components/base'
import { ReactNativeFile } from '@src/utils/image'
import { usePhotoUpload } from '@src/utils/hooks'
import { Image } from '@src/utils'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'

const MIN_IMAGE_HEIGHT = 125

interface InsuranceCardPhotoProps {
  onInitialPress: () => void
  onChange: (file: ReactNativeFile | undefined) => void
  photo: ReactNativeFile | undefined
}

export const InsuranceCardPhoto = (props: InsuranceCardPhotoProps) => {
  const styles = useStyleSheet(themedStyle)
  const { onChange, onInitialPress, photo } = props
  const { launchCameraScreen, launchImageLibrary } = usePhotoUpload((file) => {
    onChange(file)
  })
  const { showActionSheet } = useApplicationActionSheet<Image.Options>()

  const onPress = () => {
    onInitialPress()
    const callback = (type: Image.Options) => {
      switch (type) {
        case Image.PHOTO_OPTION: {
          launchCameraScreen()
          break
        }
        case Image.LIBRARY_OPTION: {
          launchImageLibrary()
          break
        }
      }
    }

    showActionSheet({ title: 'Select Photo', options: Image.DEFAULT_OPTIONS, callback })
  }

  if (photo) {
    return (
      <TouchableOpacity accessibilityLabel="Upload Photo" onPress={onPress}>
        <View style={styles.uploadBox}>
          <ReactNativeImage
            source={{ uri: photo.uri }}
            style={styles.photo as StyleProp<ImageStyle>}
            resizeMode="contain"
          />
        </View>
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity accessibilityLabel="Upload Photo" onPress={onPress}>
      <View style={styles.uploadBox}>
        <Icon name="camera" size="32" />
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  uploadBox: {
    borderStyle: 'dashed',
    backgroundColor: 'theme.surface.base3',
    borderColor: 'theme.surface.base1',
    borderRadius: 16,
    borderWidth: 1,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: MIN_IMAGE_HEIGHT,
  },
  photo: {
    flex: 1,
    minHeight: MIN_IMAGE_HEIGHT,
    marginVertical: 16,
  },
})
