import { useSelector } from 'react-redux'
import { groupBy, xor } from 'lodash'
import { dailyActionsConfigSelector } from '@src/selectors/app'
import { DailyActionKind, UserDailyActionKind } from '@src/types'
import { dailyActionsSelector } from '@src/selectors/dailyActions'

export const TODAY_SECTION_ID = 0

export interface DailyActionsSection {
  id: number
  title: string
  data: DailyActionKind[]
}

export const useEditDailyActionsSections = (): DailyActionsSection[] => {
  const allDailyActionKinds = useSelector(dailyActionsConfigSelector)
  const currentDailyActionsKinds = useSelector(dailyActionsSelector).map((action) => action.kind)

  const todaySectionItems = allDailyActionKinds.filter((action) =>
    currentDailyActionsKinds.includes(action.key as UserDailyActionKind),
  )

  const dailyActionsCategories = groupBy(xor(allDailyActionKinds, todaySectionItems), 'category')

  const groupedAllDailyActions = Object.keys(dailyActionsCategories).map((category, i) => ({
    id: i + 1,
    title: category,
    data: dailyActionsCategories[category],
  }))

  return [
    { id: TODAY_SECTION_ID, title: 'Selected Focus Items', data: todaySectionItems },
    ...groupedAllDailyActions,
  ]
}
