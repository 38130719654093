import React, { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useNavigation } from '@react-navigation/core'
import moment from 'moment'
import { useIsFocused, useRoute } from '@react-navigation/native'
import { SensorTypes } from 'react-native-freestyle-libre'
import { useImpersonationContext } from '@src/config/impersonation'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import { NavigationContainer } from '@src/screens/Common/containers'
import { sensorActivationTimeSelector, sensorSelector } from '@src/selectors/sensor'
import { Feature, useFeatureFlag } from '@src/components'
import { ScanningContext } from '@src/context/scanningContext'
import { getTutorialGroup } from '@src/utils/tutorials'
import { useIsLibreLinkup, useLibre3NativeEnabled } from '@src/utils/hooks'
import { AppRouteProp } from '@src/navigation/types'
import { sensorIsWarmingUp } from '@src/screens/Scans/utils/utils'
import { MobileAppFeature, SurveysConfigKind } from '@src/types'
import { User } from '@src/utils'
import { SensorSettings as SensorSettingsComponent } from '../components/SensorSettings'

export const SensorSettingsContainer = () => {
  const sensor = useSelector(sensorSelector)
  const clientConfig = useSelector(clientConfigStoreStateSelector)
  const isImpersonating = useImpersonationContext()
  const navigation = useNavigation()
  const isLibreLinkup = useIsLibreLinkup()
  const { uxSensorModePendingLibre3 } = useLibre3NativeEnabled()
  const { bluetoothConnectionState, patchInfo } = useContext(ScanningContext)
  const route = useRoute<AppRouteProp<'SensorSettings'>>()
  const { showSensorWarmUpModal = true } = route.params || {}
  const libre3RealTime = useFeatureFlag(Feature.Libre3RealTime)
  const isActivateOwnSensorFeatureAvailable = User.hasFeature(MobileAppFeature.ActivateOwnSensor)

  const isFocused = useIsFocused()

  const sensorActivationTime = useSelector(sensorActivationTimeSelector)

  const sensorWarmUpModalShownRef = useRef(false)

  const sensorModel = sensor?.model as SensorTypes | undefined

  useEffect(() => {
    if (!showSensorWarmUpModal || !isFocused || sensorWarmUpModalShownRef.current) {
      return
    }

    const secondsAfterSensorActivation = moment().diff(sensorActivationTime, 'seconds')

    if (
      !sensorIsWarmingUp({
        secondsAfterSensorActivation,
        sensorModel,
        liveDataEnabled: libre3RealTime,
      })
    ) {
      return
    }

    sensorWarmUpModalShownRef.current = true
    navigation.navigate('SensorWarmUpModal')
  }, [
    sensorActivationTime,
    navigation,
    showSensorWarmUpModal,
    isFocused,
    sensorModel,
    libre3RealTime,
  ])

  const onActivateSensorPress = () => {
    if (isImpersonating) {
      return
    }

    if (isLibreLinkup || uxSensorModePendingLibre3) {
      navigation.navigate('TutorialsGroup', {
        group: getTutorialGroup(uxSensorModePendingLibre3),
      })
    } else {
      if (isActivateOwnSensorFeatureAvailable) {
        navigation.navigate('Questionnaire', {
          questionnaire: SurveysConfigKind.OwnSensor,
          nextScreen: { screen: 'ConfirmSensorSelection' },
        })
        return
      }
      navigation.navigate('AddScan', { action: 'activate' })
    }
  }

  const onCalibrateSensorPress = () => {
    navigation.navigate('CalibrateSensor')
  }

  const onIconPress = () => {
    const tutorial = get(clientConfig, 'onboarding.tutorials.calibration', {})
    navigation.navigate('ShowTutorial', { tutorial })
  }

  const onGetMoreSensorsPress = () => {
    navigation.navigate('Subscriptions')
  }

  return (
    <NavigationContainer title="Sensor Settings" showLoadingIndicator>
      <SensorSettingsComponent
        sensor={sensor}
        activateDisabled={isImpersonating}
        onActivateSensorPress={onActivateSensorPress}
        onCalibrateSensorPress={onCalibrateSensorPress}
        onGetMoreSensorsPress={onGetMoreSensorsPress}
        onIconPress={onIconPress}
        bluetoothConnectionState={bluetoothConnectionState}
        patchInfo={patchInfo}
      />
    </NavigationContainer>
  )
}
