import { UnleashClient } from 'unleash-proxy-client'
import Config from 'react-native-config'
import Storage from '@src/utils/storage'
import App from './app'

const client = new UnleashClient({
  url: Config.UNLEASH_URL,
  clientKey: Config.UNLEASH_CLIENT_KEY,
  refreshInterval: 30,
  appName: 'nutrisense',
  environment: App.build.env,
  storageProvider: {
    get: (name) => {
      const value = Storage.get(`${Storage.UNLEASH_STORAGE_KEY}_${name}`)
      return Promise.resolve(value)
    },
    save: (name, data) => {
      Storage.set(`${Storage.UNLEASH_STORAGE_KEY}_${name}`, data)
      return Promise.resolve()
    },
  },
  disableMetrics: true,
  bootstrap: [],
})

export default {
  client,
}
