import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppRouteProp } from '@src/navigation/types'
import { ScrollableAvoidKeyboard } from '@src/components'
import { Text } from '@components/base/Text'
import { measurementTypeDefLookupSelector } from '@src/selectors/app'
import { PickerButtonBlock } from '@src/components/modalBlocks/PickerButtonBlock'
import { MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { ChoiceItem, ChoiceList } from '@src/components/Choice'
import { HealthDataMeasurementSource } from '@src/types'
import { Measurement } from '@src/screens/Events/models/events.types'
import { RootStoreState } from '@src/models/app.types'
import { effectiveDailyMeasurementSelector } from '../models/dailyMeasurements.selectors'
import { SelectedSourceChoiceContent } from './SelectedSourceChoiceContent'

export const EditDailyMeasurementModal = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const route = useRoute<AppRouteProp<'EditDailyMeasurementModal'>>()
  const navigation = useNavigation()

  const { date, type } = route.params

  const effectiveDailyMeasurement = useSelector((state: RootStoreState) =>
    effectiveDailyMeasurementSelector(state, date, type),
  )

  const measurementTypeDef = useSelector(measurementTypeDefLookupSelector)[type]

  const manualMeasurement = effectiveDailyMeasurement?.availableMeasurements?.find(
    (availableMeasurement) =>
      availableMeasurement.externalSource === HealthDataMeasurementSource.Nutrisense,
  )

  const fetchEffectiveDailyMeasurement = useCallback(() => {
    dispatch({
      type: 'dailyMeasurements/fetchEffectiveMeasurement',
      payload: { type, date },
    })
  }, [dispatch, date, type])

  useEffect(fetchEffectiveDailyMeasurement, [fetchEffectiveDailyMeasurement])

  const measurementTemplate = { type, occurredAt: date } as Measurement

  const saveAction = {
    renderIconComponent: () => (
      <Text type="regular" bold style={styles.saveAction}>
        Add Data
      </Text>
    ),
    onPress: () => navigation.navigate('AddMeasurement', { item: measurementTemplate }),
  }

  const onSourceChoicePress = (item: ChoiceItem) => {
    const newSelectedMeasurement = effectiveDailyMeasurement.availableMeasurements.find(
      (availableMeasurement) => availableMeasurement.externalSource === item.id,
    )

    dispatch({
      type: 'dailyMeasurements/updateEffectiveMeasurement',
      payload: { type, date, measurement: newSelectedMeasurement },
    })
  }

  const getMeasurementTag = (measurement: Measurement) => {
    if (effectiveDailyMeasurement.selectedSource === measurement.externalSource) {
      return 'Override'
    }

    if (
      !effectiveDailyMeasurement.selectedSource &&
      effectiveDailyMeasurement.defaultSource === measurement.externalSource
    ) {
      return 'Default'
    }

    return undefined
  }

  const items =
    effectiveDailyMeasurement?.availableMeasurements?.map((availableMeasurement) => ({
      id: availableMeasurement.externalSource as HealthDataMeasurementSource,
      body: <SelectedSourceChoiceContent measurement={availableMeasurement as Measurement} />,
      tag: getMeasurementTag(availableMeasurement as Measurement),
    })) || []

  return (
    <NavigationContainer
      title={measurementTypeDef?.label}
      rightAccessories={manualMeasurement ? [] : [saveAction]}
    >
      <View style={styles.container}>
        <ScrollableAvoidKeyboard>
          <Divider />
          <PickerButtonBlock label="Type" title={measurementTypeDef?.label || ''} isReadonlyMode />
          <Divider />
          <PickerButtonBlock
            label="Date"
            title={moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)}
            isReadonlyMode
          />
          <Divider />
          <Text type="regular" style={styles.dataSourceText}>
            Data Source
          </Text>
          <View style={styles.sourcesContainer}>
            <ChoiceList
              items={items}
              onPress={onSourceChoicePress}
              selectedItemId={
                effectiveDailyMeasurement?.selectedSource ||
                effectiveDailyMeasurement?.defaultSource
              }
            />
          </View>
        </ScrollableAvoidKeyboard>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: 'theme.background',
  },
  sourcesContainer: {
    paddingHorizontal: 16,
  },
  saveAction: {
    padding: 12,
  },
  dataSourceText: {
    marginTop: 16,
    marginBottom: 16,
    marginLeft: 16,
  },
})
