import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { Image, ImageStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useSnack } from '@src/utils/navigatorContext'
import { libreLinkupConnectionSelector } from '@src/selectors/integrations'
import { LibreLinkupConnectionStatus } from '@src/types'
import { IconSources } from '@src/assets/icons'
import { LibreLoginForm } from './LibreLoginForm'
import { Libre2FaForm } from './Libre2FaForm'

export const LibreAuth = () => {
  const styles = useStyleSheet(themedStyles)
  const linkupConnection = useSelector(libreLinkupConnectionSelector)
  const navigation = useNavigation()
  const showSnack = useSnack()

  const codeSent =
    linkupConnection &&
    linkupConnection.status === LibreLinkupConnectionStatus.AuthorizationCodeSent

  useEffect(() => {
    if (
      linkupConnection &&
      linkupConnection.status === LibreLinkupConnectionStatus.ConnectionAuthorized
    ) {
      navigation.navigate('IntegrationSuccessful')
    }
  }, [linkupConnection, showSnack, navigation])

  return (
    <NavigationContainer title="Connect Libre account" showLoadingIndicator>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <Image source={IconSources.freeStyleLibreIcon} style={styles.icon as ImageStyle} />
        {codeSent ? <Libre2FaForm /> : <LibreLoginForm />}
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  icon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    marginVertical: 32,
  },
})
