import moment from 'moment'

export const YEAR_FORMAT = 'YYYY'

export const DATE_FORMAT = `${YEAR_FORMAT}-MM-DD`

export const DATE_TIME_FORMAT = `${DATE_FORMAT}THH:mm:ss`
export const FULL_DATE_TIME_FORMAT = `MM/DD/YYYY hh:mm:ss a`

export const MONTH_NAME_AND_DATE_FORMAT = 'MMM D'

export const DAY_MONTH_FORMAT = 'MM/D'
export const DAY_MONTH_YEAR_FORMAT = 'L' // 09/04/1986

export const HOUR_MINUTE_AM_PM = 'h:mm a'

export const MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT = `${MONTH_NAME_AND_DATE_FORMAT}, ${YEAR_FORMAT}`

export const TWELVE_HOURS_TIME_FORMAT = 'hh:mmA'
export const SHORT_TWELVE_HOURS_TIME_FORMAT = 'h:mmA'

export const TODAY = 'Today'
export const YESTERDAY = 'Yesterday'

export const FULL_DISPLAY_TIME_FORMAT = `${MONTH_NAME_AND_DATE_FORMAT} - ${TWELVE_HOURS_TIME_FORMAT}`
export const FULL_DISPLAY_TIME_FORMAT_WITH_COMMA = `${MONTH_NAME_AND_DATE_FORMAT}, ${SHORT_TWELVE_HOURS_TIME_FORMAT}`
export const DAY_NAME_MONTH_AND_DATE_FORMAT = 'ddd, MMM DD' // Sat, Oct 25
export const DAY_NAME_MONTH_AND_DATE_WITH_YEAR_FORMAT = 'ddd, MMM DD, YY' // Sat, Oct 25, 24
export const DAY_NAME_MONTH_AND_DATE_WITH_LONG_YEAR_FORMAT = 'ddd, MMM DD, YYYY' // Sat, Oct 25, 2024

export const DATE_PICKER_FORMAT = 'MMM DD, YYYY - h:mma'

export const formatDate = (date: moment.MomentInput, fromFormat: string) => {
  const momentDate = moment(date, [fromFormat])
  const isToday = momentDate.isSame(moment(), 'date')

  if (isToday) {
    return `${TODAY}, ${moment(momentDate).format(SHORT_TWELVE_HOURS_TIME_FORMAT)}`
  }

  return momentDate.format(FULL_DISPLAY_TIME_FORMAT_WITH_COMMA)
}

export const formatDateRange = (startDate: string, endDate: string, showTodayText = true) => {
  if (moment(startDate).isSame(moment(endDate))) {
    // single date
    if (showTodayText && moment(startDate).isSame(moment().format(DATE_FORMAT))) {
      // today
      return TODAY
    }

    // other day
    return moment(startDate).format(MONTH_NAME_AND_DATE_FORMAT)
  }

  // date range
  if (moment(startDate).isSame(moment(endDate), 'month')) {
    // same month
    return `${moment(startDate).format('MMM D')} - ${moment(endDate).format('D')}`
  }

  // different month
  return `${moment(startDate).format(MONTH_NAME_AND_DATE_FORMAT)} - ${moment(endDate).format(
    MONTH_NAME_AND_DATE_FORMAT,
  )}`
}

export const formatRelativeDate = (relativeFromDate: string) => {
  if (moment().startOf('day').subtract('30', 'days').isBefore(relativeFromDate)) {
    return moment(relativeFromDate).fromNow()
  }

  const isMoreThanOneYearInThePast = moment(relativeFromDate).isBefore(
    moment().startOf('day').subtract('1', 'year'),
  )

  return `on ${moment(relativeFromDate).format(
    isMoreThanOneYearInThePast
      ? DAY_NAME_MONTH_AND_DATE_WITH_YEAR_FORMAT
      : DAY_NAME_MONTH_AND_DATE_FORMAT,
  )}`
}
