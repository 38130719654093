import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { InsurancePolicy, UserInsurancePolicyStateKind } from '@src/types'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useInsuranceCardSubmissionData } from '@src/screens/NutritionistHub/hooks/useInsuranceCardSubmissionData'

interface InsurancePolicyStateProps {
  insurancePolicy: InsurancePolicy
}

const CANCELED_STATES = [
  UserInsurancePolicyStateKind.Canceled,
  UserInsurancePolicyStateKind.CanceledInsuranceNotAccepted,
  UserInsurancePolicyStateKind.CanceledNoProvidersInn,
]

const ELIGIBLE_STATES = [
  UserInsurancePolicyStateKind.Eligible,
  UserInsurancePolicyStateKind.EligiblePatientResponsibility,
  UserInsurancePolicyStateKind.EligiblePreAuth,
]

export const InsurancePolicyState = (props: InsurancePolicyStateProps) => {
  const styles = useStyleSheet(themedStyles)
  const { insurancePolicy } = props
  const insuranceCardSubmissionData = useInsuranceCardSubmissionData(insurancePolicy)
  const navigation = useNavigation()
  const state = insurancePolicy.lastState?.kind
  const insurancePolicyStateId = insuranceCardSubmissionData.insurancePolicyStateId
  const isStateRequiringInsuranceCardSubmission =
    insuranceCardSubmissionData.isInsuranceCardSubmissionPending

  if (!state) {
    return null
  }

  const onResubmitPress = () => {
    navigation.navigate('InsurancePolicyForm')
  }

  const onSubmitInsuranceCardPress = () => {
    navigation.navigate('SubmitInsuranceCardScreen')
  }

  const renderState = () => {
    if (ELIGIBLE_STATES.includes(state)) {
      return (
        <View style={styles.iconWithTextContainer}>
          <Icon name="check-circle" weight="fill" size={16} style={styles.eligibleIconAndText} />
          <Text type="regular" style={styles.eligibleIconAndText}>
            All good
          </Text>
        </View>
      )
    }

    if (state === UserInsurancePolicyStateKind.NotEligible) {
      return (
        <Text type="regular" style={styles.notEligibleText}>
          Not Eligible
        </Text>
      )
    }

    if (CANCELED_STATES.includes(state)) {
      return (
        <>
          <Text type="regular" style={styles.notEligibleText}>
            Canceled
          </Text>
          <TouchableOpacity onPress={onResubmitPress} accessibilityLabel="Re-check insurance">
            <Text type="regular" bold style={styles.actionText}>
              Re-check
            </Text>
          </TouchableOpacity>
        </>
      )
    }

    if (state === UserInsurancePolicyStateKind.Hold) {
      return (
        <>
          <Text type="regular" style={styles.inProgressText}>
            On Hold
          </Text>
          {isStateRequiringInsuranceCardSubmission && insurancePolicyStateId && (
            <TouchableOpacity
              onPress={onSubmitInsuranceCardPress}
              accessibilityLabel="Submit insurance card"
            >
              <Text type="regular" style={styles.actionText}>
                Upload Insurance Card
              </Text>
            </TouchableOpacity>
          )}
        </>
      )
    }

    if (state === UserInsurancePolicyStateKind.InReview) {
      return (
        <>
          <Text type="regular" style={styles.inProgressText}>
            In Progress
          </Text>
          <Text type="regular" style={styles.actionText}>
            Re-Submit
          </Text>
        </>
      )
    }

    if (state === UserInsurancePolicyStateKind.Created) {
      return <Text type="regular">Submitted</Text>
    }

    if (state === UserInsurancePolicyStateKind.NotSupported) {
      return (
        <Text type="regular" style={styles.notEligibleText}>
          Not Supported
        </Text>
      )
    }

    return <Text type="regular">Unknown State</Text>
  }

  return <View style={styles.content}>{renderState()}</View>
}

const themedStyles = StyleService.create({
  content: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 8,
  },
  notEligibleText: {
    color: 'theme.error.base',
  },
  inProgressText: {
    color: 'theme.warning.darkest',
  },
  actionText: {
    color: 'theme.text.link',
  },
  eligibleIconAndText: {
    color: 'theme.success.base',
    fontWeight: 400,
  },
  iconWithTextContainer: {
    gap: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
