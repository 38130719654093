import { EhrAppointmentRecurrenceCadence, EhrAppointmentRecurrenceDay } from '@src/types.ts'

export const TIME_SLOT_FORMAT = 'hh:mm a'

export const MAX_RECURRENCE_CALLS = 12
export const UNLIMITED_INSURANCE_CALLS = -1

export const UPSERT_ERROR_MESSAGE =
  'Something went wrong. Please check your appointments and try again.'

export const orderedCadenceOptions = [
  EhrAppointmentRecurrenceCadence.Weekly,
  EhrAppointmentRecurrenceCadence.BiWeekly,
  EhrAppointmentRecurrenceCadence.Monthly,
]

export const dayOfWeekToRecurrenceDayLookup: { [key: number]: EhrAppointmentRecurrenceDay } = {
  1: EhrAppointmentRecurrenceDay.Monday,
  2: EhrAppointmentRecurrenceDay.Tuesday,
  3: EhrAppointmentRecurrenceDay.Wednesday,
  4: EhrAppointmentRecurrenceDay.Thursday,
  5: EhrAppointmentRecurrenceDay.Friday,
}
