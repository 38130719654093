import React, { Pressable } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { ServerError } from '@apollo/client'
import { useNavigation } from '@react-navigation/native'
import { Icon, Checkbox } from '@components/base'
import { DailyAction } from '@src/types'
import { useSnack } from '@src/utils'
import { statsSelector } from '@src/screens/Insights/models/insights.selectors'
import { Analytics, CustomEventTypes } from '@src/config'

export interface DailyActionCardInteractionProps {
  dailyAction: DailyAction & { completed: boolean }
}

export const DailyActionCardInteraction = ({ dailyAction }: DailyActionCardInteractionProps) => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const navigation = useNavigation()
  const stats = useSelector(statsSelector)

  const navigateToStat = () => {
    const stat = stats.find((stat) => stat.meta.key === dailyAction.statKey)
    if (!stat) {
      return
    }

    Analytics.track(CustomEventTypes.HomeScreenNavigateToTrendsTapped, {
      actionKey: dailyAction.kind,
      statKey: dailyAction.statKey,
    })
    navigation.navigate('InsightsDetailedPage', { statistic: stat })
  }

  if (dailyAction.statKey) {
    return (
      <Pressable accessibilityLabel="navigateToStat" onPress={navigateToStat}>
        <Icon name="caret-right" size={28} />
      </Pressable>
    )
  }

  const toggleCompleted = () => {
    const type = dailyAction.completed ? 'dailyActions/markIncomplete' : 'dailyActions/markComplete'

    dispatch({
      type,
      payload: { dailyActionId: dailyAction.id },
      failure: (error: ServerError) => {
        showSnack(error.message)
      },
    })

    Analytics.track(CustomEventTypes.HomeScreenDailyActionToggleTapped, {
      actionKey: dailyAction.kind,
      completed: !dailyAction.completed,
    })
  }

  return (
    <Checkbox
      checked={dailyAction.completed}
      accessibilityLabel="toggleCompleted"
      onChange={toggleCompleted}
    />
  )
}
