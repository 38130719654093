import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { BottomSheet, InlineAlert, OutlinedIcon } from '@src/components'
import { SurveyLink, SurveysConfigKind, UiInlineAlert } from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { openUrl, useGoBack } from '@src/utils'
import { useIsEligibleForResearchSensor } from '@src/screens/Dexcom/Research/hooks/useIsEligibleForDexcomResearch'
import { lastFinishedSurveyOfKind } from '@src/screens/Questionnaire/utils'
import { researchFaqsSelector, userSelector } from '@src/selectors/app'

export const ScanDisableAnnouncement = () => {
  const styles = useStyleSheet(themedStyle)
  const isEligibleForDexcom = useIsEligibleForResearchSensor()
  const user = useSelector(userSelector)
  const surveyLinks = user?.surveyLinks || []
  const researchFaqs = useSelector(researchFaqsSelector)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()

  const lastFinishedMedicalHistorySurveyLink = lastFinishedSurveyOfKind(
    SurveysConfigKind.MedicalHistory,
    surveyLinks as SurveyLink[],
  )

  const navigateToDexcomEnrollment = () => {
    if (lastFinishedMedicalHistorySurveyLink) {
      return navigation.replace('Questionnaire', {
        questionnaire: SurveysConfigKind.DexcomResearchConsent,
      })
    }

    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.MedicalHistory,
    })
  }

  const handleFAQPress = () => {
    openUrl(researchFaqs.disableLibreScanUrl)
  }

  const title = isEligibleForDexcom
    ? 'Upcoming Changes to FreeStyle Libre 14-Day Sensor Scanning'
    : 'Upcoming change to FreeStyle Libre 14 Days & Libre 2 sensors scanning'
  const alertMessage = (
    <View>
      <Text type="regular">
        For more information, please check our{' '}
        <Text type="regular" bold style={styles.link} onPress={() => handleFAQPress()}>
          FAQ page
        </Text>
      </Text>
    </View>
  )

  const body = (
    <>
      <View style={styles.contentContainer}>
        <Text type="regular" style={styles.info}>
          <Text type="regular" bold>
            Starting November 29th
          </Text>
          , you will no longer be able to scan FreeStyle Libre 14-Day sensors directly in the
          Nutrisense app.
        </Text>
        {isEligibleForDexcom ? (
          <>
            <Text type="regular" style={styles.info}>
              {' '}
              We offer 2 options you can choose from:
            </Text>
            <View style={styles.benefit}>
              <Icon name="arrow-square-right" style={styles.benefitIcon} />
              <Text type="regular">You can switch to the bluetooth-enabled Dexcom G7 sensor.</Text>
            </View>
            <View style={styles.benefit}>
              <Icon name="arrow-square-right" style={styles.benefitIcon} />
              <Text type="regular">
                Use the FreeStyle LibreLink app to connect your sensor to the Nutrisense app.
                Migration guide to setup connection coming soon!
              </Text>
            </View>
          </>
        ) : (
          <Text type="regular" style={styles.secondaryInfo}>
            Rest assured, you will still be able to sync data with Nutrisense after this date - you
            will need to simply connect your sensor to the FreeStyle LibreLink app.
          </Text>
        )}
      </View>
      <InlineAlert
        style={styles.infoAlert}
        textStyle={styles.infoAlertText}
        iconStyle={styles.infoAlertIcon}
        category={UiInlineAlert.Success}
        iconName="info"
        message={alertMessage}
      />
      {isEligibleForDexcom && (
        <Text type="small" style={styles.disclaimer}>
          Switching to the Dexcom G7 requires research opt-in
        </Text>
      )}
    </>
  )

  const primaryButtonText = isEligibleForDexcom ? 'Switch Sensor' : 'Close'
  const primaryButtonOnPress = isEligibleForDexcom ? navigateToDexcomEnrollment : goBack

  const primaryButton = {
    text: primaryButtonText,
    onPress: primaryButtonOnPress,
  }

  const dexcomEligibleSecondaryButton = {
    text: 'Dismiss',
    onPress: goBack,
    type: 'transparent',
  }

  const secondaryButton = isEligibleForDexcom ? dexcomEligibleSecondaryButton : undefined

  return (
    <BottomSheet
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      buttonDirection="column-reverse"
    >
      <OutlinedIcon iconName="warning" style={styles.icon} />
      <Text type="title-2" style={styles.primaryText}>
        {title}
      </Text>
      <View>{body}</View>
    </BottomSheet>
  )
}

const themedStyle = StyleService.create({
  icon: {
    alignSelf: 'center',
  },
  contentContainer: {
    marginHorizontal: 16,
  },
  info: {
    marginBottom: 24,
    textAlign: 'left',
  },
  secondaryInfo: {
    textAlign: 'left',
    marginBottom: 36,
  },
  benefit: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  primaryText: {
    marginBottom: 24,
    textAlign: 'center',
  },
  benefitIcon: {
    marginRight: 8,
  },
  infoAlert: {
    margin: 16,
  },
  infoAlertText: {
    color: 'theme.text.secondary',
  },
  infoAlertIcon: {
    color: 'theme.success.darkest',
  },
  link: {
    color: 'theme.text.link',
  },
  disclaimer: {
    color: 'theme.text.secondary',
    textAlign: 'center',
    marginBottom: 16,
  },
})
