import { gql } from '@apollo/client'
import { APPOINTMENT_RECURRENCE_FIELDS } from '../fragments/appointment_recurrence.ts'

export const UPSERT_APPOINTMENT_RECURRENCE = gql`
  ${APPOINTMENT_RECURRENCE_FIELDS}
  mutation upsertEhrAppointmentRecurrence(
    $cadence: EhrAppointmentRecurrenceCadence
    $timeSlots: [ISO8601DateTime!]
    $days: [EhrAppointmentRecurrenceDay!]
    $numberOfCalls: Int
  ) {
    upsertEhrAppointmentRecurrence(
      cadence: $cadence
      timeSlots: $timeSlots
      days: $days
      numberOfCalls: $numberOfCalls
    ) {
      ...AppointmentRecurrenceFields
    }
  }
`
