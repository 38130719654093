import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '@src/components/base'

export interface TrialStepProps {
  title: string
  description: string
  iconName: IconName
  iconContainerStyle?: StyleProp<ViewStyle>
  lightConnector?: boolean
  darkConnector?: boolean
}

export const TrialStep = ({
  title,
  description,
  iconName,
  iconContainerStyle,
  lightConnector,
  darkConnector,
}: TrialStepProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.stepJourneyBar}>
      {lightConnector && <View style={styles.lightConnector} />}
      {darkConnector && <View style={styles.darkConnector} />}
      <View style={styles.iconHeaderContainer}>
        <View style={styles.circleMilestone} />
        <View style={[styles.iconContainer, iconContainerStyle]}>
          <Icon style={styles.milestoneIcon} weight="fill" name={iconName} />
        </View>
        <Text type="regular" bold style={styles.milestoneTextHeader}>
          {title}
        </Text>
      </View>
      <View style={styles.connectorDescription}>
        <Text type="regular">{description}</Text>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  stepJourneyBar: {
    width: '100%',
    flexDirection: 'column',
  },
  iconHeaderContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  connectorDescription: {
    flex: 1,
    alignContent: 'flex-start',
    marginLeft: 48,
  },
  milestoneIcon: {
    color: 'theme.marketing.brown',
    alignSelf: 'center',
    width: 16,
    height: 16,
    justifyContent: 'center',
  },
  iconContainer: {
    position: 'absolute',
    left: 8,
  },
  milestoneTextHeader: {
    marginLeft: 16,
  },
  circleMilestone: {
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: 'theme.marketing.yellow',
  },
  lightConnector: {
    position: 'absolute',
    left: 10,
    top: 20,
    bottom: -5,
    width: 10,
    backgroundColor: 'theme.marketing.lightYellow',
  },
  darkConnector: {
    position: 'absolute',
    left: 10,
    top: 0,
    bottom: 20,
    width: 10,
    borderRadius: 32,
    backgroundColor: 'theme.marketing.yellow',
  },
})
