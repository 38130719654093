const prepareArm = () => require('./source/prepare-arm.mp4')
const sanitize = () => require('./source/sanitize.mp4')
const prepareSensor = () => require('./source/prepare-sensor.mp4')
const alignApplicator = () => require('./source/align-applicator.mp4')
const connectApplicator = () => require('./source/connect-applicator.mp4')
const getReadyToApply = () => require('./source/get-ready-to-apply.mp4')
const applyCgm = () => require('./source/apply-cgm.mp4')
const bandage = () => require('./source/bandage.mp4')

export const prepareArmVideo = {
  source: prepareArm,
}

export const sanitizeVideo = {
  source: sanitize,
}

export const prepareSensorVideo = {
  source: prepareSensor,
}

export const alignApplicatorVideo = {
  source: alignApplicator,
}

export const connectApplicatorVideo = {
  source: connectApplicator,
}

export const getReadyToApplyVideo = {
  source: getReadyToApply,
}

export const applyCgmVideo = {
  source: applyCgm,
}

export const bandageVideo = {
  source: bandage,
}
