import { gql } from '@apollo/client'

export const FETCH_TUTORIAL_GROUPS = gql`
  query allTutorialGroups($ids: [ID]!) @api(name: cms) {
    tutorialGroups(filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          title
          duration
          summary
          tutorials(sort: "position", filters: { publishedAt: { notNull: true } }) {
            data {
              id
              attributes {
                position
                name
                title
                duration
                description
                buttonText
                platform
                sensorKind
                image {
                  data {
                    id
                    attributes {
                      url
                      previewUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
