import React from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { Controller, useForm } from 'react-hook-form'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Input, Text } from '@components/base'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { AppStackParamList } from '@src/navigation/types'
import { ScrollableAvoidKeyboard, SelectDateTime } from '@src/components'
import { Analytics, CustomEventTypes, ErrorMessages } from '@src/config'
import { useSnack } from '@src/utils'
import { validateFloatNumberInput } from '@src/utils/validators'
import { BiomarkerEntryScreen } from './BiomarkerEntryScreen'

interface BiomarkerFormData {
  date?: Date
  value?: string
}

export const BiomarkerForm = () => {
  const route = useRoute<RouteProp<AppStackParamList, 'BiomarkerForm'>>()
  const { id, biomarkerName, value, date, unit, biomarkerKind } = route.params
  const isEdit = !!id
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()

  const { control, handleSubmit } = useForm<BiomarkerFormData>({
    mode: 'onBlur',
    defaultValues: { date: date ? new Date(date) : undefined, value: value?.toString() },
  })

  const onDeletePress = () => {
    dispatch({
      payload: { id },
      type: 'labResults/deleteHealthMetric',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
      success: () => {
        Analytics.track(CustomEventTypes.OutcomesTrackingLoggedMetricDeleted)
        navigation.navigate('LabResultsScreen')
      },
    })
  }

  const onSavePress = handleSubmit((formData) => {
    if (!formData.date || !formData.value) {
      showSnack('Please fill in all required fields', null, 'error')
      return
    }

    const payload = {
      value: Number.parseFloat(formData.value),
      date: formData.date,
    }

    if (isEdit) {
      dispatch({
        payload: { id, ...payload },
        type: 'labResults/updateHealthMetric',
        failure: (error: any) => {
          const errorMessage = error?.message || ErrorMessages.ServerError
          showSnack(errorMessage, null, 'error')
        },
        success: () => {
          Analytics.track(CustomEventTypes.OutcomesTrackingLoggedMetricEdited)
          navigation.navigate('LabResultsScreen')
        },
      })
      return
    }

    dispatch({
      payload: { kind: biomarkerKind, ...payload },
      type: 'labResults/createHealthMetric',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
      success: () => {
        Analytics.track(CustomEventTypes.OutcomesTrackingIndividualMetricLogged)
        navigation.navigate('LabResultsScreen')
      },
    })
  })

  return (
    <BiomarkerEntryScreen
      biomarkerName={biomarkerName}
      isEdit={isEdit}
      onDeletePress={onDeletePress}
    >
      <ScrollableAvoidKeyboard style={styles.content}>
        <View style={styles.inputGroup}>
          <Text type="regular">Biomarker</Text>
          <Input value={biomarkerName} disabled />
        </View>

        <View style={styles.inputGroup}>
          <Text type="regular">Value</Text>
          <Controller
            control={control}
            name="value"
            rules={{ required: 'Please enter a value' }}
            render={({ field, fieldState }) => (
              <Input
                value={field.value?.toString()}
                accessoryRight={<Text type="regular">{unit}</Text>}
                onChangeText={(text) => {
                  const value = validateFloatNumberInput(text)
                  field.onChange(value)
                }}
                hasError={fieldState.invalid}
                errorText={fieldState.error?.message}
                keyboardType="decimal-pad"
              />
            )}
          />
        </View>
        <Controller
          control={control}
          name="date"
          rules={{ required: 'Please select a date' }}
          render={({ field, fieldState }) => (
            <SelectDateTime
              inputProps={{
                label: 'Date',
                accessoryLeft: <Icon name="calendar-blank" style={styles.icon} />,
                disabled: isEdit,
                value: field.value
                  ? moment.utc(field.value).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)
                  : undefined,
                hasError: fieldState.invalid,
                errorText: fieldState.error?.message,
              }}
              pickerProps={{
                mode: 'date',
                maximumDate: new Date(),
                timeZoneName: 'UTC',
              }}
              onChange={(value) => field.onChange(moment(value).format(DATE_FORMAT))}
            />
          )}
        />
        <View style={[styles.saveButton, { paddingBottom: Math.max(insets.bottom, 16) }]}>
          <Button accessibilityLabel="Save" onPress={onSavePress} type="primary" size="block">
            Save
          </Button>
        </View>
      </ScrollableAvoidKeyboard>
    </BiomarkerEntryScreen>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
  },
  icon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  saveButton: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  inputGroup: {
    marginBottom: 16,
  },
})
