import React, { useState } from 'react'
import moment from 'moment'
import { View, ScrollView, TouchableOpacity, Dimensions } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Icon } from '@components/base'
import { ActivityType, ICON_NAME_BY_ACTIVITY_TYPE } from '@src/screens/Events/models/events.types'
import { GlucoseGraphCard } from '@src/components/list/items/expanded/Cards'
import { Activity } from '@src/types'

const DATE_TIME_DISPLAY_FORMAT = 'MMM DD, YYYY - h:mm a'

interface ActivityDetailProps {
  activity: Activity
  favorite: boolean
  onToggleFavorite: () => void
  onShare: () => void
}

export const ActivityDetail = ({
  activity,
  favorite,
  onToggleFavorite,
  onShare,
}: ActivityDetailProps) => {
  const styles = useStyleSheet(themedStyle)

  const [contentWidth, setContentWidth] = useState(Dimensions.get('window').width)

  return (
    <ScrollView
      style={styles.container}
      bounces={false}
      onLayout={(e) => setContentWidth(e.nativeEvent.layout.width)}
    >
      <View style={styles.actionContainer}>
        <View style={styles.activityIconContainer}>
          <Icon
            name={ICON_NAME_BY_ACTIVITY_TYPE[activity.type as ActivityType]}
            weight="fill"
            style={styles.activityIcon}
          />
        </View>
        <View style={styles.actions}>
          <TouchableOpacity
            hitSlop={{ top: 7, bottom: 7 }}
            onPress={onShare}
            style={styles.actionButton}
            accessibilityLabel="Share Activity"
          >
            <Icon name="share-network" weight="fill" style={styles.actionIcon} />
            <Text type="regular" bold style={styles.actionTitle}>
              Share
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            hitSlop={{ top: 7, bottom: 7 }}
            onPress={onToggleFavorite}
            style={styles.actionButton}
            accessibilityLabel="Favorite Activity"
          >
            <Icon
              name="heart"
              weight={favorite ? 'fill' : 'regular'}
              style={[styles.actionIcon, favorite && styles.actionIconRed]}
            />
            <Text type="regular" bold style={[!favorite && styles.actionTitle]}>
              Favorite
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {!!activity.description && (
        <View style={styles.fieldContainer}>
          <Text type="small" style={styles.fieldLabel}>
            Description
          </Text>
          <Text type="large" bold>
            {activity.description}
          </Text>
        </View>
      )}
      <View style={styles.fieldContainer}>
        <Text type="small" style={styles.fieldLabel}>
          From
        </Text>
        <Text type="large" bold>
          {moment(activity.startedAt).format(DATE_TIME_DISPLAY_FORMAT)}
        </Text>
      </View>
      <View style={styles.fieldContainer}>
        <Text type="small" style={styles.fieldLabel}>
          To
        </Text>
        <Text type="large" bold>
          {moment(activity.endedAt).format(DATE_TIME_DISPLAY_FORMAT)}
        </Text>
      </View>
      <Divider />
      <View style={styles.glucoseGraphContainer}>
        <GlucoseGraphCard
          item={activity}
          contentWidth={contentWidth}
          statistics={activity.statistics}
          renderCharts
        />
      </View>
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  actionContainer: {
    flexDirection: 'row',
    height: 56,
    paddingHorizontal: 24,
    marginVertical: 16,
    marginBottom: 24,
  },
  activityIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 24,
    height: 56,
    width: 56,
    borderRadius: 8,
    backgroundColor: 'theme.surface.base1',
  },
  activityIcon: {
    color: 'theme.primary.base',
  },
  actions: {
    justifyContent: 'space-between',
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionIcon: {
    marginRight: 8,
    color: 'theme.text.secondary',
  },
  actionIconRed: {
    color: 'theme.like',
  },
  actionTitle: {
    color: 'theme.text.secondary',
  },
  fieldContainer: {
    paddingHorizontal: 24,
    marginBottom: 16,
  },
  fieldLabel: {
    marginBottom: 6,
    lineHeight: 16,
    color: 'theme.text.secondary',
  },
  glucoseGraphContainer: {
    marginTop: 16,
  },
})
