import { gql } from '@apollo/client'

const SUBMIT_INSURANCE_CARD = gql`
  mutation submitInsuranceCard(
    $insurancePolicyStateId: ID!
    $frontPhotoFileId: ID!
    $backPhotoFileId: ID!
  ) {
    submitInsuranceCard(
      insurancePolicyStateId: $insurancePolicyStateId
      frontPhotoFileId: $frontPhotoFileId
      backPhotoFileId: $backPhotoFileId
    ) {
      id
    }
  }
`

export default SUBMIT_INSURANCE_CARD
