import { useRoute } from '@react-navigation/native'
import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { BottomSheet } from '@src/components'
import { AppRouteProp } from '@src/navigation/types'
import { useStyleSheet, StyleService } from '@src/style/service'
import { useSubscriptionFromRoute } from '../../../utils/hooks'
import { UpsellOfferContent } from '../UpsellOffer/UpsellOfferContent'

export const ChurnRecoveryProactiveUpsellOffer = () => {
  const styles = useStyleSheet(themedStyle)
  const { subscription } = useSubscriptionFromRoute()
  const { params } = useRoute<AppRouteProp<'ChurnRecoveryUpsellOffer'>>()
  const {
    billingProduct,
    title,
    description,
    billingProductPrice,
    billingProductIntervalDescription,
    offerPillText,
    offerHeaderText,
    offerSubheaderText,
    offerFooterText,
  } = params

  if (!subscription) {
    return null
  }

  return (
    <BottomSheet>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <UpsellOfferContent
          showCommitmentDisclaimer={false}
          billingProduct={billingProduct}
          title={title}
          description={description}
          billingProductPrice={billingProductPrice}
          billingProductIntervalDescription={billingProductIntervalDescription}
          offerPillText={offerPillText}
          offerHeaderText={offerHeaderText}
          offerSubheaderText={offerSubheaderText}
          offerFooterText={offerFooterText}
          subscription={subscription}
          primaryButtonText="Claim offer"
        />
      </SafeAreaView>
    </BottomSheet>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
})
