/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Circle, G, Mask, Path, Rect } from 'react-native-svg'

export const HomeScreenWalkthroughGraphicDark = () => (
  <Svg width="313" height="299" viewBox="0 0 313 299" fill="none">
    <Mask id="mask0_3250_23075" maskUnits="userSpaceOnUse" x="0" y="0" width="313" height="299">
      <Rect width="313" height="299" rx="24" fill="white" />
    </Mask>
    <G mask="url(#mask0_3250_23075)">
      <Circle cx="158" cy="99" r="200" fill="#1A1C1E" />
      <G filter="url(#filter0_d_3250_23075)">
        <Path
          d="M279.735 44.6405V162.193C279.735 167.48 275.384 171.833 270.101 171.833H152.634C147.351 171.833 143 167.48 143 162.193V44.6405C143 39.3538 147.351 35 152.634 35H270.101C275.384 35 279.735 39.3538 279.735 44.6405Z"
          fill="#141418"
        />
      </G>
      <Path
        d="M264.332 156.38H158.966C156.469 156.38 154.439 153.336 154.439 149.59V56.634C154.439 52.8876 156.469 49.8438 158.966 49.8438H264.332C266.829 49.8438 268.859 52.8876 268.859 56.634V149.59C269.015 153.336 266.985 156.38 264.332 156.38Z"
        stroke="#667079"
        strokeWidth="1.97289"
        strokeMiterlimit="10"
        strokeDasharray="5.92 5.92"
      />
      <Path
        d="M245.086 104.971H177.65C176.873 104.971 176.252 104.349 176.252 103.572V101.706C176.252 100.929 176.873 100.307 177.65 100.307H245.086C245.863 100.307 246.484 100.929 246.484 101.706V103.572C246.484 104.194 245.863 104.971 245.086 104.971Z"
        fill="#C2D0AE"
      />
      <Path
        d="M225.041 116.477H197.539C196.762 116.477 196.141 115.855 196.141 115.078V113.212C196.141 112.434 196.762 111.812 197.539 111.812H225.041C225.818 111.812 226.44 112.434 226.44 113.212V115.078C226.44 115.855 225.818 116.477 225.041 116.477Z"
        fill="#C2D0AE"
      />
      <Path
        d="M114.236 142.367H31.4184C29.5538 142.367 28 140.812 28 138.946V118.421C28 116.555 29.5538 115 31.4184 115H114.236C116.101 115 117.655 116.555 117.655 118.421V138.946C117.81 140.812 116.256 142.367 114.236 142.367Z"
        fill="#202325"
        stroke="#373D41"
        strokeWidth="3.94578"
        strokeMiterlimit="10"
      />
      <Path
        d="M106.312 131.017H38.8769C38.1 131.017 37.4785 130.395 37.4785 129.617V127.751C37.4785 126.974 38.1 126.352 38.8769 126.352H106.312C107.089 126.352 107.711 126.974 107.711 127.751V129.617C107.711 130.395 107.089 131.017 106.312 131.017Z"
        fill="#EFD482"
      />
      <Path
        d="M114.236 177.818H31.4184C29.5538 177.818 28 176.263 28 174.397V153.717C28 151.851 29.5538 150.296 31.4184 150.296H114.236C116.101 150.296 117.655 151.851 117.655 153.717V174.242C117.81 176.263 116.256 177.818 114.236 177.818Z"
        fill="#202325"
        stroke="#373D41"
        strokeWidth="3.94578"
        strokeMiterlimit="10"
      />
      <Path
        d="M114.236 213.269H31.4184C29.5538 213.269 28 211.714 28 209.848V189.168C28 187.302 29.5538 185.747 31.4184 185.747H114.236C116.101 185.747 117.655 187.302 117.655 189.168V209.693C117.81 211.714 116.256 213.269 114.236 213.269Z"
        fill="#373D41"
        stroke="#667079"
        strokeWidth="1.97289"
        strokeMiterlimit="10"
        strokeDasharray="5.81 5.81"
      />
      <Path
        d="M106.312 166.467H38.8769C38.1 166.467 37.4785 165.845 37.4785 165.067V163.201C37.4785 162.424 38.1 161.802 38.8769 161.802H106.312C107.089 161.802 107.711 162.424 107.711 163.201V165.067C107.711 165.845 107.089 166.467 106.312 166.467Z"
        fill="#EFA982"
      />
      <Path
        d="M114.236 248.722H31.4184C29.5538 248.722 28 247.167 28 245.301V224.776C28 222.91 29.5538 221.355 31.4184 221.355H114.236C116.101 221.355 117.655 222.91 117.655 224.776V245.301C117.81 247.167 116.256 248.722 114.236 248.722Z"
        fill="#202325"
        stroke="#373D41"
        strokeWidth="3.94578"
        strokeMiterlimit="10"
      />
      <Path
        d="M106.312 237.371H38.8769C38.1 237.371 37.4785 236.749 37.4785 235.972V234.106C37.4785 233.329 38.1 232.707 38.8769 232.707H106.312C107.089 232.707 107.711 233.329 107.711 234.106V235.972C107.711 236.749 107.089 237.371 106.312 237.371Z"
        fill="#C2D0AE"
      />
      <Path
        d="M184.315 202.696H88.9368C86.7895 202.696 85 200.906 85 198.757V174.94C85 172.791 86.7895 171 88.9368 171H184.315C186.463 171 188.252 172.791 188.252 174.94V198.578C188.252 200.906 186.463 202.696 184.315 202.696Z"
        fill="#202325"
        stroke="#373D41"
        strokeWidth="3.94578"
        strokeMiterlimit="10"
      />
      <Path
        d="M209.189 194.746C211.244 194.746 212.887 196.39 212.887 198.446V222.292C212.887 227.637 208.779 231.749 203.438 231.749H187.825C186.182 231.749 184.539 231.338 183.717 230.104C180.019 226.815 172.213 220.237 170.57 217.77C170.159 216.948 169.748 216.536 169.748 215.714V210.369C169.748 207.08 172.213 204.613 175.5 204.613H177.554V214.892H178.787V193.512C178.787 191.457 180.43 189.812 182.484 189.812C184.539 189.812 186.182 191.457 186.182 193.512V197.624H187.414V192.279C187.414 190.223 189.058 188.579 191.112 188.579C193.166 188.579 194.81 190.223 194.81 192.279V197.624H196.453V194.746C196.453 192.69 198.097 191.046 200.151 191.046C202.205 191.046 203.848 192.69 203.848 194.746V197.624H205.492C205.903 195.979 207.135 194.746 209.189 194.746Z"
        fill="white"
        stroke="#1A7079"
        strokeWidth="3.94578"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M174.434 189.378H96.7707C95.8759 189.378 95.1602 188.662 95.1602 187.766V185.617C95.1602 184.722 95.8759 184.006 96.7707 184.006H174.434C175.328 184.006 176.044 184.722 176.044 185.617V187.766C176.044 188.662 175.328 189.378 174.434 189.378Z"
        fill="#A9B7B9"
      />
    </G>
  </Svg>
)
