import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import {
  Appointment,
  InsuranceBerryStreetAppointmentMeetingStatus,
  InsuranceBerryStreetAppointmentType,
} from '@src/types'
import {
  DEFAULT_VIDEO_CALL_INFO,
  VIDEO_CALL_CARD_DATE_FORMAT,
  VIDEO_CALL_CARD_TIME_FORMAT,
} from '@src/screens/NutritionistHub/constants'
import { useVideoCallDurations } from '@screens/NutritionistHub/hooks/useVideoCallDurations.ts'
import { isCallConfirmed } from '@screens/NutritionistHub/utils.ts'

export interface CallCardProps {
  appointment: Appointment
}

const meetingStatusTextLookup = {
  [InsuranceBerryStreetAppointmentMeetingStatus.Occurred]: 'Completed',
  [InsuranceBerryStreetAppointmentMeetingStatus.NoShow]: 'No-show',
  [InsuranceBerryStreetAppointmentMeetingStatus.Cancelled]: 'Cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.LateCancellation]: 'Late Cancellation',
  [InsuranceBerryStreetAppointmentMeetingStatus.Rescheduled]: 'Rescheduled',
  [InsuranceBerryStreetAppointmentMeetingStatus.PendingReschedule]: 'Rescheduled',
}

const meetingStatusCssLookup: {
  [key in InsuranceBerryStreetAppointmentMeetingStatus]: 'occurred' | 'cancelled' | 'rescheduled'
} = {
  [InsuranceBerryStreetAppointmentMeetingStatus.Occurred]: 'occurred',
  [InsuranceBerryStreetAppointmentMeetingStatus.Cancelled]: 'cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.LateCancellation]: 'cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.NoShow]: 'cancelled',
  [InsuranceBerryStreetAppointmentMeetingStatus.Rescheduled]: 'rescheduled',
  [InsuranceBerryStreetAppointmentMeetingStatus.PendingReschedule]: 'rescheduled',
}

export const CallCard = ({ appointment }: CallCardProps) => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyles)
  const { getVideoCallDuration } = useVideoCallDurations()
  const {
    title,
    meetingAt,
    meetingStatus,
    nutritionistName,
    appointmentType,
    recurrent,
  } = appointment
  const inFuture = meetingAt && moment(meetingAt).isAfter(moment())
  const borderColor = meetingStatus
    ? styles[`${meetingStatusCssLookup[meetingStatus]}Border`]
    : null

  const handleOnPress = () => {
    if (inFuture) {
      const props = {
        ...appointment,
        date: meetingAt,
        title: title || DEFAULT_VIDEO_CALL_INFO.title,
        appointmentType: appointmentType || InsuranceBerryStreetAppointmentType.Initial,
      }
      navigation.navigate(
        isCallConfirmed(appointment) ? 'VideoCallConfirmedModal' : 'VideoCallInReviewModal',
        props,
      )
    }
  }

  return (
    <TouchableOpacity
      accessibilityLabel="appointment"
      style={[styles.container, borderColor]}
      onPress={handleOnPress}
      activeOpacity={inFuture ? 0.2 : 1}
    >
      <View style={styles.title}>
        <Text type="regular" bold>
          {title || ''} with {nutritionistName?.split(' ')[0] || ''}
          {meetingStatus ? ` (${meetingStatusTextLookup[meetingStatus]})` : ''}
          {!meetingStatus && recurrent ? ' (Recurring)' : ''}
        </Text>
        {inFuture && <Icon name="dots-three-vertical" weight="bold" />}
      </View>
      <Text type="small" style={styles.time}>
        {moment(meetingAt).format(VIDEO_CALL_CARD_DATE_FORMAT)} -{' '}
        {moment(meetingAt)
          .add(getVideoCallDuration(appointmentType), 'minutes')
          .format(VIDEO_CALL_CARD_TIME_FORMAT)}
      </Text>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    borderLeftWidth: 3,
    borderColor: 'theme.success.base',
    paddingLeft: 12,
  },
  title: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  occurredBorder: {
    borderColor: 'theme.success.darkest',
  },
  cancelledBorder: {
    borderColor: 'theme.error.base',
  },
  rescheduledBorder: {
    borderColor: 'theme.tooltip.textSecondary',
  },
  time: {
    marginTop: 12,
    color: 'theme.text.secondary',
  },
})
