import { gql } from '@apollo/client'
import { CORE_INSURANCE_POLICY_FIELDS } from '@src/graphql/fragments/insurancePolicy'

const SUBMIT_INSURANCE_POLICY = gql`
  ${CORE_INSURANCE_POLICY_FIELDS}
  mutation submitInsurancePolicy {
    submitInsurancePolicy {
      ...CoreInsurancePolicyFields
    }
  }
`

export default SUBMIT_INSURANCE_POLICY
