import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dictionary, groupBy, uniqueId } from 'lodash'
import { useNavigation } from '@react-navigation/core'
import moment from 'moment'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Timeline } from '@src/components/Timeline/Timeline'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { historyObjectsForDateSelector, historySelector } from '@src/selectors/history'
import { Calendar, Sort } from '@src/models/app.types'
import { isModified } from '@src/navigation/utils'
import { HistoryItemType } from '@src/screens/History/models/history.types'
import { HistoryList } from '@src/screens/History/components/HistoryList'
import { OCCURRED_AT } from '@src/containers/ListFilter/utils'
import { Logger, LoggerScreens, Messages } from '@src/config'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { useChartInteractions } from '@src/screens/Events/utils/useChartInteractions'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { useDispatchAsync } from '@src/utils'
import { dexcomConnectionSelector } from '@src/selectors/app'
import { eventsCalendarSelector, eventsChartsSelector } from '@src/selectors/events'
import { useHasLargeScreen } from '@src/config/device'
import { IconButton } from '@components/base'
import { PLUS_MENU_BOTTOM_MARGIN } from '@src/screens/Events/containers/Events'
import { AppRouteProp } from '@src/navigation/types'
import { buildSections } from '../utils/utils'
import { HISTORY_DEFAULT_SORT_STATE } from '../models/constants'

export interface HistoryProps {
  items: HistoryItemType[]
  sort: Sort
  calendar: Calendar
  filters: Dictionary<string[]>
}

export const HistoryContainer = () => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation()
  const hasLargeScreen = useHasLargeScreen()
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)
  const areDailyActionsEnabled = useFeatureFlag(Feature.UserDailyActions)

  const route = useRoute<AppRouteProp<'History'>>()
  const resetFilters = route.params?.resetFilters
  const isDataLoading = useRef(false)
  const dexcomConnection = useSelector(dexcomConnectionSelector)
  const { items, sort, filters, calendar } = useSelector(historySelector)
  const eventsCalendar = useSelector(eventsCalendarSelector)
  const { startDate, endDate } = areDailyActionsEnabled ? eventsCalendar : calendar

  const [query, setQuery] = useState<string>(sort.query || '')
  const [showSearchBar, setShowSearchBar] = useState(false)

  useFocusEffect(
    useCallback(() => {
      if (resetFilters && areDailyActionsEnabled) {
        navigation.setParams({ resetFilters: undefined }) // make sure it runs only once

        const today = moment().format(DATE_FORMAT)
        dispatch({
          type: 'events/changeDateRange',
          payload: {
            startDate: today,
            endDate: today,
          },
        })
        dispatch({
          type: 'history/updateSort',
          payload: {
            ...HISTORY_DEFAULT_SORT_STATE,
          },
        })
      }
    }, [areDailyActionsEnabled, dispatch, navigation, resetFilters]),
  )

  useEffect(() => {
    setQuery(sort.query)
  }, [sort.query])

  const onQueryUpdated = (text: string) => {
    setQuery(text)
  }

  const onSearchUpdated = () => {
    dispatch({
      type: 'history/updateSort',
      payload: {
        ...sort,
        query,
      },
    })

    Logger.sendInfo(LoggerScreens.History, Messages.GeneralSearch, {
      query,
    })
  }

  const refreshData = useCallback(
    async (useCache: boolean) => {
      if (isDataLoading.current) {
        return
      }

      const promises = [
        dispatchAsync({
          type: 'events/fetchNutrition',
          useCache,
        }),
        dispatchAsync({
          type: 'events/fetchCharts',
          useCache,
        }),
      ]

      isDataLoading.current = true

      try {
        await Promise.all(promises)
      } finally {
        isDataLoading.current = false
      }
    },
    [dispatchAsync],
  )

  const shouldRefreshWithoutCacheOnFocus =
    moment(startDate).isSame(moment().format(DATE_FORMAT)) && !!dexcomConnection

  const loadDataOnFocus = useCallback(() => {
    if (!areDailyActionsEnabled) {
      return
    }
    refreshData(!shouldRefreshWithoutCacheOnFocus)
  }, [areDailyActionsEnabled, refreshData, shouldRefreshWithoutCacheOnFocus])

  useFocusEffect(loadDataOnFocus)

  const sections = useMemo(() => buildSections(items), [items])

  const rightAccessories: NavigationBarAccessory[] = [
    {
      iconDescriptor: { pack: 'eva', name: 'options-2' },
      testID: 'History/Filter',
      iconTintColor: isModified(sort, 'history') ? 'theme.primary.base' : undefined,
      onPress: () => navigation.navigate('HistoryEdit'),
      accessibilityLabel: 'History Settings',
    },
  ]

  if (areDailyActionsEnabled) {
    rightAccessories.unshift({
      iconDescriptor: { pack: 'eva', name: 'search' },
      testID: 'History/Search',
      iconTintColor: showSearchBar ? 'theme.primary.base' : undefined,
      onPress: () => setShowSearchBar(!showSearchBar),
      accessibilityLabel: 'Search',
    })
  }

  const groupedEvents: any[] = useMemo(() => {
    const dateGroups = groupBy(items, (i) => moment(i.occurredAt).format(DATE_FORMAT))
    return Object.entries(dateGroups).map(([date, data], index) => ({
      id: uniqueId(date),
      index,
      title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
      occurredAt: moment(date),
      data,
    }))
  }, [items])

  const {
    listRef,
    primaryChartRef,
    secondaryChartRef,
    highlightedIndex,
    onChartLoadEnd,
    onChartHover,
    onEventListViewableItemsChanged,
    onEventListDragStart,
    onEventListMomentumScrollEnd,
  } = useChartInteractions(groupedEvents)

  return (
    <AccountAvatarNavigationBar
      rightAccessories={rightAccessories}
      hideLeftIcon
      navigationBarProps={{
        dateSelectorType: areDailyActionsEnabled ? 'events' : 'app',
      }}
    >
      <HistoryList
        ChartComponent={
          areDailyActionsEnabled ? (
            <Timeline
              primaryChartRef={primaryChartRef}
              secondaryChartRef={secondaryChartRef}
              onChartHover={onChartHover}
              onChartLoadEnd={onChartLoadEnd}
              hideMultiSlider
              chartsSelector={eventsChartsSelector<HistoryItemType>(historyObjectsForDateSelector)}
            />
          ) : null
        }
        listRef={listRef}
        items={sections}
        name="history"
        dataPath="events.events"
        filter={{ scope: 'history' }}
        sort={sort}
        pagination={{ type: sort.orderBy === OCCURRED_AT ? 'date' : 'page' }}
        useCache
        onDataLoadStart={refreshData}
        filters={filters}
        query={query}
        highlightedIndex={highlightedIndex}
        calendar={{ startDate, endDate }}
        onQueryUpdated={onQueryUpdated}
        onSearchUpdated={onSearchUpdated}
        onViewableItemsChanged={onEventListViewableItemsChanged}
        onDragStart={onEventListDragStart}
        onMomentumScrollEnd={onEventListMomentumScrollEnd}
        showSearchBar={areDailyActionsEnabled ? showSearchBar : true}
      />
      {areDailyActionsEnabled ? (
        <IconButton
          accessibilityLabel="Open Menu"
          iconName="plus"
          size="l"
          style={[
            styles.toggle,
            { bottom: hasLargeScreen && insets.bottom ? insets.bottom : PLUS_MENU_BOTTOM_MARGIN },
          ]}
          type="primary"
          onPress={() => navigation.navigate('ShowActions')}
        />
      ) : null}
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  toggle: {
    position: 'absolute',
    right: 20,
  },
})
