import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { markModalAsShown, shouldShowModalOnce } from '@src/hooks/useOneTimeVisitedState'
import Storage from '@src/utils/storage'
import {
  InsuranceBerryStreetAppointmentChargeType,
  InsuranceBerryStreetAppointmentStatus,
  InsuranceBerryStreetAppointmentType,
} from '@src/types'
import { User } from '@src/utils'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import {
  insurancePolicySelector,
  latestUserAppointmentSelector,
} from '../models/nutritionistHub.selectors'

const defaultNoShow = {
  shouldShow: () => false,
  markAsShown: () => {},
  getRoute: () => ({ name: '', params: undefined }),
}

const useClaimFreeVideoCallPromptInteractions = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const latestAppointment = useSelector(latestUserAppointmentSelector)

  return useMemo(() => {
    if (!insurancePolicy || !latestAppointment) {
      return defaultNoShow
    }

    const modalKey = `${Storage.CLAIM_FREE_VIDEO_CALL_MODAL_VISITED_KEY}_${insurancePolicy.id}`

    return {
      shouldShow: () =>
        shouldShowModalOnce(modalKey) &&
        latestAppointment.status === InsuranceBerryStreetAppointmentStatus.SchedulingPending &&
        latestAppointment.chargeType === InsuranceBerryStreetAppointmentChargeType.Free,
      markAsShown: () => markModalAsShown(modalKey),
      getRoute: () => ({
        name: 'ClaimFreeVideoCallModal',
        params: {
          appointmentId: latestAppointment.id,
          appointmentType:
            latestAppointment.appointmentType || InsuranceBerryStreetAppointmentType.NoneCovered,
        },
      }),
    }
  }, [insurancePolicy, latestAppointment])
}

export const useNutritionistHubModals = () => {
  const navigation = useNavigation()

  const {
    shouldShow: shouldShowClaimFreeVideoCallPrompt,
    markAsShown: markClaimFreeVideoPrompt,
    getRoute: getClaimFreeVideoPromptRoute,
  } = useClaimFreeVideoCallPromptInteractions()

  const { getRequiredPolicyConsents } = useGetRequiredPolicyConsents()

  const handleModals = useCallback(async () => {
    if (User.isImpersonating()) {
      // using the useImpersonationContext leads to a race condition that shows the welcome tour during impersonation
      return
    }

    const showClaimFreeVideoCallPrompt = shouldShowClaimFreeVideoCallPrompt()

    const routes: { name: string; params?: any }[] = []

    if (showClaimFreeVideoCallPrompt) {
      routes.push(getClaimFreeVideoPromptRoute())
      markClaimFreeVideoPrompt()
    }

    // Routes will be pushed in reverse order and we always want the consent modals to show first if present.
    // So, this should be the last item added to the stack.
    //
    const requiredPolicies = await getRequiredPolicyConsents()
    if (requiredPolicies.length > 0) {
      routes.push({ name: 'RequiredPolicyConsent' })
    }

    routes.forEach(({ name, params }) => {
      navigation.navigate(name as any, params)
    })
  }, [
    navigation,
    getRequiredPolicyConsents,
    shouldShowClaimFreeVideoCallPrompt,
    markClaimFreeVideoPrompt,
    getClaimFreeVideoPromptRoute,
  ])

  useFocusEffect(
    useCallback(() => {
      handleModals()
    }, [handleModals]),
  )
}
