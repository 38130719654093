import React, { useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute } from '@react-navigation/core'
import { Controller, useForm } from 'react-hook-form'
import { SafeAreaView } from 'react-native-safe-area-context'
import moment from 'moment'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@style/service'
import { Button, Input } from '@components/base'
import { CircleIcon, ScrollViewWithFade, SelectDateTime } from '@components'
import { AppRouteProp } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { WellnessTracking } from '@screens/JournalEntries/components/WellnessTracking'
import { updateJournalEngagementPromptConfig } from '@screens/JournalEntries/utils'
import { JournalMeasurement } from '@screens/JournalEntries/types'
import { journalMeasurementTypesSelector } from '@selectors/app'
import { Global } from '@utils'
import { TIME_FORMAT } from '@utils/global'
import { useCancelModal } from '@utils/hooks'
import { useGoBack } from '@utils/navigation'

const DATE_TIME_DISPLAY_FORMAT = 'MMM DD, YYYY - h:mm a'

export const JournalEntryModal = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()

  const route = useRoute<AppRouteProp<'AddJournalEntry'>>()
  const { item: itemParam, id, body, occurredAt } = route.params || {}

  const measurementTypes = useSelector(journalMeasurementTypesSelector)

  const getItemFromRouteParams = () => {
    if (itemParam) {
      return itemParam
    }

    return {
      id,
      body,
      occurredAt,
      measurements: [],
    }
  }

  const item = getItemFromRouteParams()

  const [time, setTime] = useState(moment(item.occurredAt).format(TIME_FORMAT))
  const [measurements, setMeasurements] = useState(
    measurementTypes.map(({ type }) => {
      const measurement = item.measurements?.find((measurement) => measurement.type === type)

      return {
        type,
        id: measurement?.id,
        value: measurement?.value ?? 0,
      } as JournalMeasurement
    }),
  )

  const isSubmitting = useRef(false)

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<{ note: string }>({
    mode: 'onBlur',
    defaultValues: {
      note: item.body ?? '',
    },
  })

  const handleMeasurementChange = (newMeasurement: JournalMeasurement) => {
    setMeasurements((prevValues) =>
      prevValues.map((measurement) =>
        measurement.type === newMeasurement.type ? newMeasurement : measurement,
      ),
    )
  }

  const onSaveButtonPress = handleSubmit(({ note }) => {
    if (isSubmitting.current) {
      return
    }

    isSubmitting.current = true

    const payload = {
      id: item.id,
      body: note,
      time: Global.formatTime(time),
      measurements,

      // fake data for optimistic reducer
      __typename: 'JournalEntry',
      createdAt: moment().format(),
      occurredAt: Global.formatTime(time),
      title: 'Journal',
      fake: true,
    }

    dispatch({
      payload,
      type: payload.id ? 'journalEntries/update' : 'journalEntries/create',
      success: () => {
        updateJournalEngagementPromptConfig({ promptLastDisplayDate: moment().toISOString() })
        goBack()
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  })

  const openCancelModal = useCancelModal({
    goBack,
    isModified: isDirty,
    itemName: 'journal entry',
  })

  const isNewJournalEntry = !item?.id

  return (
    <NavigationContainer
      title={isNewJournalEntry ? 'Journal' : 'Edit Journal'}
      goBack={isNewJournalEntry ? openCancelModal : goBack}
      leftIcon={isNewJournalEntry ? { pack: 'eva', name: 'close' } : undefined}
    >
      <ScrollViewWithFade style={styles.container}>
        <SafeAreaView edges={['bottom']}>
          <View style={styles.content}>
            <View style={styles.timePicker}>
              <CircleIcon name="pencil-line" style={styles.icon} />
              <SelectDateTime
                inputProps={{
                  label: 'Time',
                  value: moment(time, TIME_FORMAT).format(DATE_TIME_DISPLAY_FORMAT),
                }}
                pickerProps={{
                  mode: 'datetime',
                  date: Global.toLocalTimezoneDate(time),
                }}
                onChange={(time) => setTime(moment(time).local().format(TIME_FORMAT))}
                style={styles.input}
              />
            </View>
            <Controller
              control={control}
              name="note"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Note"
                  placeholder="Provide additional details on what affected your mood"
                  numberOfLines={3}
                  style={styles.input}
                />
              )}
            />
          </View>
          <Divider style={styles.divider} />
          <WellnessTracking measurements={measurements} onChange={handleMeasurementChange} />
          <Button
            accessibilityLabel="Save"
            size="block"
            type="primary"
            style={styles.button}
            onPress={onSaveButtonPress}
          >
            Save
          </Button>
        </SafeAreaView>
      </ScrollViewWithFade>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    paddingHorizontal: 16,
  },
  timePicker: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  input: {
    flex: 1,
    marginTop: 16,
  },
  divider: {
    height: 8,
    marginTop: 20,
    backgroundColor: 'theme.surface.base2',
  },
  button: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
