import React from 'react'
import { Keyboard, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { get } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { NavigationContainer } from '@screens/Common/containers'
import { clientConfigStoreStateSelector } from '@selectors/app'
import { useSnack } from '@utils'
import { HealthMetricType, HealthMetricsKind, HealthMetricsParam } from '@src/types'
import { ScrollableAvoidKeyboard, SelectDateTime, useActionBottomSheet } from '@src/components'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { AppStackParamList } from '@src/navigation/types'
import { Analytics, CustomEventTypes, ErrorMessages } from '@src/config'
import { useMeasurementHealthMetricsUnits } from '../hooks'
import { LabResultsFormInput } from '../components/LabResultsFormInput'
import { isInputValueValid } from '../utils'

const preparePayload = (
  formData: { [key: string]: any },
  biomarkerHealthMetricsKeys: string[],
  measurementHealthMetricsKeys: string[],
) => {
  const biomarkers = Object.fromEntries(
    Object.entries(formData).filter(([key]) => biomarkerHealthMetricsKeys.includes(key)),
  )
  const measurements = Object.fromEntries(
    Object.entries(formData).filter(([key]) => measurementHealthMetricsKeys.includes(key)),
  )

  const healthMetricsData = Object.entries(biomarkers).map((entry) => ({
    kind: entry[0],
    value: Number.parseFloat(entry[1]),
  }))

  const measurementsData = Object.entries(measurements).map((entry) => {
    if (entry[0] === HealthMetricsParam.BloodPressure) {
      return {
        type: entry[0],
        values: {
          systolic: Number.parseFloat(entry[1].systolic),
          diastolic: Number.parseFloat(entry[1].diastolic),
        },
      }
    }

    return { type: entry[0], values: { value: Number.parseFloat(entry[1]) } }
  })

  const date = formData['date']

  return {
    date,
    healthMetricsData,
    measurementsData,
  }
}

export const AddLabResults = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const clientConfig = useSelector(clientConfigStoreStateSelector)
  const showSnack = useSnack()
  const healthMetricsConfig = get(clientConfig, 'healthMetrics', [])
  const dispatch = useDispatch()
  const showActionBottomSheet = useActionBottomSheet()
  const insets = useSafeAreaInsets()

  const measurementHealthMetricsKeys = healthMetricsConfig.flatMap((metric) => {
    if (metric.kind === HealthMetricsKind.Measurement) {
      return metric.key.toString()
    }
    return []
  })

  const biomarkerHealthMetricsKeys = healthMetricsConfig.flatMap((metric) => {
    if (metric.kind === HealthMetricsKind.Biomarker) {
      return metric.key.toString()
    }
    return []
  })

  const measurementHealthMetricsUnits = useMeasurementHealthMetricsUnits()

  const { control, handleSubmit } = useForm({})

  const submitResults = handleSubmit((formData) => {
    Keyboard.dismiss()

    const data = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        if (key === HealthMetricsParam.BloodPressure) {
          return isInputValueValid(value?.systolic) && isInputValueValid(value?.diastolic)
        }

        return isInputValueValid(value)
      }),
    )

    if (Object.keys(data).length === 1) {
      showActionBottomSheet({
        title: 'No data entered',
        body: 'Please enter at least one lab result to submit',
        primaryButton: {
          text: 'Dismiss',
        },
      })
      return
    }

    const payload = preparePayload(data, biomarkerHealthMetricsKeys, measurementHealthMetricsKeys)

    dispatch({
      payload,
      type: 'labResults/submit',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
      success: () => {
        Analytics.track(CustomEventTypes.OutcomesTrackingLabResultsSubmitted)
        showActionBottomSheet({
          title: 'Do you want to submit another lab result?',
          body:
            'Finish uploading your lab results or submit an additional one from a different date',
          primaryButton: {
            text: 'Finish',
            onPress: () => navigation.navigate('LabResultsSubmittedScreen'),
          },
          secondaryButton: {
            text: 'Submit another lab result',
            onPress: () => {
              navigation.pop()
              navigation.navigate('AddLabResults')
            },
          },
        })
      },
    })
  })

  const handleCancelPress = () => {
    Analytics.track(CustomEventTypes.OutcomesTrackingNewLabResultsCancelTapped)
    navigation.navigate('SetReminderModal', {
      dismissButtonText: 'Go Back',
      goBackOnDismiss: true,
    })
  }

  return (
    <NavigationContainer
      style={styles.container}
      title="New Lab Results"
      goBack={handleCancelPress}
    >
      <ScrollableAvoidKeyboard
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
      >
        <Text type="regular" style={styles.descriptionText}>
          Help our nutritionists better assess your health by sharing your health outcomes and keep
          track of your trends in the app.
        </Text>

        <Controller
          control={control}
          name="date"
          rules={{ required: 'Please select a date' }}
          render={({ field, fieldState }) => (
            <SelectDateTime
              inputProps={{
                label: 'Date of the lab test',
                accessoryLeft: <Icon name="calendar-blank" style={styles.icon} />,
                value: field.value
                  ? moment.utc(field.value).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)
                  : undefined,
                hasError: fieldState.invalid,
                errorText: fieldState.error?.message,
              }}
              pickerProps={{
                mode: 'date',
                maximumDate: new Date(),
                timeZoneName: 'UTC',
              }}
              onChange={(value) => field.onChange(moment(value).format(DATE_FORMAT))}
              style={styles.datePicker}
            />
          )}
        />

        <Text type="large" bold>
          Biomarkers
        </Text>
        <View style={styles.form}>
          {healthMetricsConfig.map((healthMetric: HealthMetricType) => (
            <LabResultsFormInput
              key={healthMetric.key}
              control={control}
              metricKey={healthMetric.key}
              label={healthMetric.label}
              unit={
                (healthMetric.unit || measurementHealthMetricsUnits[healthMetric.key]) ?? undefined
              }
            />
          ))}
        </View>
      </ScrollableAvoidKeyboard>
      <View style={styles.buttons}>
        <Button
          accessibilityLabel="Cancel"
          type="outline"
          size="block"
          style={styles.cancelButton}
          onPress={handleCancelPress}
        >
          Cancel
        </Button>
        <View style={styles.buttonSpacer} />
        <Button
          accessibilityLabel="Save"
          type="primary"
          size="block"
          style={styles.saveButton}
          onPress={submitResults}
        >
          Save
        </Button>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    padding: 16,
  },
  icon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  form: {
    flex: 1,
  },
  descriptionText: {
    marginBottom: 24,
  },
  datePicker: {
    marginBottom: 24,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 32,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  cancelButton: {
    flex: 1,
  },
  saveButton: {
    flex: 1.7,
  },
  buttonSpacer: {
    width: 8,
    height: 8,
  },
})
