import { gql } from '@apollo/client'

export const FETCH_ALL_AVAILABILITIES = gql`
  query schedulingAllAvailabilities {
    schedulingAllAvailabilities {
      dayOfWeek
      appointmentType
      rangeStartAt
      rangeEndAt
    }
  }
`
