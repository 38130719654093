import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { List } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { NavigationContainer } from '@screens/Common/containers'
import { useVisitedTutorial } from '@screens/Education/hooks'
import { TutorialKey } from '@screens/Education/types'
import { educationCollectionsSelector } from '@selectors/app'
import { EducationCollection } from '@src/types'

export const GlucoseEducation = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const collections = useSelector(educationCollectionsSelector)

  useVisitedTutorial(TutorialKey.GlucoseEducation)

  const renderItem = ({ item }: { item: EducationCollection }) => {
    return (
      <TouchableOpacity
        accessibilityLabel={item.title}
        style={styles.cell}
        onPress={() => navigation.navigate('FAQsArticles', { articles: item.articles })}
      >
        <Text type="large">{item.title}</Text>
        <Text type="small" style={styles.subtitle}>
          {item.subtitle}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <NavigationContainer title="Frequently Asked Questions">
      <List
        data={collections}
        keyExtractor={(item) => item.title}
        style={styles.container}
        contentContainerStyle={{ paddingBottom: (insets?.bottom || 0) + 16 }}
        renderItem={renderItem}
      />
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  cell: {
    flex: 1,
    padding: 20,
    marginHorizontal: 16,
    marginVertical: 8,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base1',
  },
  subtitle: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
})
