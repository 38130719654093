import React from 'react'
import moment from 'moment'
import { ScrollView, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  Libre3BluetoothManagerConnectionState,
  PatchInfoData,
  PatchState,
  SensorTypes,
} from 'react-native-freestyle-libre'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { Section } from '@components'
import { MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { Sensor } from '@src/types'
import { FORMATTED_BLUETOOTH_CONNECTION_STATE } from '@src/utils/bluetooth'
import { NoSensorView } from './UserSettings/NoSensorView'

interface SensorSettingsProps {
  sensor?: Sensor | null
  onIconPress: () => void
  activateDisabled: boolean
  onActivateSensorPress: () => void
  onCalibrateSensorPress: () => void
  onGetMoreSensorsPress: () => void
  bluetoothConnectionState: Libre3BluetoothManagerConnectionState
  patchInfo: PatchInfoData | null
}

const DAYS_UNTIL_EXPIRATION = 3

const getSensorExpirationText = (daysRemaining: number) => {
  if (daysRemaining <= 0) {
    return '-'
  }

  if (daysRemaining === 1) {
    return 'Expires today'
  }

  return `${daysRemaining} days`
}

const FORMATTED_PATCH_INFO_STATE = {
  [PatchState.Manufacturing]: 'Manufacturing',
  [PatchState.Storage]: 'Out of package, not activated',
  [PatchState.InsertionDetection]: 'Detecting insertion...',
  [PatchState.InsertionFailed]: 'Insertion failed',
  [PatchState.Paired]: 'Paired',
  [PatchState.Expired]: 'Expired',
  [PatchState.Terminated]: 'Terminated',
  [PatchState.Error]: 'Error',
  [PatchState.ErrorTerminated]: 'Error - terminated',
}

const ACTIVATE_NEW_SENSOR_TEXT = 'Activate a New Sensor'
const GET_MORE_SENSORS_TEXT = 'Get More Sensors'

export const SensorSettings = ({
  sensor,
  onIconPress,
  activateDisabled,
  bluetoothConnectionState,
  onActivateSensorPress,
  onCalibrateSensorPress,
  onGetMoreSensorsPress,
  patchInfo,
}: SensorSettingsProps) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  if (!sensor) {
    return (
      <NoSensorView
        activateDisabled={activateDisabled}
        onActivateSensorPress={onActivateSensorPress}
        onGetMoreSensorsPress={onGetMoreSensorsPress}
        getMoreSensorsText={GET_MORE_SENSORS_TEXT}
      />
    )
  }

  // On the last sensor day, the moment().diff call will return zero. We want to
  // show one to help the user understanding it's the last sensor day
  const daysRemaining = sensor.expirationTime
    ? moment(sensor.expirationTime).diff(moment(), 'days') + 1
    : 0

  const baselineAdjustment = sensor.baselineAdjustment || 0

  const isExpiring = daysRemaining <= DAYS_UNTIL_EXPIRATION

  const getSensorState = () => {
    if (patchInfo) {
      return FORMATTED_PATCH_INFO_STATE[patchInfo.patchState]
    }

    if (sensor.lastStatusChange?.failure) {
      return FORMATTED_PATCH_INFO_STATE[PatchState.Error]
    }

    return 'N/A'
  }

  return (
    <ScrollView
      contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
      style={styles.container}
    >
      <View style={styles.content}>
        <Section title="Active Sensor Type">
          <Text type="regular" style={styles.text}>
            {sensor.description}
          </Text>
        </Section>
        <Section title="Serial Number">
          <Text type="regular" style={styles.text}>
            {sensor?.serialNumber || '-'}
          </Text>
        </Section>
        <Divider />
        <Section title="Activation Date">
          <Text type="regular" style={styles.text}>
            {sensor?.activationTime
              ? moment(sensor.activationTime).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)
              : '-'}
          </Text>
        </Section>
        <Divider />
        <Section title="Days Until Expiration">
          <Text type="regular" style={isExpiring ? styles.expiringSensorText : styles.text}>
            {getSensorExpirationText(daysRemaining)}
          </Text>
          {isExpiring && <Icon name="warning" style={styles.expiringSensorIcon} />}
        </Section>
        <Divider />
        {sensor.model === SensorTypes.Libre3 && (
          <>
            <Section title="Connection state">
              <Text type="regular" style={styles.text}>
                {FORMATTED_BLUETOOTH_CONNECTION_STATE[bluetoothConnectionState]}
              </Text>
            </Section>
            <Divider />
            <Section title="Sensor state">
              <Text type="regular" style={styles.text}>
                {getSensorState()}
              </Text>
            </Section>
            <Divider />
          </>
        )}
        <Section title="Manual Calibration" leftStyle={styles.sensorInfoTitle}>
          <TouchableOpacity
            accessibilityLabel="Calibration Info"
            style={styles.sensorInfo}
            onPress={onIconPress}
          >
            <Icon name="info" style={styles.infoIcon} />
          </TouchableOpacity>
          <TouchableWithoutFeedback
            accessibilityLabel="Calibrate sensor"
            onPress={onCalibrateSensorPress}
            disabled={daysRemaining === 0}
          >
            <View style={styles.textContainer}>
              <Text type="regular">{baselineAdjustment}</Text>
            </View>
          </TouchableWithoutFeedback>
        </Section>
        <Divider />
      </View>
      <View style={styles.buttonsWrapper}>
        <Button
          type={isExpiring ? 'primary' : 'outline'}
          size="block"
          disabled={activateDisabled}
          onPress={onActivateSensorPress}
          accessibilityLabel={ACTIVATE_NEW_SENSOR_TEXT}
        >
          {ACTIVATE_NEW_SENSOR_TEXT}
        </Button>
        {onGetMoreSensorsPress && (
          <Button
            accessibilityLabel={GET_MORE_SENSORS_TEXT}
            type="transparent"
            size="block"
            style={styles.getSensorsButton}
            onPress={onGetMoreSensorsPress}
          >
            {GET_MORE_SENSORS_TEXT}
          </Button>
        )}
      </View>
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
  },
  text: {
    flex: 1,
    textAlign: 'right',
  },
  expiringSensorText: {
    color: 'theme.warning.base',
  },
  expiringSensorIcon: {
    marginLeft: 8,
    color: 'theme.warning.base',
  },
  sensorInfoTitle: {
    flex: 0,
  },
  sensorInfo: {
    flex: 1,
  },
  infoIcon: {
    width: 20,
    height: 20,
  },
  textContainer: {
    justifyContent: 'center',
    width: 80,
    height: 40,
    paddingHorizontal: 20,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
  },
  buttonsWrapper: {
    marginTop: 24,
    marginHorizontal: 16,
  },
  getSensorsButton: {
    marginTop: 8,
  },
})
