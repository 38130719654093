import { omit } from 'lodash'
import { User } from '@utils'
import { User as UserType } from '@src/types'
import { Model } from '@models'
import { UserStoreState } from '@src/models/user.types'
import { Login } from '@src/models/app.types'
import { ALL_USERS_AUTOCOMPLETE } from '../graphql/allUsersAutocomplete'
import { CREATE_USER } from '../graphql/createUser'
import { CREATE_THIRD_PARTY_USER } from '../graphql/createThirdPartyUser'
import { FETCH_USER } from '../graphql/fetchUser'
import { REGISTER_NOTIFICATION_TOKEN } from '../graphql/registerNotificationToken'
import { RESET_PASSWORD } from '../graphql/resetPassword'
import { RESET_PASSWORD_REQUEST } from '../graphql/resetPasswordRequest'
import { UPDATE_USER } from '../graphql/updateUser'
import commonReducers from './commonReducers'

const updateEffect = (name: string) => {
  return Model.buildEffect({
    name,
    query: UPDATE_USER,
    dataPath: 'updateUser',
    onSuccess: (user: UserType) => {
      User.onUpdate(user)
    },
    reducers: [
      {
        name: 'app/updateUserState',
        payload: (response: any) => response,
      },
    ],
  })
}

export default class Users {
  namespace = 'users'

  state: UserStoreState = {
    ...Model.defaultState,

    autocomplete: [],
  }

  effects = {
    autocomplete: Model.buildEffect({
      name: `${this.namespace}/autocomplete`,
      query: ALL_USERS_AUTOCOMPLETE,
      dataPath: 'allUsersAutocomplete',
      reducers: [{ name: 'fetchList', dataPath: 'users', storePath: 'autocomplete' }],
    }),

    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: FETCH_USER,
      dataPath: 'currentUser',
      isSuccess: (response: any) => response?.id,
      onSuccess(user: UserType) {
        User.onFetch(user)
      },
      reducers: [
        {
          name: 'app/updateUserState',
          payload: (response: any) => omit(response, 'refillConsentNeeded', 'settings', 'sensor'),
        },
        {
          name: 'policyConsents/setRefillConsentNeeded',
          payload: (response: any) => response.refillConsentNeeded,
        },
        {
          name: 'settings/updateSettings',
          payload: (response: any) => response.settings,
        },
        {
          name: 'sensor/updateSensor',
          payload: (response: any) => response.sensor,
        },
      ],
    }),

    create: Model.buildEffect({
      name: `${this.namespace}/create`,
      query: CREATE_USER,
      dataPath: 'createUser',
      isSuccess: (response: any) => response?.success,
      //eslint-disable-next-line
      *onSuccess(login: Login, { call }: any) {
        yield call(User.onCreate, login)
      },
      reducers: [{ name: 'app/updateLoginState' }],
    }),

    createThirdParty: Model.buildEffect({
      name: `${this.namespace}/createThirdParty`,
      query: CREATE_THIRD_PARTY_USER,
      dataPath: 'createThirdPartyUser',
      isSuccess: (response: any) => response?.success,
      //eslint-disable-next-line
      *onSuccess(login: Login, { call }: any) {
        yield call(User.onCreate, login)
      },
      reducers: [{ name: 'app/updateLoginState' }],
    }),

    update: updateEffect(`${this.namespace}/update`),

    updatePhoto: updateEffect(`${this.namespace}/updatePhoto`),

    updatePassword: updateEffect(`${this.namespace}/updatePassword`),

    registerToken: Model.buildEffect({
      name: `${this.namespace}/registerToken`,
      query: REGISTER_NOTIFICATION_TOKEN,
    }),

    resetPasswordRequest: Model.buildEffect({
      name: `${this.namespace}/resetPasswordRequest`,
      query: RESET_PASSWORD_REQUEST,
    }),

    resetPassword: Model.buildEffect({
      name: `${this.namespace}/resetPassword`,
      query: RESET_PASSWORD,
      isSuccess: (response: any) => response.resetPassword.success,
    }),
  }

  reducers = {
    ...Model.defaultReducers,

    fetchList: commonReducers.fetchList,
  }
}
