import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { get, isEqual, isFunction } from 'lodash'
import { ListFilter as ListFilterComponent } from '@components'
import { listFilterSelector } from '@src/selectors/listFilter'
import { Sort, TypeFilter } from '@src/models/app.types'
import { LoggerComponents, logInfoWithDetails } from '@src/config/loggerWithDetails'
import { HistoryFilters, ListFilters } from '@src/types'
import { HistoryStoreState } from '@src/screens/History/models/history.types'
import { Fields, getOrderByAfterTypesChanged, getTypesAfterOrderByChanged } from './utils'

interface ListFilterOptionsProps {
  disableOrder?: boolean
  disableOrderBy?: boolean
  disableFilter?: boolean
  disableSearch?: boolean
  disableTime?: boolean
}

type ListFilterScreenProps = ListFilterOptionsProps & {
  onSaveCallback?: (data: any) => void
} & {
  model: 'history'
  filterModel?: 'meals'
}

type ListFilterState = Omit<Sort, 'startDate' | 'endDate' | 'scope' | 'types'> & {
  possibleOrderByFields: string[]
  possibleOrder: string[]
  possibleTypes: TypeFilter | string[]
  types: TypeFilter | string[]
}

export type ListFilterComponentProps = ListFilterOptionsProps &
  ListFilterState & {
    onChange: (field: string) => (value: any) => void
  }

interface Data {
  filters: {
    history: HistoryFilters
    meals: ListFilters
  }
  history: HistoryStoreState
}

const defineInitialState = (props: ListFilterScreenProps, data: Data): ListFilterState => {
  const { filterModel, model } = props
  const possibleOrderByFields = data.filters[filterModel || model]?.orderBy || []
  const possibleOrder = data.filters[filterModel || model]?.order || []
  const possibleTypes = (data.filters[filterModel || model]?.types || []) as string[]

  // sort params
  const orderBy = get(data, `${model}.sort.orderBy`)
  const order = get(data, `${model}.sort.order`)
  const types = get(data, `${model}.sort.types`)
  const query = get(data, `${model}.sort.query`, '')
  const startHour = get(data, `${model}.sort.startHour`)
  const endHour = get(data, `${model}.sort.endHour`)

  return {
    orderBy,
    order,
    types,
    query,
    startHour,
    endHour,
    possibleOrderByFields,
    possibleOrder,
    possibleTypes,
  }
}

export const ListFilterContainer = (props: ListFilterScreenProps) => {
  const {
    model,
    disableOrder,
    disableOrderBy,
    disableFilter,
    disableSearch,
    disableTime,
    onSaveCallback,
  } = props

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const data = useSelector(listFilterSelector)

  const [state, setState] = useState(() => defineInitialState(props, data))

  const onSaveSort = useCallback(() => {
    const { order, types, orderBy, query, startHour, endHour } = state

    const payload = {
      orderBy,
      order,
      types,
      query,
      startHour,
      endHour,
    }

    const sort = get(data, `${model}.sort`, {})

    if (isEqual(sort, payload)) {
      return
    }

    if (isFunction(onSaveCallback)) {
      onSaveCallback({ order, types, orderBy, query, startHour, endHour })
    }

    if (query) {
      logInfoWithDetails(LoggerComponents.ListFilter, model, query)
    }

    dispatch({
      type: `${model}/updateSort`,
      payload,
    })
  }, [data, model, onSaveCallback, state, dispatch])

  useEffect(() => {
    return navigation.addListener('blur', onSaveSort)
  }, [navigation, onSaveSort])

  const onChange = useCallback(
    (field: string) => (value: any) => {
      const newState = { ...state, [field]: value }

      if (field === Fields.OrderBy) {
        newState.types = getTypesAfterOrderByChanged(
          value,
          state.possibleTypes as TypeFilter,
          state.types as TypeFilter,
        )
      } else if (field === Fields.Types) {
        newState.orderBy = getOrderByAfterTypesChanged(value, state.orderBy)
      }

      setState(newState)
    },
    [state],
  )

  const {
    orderBy,
    order,
    types,
    query,
    startHour,
    endHour,
    possibleOrderByFields,
    possibleOrder,
    possibleTypes,
  } = state

  return (
    <ListFilterComponent
      possibleOrderByFields={possibleOrderByFields}
      possibleOrder={possibleOrder}
      possibleTypes={possibleTypes}
      orderBy={orderBy}
      order={order}
      types={types}
      query={query}
      startHour={startHour}
      endHour={endHour}
      onChange={onChange}
      disableFilter={disableFilter}
      disableSearch={disableSearch}
      disableTime={disableTime}
      disableOrderBy={disableOrderBy}
      disableOrder={disableOrder}
    />
  )
}
