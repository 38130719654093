import { BillingProduct } from '@src/types'
import { useActiveSubscriptions } from './useActiveSubscriptions'

export const MEDICAL_CONSULT_PRODUCTS = [BillingProduct.MedicalConsultAddon]

export const useHasMedicalSubscription = () => {
  const subscriptions = useActiveSubscriptions()

  return subscriptions.some((subscription) =>
    MEDICAL_CONSULT_PRODUCTS.includes(subscription.primaryProduct.key as BillingProduct),
  )
}
