import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RouteProp, useRoute } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { TabView } from '@components'
import { AppStackParamList } from '@navigation/types'
import { ActivityForm } from '@screens/Activities/components/ActivityForm'
import { ActivitiesList } from '@screens/Activities'
import { NavigationContainer } from '@screens/Common/containers'
import { hoverTime } from '@screens/Events/utils/useChartInteractions'
import { favoriteActivitiesStoreState, recentActivitiesStoreState } from '@selectors/activities'
import { useCancelModal } from '@utils/hooks'
import { useGoBack } from '@utils/navigation'
import { Activity } from '@src/types'

export const ActivityModal = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()

  const route = useRoute<RouteProp<AppStackParamList, 'AddActivity'>>()
  const { item, activityType = 'Exercise' } = route.params || {}

  const favoriteActivities = useSelector(favoriteActivitiesStoreState)
  const recentActivities = useSelector(recentActivitiesStoreState)

  const [tabIndex, setTabIndex] = useState(0)
  const [initialActivity, setInitialActivity] = useState<
    Omit<Activity, 'id'> & { id: string | undefined }
  >(item ?? ({ type: activityType, startedAt: hoverTime, endedAt: hoverTime } as Activity))
  const [isModified, setModified] = useState(false)

  // List to keep the initial favorite items. All initial favorite activities
  // should be displayed for the current session, even when they are toggled off
  const [favorites, setFavorites] = useState(favoriteActivities)

  const onItemSelected = (item: Activity) => {
    setInitialActivity({
      ...item,
      id: undefined,
      externalSource: undefined,
      startedAt: initialActivity.startedAt,
      endedAt: initialActivity.endedAt,
    })
    setTabIndex(0)
  }

  const onToggleFavorite = (item: Activity) => {
    setFavorites((prevFavorites) => {
      const isExistingFavorite = prevFavorites.some((activity) => activity.id === item.id)

      if (!isExistingFavorite) {
        return [{ ...item, favorite: true }, ...prevFavorites]
      }

      return prevFavorites.map((activity) =>
        activity.id === item.id ? { ...activity, favorite: !activity.favorite } : activity,
      )
    })

    dispatch({
      type: 'activities/updateFavorite',
      payload: {
        ...item,
        favorite: !item.favorite,
        startTime: item.startedAt,
        endTime: item.endedAt,
      },
    })
  }

  const openCancelModal = useCancelModal({
    goBack,
    isModified,
    itemName: 'activity',
  })

  const isNewActivity = !initialActivity?.id

  return (
    <NavigationContainer
      title={isNewActivity ? 'New Activity' : 'Edit Activity'}
      goBack={isNewActivity ? openCancelModal : goBack}
      leftIcon={isNewActivity ? { pack: 'eva', name: 'close' } : undefined}
    >
      <TabView
        swipeEnabled
        tabIndex={tabIndex}
        onChangeTabIndex={setTabIndex}
        routes={[
          {
            key: 'new-activity',
            title: 'New Activity',
            sceneComponent: (
              <ActivityForm
                key={JSON.stringify(initialActivity)}
                activity={initialActivity}
                onModifiedStateChange={setModified}
              />
            ),
          },
          {
            key: 'favorite-meals',
            title: 'Favorites',
            sceneComponent: (
              <ActivitiesList
                items={favorites}
                emptyListIcon={<Icon name="heart" style={styles.icon} />}
                emptyListText={
                  'Your favorite activities will appear here.\n' +
                  "Press the heart icon on the activity you'd like to favorite and it'll appear here."
                }
                onItemSelected={onItemSelected}
                onToggleFavorite={onToggleFavorite}
              />
            ),
          },
          {
            key: 'recent-meals',
            title: 'Recents',
            sceneComponent: (
              <ActivitiesList
                items={recentActivities}
                emptyListIcon={<Icon name="clock-counter-clockwise" style={styles.icon} />}
                emptyListText={
                  "Activities you're logging will appear here.\n" +
                  'Start logging your activities to get them here.'
                }
                onItemSelected={onItemSelected}
                onToggleFavorite={onToggleFavorite}
              />
            ),
          },
        ]}
      />
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  icon: {
    width: 32,
    height: 32,
  },
})
