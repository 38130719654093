import { gql } from '@apollo/client'

export const FETCH_INSURANCE_CARD_SUBMISSION = gql`
  query insuranceCardSubmission($insurancePolicyStateId: ID!) {
    insuranceCardSubmission(insurancePolicyStateId: $insurancePolicyStateId) {
      id
      insurancePolicyStateId
    }
  }
`
