import { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CommonActions, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { findLastIndex } from 'lodash'
import { useSnack } from '@utils/navigatorContext'
import { AppStackParamList, CoursesModuleParams } from '@src/navigation/types'
import { LessonWithContent } from '@src/screens/Learn/models/courses.types'
import {
  programWithContentSelector,
  moduleWithContentSelector,
  modulesWithContentSelector,
  lessonsWithContentSelector,
  publishedProgramsSelector,
  programsWithContentSelector,
} from './models/courses.selectors'

const LOADING_ERROR = 'Failed to load course; please check your internet connection'

export const useLessonCompleted = (onComplete: () => void) => {
  useEffect(() => {
    onComplete()
  }, [onComplete])
}

export const useProgram = (programId: string) => {
  const showSnack = useSnack()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const program = useSelector(programWithContentSelector(programId))

  const moduleIds = program?.modules?.map((m) => m.id) ?? []
  const modules = useSelector(modulesWithContentSelector(programId, moduleIds ?? [])) || []

  const moduleContentIds = useMemo(
    () => program?.modules?.map((m) => m.contentId) ?? [],
    // The program object can be updated when user program progress changes, but we
    // only want to update moduleIds and the featuredImage if the actual program changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [program?.id],
  )

  useEffect(() => {
    if (moduleContentIds?.length) {
      dispatch({
        type: 'courses/fetchModules',
        payload: { ids: moduleContentIds },
        failure: () => {
          showSnack(LOADING_ERROR, null, 'error')
        },
        complete: () => {
          setIsLoading(false)
        },
      })
    }
  }, [dispatch, showSnack, moduleContentIds])

  return {
    program,
    modules,
    isLoading,
  }
}

export const useModule = ({
  programId,
  moduleId,
}: Pick<CoursesModuleParams, 'programId' | 'moduleId'>) => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const [isLoading, setIsLoading] = useState(true)

  const module = useSelector(moduleWithContentSelector(programId, moduleId))

  const shouldLoadModule = !module

  useEffect(() => {
    if (shouldLoadModule) {
      dispatch({
        type: 'courses/fetchModules',
        payload: { ids: moduleId },
      })
    }
  }, [dispatch, shouldLoadModule, moduleId])

  const contentIds = useMemo(
    () => module?.lessons?.map((l) => l.contentId),
    // The module object can be updated when user module progress changes, but we
    // only want to update lessonIds if the actual module changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [module?.id],
  )

  const lessonIds = module?.lessons?.map((l) => l.id) ?? []
  const lessons = useSelector(lessonsWithContentSelector(programId, moduleId, lessonIds))

  useEffect(() => {
    if (contentIds) {
      dispatch({
        payload: { ids: contentIds },
        type: 'courses/fetchLessons',
        failure: () => {
          showSnack(LOADING_ERROR, null, 'error')
        },
        complete: () => {
          setIsLoading(false)
        },
      })
    }
  }, [contentIds, dispatch, showSnack])

  return {
    module,
    lessons,
    isLoading,
  }
}

export const useProgramsWithContent = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)

  const publishedPrograms = useSelector(publishedProgramsSelector)
  const programsWithContent = useSelector(
    programsWithContentSelector(publishedPrograms.map(({ id }) => id)),
  )

  useEffect(() => {
    dispatch({ type: 'courses/fetchCourses' })
  }, [dispatch])

  useEffect(() => {
    if (publishedPrograms.length > 0) {
      dispatch({
        type: 'courses/fetchPrograms',
        payload: { ids: publishedPrograms.map(({ contentId }) => contentId) },
        complete: () => setIsLoading(false),
      })
    }
  }, [dispatch, publishedPrograms])

  return {
    programs: programsWithContent,
    isLoading,
  }
}

export const useNextCourseItem = (lesson: LessonWithContent, moduleParams: CoursesModuleParams) => {
  const { program } = useProgram(moduleParams.programId)
  const { module, lessons } = useModule(moduleParams)

  const currentLessonPosition = lesson.position
  const currentModulePosition = module?.position

  const nextLesson = useMemo(() => {
    if (typeof currentLessonPosition !== 'number') {
      return
    }

    return lessons.find((item) => item.position === currentLessonPosition + 1)
  }, [currentLessonPosition, lessons])

  const nextModule = useMemo(() => {
    if (typeof currentModulePosition !== 'number') {
      return
    }

    return program?.modules?.find((item) => item.position === currentModulePosition + 1)
  }, [currentModulePosition, program])

  return {
    nextLesson,
    nextModule,
  }
}

// Clean up the navigation stack in order for navigation.goBack() to work as expected
export const useResetNavigationToModule = (moduleParams: CoursesModuleParams) => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const resetNavigationToModule = useCallback(() => {
    navigation.dispatch((state) => {
      const lastModuleScreenIndex = findLastIndex(
        state.routes,
        (route) => route.name === 'CoursesModule',
      )

      const routes =
        lastModuleScreenIndex === -1
          ? // Add parent routes if they don't exist
            [
              ...state.routes.slice(0, 1),
              { name: 'CoursesProgram', params: { programId: moduleParams.programId } },
              { name: 'CoursesModule', params: moduleParams },
            ]
          : // Remove all routes after the last module screen
            state.routes.slice(0, lastModuleScreenIndex + 1)

      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      })
    })
  }, [moduleParams, navigation])

  return resetNavigationToModule
}

// Check if the current lesson completes the module/program
export const useCheckModuleCompletion = (lessonId: string, moduleParams: CoursesModuleParams) => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const { program } = useProgram(moduleParams.programId)
  const { module } = useModule(moduleParams)

  const isModulePreviouslyCompleted = module?.progress?.complete
  const isModuleComplete =
    !isModulePreviouslyCompleted &&
    module?.lessons
      ?.filter((lesson) => lesson.id !== lessonId)
      .every((lesson) => lesson.progress?.complete)
  const isProgramComplete =
    isModuleComplete &&
    program?.modules
      ?.filter((item) => item.id !== module?.id)
      .every((item) => item.progress?.complete)

  const programTitle = `${program.title} - ${program.subtitle}`
  const moduleTitle = module?.title || ''
  const title = isProgramComplete ? programTitle : moduleTitle

  const startableModule = program.modules?.find(
    (programModule) => (module?.position || 0) < (programModule.position || 0),
  )
  const nextModuleId = startableModule?.id || ''

  const goToModuleCompletion = useCallback(() => {
    if (isModuleComplete || isProgramComplete) {
      navigation.navigate('CoursesModuleCompletion', {
        title,
        isProgramComplete: !!isProgramComplete,
        nextModuleParams: {
          ...moduleParams,
          moduleId: nextModuleId,
        },
      })
    }
  }, [isModuleComplete, isProgramComplete, moduleParams, navigation, nextModuleId, title])

  return {
    isModuleComplete,
    goToModuleCompletion,
  }
}

export const useNextIncompleteLesson = (programId = '') => {
  const { modules } = useProgram(programId)

  const incompleteModule = modules?.find((module) => !module.progress?.complete)
  const moduleId = incompleteModule?.id || ''

  const { lessons } = useModule({ programId, moduleId })

  return {
    lesson: lessons[0],
    parentScreenParams: {
      programId,
      moduleId,
      locked: false,
    },
  }
}
