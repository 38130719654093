import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { openUrl } from '@src/utils'
import {
  TutorialStep,
  TutorialStepActionLink,
  TutorialStepActionVideo,
  TutorialStepPlatformLink,
} from '../models/tutorials.types'
import { isPlatformLink, isTutorialLink, isTutorialVideo, platformLinkUrl } from './utils'

type StepActionProps = {
  action: TutorialStepActionLink | TutorialStepActionVideo | TutorialStepPlatformLink
  step: TutorialStep
}

export const StepAction = ({ action, step }: StepActionProps) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const { buttonText } = step
  const onPress = () => {
    if (isTutorialLink(action)) {
      openUrl(action.url)
    } else if (isPlatformLink(action)) {
      openUrl(platformLinkUrl(action))
    } else if (isTutorialVideo(action)) {
      navigation.navigate({
        name: 'VideoTutorial',
        params: {
          url: action.video.data.attributes.url,
          title: action.title,
          description: action.description,
        },
      })
    }
  }
  return (
    <Button
      type="primary"
      size="small"
      style={styles.actionButton}
      onPress={onPress}
      accessibilityLabel={buttonText}
    >
      {buttonText}
    </Button>
  )
}

const themedStyle = StyleService.create({
  actionButton: {
    marginTop: 'auto',
    alignSelf: 'flex-start',
  },
})
