import React, { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import Rate from 'react-native-rate'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationCard } from '@src/navigation/components/NavigationCard'
import { referralCreditsSelector } from '@src/selectors/app'
import { App } from '@src/config'
import { Text } from '@src/components/base'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'

export const ProfileContentGrid = () => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyles)
  const referralCredits = useSelector(referralCreditsSelector)

  const activeSubscriptions = useActiveSubscriptions()

  const subscription: typeof activeSubscriptions[0] | undefined = activeSubscriptions[0]

  const handleManageSubscriptionPress = () => {
    if (subscription?.id) {
      navigation.navigate('ProgramDetails', { subscriptionId: subscription.id })
    } else {
      navigation.navigate('Subscriptions')
    }
  }

  const handleProgramsPress = () => {
    navigation.navigate('Subscriptions')
  }

  const handleAccountPasswordPress = () => {
    navigation.navigate('ChangePassword')
  }

  const handleReferAFriendPress = () => {
    navigation.navigate('FriendReferral', { source: 'Account' })
  }

  const handleSettingsPress = () => {
    navigation.navigate('AppSettings')
  }

  const handleLeaveAReviewPress = () =>
    Rate.rate({
      AppleAppID: App.appleAppID,
      GooglePackageName: App.googlePackageName,
    })

  return (
    <View style={styles.grid}>
      <View style={styles.gridRow}>
        <NavigationCard
          title="Programs & Add-ons"
          onPress={handleProgramsPress}
          iconName="shopping-cart"
        />
        <NavigationCard
          title="Account Password"
          onPress={handleAccountPasswordPress}
          iconName="lock-simple"
        />
      </View>
      <View style={styles.gridRow}>
        <NavigationCard
          title="Manage Subscription"
          onPress={handleManageSubscriptionPress}
          iconName="credit-card"
        />
        <NavigationCard
          title="Refer a Friend"
          onPress={handleReferAFriendPress}
          pill={
            referralCredits > 0 ? (
              <View style={styles.referralPill}>
                <Text type="small" bold style={styles.pillText}>{`GET $${referralCredits}`}</Text>
              </View>
            ) : undefined
          }
          iconName="gift"
        />
      </View>
      <View style={styles.gridRow}>
        <NavigationCard title="Leave a Review" onPress={handleLeaveAReviewPress} iconName="star" />
        <NavigationCard title="Settings" onPress={handleSettingsPress} iconName="gear" />
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  grid: {
    paddingHorizontal: 16,
    marginBottom: 16,
    gap: 8,
  },
  gridRow: {
    gap: 8,
    flexDirection: 'row',
  },
  referralPill: {
    height: 24,
    borderRadius: 100,
    backgroundColor: 'theme.like',
    paddingTop: 2,
    paddingHorizontal: 12,
    marginLeft: 8,
    justifyContent: 'center',
    flexShrink: 0,
  },
  pillText: {
    color: 'theme.solid.white',
  },
  textGrow: {
    flex: 1,
  },
  optedIn: {
    marginTop: 4,
    color: 'theme.success.darkest',
  },
  optedOut: {
    marginTop: 4,
    color: 'theme.disabled.text',
  },
})
