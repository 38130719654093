import { gql } from '@apollo/client'
import { APPOINTMENT_RECURRENCE_FIELDS } from '../fragments/appointment_recurrence.ts'

export const FETCH_APPOINTMENT_RECURRENCE = gql`
  ${APPOINTMENT_RECURRENCE_FIELDS}
  query ehrAppointmentRecurrence {
    ehrAppointmentRecurrence {
      ...AppointmentRecurrenceFields
    }
  }
`
