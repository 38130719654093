import { gql } from '@apollo/client'
import { CORE_INSURANCE_POLICY_FIELDS } from '@src/graphql/fragments/insurancePolicy'

const FETCH_INSURANCE_POLICIES = gql`
  ${CORE_INSURANCE_POLICY_FIELDS}
  query insurancePolicies {
    insurancePolicies {
      insurancePolicies {
        ...CoreInsurancePolicyFields
      }
      canCreateNewPolicy
    }
  }
`

export default FETCH_INSURANCE_POLICIES
