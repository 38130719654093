import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import Snackbar from './Snackbar'

export type SnackLevel = 'success' | 'warning' | 'error'

export interface SnackContentProps {
  title: string
  content?: string | null
  level: 'success' | 'warning' | 'error'
  isVisible: boolean
  onDismiss: () => void
}

export const SnackContent = (props: SnackContentProps) => {
  const { title, content, level, isVisible, onDismiss } = props
  const styles = useStyleSheet(themedStyle)

  const indicatorStyle = () => {
    switch (level) {
      case 'warning':
        return styles.warningLevel
      case 'error':
        return styles.errorLevel
      default:
        return
    }
  }

  const action = {
    label: 'HIDE',
    onPress: onDismiss,
  }

  return (
    <Snackbar
      style={styles.snackBar}
      visible={isVisible}
      duration={Snackbar.DURATION_MEDIUM}
      onDismiss={onDismiss}
      action={action}
      testID="Snackbar"
    >
      <View style={styles.container}>
        <View style={[styles.indicator, indicatorStyle()]} />
        <View>
          <Text type="large" bold testID="Snackbar/TitleText">
            {title}
          </Text>
          {content && (
            <Text type="regular" bold style={styles.content}>
              {content}
            </Text>
          )}
        </View>
      </View>
    </Snackbar>
  )
}

const themedStyle = StyleService.create({
  snackBar: {
    backgroundColor: 'theme.background',
    padding: 8,
    borderRadius: 16,
  },
  container: {
    flexDirection: 'row',
    paddingRight: 16,
  },
  indicator: {
    width: 4,
    backgroundColor: 'theme.success.base',
    marginRight: 16,
    borderRadius: 8,
  },
  warningLevel: {
    backgroundColor: 'theme.warning.base',
  },
  errorLevel: {
    backgroundColor: 'theme.error.base',
  },
  content: {
    marginTop: 8,
  },
})
