import React, { useEffect } from 'react'
import { useRoute, RouteProp, useFocusEffect } from '@react-navigation/core'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppStackParamList } from '@src/navigation/types'
import { useSnack } from '@src/utils/navigatorContext'
import { TutorialKey } from '@screens/Education/types'
import { useVisitedTutorial } from '@screens/Education/hooks'
import { useGoBack } from '@src/utils'
import { TutorialsGroup as TutorialsGroupComponent } from '@src/screens/Tutorials/components/TutorialsGroup'
import { tutorialGroupSelector } from '@src/screens/Tutorials/models/tutorials.selectors'

const LOADING_ERROR = 'Failed to load tutorials, please check your internet connection.'

export const TutorialsGroup = () => {
  const {
    params: { group },
  } = useRoute<RouteProp<AppStackParamList, 'TutorialsGroup'>>()
  const tutorialGroup = useSelector(tutorialGroupSelector(group))
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const goBack = useGoBack()
  const styles = useStyleSheet(themedStyle)

  useVisitedTutorial(TutorialKey.CGMEducation)

  useFocusEffect(() => {
    dispatch({ type: 'tutorials/resetTutorial' })
  })

  useEffect(() => {
    dispatch({
      type: 'tutorials/fetchTutorialGroups',
      payload: { ids: [group] },
      failure: () => {
        showSnack(LOADING_ERROR, null, 'error')
        goBack()
      },
    })
  }, [dispatch, showSnack, group, goBack])

  return (
    <NavigationContainer
      title={tutorialGroup?.title || 'Loading...'}
      navigationBarProps={styles.container}
      goBack={goBack}
    >
      <View style={styles.container}>
        {tutorialGroup && <TutorialsGroupComponent tutorialGroup={tutorialGroup} />}
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
