/* GENERATED FILE */
/* to regenerate, run 'yarn generate-icon-map' */

import AddressBook from 'phosphor-react-native/src/icons/AddressBook'
import AlignLeft from 'phosphor-react-native/src/icons/AlignLeft'
import AppleLogo from 'phosphor-react-native/src/icons/AppleLogo'
import ArrowClockwise from 'phosphor-react-native/src/icons/ArrowClockwise'
import ArrowCounterClockwise from 'phosphor-react-native/src/icons/ArrowCounterClockwise'
import ArrowRight from 'phosphor-react-native/src/icons/ArrowRight'
import ArrowSquareRight from 'phosphor-react-native/src/icons/ArrowSquareRight'
import ArrowsClockwise from 'phosphor-react-native/src/icons/ArrowsClockwise'
import ArrowsLeftRight from 'phosphor-react-native/src/icons/ArrowsLeftRight'
import ArrowsOutSimple from 'phosphor-react-native/src/icons/ArrowsOutSimple'
import Avocado from 'phosphor-react-native/src/icons/Avocado'
import Barbell from 'phosphor-react-native/src/icons/Barbell'
import Barcode from 'phosphor-react-native/src/icons/Barcode'
import Bed from 'phosphor-react-native/src/icons/Bed'
import Bell from 'phosphor-react-native/src/icons/Bell'
import BellRinging from 'phosphor-react-native/src/icons/BellRinging'
import BellSimple from 'phosphor-react-native/src/icons/BellSimple'
import Bluetooth from 'phosphor-react-native/src/icons/Bluetooth'
import BookOpen from 'phosphor-react-native/src/icons/BookOpen'
import BookmarkSimple from 'phosphor-react-native/src/icons/BookmarkSimple'
import BowlFood from 'phosphor-react-native/src/icons/BowlFood'
import Cake from 'phosphor-react-native/src/icons/Cake'
import Calculator from 'phosphor-react-native/src/icons/Calculator'
import Calendar from 'phosphor-react-native/src/icons/Calendar'
import CalendarBlank from 'phosphor-react-native/src/icons/CalendarBlank'
import CalendarCheck from 'phosphor-react-native/src/icons/CalendarCheck'
import CalendarPlus from 'phosphor-react-native/src/icons/CalendarPlus'
import Camera from 'phosphor-react-native/src/icons/Camera'
import CaretDown from 'phosphor-react-native/src/icons/CaretDown'
import CaretLeft from 'phosphor-react-native/src/icons/CaretLeft'
import CaretRight from 'phosphor-react-native/src/icons/CaretRight'
import CaretUp from 'phosphor-react-native/src/icons/CaretUp'
import Carrot from 'phosphor-react-native/src/icons/Carrot'
import ChartLine from 'phosphor-react-native/src/icons/ChartLine'
import ChatCircle from 'phosphor-react-native/src/icons/ChatCircle'
import ChatCircleText from 'phosphor-react-native/src/icons/ChatCircleText'
import ChatText from 'phosphor-react-native/src/icons/ChatText'
import ChatsCircle from 'phosphor-react-native/src/icons/ChatsCircle'
import Check from 'phosphor-react-native/src/icons/Check'
import CheckCircle from 'phosphor-react-native/src/icons/CheckCircle'
import Circle from 'phosphor-react-native/src/icons/Circle'
import CircleDashed from 'phosphor-react-native/src/icons/CircleDashed'
import Clock from 'phosphor-react-native/src/icons/Clock'
import ClockCountdown from 'phosphor-react-native/src/icons/ClockCountdown'
import ClockCounterClockwise from 'phosphor-react-native/src/icons/ClockCounterClockwise'
import CloudArrowUp from 'phosphor-react-native/src/icons/CloudArrowUp'
import Command from 'phosphor-react-native/src/icons/Command'
import Confetti from 'phosphor-react-native/src/icons/Confetti'
import Copy from 'phosphor-react-native/src/icons/Copy'
import CornersOut from 'phosphor-react-native/src/icons/CornersOut'
import CreditCard from 'phosphor-react-native/src/icons/CreditCard'
import DotsSixVertical from 'phosphor-react-native/src/icons/DotsSixVertical'
import DotsThree from 'phosphor-react-native/src/icons/DotsThree'
import DotsThreeVertical from 'phosphor-react-native/src/icons/DotsThreeVertical'
import Drop from 'phosphor-react-native/src/icons/Drop'
import EnvelopeSimple from 'phosphor-react-native/src/icons/EnvelopeSimple'
import Export from 'phosphor-react-native/src/icons/Export'
import Eye from 'phosphor-react-native/src/icons/Eye'
import EyeClosed from 'phosphor-react-native/src/icons/EyeClosed'
import FacebookLogo from 'phosphor-react-native/src/icons/FacebookLogo'
import FadersHorizontal from 'phosphor-react-native/src/icons/FadersHorizontal'
import Files from 'phosphor-react-native/src/icons/Files'
import FirstAid from 'phosphor-react-native/src/icons/FirstAid'
import Fish from 'phosphor-react-native/src/icons/Fish'
import Flag from 'phosphor-react-native/src/icons/Flag'
import FlowerLotus from 'phosphor-react-native/src/icons/FlowerLotus'
import Footprints from 'phosphor-react-native/src/icons/Footprints'
import ForkKnife from 'phosphor-react-native/src/icons/ForkKnife'
import Funnel from 'phosphor-react-native/src/icons/Funnel'
import Gear from 'phosphor-react-native/src/icons/Gear'
import Gift from 'phosphor-react-native/src/icons/Gift'
import Hamburger from 'phosphor-react-native/src/icons/Hamburger'
import HandHeart from 'phosphor-react-native/src/icons/HandHeart'
import HandWaving from 'phosphor-react-native/src/icons/HandWaving'
import Heart from 'phosphor-react-native/src/icons/Heart'
import HouseSimple from 'phosphor-react-native/src/icons/HouseSimple'
import IdentificationCard from 'phosphor-react-native/src/icons/IdentificationCard'
import Image from 'phosphor-react-native/src/icons/Image'
import Info from 'phosphor-react-native/src/icons/Info'
import InstagramLogo from 'phosphor-react-native/src/icons/InstagramLogo'
import JarLabel from 'phosphor-react-native/src/icons/JarLabel'
import Lightbulb from 'phosphor-react-native/src/icons/Lightbulb'
import LightbulbFilament from 'phosphor-react-native/src/icons/LightbulbFilament'
import Lightning from 'phosphor-react-native/src/icons/Lightning'
import LightningSlash from 'phosphor-react-native/src/icons/LightningSlash'
import Link from 'phosphor-react-native/src/icons/Link'
import ListChecks from 'phosphor-react-native/src/icons/ListChecks'
import Lock from 'phosphor-react-native/src/icons/Lock'
import LockSimple from 'phosphor-react-native/src/icons/LockSimple'
import LockSimpleOpen from 'phosphor-react-native/src/icons/LockSimpleOpen'
import MagnifyingGlass from 'phosphor-react-native/src/icons/MagnifyingGlass'
import Minus from 'phosphor-react-native/src/icons/Minus'
import Moon from 'phosphor-react-native/src/icons/Moon'
import MoonStars from 'phosphor-react-native/src/icons/MoonStars'
import Note from 'phosphor-react-native/src/icons/Note'
import NotePencil from 'phosphor-react-native/src/icons/NotePencil'
import Notepad from 'phosphor-react-native/src/icons/Notepad'
import PaperPlaneTilt from 'phosphor-react-native/src/icons/PaperPlaneTilt'
import Pause from 'phosphor-react-native/src/icons/Pause'
import PauseCircle from 'phosphor-react-native/src/icons/PauseCircle'
import Pen from 'phosphor-react-native/src/icons/Pen'
import Pencil from 'phosphor-react-native/src/icons/Pencil'
import PencilLine from 'phosphor-react-native/src/icons/PencilLine'
import PencilSimple from 'phosphor-react-native/src/icons/PencilSimple'
import PencilSimpleLine from 'phosphor-react-native/src/icons/PencilSimpleLine'
import Percent from 'phosphor-react-native/src/icons/Percent'
import PersonArmsSpread from 'phosphor-react-native/src/icons/PersonArmsSpread'
import PersonSimpleBike from 'phosphor-react-native/src/icons/PersonSimpleBike'
import PersonSimpleRun from 'phosphor-react-native/src/icons/PersonSimpleRun'
import PersonSimpleSwim from 'phosphor-react-native/src/icons/PersonSimpleSwim'
import PersonSimpleWalk from 'phosphor-react-native/src/icons/PersonSimpleWalk'
import Pill from 'phosphor-react-native/src/icons/Pill'
import PintGlass from 'phosphor-react-native/src/icons/PintGlass'
import Plant from 'phosphor-react-native/src/icons/Plant'
import Play from 'phosphor-react-native/src/icons/Play'
import PlayCircle from 'phosphor-react-native/src/icons/PlayCircle'
import Plus from 'phosphor-react-native/src/icons/Plus'
import Prescription from 'phosphor-react-native/src/icons/Prescription'
import Prohibit from 'phosphor-react-native/src/icons/Prohibit'
import Pulse from 'phosphor-react-native/src/icons/Pulse'
import PushPin from 'phosphor-react-native/src/icons/PushPin'
import Question from 'phosphor-react-native/src/icons/Question'
import RadioButton from 'phosphor-react-native/src/icons/RadioButton'
import Record from 'phosphor-react-native/src/icons/Record'
import Ruler from 'phosphor-react-native/src/icons/Ruler'
import Scales from 'phosphor-react-native/src/icons/Scales'
import Scan from 'phosphor-react-native/src/icons/Scan'
import SealCheck from 'phosphor-react-native/src/icons/SealCheck'
import SealQuestion from 'phosphor-react-native/src/icons/SealQuestion'
import Share from 'phosphor-react-native/src/icons/Share'
import ShareNetwork from 'phosphor-react-native/src/icons/ShareNetwork'
import ShoppingCart from 'phosphor-react-native/src/icons/ShoppingCart'
import ShoppingCartSimple from 'phosphor-react-native/src/icons/ShoppingCartSimple'
import SignOut from 'phosphor-react-native/src/icons/SignOut'
import SlidersHorizontal from 'phosphor-react-native/src/icons/SlidersHorizontal'
import Sparkle from 'phosphor-react-native/src/icons/Sparkle'
import SpeakerLow from 'phosphor-react-native/src/icons/SpeakerLow'
import SpeakerSimpleHigh from 'phosphor-react-native/src/icons/SpeakerSimpleHigh'
import SpeakerSimpleSlash from 'phosphor-react-native/src/icons/SpeakerSimpleSlash'
import SquaresFour from 'phosphor-react-native/src/icons/SquaresFour'
import Stack from 'phosphor-react-native/src/icons/Stack'
import Star from 'phosphor-react-native/src/icons/Star'
import Steps from 'phosphor-react-native/src/icons/Steps'
import Target from 'phosphor-react-native/src/icons/Target'
import Thermometer from 'phosphor-react-native/src/icons/Thermometer'
import ThumbsDown from 'phosphor-react-native/src/icons/ThumbsDown'
import ThumbsUp from 'phosphor-react-native/src/icons/ThumbsUp'
import Timer from 'phosphor-react-native/src/icons/Timer'
import Trash from 'phosphor-react-native/src/icons/Trash'
import TrashSimple from 'phosphor-react-native/src/icons/TrashSimple'
import TrendUp from 'phosphor-react-native/src/icons/TrendUp'
import Triangle from 'phosphor-react-native/src/icons/Triangle'
import Truck from 'phosphor-react-native/src/icons/Truck'
import User from 'phosphor-react-native/src/icons/User'
import UserPlus from 'phosphor-react-native/src/icons/UserPlus'
import Users from 'phosphor-react-native/src/icons/Users'
import Video from 'phosphor-react-native/src/icons/Video'
import VideoCamera from 'phosphor-react-native/src/icons/VideoCamera'
import Warning from 'phosphor-react-native/src/icons/Warning'
import WarningCircle from 'phosphor-react-native/src/icons/WarningCircle'
import WarningOctagon from 'phosphor-react-native/src/icons/WarningOctagon'
import WaveTriangle from 'phosphor-react-native/src/icons/WaveTriangle'
import WifiX from 'phosphor-react-native/src/icons/WifiX'
import Wine from 'phosphor-react-native/src/icons/Wine'
import X from 'phosphor-react-native/src/icons/X'
import XCircle from 'phosphor-react-native/src/icons/XCircle'
import YinYang from 'phosphor-react-native/src/icons/YinYang'

export const iconMap = {
  'address-book': AddressBook,
  'align-left': AlignLeft,
  'apple-logo': AppleLogo,
  'arrow-clockwise': ArrowClockwise,
  'arrow-counter-clockwise': ArrowCounterClockwise,
  'arrow-right': ArrowRight,
  'arrow-square-right': ArrowSquareRight,
  'arrows-clockwise': ArrowsClockwise,
  'arrows-left-right': ArrowsLeftRight,
  'arrows-out-simple': ArrowsOutSimple,
  avocado: Avocado,
  barbell: Barbell,
  barcode: Barcode,
  bed: Bed,
  bell: Bell,
  'bell-ringing': BellRinging,
  'bell-simple': BellSimple,
  bluetooth: Bluetooth,
  'book-open': BookOpen,
  'bookmark-simple': BookmarkSimple,
  'bowl-food': BowlFood,
  cake: Cake,
  calculator: Calculator,
  calendar: Calendar,
  'calendar-blank': CalendarBlank,
  'calendar-check': CalendarCheck,
  'calendar-plus': CalendarPlus,
  camera: Camera,
  'caret-down': CaretDown,
  'caret-left': CaretLeft,
  'caret-right': CaretRight,
  'caret-up': CaretUp,
  carrot: Carrot,
  'chart-line': ChartLine,
  'chat-circle': ChatCircle,
  'chat-circle-text': ChatCircleText,
  'chat-text': ChatText,
  'chats-circle': ChatsCircle,
  check: Check,
  'check-circle': CheckCircle,
  circle: Circle,
  'circle-dashed': CircleDashed,
  clock: Clock,
  'clock-countdown': ClockCountdown,
  'clock-counter-clockwise': ClockCounterClockwise,
  'cloud-arrow-up': CloudArrowUp,
  command: Command,
  confetti: Confetti,
  copy: Copy,
  'corners-out': CornersOut,
  'credit-card': CreditCard,
  'dots-six-vertical': DotsSixVertical,
  'dots-three': DotsThree,
  'dots-three-vertical': DotsThreeVertical,
  drop: Drop,
  'envelope-simple': EnvelopeSimple,
  export: Export,
  eye: Eye,
  'eye-closed': EyeClosed,
  'facebook-logo': FacebookLogo,
  'faders-horizontal': FadersHorizontal,
  files: Files,
  'first-aid': FirstAid,
  fish: Fish,
  flag: Flag,
  'flower-lotus': FlowerLotus,
  footprints: Footprints,
  'fork-knife': ForkKnife,
  funnel: Funnel,
  gear: Gear,
  gift: Gift,
  hamburger: Hamburger,
  'hand-heart': HandHeart,
  'hand-waving': HandWaving,
  heart: Heart,
  'house-simple': HouseSimple,
  'identification-card': IdentificationCard,
  image: Image,
  info: Info,
  'instagram-logo': InstagramLogo,
  'jar-label': JarLabel,
  lightbulb: Lightbulb,
  'lightbulb-filament': LightbulbFilament,
  lightning: Lightning,
  'lightning-slash': LightningSlash,
  link: Link,
  'list-checks': ListChecks,
  lock: Lock,
  'lock-simple': LockSimple,
  'lock-simple-open': LockSimpleOpen,
  'magnifying-glass': MagnifyingGlass,
  minus: Minus,
  moon: Moon,
  'moon-stars': MoonStars,
  note: Note,
  'note-pencil': NotePencil,
  notepad: Notepad,
  'paper-plane-tilt': PaperPlaneTilt,
  pause: Pause,
  'pause-circle': PauseCircle,
  pen: Pen,
  pencil: Pencil,
  'pencil-line': PencilLine,
  'pencil-simple': PencilSimple,
  'pencil-simple-line': PencilSimpleLine,
  percent: Percent,
  'person-arms-spread': PersonArmsSpread,
  'person-simple-bike': PersonSimpleBike,
  'person-simple-run': PersonSimpleRun,
  'person-simple-swim': PersonSimpleSwim,
  'person-simple-walk': PersonSimpleWalk,
  pill: Pill,
  'pint-glass': PintGlass,
  plant: Plant,
  play: Play,
  'play-circle': PlayCircle,
  plus: Plus,
  prescription: Prescription,
  prohibit: Prohibit,
  pulse: Pulse,
  'push-pin': PushPin,
  question: Question,
  'radio-button': RadioButton,
  record: Record,
  ruler: Ruler,
  scales: Scales,
  scan: Scan,
  'seal-check': SealCheck,
  'seal-question': SealQuestion,
  share: Share,
  'share-network': ShareNetwork,
  'shopping-cart': ShoppingCart,
  'shopping-cart-simple': ShoppingCartSimple,
  'sign-out': SignOut,
  'sliders-horizontal': SlidersHorizontal,
  sparkle: Sparkle,
  'speaker-low': SpeakerLow,
  'speaker-simple-high': SpeakerSimpleHigh,
  'speaker-simple-slash': SpeakerSimpleSlash,
  'squares-four': SquaresFour,
  stack: Stack,
  star: Star,
  steps: Steps,
  target: Target,
  thermometer: Thermometer,
  'thumbs-down': ThumbsDown,
  'thumbs-up': ThumbsUp,
  timer: Timer,
  trash: Trash,
  'trash-simple': TrashSimple,
  'trend-up': TrendUp,
  triangle: Triangle,
  truck: Truck,
  user: User,
  'user-plus': UserPlus,
  users: Users,
  video: Video,
  'video-camera': VideoCamera,
  warning: Warning,
  'warning-circle': WarningCircle,
  'warning-octagon': WarningOctagon,
  'wave-triangle': WaveTriangle,
  'wifi-x': WifiX,
  wine: Wine,
  x: X,
  'x-circle': XCircle,
  'yin-yang': YinYang,
}
